.paragraphs-item-related-resource {
  color: $rule-color;

  .field {
    margin: 0;
  }

  .field-label {
    font-weight: normal;
  }

  a {
    border-bottom: 0;
  }
}

.related-resources {
  border: 1px solid $nav-hover;

  h2 {
    background-color: $nav-hover;
    color: #fff;
    padding: 12px;
    margin: 0;
    border: none;
    font-size: $h3-font-size;
    font-family: $myriad;
    font-weight: normal !important;
    a {
      color: #fff;
      border-bottom: none;
    }
  }

  ul {
    margin: 0;

    li {
      padding: 0 12px !important;
      text-indent: 0 !important;
      margin: 0;

      &::before {
        display: none;
      }

      .entity {
        padding: 12px 0;
        border-bottom: 1px solid $light-rule-color;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.block-book,
.block-i18n-book-navigation {
  margin: 0 0 $horiz-gutter*2 0;

  .block__title {
    position: relative;

    a {
      border-bottom: 0;
      color: $header-blue;
      font-family: $page-title-font;
      font-size: $h2-font-size;

      &.active {
        display: none;
      }
    }
  }

  > .menu {
    li, .parent-book-link {
      padding: 0 !important;
      text-indent: 0 !important;
      list-style-type: none;

      &::before {
        display: none;
      }
    }

    > li, .parent-book-link {
      border-bottom: 1px solid $rule-color;
      padding-top: $horiz-gutter !important;
      padding-bottom: 18px !important;
      margin-bottom: 0 !important;

      > a {
        color: $header-blue;
        display: block;
        margin: 0;
        border: none;
        font-size: $h4-font-size;
        font-family: $header-font;
        font-weight: normal !important;
        position: relative;
        padding-right: 10px;
      }

      &.is-collapsed,
      &.is-expanded {
        > a::after {
          content: "\2228";
          display: block;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      &.is-expanded {
        > a::after {
          transform: rotate(90deg);
        }
      }

      .menu {
        margin-top: 5px;
      }

      li, .parent-book-link {
        margin: 8px 0 0;

        a {
          color: $link-color;

          &.active {
            color: $body-font-color;
            font-weight: bold;
            border-bottom: 0;

            &::before {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 4px;
              background: $link-color;
              display: inline-block;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  @include breakpoint($tablet-portrait-min) {
    margin-bottom: 0;

    .block__title {
      position: relative;

      a {
        @include flower-rule(bottom, #fff);

        position: static;
      }
    }

    > .menu {
      width: 33.33%;
      float: left;
      padding-right: $horiz-gutter !important;
      padding-top: $horiz-gutter !important;
      border-right: 1px solid $rule-color;
    }

    + .node {
      width: 66.67%;
      float: right;
      padding-left: $horiz-gutter;
      border-left: 1px solid $rule-color;
      position: relative;
      left: -1px;

      .field-title-field,
      .field-title-field h1 {
        margin-bottom: 10px;
      }
    }
  }

  @include breakpoint($desktop-min) {
    > .menu {
      width: 25%;
    }

    + .node {
      width: 75%;
    }
  }
}

.views-course-intro-block {
  margin-bottom: $horiz-gutter*2 !important;
}

.book-navigation {
  clear: both;
  float: none;

  .menu {
    display: none;
  }

  .page-links {
    border-top: 1px solid $rule-color;
    border-bottom: 0;
    padding: $horiz-gutter 0 0;
    margin-top: $horiz-gutter*2;

    a {
      display: inline-block;
      width: auto;

      &.page-up {
        display: none;
      }
    }
  }
}

.node-quiz .book_add_child,
.book_printer {
  display: none !important;
}

.node-quiz {
  &.view-mode-longteaser {
    .field-basic-thumbnail {
      margin-bottom: $horiz-gutter;
      padding-left: 0;
      @include breakpoint($tablet-portrait-min) {
        width: 25%;
        padding-right: $horiz-gutter;
        margin-right: 0;
        margin-bottom: 20px;
        float: left;
      }

      img {
        width: 100%;
      }
    }

    @include breakpoint($tablet-portrait-min) {
      .field-description-field,
      .field-title-field {
        width: 75%;
        padding-left: 15px;
        float: left;
      }
    }
  }

  .page__title {
    border-bottom: 1px solid $rule-color;
    padding-bottom: 10px;
    margin-bottom: $horiz-gutter;
  }

  .field-basic-thumbnail {
    padding-right: 0;
    margin-right: 0;
  }

  .paragraphs-items-field-quiz-questions {
    border-top: 1px solid $rule-color;
    clear: both;
    padding-top: $horiz-gutter*2;
  }

  .btn {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;

    &:not([disabled]) {
      background: $language-button-color;
      color: #fff;

      &:hover,
      &:focus {
        background: darken($language-button-color, 10%);
      }
    }

    &[disabled] {
      &:hover,
      &:focus {
        background: $disabled-background-color;
      }
    }
  }

  @include breakpoint($tablet-portrait-min) {
    .field-basic-thumbnail {
      width: 33.33%;
      float: left;
      padding-right: $horiz-gutter;
      margin-right: $horiz-gutter;
    }
  }
}

.views-course-intro-block {
  margin-bottom: 0;
}

.views-course-intro-block .views-field-field-course-image {
  margin-bottom: 15px;
}

.views-course-intro-block .view-footer {
  margin-top: 15px;
}

.views-field-field-funder-credit {
  border: 1px solid $primary-color;
  margin-bottom: $horiz-gutter;

  img {
    padding: $horiz-gutter;
  }

  p:last-child {
    background-color: $primary-color;
    width: 100%;
    color: #fff;
    padding: $horiz-gutter;
    font-size: smaller;
  }
}

@include breakpoint($phone-min) {
  .views-course-intro-block .views-field-field-course-image {
    width: 33.33%;
    float: left;
    padding-right: $horiz-gutter;
    margin-right: $horiz-gutter;
    margin-bottom: $horiz-gutter;
  }

  .views-field-field-funder-credit {
    margin: $horiz-gutter*2 0 $horiz-gutter*3;
  }

  .views-course-intro-block .view-footer {
    overflow: visible;
  }
}

@include breakpoint($tablet-portrait-min) {
  .views-course-intro-block {
    .views-row {
      width: 100%;
      float: left;
      padding-right: $horiz-gutter;
    }

    .views-field-field-course-image {
      width: 25%;
    }

    .views-field-field-course-description {
      width: 48%;
      float: left;
      padding-right: $horiz-gutter;
    }

    .views-field-field-funder-credit {
      width: 23%;
      float: right;
      margin-top: 0;
    }

    .view-footer {
      width: 100%;
      float: right;
    }
  }

  .view-course-intro::after {
    content: "";
    display: block;
    clear: both;
  }
}

.group-basic-intro {
  .field-basic-thumbnail {
    margin-bottom: 2em !important;
  }
}

.node-basic-page .group-content {
  > .field-basic-body {
    border-top: 1px solid $rule-color;
    clear: both;
    padding-top: $horiz-gutter*2;

    > .item h2 {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.node-type-course-landing-page {
  *:first-child {
    margin-top: 0;
  }

  .field-course-body {
    .course-landing-links,
    table {
      width: 100% !important;
      border-color: transparent;

      tr {
        background-color: transparent !important;

        a {
          border-bottom: none;
        }
      }
    }
  }
}

.view-course-intro-block {
  @include flower-rule(bottom, #fff);
}

.node-type-course-landing-page {
  .sidebar {
    width: 100% !important;
    margin-left: 0;
    float: none;
    background: $callout-bgrd;
    border: 1px solid $rule-color;
    padding: $horiz-gutter;
    margin-bottom: $horiz-gutter;

    @include flower-rule-full;

    @include breakpoint($tablet-portrait-min) {
      margin-top: 30px;
      &:before {
        background-size: auto 30px;
        position: absolute;
        top: -30px;
        left: 0;
        height: 30px;
      }
    }
    @include breakpoint($tablet-portrait-max) {
      margin-top: 45px;
      &:before {
        background-size: auto 40px;
        position: absolute;
        top: -40px;
        left: 0;
        height: 40px;
      }
    }
  }
}

.field-quiz-questions > .quiz-question {
  margin-bottom: 30px;
}

.quiz-question {
  background: transparent;
  border: 0;
  padding: 0;

  .field {
    margin-bottom: 0;
  }

  .answer {
    margin-top: 10px;

    input[disabled] {
      display: none;

      & + label {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &.selected {
      label {
        background-color: $disabled-background-color;
        border-radius: 4px;
        font-weight: bold;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-right: 10px;
      }
    }

    .key {
      display: inline-block;
      font-size: 0.825em;

      strong::before {
        font-family: "Font Awesome 5 Pro";
        margin-right: 4px;
      }
    }

    &.correct {
      .key strong {
        color: #0A8023;

        &::before {
          content: "\f058";
        }
      }
    }

    &.incorrect {
      .key strong {
        color: $header-red;

        &::before {
          content: "\f057";
        }
      }
    }
  }

  &.not-answered {
    border-left: 2px solid $header-red;
    padding-left: $horiz-gutter;
  }
}

.quiz-message {
  display: none;
}

.quiz-confirmation {
  .quiz-message {
    display: block;
  }

  > * {
    background: $callout-bgrd;
    border: 1px solid $rule-color;
    padding: $horiz-gutter;
    margin-bottom: $horiz-gutter;
  }

  .btn {
    margin-right: $horiz-gutter;
  }

  .continue::after,
  .retake::before,
  .download::before {
    font-family: "Font Awesome 5 Pro";
  }

  .continue::after {
    content: "\f061";
    margin-left: 5px;
    font-weight: 300;
  }

  .download::before {
    content: "\f33d";
    margin-right: 5px;
    font-weight: 300;
  }

  .retake {
    color: $language-button-color;
    border-bottom: 0;

    &:hover,
    &:focus {
      color: darken($language-button-color, 10%);
      border-bottom: 1px dotted darken($language-button-color, 10%);
    }

    &::before {
      content: "\f0e2";
      margin-right: 5px;
      font-weight: 300;
    }
  }
}

.error-message p {
  color: $language-button-color;
  font-weight: bold;

  &::before {
    content: "\f057";
    font-family: "Font Awesome 5 Pro";
    margin-right: 5px;
  }
}

.field-content {
  position: relative;

  .image-field-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(84,84,86,0.95);
    width: 100%;
    padding: 8px $horiz-gutter;
    color: #fff;
    margin: 0;
    font-size: .8rem;
    text-align: center;
  }
}