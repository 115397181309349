@charset "UTF-8";
/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/**
  Slider
*/
.slick-slider li::before {
  all: unset;
  content: "" !important;
}

.slideshow::before, .slideshow::after {
  all: unset !important;
}

.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

.slick-slide {
  display: none;
}

.slick-active {
  display: block;
}

.slick-initialized {
  overflow: hidden;
}

/*=================================
  Previous and Next icon buttons
==================================*/
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  border: none;
  transform: translate(0, -50%);
}

.slick-prev:hover .slick-prev-icon,
.slick-prev:hover .slick-next-icon, .slick-prev:focus .slick-prev-icon,
.slick-prev:focus .slick-next-icon,
.slick-next:hover .slick-prev-icon,
.slick-next:hover .slick-next-icon,
.slick-next:focus .slick-prev-icon,
.slick-next:focus .slick-next-icon {
  opacity: 1;
}

.slick-prev:focus,
.slick-next:focus {
  top: calc(50% - 1px);
}

.slick-prev:focus .slick-prev-icon,
.slick-prev:focus .slick-next-icon,
.slick-next:focus .slick-prev-icon,
.slick-next:focus .slick-next-icon {
  color: orange;
  font-size: 28px;
  margin-left: -2px;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  cursor: default;
}

.slick-prev.slick-disabled .slick-prev-icon,
.slick-prev.slick-disabled .slick-next-icon,
.slick-next.slick-disabled .slick-prev-icon,
.slick-next.slick-disabled .slick-next-icon {
  opacity: 0.25;
}

.slick-prev .slick-prev-icon,
.slick-prev .slick-next-icon,
.slick-next .slick-prev-icon,
.slick-next .slick-next-icon {
  display: block;
  color: black;
  opacity: 0.75;
  font-family: "slick";
  font-size: 24px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev .slick-prev-icon:before {
  content: "←";
}

[dir="rtl"] .slick-prev .slick-prev-icon:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next .slick-next-icon:before {
  content: "→";
}

[dir="rtl"] .slick-next .slick-next-icon:before {
  content: "←";
}

/*==========================
  Slide navigation dots
===========================*/
.slick-slider {
  margin-bottom: 30px;
}

.slick-slider.slick-dotted {
  margin-bottom: 60px;
}

.slick-dots {
  position: absolute;
  bottom: -30px;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li::before {
  content: "";
}

.slick-dots li button {
  display: block;
  height: 20px;
  width: 20px;
  margin-top: -4px;
  margin-left: -4px;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.slick-dots li button:hover .slick-dot-icon, .slick-dots li button:focus .slick-dot-icon {
  opacity: 1;
}

.slick-dots li button:focus .slick-dot-icon:before {
  color: orange;
}

.slick-dots li button .slick-dot-icon {
  color: black;
  opacity: 0.25;
}

.slick-dots li button .slick-dot-icon:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  font-family: "slick";
  font-size: 12px;
  line-height: 1;
  text-align: center;
  transition: all .05s linear;
}

.slick-dots li.slick-active button:focus .slick-dot-icon {
  color: orange;
  opacity: 1;
}

.slick-dots li.slick-active button .slick-dot-icon {
  color: black;
  opacity: 1;
}

.slick-dots li.slick-active button .slick-dot-icon:before {
  margin-top: -3px;
  margin-left: -2px;
  font-size: 18px;
}

/**
 Improved .sr-only class by ffoodd: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
 */
.slick-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/*===========================
  Pause/play icon button
============================*/
.slick-autoplay-toggle-button {
  position: absolute;
  left: 5px;
  bottom: -32px;
  z-index: 10;
  opacity: 0.75;
  background: none;
  border: 0;
  cursor: pointer;
  color: black;
}

.slick-autoplay-toggle-button:hover, .slick-autoplay-toggle-button:focus {
  opacity: 1;
}

.slick-autoplay-toggle-button:focus {
  color: orange;
}

.slick-autoplay-toggle-button .slick-pause-icon:before {
  content: "⏸";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}

.slick-autoplay-toggle-button .slick-play-icon:before {
  content: "▶";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-slider {
  background: #00529b;
  padding-bottom: 30px;
}

.slick-slider * {
  box-sizing: border-box;
}

.slick-slider .node-slide {
  max-width: 100%;
  width: 100%;
  text-align: left;
}

.slick-slider .node-slide .node-title {
  display: inline-block;
  margin: 0 15px;
}

.slick-slider .slick-slide img {
  width: 100%;
  margin: 0;
}

.slick-slider .slick-slide h3 {
  color: #ffffff;
}

.slick-slider .slick-slide h3 a {
  color: #ffffff !important;
}

.slick-slider .slick-slide h3 a:hover {
  text-decoration: underline;
}

.slick-slider .slick-dots {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  text-align: center;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 10px;
}

.slick-slider .slick-dots .slick-active button {
  background: #48a8db;
}

.slick-slider .slick-dots li {
  all: unset;
  list-style-type: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.slick-slider .slick-dots li::before {
  all: unset;
}

.slick-slider .slick-dots button {
  all: unset;
  background: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 12px solid #00529b;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-indent: -9999px;
}

.slick-slider .slick-dots button:hover {
  background: #F9D270;
  cursor: pointer;
}

.slick-slider .slick-dots button:focus {
  background: #48a8db;
}

.slick-slider .slick-dots button[aria-selected="true"] {
  background: #00529b;
}

.slick-slider .slick-arrow {
  position: absolute;
  bottom: 0;
  z-index: 3;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: left;
  text-indent: -9999px;
  margin-top: 10px;
  background: #646464;
  border: 1px solid #eaeaea;
  transition: all 0.2s ease;
}

.slick-slider .slick-arrow::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
}

.slick-slider .slick-arrow.slick-prev {
  left: 0;
  margin-left: 10px;
}

.slick-slider .slick-arrow.slick-prev::after {
  border-bottom: 2px solid #222222;
  border-left: 2px solid #222222;
  left: 12px;
}

.slick-slider .slick-arrow.slick-next {
  right: 0;
  margin-right: 10px;
}

.slick-slider .slick-arrow.slick-next::after {
  border-top: 2px solid #222222;
  border-right: 2px solid #222222;
  right: 12px;
}

.slick-slider .slick-arrow:hover, .slick-slider .slick-arrow:focus {
  background: #B7531C;
  border-color: transparent;
}

.slick-slider .slick-arrow:hover::after, .slick-slider .slick-arrow:focus::after {
  border-color: white;
}

.slick-slider .slick-arrow.slick-disabled {
  pointer-events: none;
  border: 1px solid #646464 !important;
  background: #eaeaea !important;
}

.slick-slider .slick-arrow.slick-disabled::after {
  border-color: #646464 !important;
}

/* @partial normalize */
/* HTML5 display definitions
   ========================================================================== */
/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */
audio,
canvas,
video {
  display: inline-block;
}

/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */
[hidden] {
  display: none;
}

/* Address styling not present in IE 8/9, Safari 5, and Chrome. */
abbr[title] {
  border-bottom: 1px dotted;
}

/* Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome. */
b,
strong {
  font-weight: bold;
}

/* Address styling not present in Safari 5 and Chrome. */
dfn {
  font-style: italic;
}

/* Address differences between Firefox and other browsers. */
hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  border: 1px solid #666;
  padding-bottom: -1px;
  margin: 36px 0;
}

/* Address styling not present in IE 8/9. */
mark {
  background: #ff0;
  color: #000;
}

/* Correct font family set oddly in Safari 5 and Chrome. */
code,
kbd,
pre,
samp,
tt,
var {
  font-family: monospace, "Courier New", "DejaVu Sans Mono", sans-serif;
  font-size: 1em;
  line-height: 1.5em;
}

/* Improve readability of pre-formatted text in all browsers. */
pre {
  white-space: pre-wrap;
}

/* Set consistent quote types. */
q {
  quotes: "“" "”" "‘" "’";
}

/* Address inconsistent and variable font size in all browsers. */
small {
  font-size: 80%;
}

/* Prevent `sub` and `sup` affecting `line-height` in all browsers. */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Links
   ========================================================================== */
/*
 * The order of link states are based on Eric Meyer's article:
 * http://meyerweb.com/eric/thoughts/2007/06/11/who-ordered-the-link-states
 */
a {
  color: #B7531C;
  text-decoration: none;
  -webkit-transition-property: compact(color, false, false, false, false, false, false, false, false, false);
  -moz-transition-property: compact(color, false, false, false, false, false, false, false, false, false);
  -o-transition-property: compact(color, false, false, false, false, false, false, false, false, false);
  transition-property: compact(color, false, false, false, false, false, false, false, false, false);
  -webkit-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -moz-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -o-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -webkit-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  -moz-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  -o-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  border-bottom: 1px dotted #B7531C;
}

a:visited {
  color: #D8500B;
  text-decoration: none;
}

a:hover {
  color: #8b3f15;
  text-decoration: none;
  /* ie8 */
  cursor: pointer;
}

a:focus {
  color: #8b3f15;
  text-decoration: none;
  /* ie8 */
}

a:active {
  color: #8b3f15;
  text-decoration: none;
}

/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: thin dotted;
}

/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: thin dotted;
}

/*
 * Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test
 */
a:hover,
a:active {
  outline: 0;
}

/* Input placeholder color
  ========================================================================== */
::-webkit-input-placeholder {
  color: #898888;
}

::-webkit-input-placeholder:focus {
  color: #eae8e8;
}

:focus::-webkit-input-placeholder {
  color: #eae8e8;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #898888;
}

:-moz-placeholder:focus {
  color: #eae8e8;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #898888;
}

::-moz-placeholder:focus {
  color: #eae8e8;
}

:-ms-input-placeholder {
  color: #898888;
}

:-ms-input-placeholder:focus {
  color: #eae8e8;
}

/* Text highlight color
  ========================================================================== */
::selection {
  background: #B7531C;
  /* Safari */
  color: white;
}

::-moz-selection {
  background: #B7531C;
  /* Firefox */
  color: white;
}

/* Lists
   ========================================================================== */
/*
 * Addresses margins set differently in IE6/7
 */
dl,
menu,
ol,
ul {
  margin: 12px 0 18px;
}

#content ul:not(.inline):not(.slick-dots) {
  list-style-type: none;
  padding: 0;
}

#content ul:not(.inline):not(.slick-dots) li {
  text-indent: -46px;
  list-style-position: inside;
  padding-left: 27px;
}

#content ul:not(.inline):not(.slick-dots) li:before {
  content: "•";
  color: #99003a;
  padding-right: 8px;
  margin-left: 30px;
}

#content ol li:before {
  display: none;
}

ul.links li, ul.tabs li {
  text-indent: 0 !important;
  padding-left: 0 !important;
}

ul.links li:before, ul.tabs li:before {
  display: none !important;
}

ul.links li {
  margin-left: 12px;
}

ul.links.inline li {
  margin-left: 0 !important;
}

ul.pager li {
  padding-left: 0 !important;
}

ul.contextual-links li,
ul.krumo-node li {
  text-indent: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

ul.contextual-links li:before,
ul.krumo-node li:before {
  display: none !important;
}

ul li p {
  text-indent: 0;
}

ul.subheads li {
  font-weight: 700;
}

ul.subheads li p {
  font-weight: 300;
  padding-left: 2px;
}

.sidebar .node-title-list li,
.group-sidebar .node-title-list li {
  text-indent: 0 !important;
  padding-left: 0 !important;
}

.sidebar .node-title-list li:before,
.group-sidebar .node-title-list li:before {
  display: none;
}

ul.inline {
  /* List of links generated by theme_links(). @Drupal override */
  display: inline;
  padding: 0;
}

ul.inline li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
  /* LTR */
}

.item-list ul li {
  /* @Drupal override */
  margin: 0;
}

dd {
  margin: 0 0 0 31.5px;
  /* LTR */
}

ul ul,
ul ol,
ol ul,
ol ol,
.item-list ul ul,
.item-list ul ol,
.item-list ol ul,
.item-list ol ol {
  margin: 0;
  /* Turn off margins on nested lists. */
}

/*
 * Addresses paddings set differently in IE6/7
 */
menu,
ol,
ul,
.item-list ul {
  padding: 0 0 0 31.5px;
  /* LTR */
}

menu li,
ol li,
ul li,
.item-list ul li {
  margin-bottom: 7px;
}

/*
 * Corrects list images handled incorrectly in IE7
 */
nav ul,
nav ol, .menu-block-wrapper, ul.links, ul.menu {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

/* Embedded content
   ========================================================================== */
/**
 * Embedded content and figures
 *
 * @todo Look into adding responsive embedded video.
 */
img {
  /* Remove border when inside `a` element in IE 8/9. */
  border: 0;
  /* Suppress the space beneath the baseline */
  vertical-align: bottom;
  /* Responsive images */
  max-width: 100%;
  height: auto;
}

.ie8 img {
  max-width: none;
  /* ie8 needs "width: auto;" and "max-width: none;" for ie8 only */
  width: auto;
}

/* Responsive video embed. Youtube / Vimeo */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
 * Corrects overflow displayed oddly in IE9
 */
svg:not(:root) {
  overflow: hidden;
}

/* Figures
   ========================================================================== */
/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */
figure {
  margin: 0;
}

/* Accessibility features
 ========================================================================== */
/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
.element-focusable,
.navigation .block-menu .block__title,
.navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Turns off the element-invisible effect. */
.element-focusable:active, .element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

/* @partial typography */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 * 3. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 */
html {
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  /* 1 */
  font-size: 14px;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  line-height: 21px;
}

/*
 * Addresses margins handled incorrectly in IE6/7
 */
body {
  margin: 0;
  padding: 0;
  color: #000000;
}

/* =============================================================================
   Typography

   To achieve a pleasant vertical rhythm, we use Compass' Vertical Rhythm mixins
   so that the line height of our base font becomes the basic unit of vertical
   measurement. We use multiples of that unit to set the top and bottom margins
   for our block level elements and to set the line heights of any fonts.
   For more information, see http://24ways.org/2006/compose-to-a-vertical-rhythm
   ========================================================================== */
/*  Header Styles
   ========================================================================== */
h1 {
  font-size: 36px;
  font-size: 2.5714285714rem;
  line-height: 1.225;
  margin-bottom: 21px;
  margin-bottom: 1.5rem;
  margin-top: 42px;
  margin-top: 3rem;
}

h2 {
  font-size: 28px;
  font-size: 2rem;
  line-height: 1.275;
  margin-bottom: 21px;
  margin-bottom: 1.5rem;
  margin-top: 42px;
  margin-top: 3rem;
}

h3 {
  font-size: 22px;
  font-size: 1.5714285714rem;
  line-height: 1.2;
  margin-bottom: 10.5px;
  margin-bottom: 0.75rem;
  margin-top: 21px;
  margin-top: 1.5rem;
  font-weight: 700;
}

h4,
h5,
h6 {
  font-size: 18px;
  font-size: 1.2857142857rem;
  line-height: 1.275;
  margin-bottom: 7.5px;
  margin-bottom: 0.5357142857rem;
  margin-top: 21px;
  margin-top: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
}

h1,
h2 {
  font-family: "lemonde-journal", georgia, serif;
  font-weight: 300;
}

h3,
h4 {
  font-family: "myriad-pro", verdana, sans-serif;
}

h3 {
  font-weight: 300;
}

h4 {
  font-weight: 300;
}

h1 a,
h2 a,
h3 a,
h4 a {
  font-weight: 300;
  border-bottom: none;
}

h5,
h6 {
  font-family: "proxima-nova-alt", verdana, sans-serif;
}

h1,
h2,
h5,
h6 {
  font-weight: normal;
}

h1 a,
h2 a,
h5 a,
h6 a {
  font-weight: 400;
}

h1,
h2,
h3,
h4 {
  color: #00539B;
}

h1 a,
h2 a,
h3 a,
h4 a {
  color: #00539B !important;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover {
  color: #003868 !important;
}

/*  Drupal Titles
   ========================================================================== */
.page__title {
  margin-top: 0;
}

.field-label {
  font-weight: bold;
  font-size: 1em;
}

h3.field-label {
  margin-bottom: 0.25em;
}

/*  Misc
   ========================================================================== */
/* Thematic break */
hr {
  height: 1px;
  border: 1px solid #eaa078;
  padding-bottom: -1px;
  margin: 36px 0;
}

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */
abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
  white-space: nowrap;
}

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
*/
b,
strong {
  font-weight: bold;
}

blockquote {
  /* Sets 1 unit of vertical rhythm on the top and bottom margin. */
  margin-left: 40px;
}

/*
 * Addresses styling not present in S5, Chrome
 */
dfn {
  font-style: italic;
}

/*
 * Addresses styling not present in IE6/7/8/9
 */
mark {
  background: #ff0;
  color: #000;
}

/*
 * Addresses margins set differently in IE6/7
 */
pre {
  /* Sets 1 unit of vertical rhythm on the top and bottom margin. */
  margin: 3em 0;
}

p {
  margin: 0;
}

p + p {
  margin-top: 18px;
}

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */
pre,
code,
kbd,
samp,
tt,
var {
  font-family: monospace, "Courier New", "DejaVu Sans Mono", sans-serif;
  /* The $font-monospace value ends with ", serif". */
}

p pre,
p code,
p kbd,
p samp,
p tt,
p var {
  margin: 0;
  padding: 0;
  color: #000000;
  background: transparent;
}

/*
 * Improves readability of pre-formatted text in all browsers
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */
/* 1 */
q {
  quotes: none;
}

/* 2 */
q::before,
q::after {
  content: "";
  content: none;
}

/* @todo Determine how big element affects vertical rhythm. */
small {
  font-size: 75%;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* WYSIWYG / BASIC PAGE FIELD CSS */
.wys-divider {
  height: 0;
  border-bottom: 1px solid #cccccc;
  margin: 36px 0;
}

.wys-intro-title {
  font-weight: bold;
}

.wys-intro-text {
  font-weight: normal;
}

.wys-section-title {
  font-weight: bold;
}

.wys-block-title {
  font-weight: bold;
  color: black;
}

h4.toggle-accordion {
  border-top: 2px solid #1E7CBC;
  cursor: pointer;
  padding-top: 0.5em;
  padding-right: 50px;
}

h4.toggle-accordion.accordion-hide {
  background: url("../images/accordion-arrow.png") no-repeat right 14px;
  background-size: 12px auto;
}

h4.toggle-accordion.accordion-show {
  background: url("../images/accordion-arrow.png") no-repeat right bottom 5px;
  background-size: 12px auto;
}

.columns {
  overflow: hidden;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

@media (min-width: 48em) {
  .columns .column {
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
  .columns .column.half {
    width: 50%;
    float: left;
  }
  .columns .column.third {
    width: 33.33%;
    float: left;
  }
}

@media (min-width: 64em) {
  .columns .column.quarter {
    width: 25%;
    float: left;
  }
}

@media (max-width: 63.9375em) and (min-width: 48em) {
  .columns .column.quarter {
    width: 50%;
    float: left;
  }
}

.callout,
.callout-left,
.group-page-intro-inner .sidebar,
.field-article-body .sidebar,
.field-basic-body .sidebar,
.group-blog-body .sidebar,
.cke_editable .sidebar,
.podcastbox {
  background-color: #FBF6E4;
  border: 1px solid #646464;
  position: relative;
  left: 0;
  padding: 15px;
  margin-bottom: 18px;
}

.callout *:first-child,
.callout-left *:first-child,
.group-page-intro-inner .sidebar *:first-child,
.field-article-body .sidebar *:first-child,
.field-basic-body .sidebar *:first-child,
.group-blog-body .sidebar *:first-child,
.cke_editable .sidebar *:first-child,
.podcastbox *:first-child {
  margin-top: 0;
}

.callout::before,
.callout-left::before,
.group-page-intro-inner .sidebar::before,
.field-article-body .sidebar::before,
.field-basic-body .sidebar::before,
.group-blog-body .sidebar::before,
.cke_editable .sidebar::before,
.podcastbox::before {
  background: url("../images/flower-separator-full.png") no-repeat bottom center;
  content: "";
  display: block;
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  height: 40px;
}

.callout h2::before, .callout h2::after,
.callout-left h2::before,
.callout-left h2::after,
.group-page-intro-inner .sidebar h2::before,
.group-page-intro-inner .sidebar h2::after,
.field-article-body .sidebar h2::before,
.field-article-body .sidebar h2::after,
.field-basic-body .sidebar h2::before,
.field-basic-body .sidebar h2::after,
.group-blog-body .sidebar h2::before,
.group-blog-body .sidebar h2::after,
.cke_editable .sidebar h2::before,
.cke_editable .sidebar h2::after,
.podcastbox h2::before,
.podcastbox h2::after {
  display: none !important;
}

.group-page-intro-inner .sidebar,
.field-article-body .sidebar,
.field-basic-body .sidebar,
.group-blog-body .sidebar,
.cke_editable .sidebar {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 45px;
}

.group-page-intro-inner .sidebar::before,
.field-article-body .sidebar::before,
.field-basic-body .sidebar::before,
.group-blog-body .sidebar::before,
.cke_editable .sidebar::before {
  background-size: auto 40px;
}

.callout,
.podcastbox {
  clear: right;
}

@media (min-width: 48em) {
  .callout,
  .podcastbox {
    margin-top: 30px;
    width: 33.33%;
    float: right;
    margin-left: 30px;
  }
  .callout::before,
  .podcastbox::before {
    background-size: auto 30px;
    position: absolute;
    top: -30px;
    left: 0;
    height: 30px;
  }
}

@media (max-width: 47.9375em) {
  .callout,
  .podcastbox {
    margin-top: 45px;
  }
  .callout::before,
  .podcastbox::before {
    background-size: auto 40px;
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
  }
}

.callout-left {
  clear: right;
}

@media (min-width: 48em) {
  .callout-left {
    margin-top: 30px;
    width: 33.33%;
    float: left;
    margin-right: 30px;
    margin-bottom: 0px;
  }
  .callout-left::before {
    background-size: auto 30px;
    position: absolute;
    top: -30px;
    left: 0;
    height: 30px;
  }
}

@media (max-width: 47.9375em) {
  .callout-left {
    margin-top: 45px;
  }
  .callout-left::before {
    background-size: auto 40px;
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
  }
}

hr {
  border: none;
  position: relative;
  overflow: visible;
}

hr::before {
  content: "";
  display: block;
  width: 38px;
  height: 50px;
  margin: 0 auto;
  background: url("../images/flower-separator.png") #fff no-repeat center;
  background-size: 30px auto;
  position: relative;
  top: -25px;
  z-index: 5;
}

hr::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #646464;
  position: absolute;
  top: 0;
}

ul.black-bullet-list {
  list-style-type: disc !important;
  padding-left: 27px !important;
}

ul.black-bullet-list li {
  list-style-type: disc;
  text-indent: -14px !important;
  padding-left: 0 !important;
}

ul.black-bullet-list li::before {
  display: none;
}

.wys-intro-container .wys-intro {
  color: #000000;
}

.wys-intro-container hr {
  clear: both;
  margin: 36px 0 15px;
}

.wys-image-caption {
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: 15px;
  position: relative;
}

.wys-image-caption .caption {
  margin-top: 5px;
  line-height: 1.5;
}

.wys-image-caption.wys-image-caption-left {
  width: 33.33%;
  float: left;
  margin-right: 15px;
  padding-right: 15px;
}

.wys-image-caption.wys-image-caption-right {
  width: 33.33%;
  float: right;
  margin-left: 15px;
  padding-left: 15px;
}

.wys-image-caption.variable-width-image {
  width: auto;
}

.wys-image-caption.variable-width-image .caption {
  width: min-content;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  margin: 0;
  padding: 5px 10px;
}

.wys-image-caption.variable-width-image a {
  color: #fff;
  border-bottom-color: #fff;
}

.wys-image-caption.variable-width-image a:hover, .wys-image-caption.variable-width-image a:focus {
  border-bottom-color: transparent;
}

.wys-image-caption.variable-width-image img {
  min-width: 44px;
  min-height: 44px;
  background: #f7f7f7;
  height: auto !important;
  float: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.primary-button {
  background-color: #00539B;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
  width: auto;
  margin-top: 15px;
}

.primary-button:hover {
  text-decoration: none;
  background-color: #003868;
}

.primary-button:active, .primary-button:focus {
  text-decoration: none;
  background-color: #003868;
}

a.primary-button:hover {
  color: #fff;
}

.textformatter-separator {
  margin-right: 5px;
}

/* @partial page-layout */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 48em) {
  #main,
  #header,
  #highlighted-inner,
  #footer,
  .region-bottom,
  .container,
  #block-views-featured-posts-block .views-row,
  #block-views-testimonials-block .view-testimonials {
    margin-left: auto;
    margin-right: auto;
    max-width: 999px;
  }
}

@media (max-width: 47.9375em) {
  #main,
  #header,
  #highlighted-inner,
  #footer,
  .region-bottom,
  .container,
  #block-views-featured-posts-block .views-row,
  #block-views-testimonials-block .view-testimonials {
    margin-left: auto;
    margin-right: auto;
    max-width: 660px;
  }
}

#page {
  min-width: 320px;
}

.header__region,
#highlighted-inner,
.no-sidebars #content,
#footer {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.sidebar-second #content,
.region-sidebar-first {
  padding-left: 15px;
  padding-right: 30px;
}

.sidebar-first #content,
.region-sidebar-second {
  padding-left: 30px;
  padding-right: 15px;
}

.block {
  margin-bottom: 36px;
  margin-top: 0;
}

.field {
  margin-bottom: 18px;
  margin-top: 0;
}

/*
 * Containers for grid items and flow items.
 */
#header:before, #header:after,
#main:before,
#main:after,
.region-highlighted:before,
.region-highlighted:after {
  content: "";
  display: table;
}

#header:after,
#main:after,
.region-highlighted:after {
  clear: both;
}

/*
 * The layout when there is only one sidebar, the left one.
 */
@media (min-width: 37.5em) and (max-width: 59.9375em) {
  .sidebar-first #content {
    width: 66.6666666667%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .sidebar-first .region-sidebar-first {
    width: 33.3333333333%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media (min-width: 60em) {
  .sidebar-first #content {
    width: 75%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
    padding-left: 15px;
    padding-right: 7.5px;
  }
  .sidebar-first .region-sidebar-first {
    width: 25%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
    padding-left: 7.5px;
    padding-right: 15px;
  }
}

/*
 * The layout when there is only one sidebar, the right one.
 */
@media (min-width: 37.5em) and (max-width: 59.9375em) {
  .sidebar-second #content {
    width: 66.6666666667%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .sidebar-second .region-sidebar-second {
    width: 33.3333333333%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media (min-width: 60em) {
  .sidebar-second #content {
    width: 75%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
    padding-left: 7.5px;
    padding-right: 15px;
  }
  .sidebar-second .region-sidebar-second {
    width: 25%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
    padding-left: 15px;
    padding-right: 7.5px;
  }
}

/*
 * The layout when there are two sidebars.
 */
@media (min-width: 37.5em) and (max-width: 59.9375em) {
  .two-sidebars #content {
    width: 66.6666666667%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .two-sidebars .region-sidebar-first {
    width: 33.3333333333%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .two-sidebars .region-sidebar-second {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media (min-width: 60em) {
  .two-sidebars #content {
    width: 50%;
    float: left;
    margin-right: -100%;
    margin-left: 25%;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .two-sidebars .region-sidebar-first {
    width: 25%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .two-sidebars .region-sidebar-second {
    width: 25%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media (max-width: 47.9375em) {
  .region-bottom {
    padding: 0 7.5px;
  }
}

/* Debug wireframes. Toggled at admin/appearance settings.
   ========================================================================== */
.with-wireframes #header,
.with-wireframes #main,
.with-wireframes #content,
.with-wireframes #navigation,
.with-wireframes .region-sidebar-first,
.with-wireframes .region-sidebar-second,
.with-wireframes #footer,
.with-wireframes .region-bottom {
  outline: 1px solid #ccc;
}

/* @partial page-theme */
/* Body
   ========================================================================== */
body {
  background-color: #ffffff;
}

@media (min-width: 48em) {
  .float {
    float: left;
  }
}

@media (max-width: 47.9375em) {
  .float {
    margin: 0 auto;
  }
}

#name-and-slogan h1 {
  display: none;
}

#name-and-slogan #site-slogan {
  font-family: "proxima-nova-alt", verdana, sans-serif;
  position: relative;
  top: -47px;
  color: #888;
  padding-left: 4px;
  font-size: 13.65px;
  margin-bottom: -40px;
}

@media (min-width: 48em) {
  #name-and-slogan {
    display: block !important;
  }
}

@media (max-width: 47.9375em) {
  #name-and-slogan {
    display: none !important;
  }
}

#higlighted-wrapper {
  background-color: #F9D270;
  color: #00539B;
  font-size: 15px;
  line-height: 26px;
}

#higlighted-wrapper h1.page__title {
  margin-top: 0;
}

#higlighted-wrapper .breadcrumb {
  padding: 15px 0;
  margin-bottom: 0;
}

#higlighted-wrapper .region-highlighted {
  position: relative;
}

#higlighted-wrapper .region-highlighted:before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

@media print {
  #higlighted-wrapper .region-highlighted:before {
    display: none;
  }
}

#higlighted-wrapper .region-highlighted:after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator-split.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 30px;
  background-color: transparent;
  margin: 0 auto 30px;
  height: 40px;
  width: 50px;
  position: relative;
  bottom: -20px;
  z-index: 10;
}

@media print {
  #higlighted-wrapper .region-highlighted:after {
    display: none;
  }
}

.node-type-l3-page #landing-page-intro {
  position: relative;
}

.node-type-l3-page #landing-page-intro:before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

@media print {
  .node-type-l3-page #landing-page-intro:before {
    display: none;
  }
}

.node-type-l3-page #landing-page-intro:after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

@media print {
  .node-type-l3-page #landing-page-intro:after {
    display: none;
  }
}

/* Header
   ========================================================================== */
@media (min-width: 48em) {
  .background-1 #header-wrapper {
    background-image: url("../images/header1.jpg");
  }
  .background-2 #header-wrapper {
    background-image: url("../images/header2.jpg");
  }
  .background-3 #header-wrapper {
    background-image: url("../images/header3.jpg");
  }
}

#header-wrapper a {
  border-bottom: none;
}

@media (min-width: 48em) {
  #header-wrapper {
    background-repeat: no-repeat;
    background-position: top right;
    background-color: #fff;
    background-size: auto 200px;
  }
}

@media (min-width: 64em) {
  #header-wrapper {
    background-position: top right;
  }
}

@media (max-width: 63.9375em) and (min-width: 48em) {
  #header-wrapper {
    background-position: top right;
  }
}

#header {
  position: relative;
}

@media (min-width: 48em) {
  #header {
    height: 200px;
  }
}

#header .header__region {
  overflow: hidden;
  position: relative;
}

#mobile-translate {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 48em) {
  #mobile-translate {
    display: none;
  }
}

#mobile-translate a {
  width: 50%;
  display: block;
  float: left;
}

.spanish,
.english {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  text-align: center;
  font-size: 1.1em;
}

.spanish:hover, .spanish:visited,
.english:hover,
.english:visited {
  color: #fff;
}

.spanish {
  background-color: #B30425;
}

.english {
  background-color: #78458D;
}

#logo {
  margin: 0;
  padding: 0;
  display: block;
  color: #555;
  clear: both;
  position: relative;
  z-index: 99;
}

#logo img {
  width: 100%;
  max-width: 470px;
  height: auto;
}

#logo p {
  font-style: italic;
  margin-top: 10px;
}

@media (min-width: 48em) {
  #logo {
    padding: 8px 0 22px;
    width: 50%;
  }
}

@media (max-width: 47.9375em) and (min-width: 30em) {
  #logo {
    padding: 15px 0 10px;
    width: 300px;
  }
}

@media (max-width: 29.9375em) {
  #logo {
    background: url("../images/logo-mobile.png") no-repeat center;
    background-size: 100% auto;
    padding: 16px 0 13px;
    width: 200px;
    height: 90px;
  }
  #logo img {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.secondary-menu {
  float: right;
}

#search-block-form {
  position: relative;
  text-align: right;
}

@media (min-width: 64em) {
  #search-block-form {
    padding: 145px 0 19px;
    width: 999px;
    margin: 0 auto;
  }
  #search-block-form input[type="text"] {
    position: absolute;
    right: 0;
  }
  #search-block-form input[type="submit"] {
    height: 35px;
    position: relative;
    top: 1px;
    right: 0;
  }
}

@media (max-width: 63.9375em) and (min-width: 48em) {
  #search-block-form {
    padding: 90px 0 18px;
  }
  #search-block-form input[type="text"] {
    position: absolute;
    right: 7px;
  }
  #search-block-form input[type="submit"] {
    height: 35px;
    position: relative;
    top: 1px;
    right: -4px;
  }
}

@media (max-width: 47.9375em) {
  #search-block-form {
    padding: 0;
    float: right;
    width: 75%;
  }
  #search-block-form input[type="text"] {
    position: absolute;
    right: 0;
  }
  #search-block-form input[type="submit"] {
    height: 33px;
    position: relative;
    top: 1px;
    right: 0;
  }
}

#search-block-form input[type="text"] {
  text-align: left;
  border: 1px solid #00539B;
  background-color: #fff;
}

@media (min-width: 48em) {
  #search-block-form input[type="text"] {
    width: 263px;
    padding: 10px 50px 10px 20px;
  }
}

@media (max-width: 47.9375em) {
  #search-block-form input[type="text"] {
    width: 100%;
  }
}

#search-block-form input[type="submit"] {
  background: url("../images/search.png") #fff no-repeat;
  background-size: 24px auto;
  width: 40px;
  box-shadow: none;
  border: none;
  text-indent: -9999px;
}

@media (min-width: 48em) {
  #search-block-form input[type="submit"] {
    background-position: center;
  }
}

@media (max-width: 47.9375em) {
  #search-block-form input[type="submit"] {
    background-position: center;
  }
}

#views-exposed-form-search-page-page {
  position: relative;
  text-align: right;
}

#views-exposed-form-search-page-page .views-exposed-widget,
#views-exposed-form-search-page-page .views-exposed-widgets {
  margin: 0;
  padding: 0;
  min-height: 0;
}

@media (min-width: 64em) {
  #views-exposed-form-search-page-page {
    padding: 145px 0 19px;
    width: 999px;
    margin: 0 auto;
  }
  #views-exposed-form-search-page-page .views-exposed-widgets {
    width: 263px;
    float: right;
    position: relative;
  }
  #views-exposed-form-search-page-page .views-widget-filter-search_api_views_fulltext {
    width: 100%;
    position: absolute;
  }
  #views-exposed-form-search-page-page .views-widget-filter-search_api_views_fulltext input {
    width: 100%;
  }
  #views-exposed-form-search-page-page .views-submit-button {
    width: auto;
    float: right;
    z-index: 2;
  }
  #views-exposed-form-search-page-page .views-submit-button input {
    max-width: none;
  }
}

@media (max-width: 63.9375em) and (min-width: 48em) {
  #views-exposed-form-search-page-page {
    padding: 90px 0 18px;
  }
  #views-exposed-form-search-page-page .views-exposed-widgets {
    width: 263px;
    float: right;
    position: relative;
  }
  #views-exposed-form-search-page-page .views-widget-filter-search_api_views_fulltext {
    width: 100%;
    position: absolute;
  }
  #views-exposed-form-search-page-page .views-widget-filter-search_api_views_fulltext input {
    width: 100%;
  }
  #views-exposed-form-search-page-page .views-submit-button {
    width: auto;
    float: right;
  }
  #views-exposed-form-search-page-page .views-submit-button input {
    max-width: none;
  }
}

@media (max-width: 47.9375em) {
  #views-exposed-form-search-page-page {
    padding: 0;
    float: right;
    width: 75%;
  }
  #views-exposed-form-search-page-page .views-widget-filter-search_api_views_fulltext {
    width: 100%;
    position: absolute;
  }
  #views-exposed-form-search-page-page .views-widget-filter-search_api_views_fulltext input {
    width: 100%;
  }
  #views-exposed-form-search-page-page .views-submit-button {
    width: auto;
    float: right;
  }
  #views-exposed-form-search-page-page .views-submit-button input {
    max-width: none;
  }
}

#views-exposed-form-search-page-page input[type="text"] {
  text-align: left;
  border: 1px solid #00539B;
  background-color: #fff;
  margin-top: 0;
}

@media (min-width: 48em) {
  #views-exposed-form-search-page-page input[type="text"] {
    width: 263px;
    padding: 9px 50px 9px 20px;
  }
}

@media (max-width: 47.9375em) {
  #views-exposed-form-search-page-page input[type="text"] {
    width: 100%;
  }
}

#views-exposed-form-search-page-page input[type="submit"] {
  background: url("../images/search.png") transparent no-repeat;
  background-size: 24px auto;
  width: 40px;
  box-shadow: none;
  border: none;
  text-indent: -9999px;
  margin-top: 0 !important;
  height: 37px;
}

@media (min-width: 48em) {
  #views-exposed-form-search-page-page input[type="submit"] {
    background-position: center;
  }
}

@media (max-width: 47.9375em) {
  #views-exposed-form-search-page-page input[type="submit"] {
    background-position: center;
  }
}

@media (min-width: 48em) {
  .cc-social {
    float: left;
    position: relative;
    top: 16px;
    left: 0;
    z-index: 99;
  }
  .cc-social a {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 47.9375em) {
  .cc-social {
    overflow: hidden;
    margin: 0 auto;
    width: 216px;
    padding-top: 30px;
  }
  .cc-social a {
    width: 40px;
    height: 40px;
  }
}

.cc-social li {
  float: left;
}

.cc-social a {
  display: block;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
}

.cc-social a.facebook {
  background-image: url("../images/social/facebook.png");
}

.cc-social a.twitter {
  background-image: url("../images/social/twitter.png");
}

.cc-social a.rss {
  background-image: url("../images/social/rss.png");
}

.cc-social a.youtube {
  background-image: url("../images/social/youtube.png");
}

.cc-social a.print {
  background-image: url("../images/social/print.png");
}

/* Main / Content
   ========================================================================== */
.view-mode-full h1 {
  margin-top: 0;
}

@media (min-width: 48em) {
  #landing-listings,
  .group-content,
  .group-article-main,
  section.comments {
    width: 68%;
    padding-right: 15px;
    float: left;
    border-right: 1px solid #aaaaaa;
  }
}

.group-content section:first-child::before, .group-content section:first-child::after {
  display: none !important;
}

.group-article-main {
  clear: both;
}

#main ul {
  overflow: auto;
}

#content {
  overflow: hidden;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.field-body ul {
  padding: 0;
  /* LTR */
  list-style-type: none;
}

.field-body ul li {
  padding-left: 28px;
  text-indent: -28px;
}

.field-body ul li::before {
  content: "•";
  color: #B7531C;
  padding-right: 20px;
}

/* Messages
   ========================================================================== */
.messages, .messages--status, .messages--warning, .messages--error {
  margin: 36px 0;
  padding: 10px 10px 10px 50px;
  /* LTR */
  background-image: inline-image("message-24-ok.png");
  background-position: 8px 8px;
  /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}

.messages--warning {
  background-image: inline-image("message-24-warning.png");
  border-color: #ed5;
}

.messages--error {
  background-image: inline-image("message-24-error.png");
  border-color: #ed541d;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333;
}

/* System status report. */
.ok,
.messages--status {
  background-color: #f8fff0;
  color: #234600;
}

.warning,
.messages--warning {
  background-color: #fffce5;
  color: #840;
}

.error,
.messages--error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/* First Sidebar
   ========================================================================== */
/* Second Sidebar
   ========================================================================== */
.region-sidebar-second {
  margin-top: 72px;
}

@media (min-width: 48em) {
  .sidebar,
  .group-sidebar {
    width: 32%;
    float: right;
    padding-left: 15px;
    border-left: 1px solid #aaaaaa;
    position: relative;
    left: -1px;
  }
}

.sidebar .node-title-list,
.group-sidebar .node-title-list {
  list-style-type: none !important;
}

.sidebar .listing,
.sidebar .funding-credit,
.sidebar .twitter,
.sidebar .promo,
.group-sidebar .listing,
.group-sidebar .funding-credit,
.group-sidebar .twitter,
.group-sidebar .promo {
  margin-bottom: 2em;
}

.sidebar .partner-aft,
.group-sidebar .partner-aft {
  margin-top: 0 !important;
}

.sidebar .listing,
.sidebar .block-views,
.group-sidebar .listing,
.group-sidebar .block-views {
  width: 100% !important;
  margin-top: 0 !important;
  border: 1px solid #00529b;
}

.sidebar .listing h2,
.sidebar .block-views h2,
.group-sidebar .listing h2,
.group-sidebar .block-views h2 {
  background-color: #00529b;
  color: #fff;
  padding: 12px;
  margin: 0 !important;
  border: none !important;
  font-size: 22px;
  font-family: "myriad-pro", verdana, sans-serif;
}

.sidebar .listing h2 a,
.sidebar .block-views h2 a,
.group-sidebar .listing h2 a,
.group-sidebar .block-views h2 a {
  color: #fff !important;
  border-bottom: none;
}

.sidebar .listing article,
.sidebar .listing .views-row,
.sidebar .listing .node-title-list li,
.sidebar .block-views article,
.sidebar .block-views .views-row,
.sidebar .block-views .node-title-list li,
.group-sidebar .listing article,
.group-sidebar .listing .views-row,
.group-sidebar .listing .node-title-list li,
.group-sidebar .block-views article,
.group-sidebar .block-views .views-row,
.group-sidebar .block-views .node-title-list li {
  padding: 0 12px !important;
  margin: 0;
}

.sidebar .listing article h3,
.sidebar .listing article .views-field-title,
.sidebar .listing .views-row h3,
.sidebar .listing .views-row .views-field-title,
.sidebar .listing .node-title-list li h3,
.sidebar .listing .node-title-list li .views-field-title,
.sidebar .block-views article h3,
.sidebar .block-views article .views-field-title,
.sidebar .block-views .views-row h3,
.sidebar .block-views .views-row .views-field-title,
.sidebar .block-views .node-title-list li h3,
.sidebar .block-views .node-title-list li .views-field-title,
.group-sidebar .listing article h3,
.group-sidebar .listing article .views-field-title,
.group-sidebar .listing .views-row h3,
.group-sidebar .listing .views-row .views-field-title,
.group-sidebar .listing .node-title-list li h3,
.group-sidebar .listing .node-title-list li .views-field-title,
.group-sidebar .block-views article h3,
.group-sidebar .block-views article .views-field-title,
.group-sidebar .block-views .views-row h3,
.group-sidebar .block-views .views-row .views-field-title,
.group-sidebar .block-views .node-title-list li h3,
.group-sidebar .block-views .node-title-list li .views-field-title {
  margin: 0;
  font-size: 0.9em;
  line-height: 1.4em;
  position: relative;
  top: -3px;
}

.sidebar .listing article a,
.sidebar .listing .views-row a,
.sidebar .listing .node-title-list li a,
.sidebar .block-views article a,
.sidebar .block-views .views-row a,
.sidebar .block-views .node-title-list li a,
.group-sidebar .listing article a,
.group-sidebar .listing .views-row a,
.group-sidebar .listing .node-title-list li a,
.group-sidebar .block-views article a,
.group-sidebar .block-views .views-row a,
.group-sidebar .block-views .node-title-list li a {
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid #aaaaaa;
}

.sidebar .listing article:last-child a,
.sidebar .listing .views-row:last-child a,
.sidebar .listing .node-title-list li:last-child a,
.sidebar .block-views article:last-child a,
.sidebar .block-views .views-row:last-child a,
.sidebar .block-views .node-title-list li:last-child a,
.group-sidebar .listing article:last-child a,
.group-sidebar .listing .views-row:last-child a,
.group-sidebar .listing .node-title-list li:last-child a,
.group-sidebar .block-views article:last-child a,
.group-sidebar .block-views .views-row:last-child a,
.group-sidebar .block-views .node-title-list li:last-child a {
  border-bottom: none;
}

.sidebar .listing article .views-field-title,
.sidebar .listing .views-row .views-field-title,
.sidebar .listing .node-title-list li .views-field-title,
.sidebar .block-views article .views-field-title,
.sidebar .block-views .views-row .views-field-title,
.sidebar .block-views .node-title-list li .views-field-title,
.group-sidebar .listing article .views-field-title,
.group-sidebar .listing .views-row .views-field-title,
.group-sidebar .listing .node-title-list li .views-field-title,
.group-sidebar .block-views article .views-field-title,
.group-sidebar .block-views .views-row .views-field-title,
.group-sidebar .block-views .node-title-list li .views-field-title {
  font-size: 22px;
  font-size: 1.5714285714rem;
  line-height: 1.2;
  font-size: 1em !important;
  font-family: "proxima-nova-alt", verdana, sans-serif;
}

.sidebar .listing .node-title-list,
.sidebar .block-views .node-title-list,
.group-sidebar .listing .node-title-list,
.group-sidebar .block-views .node-title-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebar .listing.listing-related, .sidebar .listing#l4-related,
.sidebar .block-views.listing-related,
.sidebar .block-views#l4-related,
.group-sidebar .listing.listing-related,
.group-sidebar .listing#l4-related,
.group-sidebar .block-views.listing-related,
.group-sidebar .block-views#l4-related {
  border: 1px solid #1E7CBC;
}

.sidebar .listing.listing-related h2, .sidebar .listing#l4-related h2,
.sidebar .block-views.listing-related h2,
.sidebar .block-views#l4-related h2,
.group-sidebar .listing.listing-related h2,
.group-sidebar .listing#l4-related h2,
.group-sidebar .block-views.listing-related h2,
.group-sidebar .block-views#l4-related h2 {
  background-color: #1E7CBC;
}

.sidebar .news-title,
.group-sidebar .news-title {
  font-size: 0.9em;
  line-height: 1.4em;
}

.sidebar .news-date,
.group-sidebar .news-date {
  display: none;
}

.sidebar .listing-related li,
.group-sidebar .listing-related li {
  font-size: 0.9em;
  line-height: 1.4em;
}

.sidebar .listing-popular,
.group-sidebar .listing-popular {
  border: 1px solid #78458D;
}

.sidebar .listing-popular h2,
.group-sidebar .listing-popular h2 {
  background-color: #78458D;
}

.sidebar .listing-popular > div,
.group-sidebar .listing-popular > div {
  padding: 12px;
}

.sidebar .listing-popular article,
.group-sidebar .listing-popular article {
  border-bottom: 1px solid #aaaaaa;
  padding: 12px 0 !important;
}

.sidebar .listing-popular article a,
.group-sidebar .listing-popular article a {
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif !important;
  border-bottom: 0;
  font-weight: normal;
  padding: 0 !important;
  color: #B7531C !important;
}

.sidebar .listing-popular article a:hover,
.group-sidebar .listing-popular article a:hover {
  color: #8b3f15 !important;
}

.sidebar .listing-popular article .field,
.group-sidebar .listing-popular article .field {
  margin-bottom: 0;
}

.sidebar .listing-popular article .field-article-image,
.group-sidebar .listing-popular article .field-article-image {
  margin-right: 0;
  width: 41%;
  float: left;
  padding-right: 15px;
}

.sidebar .listing-popular article .field-article-image + .field-title-field,
.group-sidebar .listing-popular article .field-article-image + .field-title-field {
  width: 59%;
  float: right;
}

.sidebar .listing-popular article:first-child,
.group-sidebar .listing-popular article:first-child {
  padding-top: 0 !important;
}

.sidebar .listing-popular article:last-child,
.group-sidebar .listing-popular article:last-child {
  padding-bottom: 0 !important;
  border-bottom: none;
}

.sidebar .listing-news > a,
.group-sidebar .listing-news > a {
  display: inline-block;
  margin: 0 12px 12px;
}

.sidebar .listing-news ul.node-title-list,
.group-sidebar .listing-news ul.node-title-list {
  padding: 12px !important;
}

.sidebar .listing-news ul.node-title-list li,
.group-sidebar .listing-news ul.node-title-list li {
  padding: 12px 0 !important;
  border-bottom: 1px solid #aaaaaa;
}

.sidebar .listing-news ul.node-title-list li a.news-title,
.group-sidebar .listing-news ul.node-title-list li a.news-title {
  padding-top: 0;
}

.sidebar .listing-news ul.node-title-list li:first-child,
.group-sidebar .listing-news ul.node-title-list li:first-child {
  padding-top: 0 !important;
}

.sidebar .view .item-list ul,
.group-sidebar .view .item-list ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.sidebar .view .item-list ul li,
.group-sidebar .view .item-list ul li {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  margin-bottom: 0;
}

@media (max-width: 47.9375em) {
  .sidebar .listing-news,
  .sidebar .listing-popular,
  .sidebar .twitter,
  .sidebar .promo,
  .group-sidebar .listing-news,
  .group-sidebar .listing-popular,
  .group-sidebar .twitter,
  .group-sidebar .promo {
    display: none;
  }
}

/* Footer
   ========================================================================== */
#block-views-testimonials-block {
  overflow: hidden;
  background-color: #0D67A8;
  color: #fff;
  margin-bottom: 0;
}

@media (min-width: 64em) {
  #block-views-testimonials-block .views-row {
    width: 67%;
    float: left;
    padding: 60px 45px 60px 0;
    background: url("../images/testimonial-vert-rule.png") no-repeat right center;
    background-size: 3px auto;
    min-height: 218px;
  }
  #block-views-testimonials-block .views-row .views-field-field-testimonial-quote {
    font-size: 1.3em;
  }
  #block-views-testimonials-block .view-footer {
    width: 33%;
    float: left;
    padding: 60px 0 60px 45px;
  }
  #block-views-testimonials-block .field-content {
    float: left;
    width: 550px;
  }
}

@media (min-width: 48em) and (max-width: 63.9375em) {
  #block-views-testimonials-block .views-row {
    width: 60%;
    float: left;
    padding: 60px 60px 60px 0;
  }
  #block-views-testimonials-block .views-row .views-field-body {
    font-size: 1.3em;
  }
  #block-views-testimonials-block .view-footer {
    width: 40%;
    float: left;
    padding: 35px 0 35px 30px;
    background: url("../images/testimonial-vert-rule.png") no-repeat left center;
    background-size: 3px auto;
  }
  #block-views-testimonials-block .field-content {
    float: left;
    width: 90%;
  }
  #block-views-testimonials-block div.testimonial-text::after {
    position: absolute;
    bottom: 3px;
    right: -40px;
  }
}

@media (max-width: 47.9375em) {
  #block-views-testimonials-block .views-row,
  #block-views-testimonials-block .view-footer {
    width: 100%;
    padding: 15px;
  }
  #block-views-testimonials-block .views-field-field-testimonial-quote {
    font-size: 1.2em;
  }
  #block-views-testimonials-block div.testimonial-text::after {
    position: absolute;
    bottom: 3px;
    right: -20px;
  }
}

#block-views-testimonials-block .views-field-field-testimonial-quote {
  line-height: 1.7em;
}

#block-views-testimonials-block .views-field-field-testimonial-quote .field-content {
  position: relative;
  top: 0;
  left: 10px;
}

#block-views-testimonials-block .views-field-field-testimonial-quote .field-content div.testimonial-text {
  position: relative;
  font-style: italic;
  display: inline-block;
}

#block-views-testimonials-block .views-field-field-testimonial-quote .field-content div.testimonial-text::after {
  content: "";
  display: block;
  width: 30px;
  height: 24px;
  background: url("../images/testimonial-endquote.png") no-repeat center;
  background-size: 100%;
  float: right;
  margin-left: 10px;
}

#block-views-testimonials-block .views-field-field-testimonial-quote .field-content p.indent {
  position: relative;
  left: 40px;
}

#block-views-testimonials-block .view-footer {
  font-size: 1.6em;
  line-height: 1.4em;
  font-style: italic;
  text-align: center;
}

#block-views-testimonials-block .view-footer img {
  width: 180px;
}

#block-views-testimonials-block .view-footer img,
#block-views-testimonials-block .view-footer p {
  position: relative;
  top: -6px;
}

#block-views-testimonials-block .view-footer a {
  color: #fff;
  font-weight: normal;
}

#footer-wrapper {
  clear: both;
  background-color: #DBDAE0;
}

.not-front #footer-wrapper {
  margin-top: 30px;
}

#footer {
  overflow: hidden;
  font-size: 0.9em;
  padding-top: 45px;
}

#footer a {
  color: #000000 !important;
  border-bottom: none;
}

#footer a:hover {
  text-decoration: underline;
}

@media (min-width: 48em) {
  #footer #block-menu-block-3 {
    width: 20%;
    float: left;
    padding-right: 30px;
  }
  #footer #block-menu-block-2 {
    width: 60%;
    float: left;
  }
  #footer #block-menu-block-4 {
    width: 20%;
    float: left;
    padding-left: 30px;
  }
}

#footer #block-menu-block-2 ul li {
  font-weight: bold;
  margin-bottom: 15px;
}

#footer #block-menu-block-2 ul li ul li {
  font-weight: normal;
  margin-bottom: 0;
}

@media (min-width: 48em) {
  #footer #block-menu-block-2 ul {
    -webkit-column-count: 3;
    /* Chrome, Safari, Opera */
    -moz-column-count: 3;
    /* Firefox */
    column-count: 3;
    -webkit-column-gap: 15px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 15px;
    /* Firefox */
    column-gap: 15px;
  }
  #footer #block-menu-block-2 ul li {
    list-style-image: none;
    list-style-type: none;
    -webkit-column-break-inside: avoid;
    /* Chrome, Safari */
    page-break-inside: avoid;
    /* Theoretically FF 20+ */
    break-inside: avoid-column;
    /* IE 11 */
    display: table;
  }
  #footer #block-menu-block-2 ul li ul {
    margin: 0;
    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;
  }
  #footer #block-menu-block-2 ul li ul li {
    width: 100%;
    float: none;
    padding: 0;
    line-height: 1.2em;
    margin-top: 7.5px;
  }
}

#footer #block-block-7 {
  clear: both;
}

#footer #block-block-2 {
  overflow: hidden;
  margin-bottom: 0;
  padding-bottom: 2em;
}

@media (min-width: 48em) {
  #footer #block-block-2 {
    width: 20%;
    float: right;
    padding-left: 30px;
  }
  #footer #block-block-2 .cc-social a {
    background-size: auto 100%;
    background-position: left center;
    padding-left: 40px;
    line-height: 30px;
    width: 100%;
    text-indent: 0;
  }
}

#block-block-1 {
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 0;
}

@media (min-width: 48em) {
  #block-block-1 {
    padding: 3em 16em;
  }
}

@media (max-width: 47.9375em) {
  #block-block-1 {
    padding-top: 3em;
  }
}

#block-block-1 .float {
  text-align: center;
}

@media (min-width: 48em) {
  #block-block-1 .float {
    width: 33.33%;
    padding: 0 30px;
  }
  #block-block-1 .float #nea {
    margin-top: 20px;
    margin-bottom: 22px;
  }
}

@media (max-width: 47.9375em) {
  #block-block-1 .float {
    margin-bottom: 4em;
  }
  #block-block-1 .float img {
    max-width: 150px;
  }
}

#block-menu-menu-footer-links {
  margin-bottom: 0;
  padding: 1px 6em 3em;
}

@media (min-width: 48em) {
  #block-menu-menu-footer-links {
    width: 50%;
    float: left;
  }
}

#block-menu-menu-footer-links h2 {
  color: #555;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 10px;
}

#block-views-faq-block .views-field-title,
#block-views-research-block .views-field-title,
#block-views-books-block-1 .views-field-title,
#block-views-books-block .views-field-title,
#block-views-web-resources-block .views-field-title {
  color: #00539B;
  font-size: 1.2em;
}

#block-views-faq-block .views-field-title a,
#block-views-research-block .views-field-title a,
#block-views-books-block-1 .views-field-title a,
#block-views-books-block .views-field-title a,
#block-views-web-resources-block .views-field-title a {
  color: #00539B;
}

#block-views-faq-block .views-row,
#block-views-research-block .views-row,
#block-views-books-block-1 .views-row,
#block-views-books-block .views-row,
#block-views-web-resources-block .views-row {
  margin-bottom: 0.75em;
}

#block-views-faq-block .view-footer,
#block-views-research-block .view-footer,
#block-views-books-block-1 .view-footer,
#block-views-books-block .view-footer,
#block-views-web-resources-block .view-footer {
  font-size: 1.2em;
}

@media (max-width: 47.9375em) {
  #block-block-3,
  #block-block-4,
  .funding-credit,
  .view-course-intro .view-footer {
    overflow: hidden;
  }
}

#block-block-3 h2,
#block-block-4 h2,
.funding-credit h2,
.view-course-intro .view-footer h2 {
  font-size: 1.7rem;
  margin-bottom: 0;
  margin-top: 0;
}

#block-block-3 div,
#block-block-4 div,
.funding-credit div,
.view-course-intro .view-footer div {
  width: 100%;
  clear: both;
}

@media (max-width: 47.9375em) {
  #block-block-3 div,
  #block-block-4 div,
  .funding-credit div,
  .view-course-intro .view-footer div {
    width: 49%;
    float: left;
    clear: none;
  }
  #block-block-3 div.partner-aft,
  #block-block-4 div.partner-aft,
  .funding-credit div.partner-aft,
  .view-course-intro .view-footer div.partner-aft {
    margin-right: 1%;
  }
  #block-block-3 div.partner-nea,
  #block-block-4 div.partner-nea,
  .funding-credit div.partner-nea,
  .view-course-intro .view-footer div.partner-nea {
    margin-left: 1%;
  }
}

#block-block-3 div img,
#block-block-4 div img,
.funding-credit div img,
.view-course-intro .view-footer div img {
  display: block;
  margin: 0 auto;
  padding: 1px;
  max-width: 200px;
}

#block-block-3 div p,
#block-block-4 div p,
.funding-credit div p,
.view-course-intro .view-footer div p {
  padding: 15px;
  font-size: 14px;
  line-height: 1.6em;
}

#block-block-3 div.partner-aft,
#block-block-4 div.partner-aft,
.funding-credit div.partner-aft,
.view-course-intro .view-footer div.partner-aft {
  margin-top: 0;
  margin-bottom: 12px;
  border: 1px solid #194997;
}

#block-block-3 div.partner-aft p,
#block-block-4 div.partner-aft p,
.funding-credit div.partner-aft p,
.view-course-intro .view-footer div.partner-aft p {
  background-color: #194997;
  color: #fff;
}

#block-block-3 div.partner-aft p a,
#block-block-4 div.partner-aft p a,
.funding-credit div.partner-aft p a,
.view-course-intro .view-footer div.partner-aft p a {
  color: #fff !important;
  border-bottom: none !important;
}

#block-block-3 div.partner-aft::after,
#block-block-4 div.partner-aft::after,
.funding-credit div.partner-aft::after,
.view-course-intro .view-footer div.partner-aft::after {
  content: "";
  clear: both;
  display: block;
}

#block-block-3 div.partner-nea,
#block-block-4 div.partner-nea,
.funding-credit div.partner-nea,
.view-course-intro .view-footer div.partner-nea {
  border: 1px solid #22489a;
  color: #22489a;
}

#block-block-3 div.partner-nea a,
#block-block-4 div.partner-nea a,
.funding-credit div.partner-nea a,
.view-course-intro .view-footer div.partner-nea a {
  color: #22489a !important;
  border-bottom: none !important;
}

.not-front #block-block-3 div p,
.not-front #block-block-4 div p,
.not-front .funding-credit div p {
  font-size: 11px;
  line-height: 1.4em;
  padding: 10px;
}

.not-front #block-block-3 img#aft,
.not-front #block-block-4 img#aft,
.not-front .funding-credit img#aft {
  max-width: 35%;
}

#block-block-3 img#aft,
#block-block-4 img#aft,
.funding-credit img#aft {
  max-width: 45%;
  padding: 12px 0;
}

@media (max-width: 47.9375em) {
  .front #block-block-3 div,
  .front #block-block-4 div,
  .front .funding-credit div {
    padding-bottom: 200px;
    margin-bottom: -200px;
  }
  .front #block-block-3 div p,
  .front #block-block-4 div p,
  .front .funding-credit div p {
    padding-bottom: 200px;
    margin-bottom: -200px;
  }
  .front #block-block-3 div img#aft,
  .front #block-block-4 div img#aft,
  .front .funding-credit div img#aft {
    width: 100%;
    max-width: 85px;
  }
  .front #block-block-3 div img#nea,
  .front #block-block-4 div img#nea,
  .front .funding-credit div img#nea {
    width: 100%;
    max-width: 250px;
  }
  .front #block-block-3 div.partner-nea p,
  .front #block-block-4 div.partner-nea p,
  .front .funding-credit div.partner-nea p {
    background-color: #194997;
    color: #fff;
  }
  .front #block-block-3 div.partner-nea p a,
  .front #block-block-4 div.partner-nea p a,
  .front .funding-credit div.partner-nea p a {
    color: #fff !important;
    border-bottom: none !important;
  }
  .not-front #block-block-3 .partner-aft,
  .not-front #block-block-3 .partner-nea,
  .not-front #block-block-4 .partner-aft,
  .not-front #block-block-4 .partner-nea,
  .not-front .funding-credit .partner-aft,
  .not-front .funding-credit .partner-nea {
    height: 150px;
    overflow: hidden;
  }
  .not-front #block-block-3 .partner-aft p,
  .not-front #block-block-3 .partner-nea p,
  .not-front #block-block-4 .partner-aft p,
  .not-front #block-block-4 .partner-nea p,
  .not-front .funding-credit .partner-aft p,
  .not-front .funding-credit .partner-nea p {
    padding-bottom: 200px;
    margin-bottom: -200px;
  }
  .not-front #block-block-3 .partner-aft img#aft,
  .not-front #block-block-3 .partner-nea img#aft,
  .not-front #block-block-4 .partner-aft img#aft,
  .not-front #block-block-4 .partner-nea img#aft,
  .not-front .funding-credit .partner-aft img#aft,
  .not-front .funding-credit .partner-nea img#aft {
    width: 100%;
    max-width: 85px;
  }
  .not-front #block-block-3 .partner-aft img#nea,
  .not-front #block-block-3 .partner-nea img#nea,
  .not-front #block-block-4 .partner-aft img#nea,
  .not-front #block-block-4 .partner-nea img#nea,
  .not-front .funding-credit .partner-aft img#nea,
  .not-front .funding-credit .partner-nea img#nea {
    width: 100%;
    max-width: 250px;
  }
  .not-front #block-block-3 .partner-aft.partner-nea p,
  .not-front #block-block-3 .partner-nea.partner-nea p,
  .not-front #block-block-4 .partner-aft.partner-nea p,
  .not-front #block-block-4 .partner-nea.partner-nea p,
  .not-front .funding-credit .partner-aft.partner-nea p,
  .not-front .funding-credit .partner-nea.partner-nea p {
    background-color: #194997;
    color: #fff;
  }
  .not-front #block-block-3 .partner-aft.partner-nea p a,
  .not-front #block-block-3 .partner-nea.partner-nea p a,
  .not-front #block-block-4 .partner-aft.partner-nea p a,
  .not-front #block-block-4 .partner-nea.partner-nea p a,
  .not-front .funding-credit .partner-aft.partner-nea p a,
  .not-front .funding-credit .partner-nea.partner-nea p a {
    color: #fff !important;
    border-bottom: none !important;
  }
}

/* Instant Click Bar
   ========================================================================== */
#instantclick-bar {
  background: #00539B;
}

.node-type-cc-book .node-cc-book {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.node-type-cc-book .node-cc-book .field-book-illustrator {
  margin-bottom: 30px;
}

.node-type-cc-book .node-cc-book.hide-audiobook .field-book-audiobook {
  display: none;
}

.node-type-cc-book .node-cc-book .field-bookshop {
  display: none;
}

.node-type-cc-book .node-cc-book .group-page-bottom {
  width: 100%;
}

@media (min-width: 48em) {
  .node-type-cc-book .node-cc-book .group-page-bottom {
    width: 75%;
    padding-right: 36px;
  }
}

.views-bookfinder-button-block {
  width: 100%;
}

@media (min-width: 48em) {
  .views-bookfinder-button-block {
    width: 25%;
  }
}

.bookfinder-button {
  background: #0f8099;
  padding: 30px 15px;
  border-radius: 8px;
  position: relative;
}

.bookfinder-button a {
  display: block;
  color: #fff;
  border-bottom: none;
  font-weight: 700 !important;
  width: 70%;
}

.bookfinder-button a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.bookfinder-button::after {
  content: "";
  background-image: url("../images/teal-flower.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 25%;
  height: 90%;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 48em) {
  .bookfinder-button::after {
    width: 50%;
    height: 90%;
  }
}

.bookfinder-button:hover, .bookfinder-button:focus {
  transition: 0.2s all ease-in-out;
  box-shadow: #bbbbbb 0px 0px 8px;
}

@media (min-width: 48em) {
  .bookfinder-button {
    padding: 15px;
  }
}

.links.inline {
  position: relative;
  z-index: 4;
  float: right;
  text-align: right;
}

@media (min-width: 48em) {
  .links.inline {
    width: 32%;
    text-align: left;
    padding-left: 15px;
  }
}

/* @partial forms */
/* Forms
   ========================================================================== */
/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin: 36px 0;
  /* Pack groups of checkboxes and radio buttons closer together. */
  /* Form items in a table. */
  /* Highlight the form elements that caused a form submission error. */
  /* The descriptive help text (separate from the label). */
}

.form-checkboxes .form-item,
.form-radios .form-item {
  /* Drupal core uses "0.4em 0". */
  margin: 0;
}

tr.odd .form-item,
tr.even .form-item {
  margin: 0;
}

.form-item label {
  /* The label for a form element */
  display: block;
  font-weight: bold;
  margin: 0 0 0 0;
  font-size: 13px;
  font-size: 0.9285714286rem;
}

.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #e02443;
}

.form-item .description {
  font-size: 13px;
  margin-top: 4px;
}

.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 2.4em;
}

.form-required {
  /* The part of the label that indicates a required field */
  color: #e02443;
}

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
}

/* Buttons used by contrib modules like Media. */
a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

/* Password confirmation. */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */
#user-login-form {
  text-align: left;
  /* LTR */
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/* Fieldsets & helper class for div around fieldset
   ========================================================================== */
/**
 * 1. Correct `color` not being inherited in IE 8/9.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
  font-weight: bold;
}

fieldset, .fieldset {
  border-color: #eaa078;
  border-top-style: solid;
  border-top-width: 0.0714285714em;
  padding-top: 0.4535714286em;
  border-bottom-style: solid;
  border-bottom-width: 0.0714285714em;
  padding-bottom: 0.9035714286em;
  border-left-style: solid;
  border-left-width: 0.0714285714em;
  padding-left: 0.9035714286em;
  border-right-style: solid;
  border-right-width: 0.0714285714em;
  padding-right: 0.9035714286em;
  border-style: solid;
  padding: 21px;
  margin: 0 0 15px 0;
  -webkit-border-radius: 1.749993px;
  -moz-border-radius: 1.749993px;
  -ms-border-radius: 1.749993px;
  -o-border-radius: 1.749993px;
  border-radius: 1.749993px;
  background: none repeat scroll 0 0 #edad89;
}

/* Drupal body field footer */
fieldset.filter-wrapper {
  margin: 0 0 15px 0;
  padding: 1.5em 0 0 0;
  font-size: 14px;
  font-size: .875rem;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomleft: 1.749993px;
  -webkit-border-bottom-left-radius: 1.749993px;
  border-bottom-left-radius: 1.749993px;
  -moz-border-radius-bottomright: 1.749993px;
  -webkit-border-bottom-right-radius: 1.749993px;
  border-bottom-right-radius: 1.749993px;
}

fieldset.filter-wrapper .filter-help {
  margin: 0;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js .collapsible .fieldset-legend {
  background-image: inline-image("menu-expanded.png");
  background-position: 5px 65%;
  /* LTR */
  background-repeat: no-repeat;
  padding-left: 15px;
  /* LTR */
}

html.js .collapsed .fieldset-legend {
  background-image: inline-image("menu-collapsed.png");
  /* LTR */
  background-position: 5px 50%;
  /* LTR */
}

.fieldset-legend .summary {
  color: #999;
  font-size: 12px;
  font-size: 0.857rem;
  margin-left: 0.5em;
}

/* Submit Buttons and Node Edit Inputs Submit
   ========================================================================== */
/**
 * 1. Correct font family not being inherited in all browsers.
 * 2. Correct font size not being inherited in all browsers.
 * 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
 * 4. Improve appearance and consistency with IE 6/7.
 * 5. Keep form elements constrained in their containers.
 */
button,
input,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 2 */
  margin: 0;
  /* 3 */
  max-width: 100%;
  /* 5 */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* 5 */
}

button:focus, button:active,
input:focus,
input:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  /* for Opera */
  z-index: 1;
}

button,
textarea,
input {
  background: #eae8e8;
  border: 1px solid transparent;
  -webkit-box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
  box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition-property: compact(border, box-shadow, color, background-color, false, false, false, false, false, false);
  -moz-transition-property: compact(border, box-shadow, color, background-color, false, false, false, false, false, false);
  -o-transition-property: compact(border, box-shadow, color, background-color, false, false, false, false, false, false);
  transition-property: compact(border, box-shadow, color, background-color, false, false, false, false, false, false);
  -webkit-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -moz-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -o-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -webkit-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  -moz-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  -o-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  padding: 8px 16px;
  font-family: inherit;
  color: #000000;
  font-size: 14px;
  max-width: 100%;
  /* fixed overflow of input fields in drupal 6 layouts */
}

button:focus, button:active,
textarea:focus,
textarea:active,
input:focus,
input:active {
  border: 1px solid #00539B;
  background: #fff;
  outline: none;
}

button:focus ::placeholder, button:active ::placeholder,
textarea:focus ::placeholder,
textarea:active ::placeholder,
input:focus ::placeholder,
input:active ::placeholder {
  color: #898888;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* Remove inner padding and border in Firefox 4+. */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="submit"],
.node-readmore a {
  background-color: #B7531C;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
}

input[type="submit"]:hover,
.node-readmore a:hover {
  text-decoration: none;
  background-color: #8b3f15;
}

input[type="submit"]:active, input[type="submit"]:focus,
.node-readmore a:active,
.node-readmore a:focus {
  text-decoration: none;
  background-color: #8b3f15;
}

button, input, textarea {
  font-size: 14px;
  font-size: 1rem;
}

.form-type-checkbox label, .form-type-radio label {
  font-size: 14px;
  font-size: 1rem;
}

.form-actions input {
  margin-right: 1em;
}

body.page-node-add input[type="submit"] {
  width: auto;
}

input[value="Delete"], input[value="Remove"] {
  background-color: #e02443;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
}

input[value="Delete"]:hover, input[value="Remove"]:hover {
  text-decoration: none;
  background-color: #b71a34;
}

input[value="Delete"]:active, input[value="Delete"]:focus, input[value="Remove"]:active, input[value="Remove"]:focus {
  text-decoration: none;
  background-color: #b71a34;
}

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="week"],
input[type="text"] {
  width: 320px;
}

input[type="email"],
input[type="url"],
#user-login .form-text,
.form-item-title input[type="text"] {
  width: 320px;
}

input[type="number"] {
  width: 60px;
}

.form-item-title .form-text {
  width: 100%;
}

/* Correct height of image inputs like inputs found in Paypal forms */
input[type="image"] {
  height: auto;
}

/**
 * 1. Fix cascading border from iput selector above in ie8
 */
input[type="radio"],
input[type="checkbox"] {
  margin-right: 3.75px;
  background: none;
  border: none;
  /* 1 */
  -webkit-box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
  box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
}

html.js input.form-autocomplete {
  background-position: 99% 5px;
}

html.js input.throbbing {
  background-position: 99% -15px;
}

/*
 * 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6
 */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
}

button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  background-color: #8b3f15;
}

button,
input[type="button"],
input[type="reset"] {
  padding: 2px 10px;
}

textarea[disabled],
select[disabled],
input[disabled] {
  background-color: #eeeeee;
  color: #888888;
}

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/* Tables
   ========================================================================== */
table {
  border-spacing: 0;
  width: 100%;
  /* Prevent cramped-looking tables */
  /* Add vertical rhythm margins. */
  margin: 36px 0;
  border-style: solid;
  border-width: 0;
  border-color: #ccc;
}

table tr {
  background-color: #fff;
  border-top-color: transparent;
}

table tr td {
  padding: 1em;
  border-top-color: white;
  display: table-cell !important;
}

table tr td::before {
  content: "";
}

tbody {
  border-style: none;
  border-width: 0;
  border-color: transparent;
}

th {
  text-align: left;
  /* LTR */
  padding-right: 1em;
  /* LTR */
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: transparent;
  background-color: #00539B;
  color: #fff;
}

th * {
  color: #fff;
}

td, th {
  padding: 0.5em 1em;
}

@media (max-width: 47.9375em) {
  th {
    visibility: hidden;
  }
  td {
    display: block;
  }
  td:before {
    content: attr(data-th) ": ";
    display: inline-block;
  }
}

tr.even,
tr:nth-child(even) {
  background: #fff;
  border-top: 1px solid transparent;
}

tr.odd {
  background: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fafafa transparent transparent transparent;
}

tr.drag {
  background: #fafafa transparent transparent transparent;
}

fieldset {
  /* Darken table when in a ui-block */
}

fieldset th {
  border-color: #eba37c;
}

fieldset tbody {
  border-color: #eba37c;
}

fieldset tr.even {
  background: #eba37c;
  border-color: #eba37c;
}

fieldset tr.odd {
  background: transparent;
  border-color: #eba37c;
}

fieldset tr.drag-previous {
  background-color: #fffce5;
}

fieldset tr.drag {
  background: #eba37c;
}

table .item-list ul {
  margin: 0;
}

td.menu-disabled {
  background: #ccc;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
a.tabledrag-handle .handle {
  background-position: center center;
  height: 32px;
  margin: 0;
  padding: 0;
  width: 32px;
  display: block;
}

a.tabledrag-handle-hover .handle {
  background-position: center center;
}

.draggable a.tabledrag-handle {
  height: 1.7em;
  margin-left: 0;
  background-color: #f1f1f1;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.draggable a.tabledrag-handle:hover {
  background-color: #d1d1d1;
}

tr.drag {
  background-color: #fffff0;
}

tr.drag-previous {
  background-color: #fffce5;
}

.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold;
}

.progress .bar {
  background: #ccc;
  border-color: #666;
  margin: 0 0.2em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.progress .filled {
  background-color: #0072b9;
  background-image: image-url("progress.gif");
}

/* Buttons
    ========================================================================== */
.btn {
  background-color: #B7531C;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
}

.btn:hover {
  text-decoration: none;
  background-color: #8b3f15;
}

.btn:active, .btn:focus {
  text-decoration: none;
  background-color: #8b3f15;
}

/* Search
    ========================================================================== */
.search-results {
  padding-left: 0;
}

#search-form .form-item-keys label {
  display: block;
}

/* General Webform Styles
   ========================================================================== */
.webform-client-form {
  border-top: 5px solid #ffffff;
  border-bottom: 5px solid #B7531C;
  background-color: #f1f1f1;
  padding: 24px;
}

.webform-client-form fieldset {
  background: none;
  border: none;
  padding: 0;
  border-bottom: 1px solid #D1D1D1;
  padding-bottom: 1em;
  margin-bottom: 1.5em;
}

.webform-client-form fieldset:before, .webform-client-form fieldset:after {
  content: "";
  display: table;
}

.webform-client-form fieldset:after {
  clear: both;
}

.webform-client-form fieldset legend {
  font-size: 1.143em;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #E36E5F;
  font-weight: bold;
}

.webform-client-form .form-item {
  margin: 0 0 20px 0;
}

.webform-client-form .form-actions {
  margin-bottom: 0;
  text-align: right;
}

.webform-client-form .form-actions input {
  margin-right: 0;
  margin-left: 16px;
}

.webform-client-form .webform-component-textfield,
.webform-client-form .webform-component-select {
  display: inline-block;
  margin-right: 8px;
}

.node-webform.view-mode-full .field-body {
  padding: 1em 0;
}

#webform-component-edit-form {
  /* adding spacing to webform submit bottom */
}

#webform-component-edit-form input [type="submit"] {
  margin-top: 1em;
}

.views-exposed-form .form-type-textfield {
  position: relative;
}

.views-exposed-form .form-type-textfield::after {
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  color: #00539B;
  position: absolute;
  font-size: 18px;
  right: 10px;
  font-weight: 500;
  top: 10px;
  z-index: -1;
}

.views-exposed-form .form-type-select {
  position: relative;
}

.views-exposed-form .form-type-select::after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  color: #00539B;
  position: absolute;
  font-size: 18px;
  right: 10px;
  font-weight: 500;
  top: 10px;
  z-index: -1;
}

/* Select Elements */
.form-type-select,
.webform-component-select,
.views-exposed-widget {
  position: relative;
  min-height: 65px;
  min-width: 150px;
}

.form-type-select label,
.webform-component-select label,
.views-exposed-widget label {
  margin-bottom: 46px;
  display: block !important;
}

.form-type-select label + select,
.webform-component-select label + select,
.views-exposed-widget label + select {
  position: absolute;
  top: 28px;
  -webkit-appearance: none;
  -moz-appearance: window;
  -o-appearance: none;
  border: none;
  outline: none;
  background: transparent;
  padding: 11px 50px 9px 12px;
  border-radius: 0;
  z-index: 25;
  width: 100%;
}

.form-type-select .views-widget,
.webform-component-select .views-widget,
.views-exposed-widget .views-widget {
  position: absolute;
  top: 35px;
  z-index: 25;
  width: 100%;
}

.form-type-select .views-widget select,
.webform-component-select .views-widget select,
.views-exposed-widget .views-widget select {
  -webkit-appearance: none;
  -moz-appearance: window;
  -o-appearance: none;
  border: 1px solid #d4d4d4;
  outline: none;
  background: transparent;
  padding: 10px 50px 10px 12px;
  border-radius: 4px;
}

.form-type-select .views-widget select:hover, .form-type-select .views-widget select:focus,
.webform-component-select .views-widget select:hover,
.webform-component-select .views-widget select:focus,
.views-exposed-widget .views-widget select:hover,
.views-exposed-widget .views-widget select:focus {
  border-color: #00539B;
}

.form-type-select select::-ms-expand,
.webform-component-select select::-ms-expand,
.views-exposed-widget select::-ms-expand {
  display: none;
}

.mc-field-group label {
  font-weight: bold;
}

.mc-field-group label + p {
  font-style: italic;
  padding-left: 15px;
  margin-top: 5px;
}

.mc-field-group ul li {
  text-indent: 0 !important;
  margin: 0 0 7.5px 0 !important;
}

.mc-field-group ul li:before {
  display: none;
}

#mc_embed_signup .button {
  background-color: #B30425 !important;
}

#mc_embed_signup .button:hover {
  background-color: #81031b !important;
}

/* @partial navigation */
/* Main Navigation
   ========================================================================== */
#cc-nav-search {
  overflow: hidden;
  width: 100%;
}

@media (min-width: 48em) {
  #cc-nav-search {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 47.9375em) {
  #cc-nav-search {
    background-color: #1E4679;
    position: relative;
    padding: 20px;
  }
}

.navigation {
  position: relative;
  /* Main menu and secondary menu links and menu block links. */
}

@media (min-width: 48em) {
  .navigation {
    margin-top: -1px;
    background-color: #1E4679;
  }
}

@media (max-width: 47.9375em) {
  .navigation {
    background-color: #1E7CBC;
  }
}

.navigation .block {
  margin-bottom: 0;
}

.navigation .links,
.navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
  /* LTR */
  overflow: visible !important;
  overflow: hidden;
}

.navigation .links li,
.navigation .menu li {
  /* A simple method to get navigation links to appear in one line. */
  display: inline-block;
  list-style-type: none;
  list-style-image: none;
}

.navigation .links li.last,
.navigation .menu li.last {
  margin-bottom: 0;
}

ul.menu {
  overflow: hidden;
}

ul.menu li {
  list-style-image: none;
  list-style-type: none;
}

ul.menu li a {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #555;
}

@media (min-width: 48em) {
  #header .secondary-menu,
  #block-menu-menu-footer-links {
    display: block;
  }
  #footer .secondary-menu {
    display: none;
  }
}

@media (max-width: 47.9375em) {
  #header-wrapper {
    padding-top: 50px;
    position: relative;
  }
  #block-menu-menu-footer-links,
  #header .cc-social {
    display: none;
  }
  #header,
  #header .header__region {
    position: static;
  }
  #header .secondary-menu {
    width: 100%;
    position: static;
    height: 0;
    padding: 0;
  }
  #header .secondary-menu ul.links,
  #header .secondary-menu #weta-link {
    display: none;
  }
  #header .secondary-menu .translate-site {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #B30425;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    font-weight: 300;
    padding: 15px;
  }
  #header .secondary-menu .translate-site:active, #header .secondary-menu .translate-site:visited {
    color: #fff;
  }
  #header .secondary-menu .translate-site:hover {
    background-color: #9a0320;
    color: #fff;
  }
  #footer .secondary-menu,
  #footer .cc-social {
    display: block;
  }
  #footer .secondary-menu ul.links:first-child,
  #footer .cc-social ul.links:first-child {
    display: block;
  }
}

.secondary-menu {
  font-size: 0.9em;
}

@media (min-width: 48em) {
  .secondary-menu {
    float: left;
    padding: 12px 0;
    margin: 0;
    position: relative;
    left: 20px;
    z-index: 99;
  }
  .secondary-menu ul.links {
    overflow: hidden;
    float: left;
  }
  .secondary-menu #weta-link {
    display: block;
    float: left;
  }
  .secondary-menu #donate-link {
    float: left;
  }
}

@media (max-width: 47.9375em) {
  .secondary-menu {
    overflow: hidden;
    float: none;
    position: relative;
    width: 300px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .secondary-menu ul.links {
    float: left;
  }
  .secondary-menu li.first:before {
    display: none;
  }
  .secondary-menu #weta-link {
    float: right;
  }
}

.secondary-menu ul.links {
  overflow: hidden;
}

.secondary-menu ul.links li {
  list-style-type: none;
  list-style-image: none;
  float: left;
  padding: 8px 10px;
  color: #555;
}

.secondary-menu ul.links li:before {
  content: "|";
  padding-right: 13px;
}

.secondary-menu ul.links li a {
  color: #555;
}

.secondary-menu ul.links li.menu-774 a {
  color: #B30425;
}

#weta-link {
  background: url("../images/weta.svg") no-repeat top center;
  background-size: 100%;
  display: block;
  width: 80px;
  height: 40px;
  text-indent: -9999px;
}

#donate-link {
  -webkit-transition: compact(compact(background-color 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(background-color 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(background-color 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(background-color 0.15s ease-in, false, false, false, false, false, false, false, false, false);
  background-color: #f07e04;
  border-radius: 5px;
  color: #fff;
  padding: 5px 20px;
  margin-left: 15px;
}

@media (max-width: 47.9375em) {
  #donate-link {
    position: absolute;
    right: 15px;
  }
}

@media (max-width: 47.9375em) and (min-width: 30em) {
  #donate-link {
    top: 75px;
  }
}

@media (max-width: 29.9375em) {
  #donate-link {
    top: 80px;
    padding: 5px 30px;
  }
}

#donate-link:hover, #donate-link:focus {
  background-color: #be6403;
}

/* Breadcrumb
   ========================================================================== */
.breadcrumb {
  margin-bottom: 2em;
  font-size: 12px;
  font-size: 0.8571428571rem;
}

.breadcrumb ol {
  margin: 0;
  padding: 0;
}

.breadcrumb li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.breadcrumb li + li {
  margin-left: 4px;
}

/* Book
   ========================================================================== */
/* General Drupal Menus
   ========================================================================== */
.menu__item.is-leaf {
  list-style-image: inline-image("menu-leaf.png");
  list-style-type: square;
}

.menu__item.is-expanded {
  list-style-image: inline-image("menu-expanded.png");
  list-style-type: circle;
}

.menu__item.is-collapsed {
  list-style-image: inline-image("menu-collapsed.png");
  /* LTR */
  list-style-type: disc;
}

/* The active item in a Drupal menu. */
.menu a.active {
  color: #D8500B;
}

/* Pagination
   ========================================================================== */
/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  text-align: center;
  list-style-type: none;
  border-top: 1px solid #646464;
  padding: 15px 0 0 0 !important;
  text-align: center;
}

.pager-item a,
.pager-first a,
.pager-previous a,
.pager-next a,
.pager-last a,
.pager-ellipsis a, .pager-current, .item-list .pager li.pager-current {
  list-style-type: none;
  -webkit-border-radius: 1.749993px;
  -moz-border-radius: 1.749993px;
  -ms-border-radius: 1.749993px;
  -o-border-radius: 1.749993px;
  border-radius: 1.749993px;
  text-transform: uppercase;
  padding: 4px 8px;
  display: inline-block;
  -webkit-transition: compact(compact(padding 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(padding 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(padding 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(padding 0.15s ease-in, false, false, false, false, false, false, false, false, false);
}

.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  /* A concatenation of several list items using an ellipsis. */
  text-indent: 0 !important;
}

.pager-item:before,
.pager-first:before,
.pager-previous:before,
.pager-next:before,
.pager-last:before,
.pager-ellipsis:before {
  display: none;
}

.pager-item a,
.pager-first a,
.pager-previous a,
.pager-next a,
.pager-last a,
.pager-ellipsis a {
  background-color: transparent;
  font-family: "proxima-nova-alt", verdana, sans-serif;
  font-weight: bold;
  border-bottom: none;
}

.pager-item a:hover,
.pager-item a :active,
.pager-first a:hover,
.pager-first a :active,
.pager-previous a:hover,
.pager-previous a :active,
.pager-next a:hover,
.pager-next a :active,
.pager-last a:hover,
.pager-last a :active,
.pager-ellipsis a:hover,
.pager-ellipsis a :active {
  text-transform: uppercase;
  text-decoration: none;
}

/* The current page's list item. */
.pager-current {
  text-indent: 0 !important;
  background-color: transparent;
  color: #000000;
}

.pager-current:before {
  display: none;
}

.item-list .pager {
  /* A list of page numbers when more than 1 page of content is available */
  padding: 15px 0 0 0;
  overflow: hidden;
}

.item-list .pager li {
  display: inline-block;
  margin-right: 2px;
}

.item-list .pager li.pager-current {
  padding: 4px 8px;
}

.item-list {
  overflow: hidden;
}

/* Node edit tabs
   ========================================================================== */
ul.tabs-primary,
ul.tabs-secondary {
  overflow: hidden;
  list-style: none;
  margin: 1em 0 2.5em;
  padding: 0 2px;
  white-space: nowrap;
}

ul.tabs-primary li,
ul.tabs-secondary li {
  float: left;
  /* LTR */
  margin: 0.5em 3px 0;
}

ul.tabs-primary a,
ul.tabs-primary a:link,
ul.tabs-secondary a,
ul.tabs-secondary a:link {
  display: block;
  text-decoration: none;
}

ul.tabs-primary li {
  margin: 0.5em 0 0 3px;
}

ul.tabs-primary li.is-active a {
  background-color: #B7531C;
  color: #494949;
  border: 1px solid #d1d1d1;
}

ul.tabs-primary li a {
  font-size: 10.5px;
  text-transform: uppercase;
  background-color: #B7531C;
  border: 1px solid #B7531C;
  color: #ffffff;
  line-height: 26px;
  padding: 0 20px;
}

ul.tabs-primary li a:hover {
  text-decoration: none;
  background-color: #753512;
  border: 1px solid #8b3f15;
}

ul.tabs-primary li a.active {
  background-color: #ffffff;
  color: #494949;
}

/* Translate Links */
li.statistics_counter {
  display: none !important;
}

li.es {
  display: block;
}

.language-link {
  background-color: #B30425;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 1em;
  padding: 10px 24px;
}

.language-link:visited {
  color: #fff;
}

.language-link:hover {
  background-color: #81031b;
  color: #fff;
}

.translate-site {
  color: #B30425;
  display: inline-block;
  float: left;
  padding: 8px 10px;
  font-weight: 700;
}

.translate-site:visited {
  color: #B30425;
}

.translate-site:hover {
  color: #81031b;
}

/* More Links */
.node-type-research .field-research-link a,
.node-type-resource .field-resource-link a {
  background-color: #00539B;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
  background-color: #0D67A8;
}

.node-type-research .field-research-link a:hover,
.node-type-resource .field-resource-link a:hover {
  text-decoration: none;
  background-color: #003868;
}

.node-type-research .field-research-link a:active, .node-type-research .field-research-link a:focus,
.node-type-resource .field-resource-link a:active,
.node-type-resource .field-resource-link a:focus {
  text-decoration: none;
  background-color: #003868;
}

.node-type-research .field-research-link a:hover,
.node-type-resource .field-resource-link a:hover {
  background-color: #0b5890;
  color: #fff;
}

.node-type-research .field-research-link a:visited,
.node-type-resource .field-resource-link a:visited {
  color: #fff;
}

/* @partial modular */
@-webkit-keyframes fadeInUp {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

/* Mobile Main Menu */
@-webkit-keyframes fadeInDown {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

/* @partial node-pages */
.node-unpublished {
  /* Unpublished nodes */
  /* background-color: #fff4f4; */
  /* Drupal core uses a #fff4f4 background */
}

.node-unpublished p.unpublished {
  /* The word "Unpublished" displayed underneath the content. */
  height: 0;
  overflow: visible;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word;
}

.node-title-list {
  margin: 0;
  padding: 0;
}

.node-title-list li {
  margin-bottom: 7.5px;
  margin-bottom: 0;
}

.node-title-list li a {
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
}

.node-title-list li a.news-title {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.node-title-list li .news-source {
  padding-top: 0 !important;
  color: #000000;
  font-size: 0.9em;
}

.group-l4-content .node-title-list,
.group-content .node-title-list,
.l4-listing .node-title-list,
#author-articles .node-title-list {
  list-style-type: disc !important;
  padding-left: 27px !important;
}

.group-l4-content .node-title-list li,
.group-content .node-title-list li,
.l4-listing .node-title-list li,
#author-articles .node-title-list li {
  text-indent: -14px !important;
  padding-left: 0 !important;
  margin-bottom: 5px;
}

.group-l4-content .node-title-list li::before,
.group-content .node-title-list li::before,
.l4-listing .node-title-list li::before,
#author-articles .node-title-list li::before {
  display: none;
}

p + .l4-listing {
  margin-top: 18px;
}

.group-page-intro-inner {
  overflow: hidden;
}

@media (min-width: 48em) {
  .group-page-intro-inner .group-image-intro-copy {
    width: 75%;
    padding-right: 15px;
  }
}

.group-page-intro-bottom {
  position: relative;
  font-size: 15px;
  line-height: 22px;
}

.group-page-intro-bottom:before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

@media print {
  .group-page-intro-bottom:before {
    display: none;
  }
}

.group-page-intro-bottom:after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

@media print {
  .group-page-intro-bottom:after {
    display: none;
  }
}

.group-page-intro-bottom + div,
.group-page-intro-bottom + section {
  border-top: none !important;
}

.group-page-intro-bottom + div h2,
.group-page-intro-bottom + section h2 {
  border-top: none !important;
  margin-top: 0;
}

.group-page-intro-bottom + .group-l4-subhead::before, .group-page-intro-bottom + .group-l4-subhead::after {
  display: none !important;
}

.node-type-l2-page h1,
.node-type-l3-page h1,
.node-type-l4-page h1 {
  margin-top: 0;
}

.node-type-l2-page .videos,
.node-type-l2-page .slideshow,
.node-type-l3-page .videos,
.node-type-l3-page .slideshow,
.node-type-l4-page .videos,
.node-type-l4-page .slideshow {
  margin-bottom: 2em;
}

.node-type-l2-page .listing-news,
.node-type-l3-page .listing-news,
.node-type-l4-page .listing-news {
  border: 1px solid #00529b;
}

.node-type-l2-page .listing-news h2,
.node-type-l3-page .listing-news h2,
.node-type-l4-page .listing-news h2 {
  margin: 0;
  padding: 7.5px;
  background-color: #00529b;
  color: #fff;
  font-size: 22px;
}

.node-type-l2-page .listing-news article li,
.node-type-l2-page .listing-news .node-title-list li,
.node-type-l3-page .listing-news article li,
.node-type-l3-page .listing-news .node-title-list li,
.node-type-l4-page .listing-news article li,
.node-type-l4-page .listing-news .node-title-list li {
  padding: 0 12px !important;
}

.node-type-l2-page .listing-news article li a,
.node-type-l2-page .listing-news .node-title-list li a,
.node-type-l3-page .listing-news article li a,
.node-type-l3-page .listing-news .node-title-list li a,
.node-type-l4-page .listing-news article li a,
.node-type-l4-page .listing-news .node-title-list li a {
  display: block;
  border-bottom: 1px solid #aaaaaa;
  padding: 12px 0;
}

.node-type-l2-page .listing-news article li a.news-title,
.node-type-l2-page .listing-news .node-title-list li a.news-title,
.node-type-l3-page .listing-news article li a.news-title,
.node-type-l3-page .listing-news .node-title-list li a.news-title,
.node-type-l4-page .listing-news article li a.news-title,
.node-type-l4-page .listing-news .node-title-list li a.news-title {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.node-type-l2-page .listing-news article li a.news-source,
.node-type-l2-page .listing-news .node-title-list li a.news-source,
.node-type-l3-page .listing-news article li a.news-source,
.node-type-l3-page .listing-news .node-title-list li a.news-source,
.node-type-l4-page .listing-news article li a.news-source,
.node-type-l4-page .listing-news .node-title-list li a.news-source {
  padding-top: 0 !important;
}

.node-type-l2-page .listing-news article li:last-child a,
.node-type-l2-page .listing-news .node-title-list li:last-child a,
.node-type-l3-page .listing-news article li:last-child a,
.node-type-l3-page .listing-news .node-title-list li:last-child a,
.node-type-l4-page .listing-news article li:last-child a,
.node-type-l4-page .listing-news .node-title-list li:last-child a {
  border-bottom: none;
}

.node-type-l2-page .listing-news article li h3,
.node-type-l2-page .listing-news .node-title-list li h3,
.node-type-l3-page .listing-news article li h3,
.node-type-l3-page .listing-news .node-title-list li h3,
.node-type-l4-page .listing-news article li h3,
.node-type-l4-page .listing-news .node-title-list li h3 {
  margin: 0;
  font-size: 1em;
}

@media (min-width: 48em) {
  .node-type-l2-page .funding-credit .partner-aft,
  .node-type-l3-page .funding-credit .partner-aft,
  .node-type-l4-page .funding-credit .partner-aft {
    margin-top: 0;
  }
  .node-type-l2-page .funding-credit .partner-nea,
  .node-type-l3-page .funding-credit .partner-nea,
  .node-type-l4-page .funding-credit .partner-nea {
    margin: 0;
  }
  .node-type-l2-page .feed,
  .node-type-l2-page .listing-news,
  .node-type-l3-page .feed,
  .node-type-l3-page .listing-news,
  .node-type-l4-page .feed,
  .node-type-l4-page .listing-news {
    width: 25%;
    margin-top: 56px;
    margin-bottom: 22.5px;
  }
  .node-type-l2-page .videos h2,
  .node-type-l3-page .videos h2,
  .node-type-l4-page .videos h2 {
    margin-top: 0;
  }
}

@media (min-width: 48em) {
  .node-type-l2-page .funding-credit,
  .node-type-l3-page .funding-credit {
    width: 25%;
    float: right;
    padding-left: 15px;
  }
  .node-type-l2-page .videos,
  .node-type-l3-page .videos {
    width: 75%;
    float: left;
    padding-right: 30px;
  }
  .node-type-l2-page .funding-credit,
  .node-type-l3-page .funding-credit {
    width: 25%;
    float: left;
    margin-top: 56px;
    margin-bottom: 22.5px;
  }
}

section.videos {
  overflow: hidden;
}

section.videos .iframe,
section.videos .player {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60% !important;
}

section.videos .iframe iframe,
section.videos .player iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}

section.videos .video-content {
  width: 100% !important;
  margin-left: -11px;
  margin-right: -11px;
}

section.videos .video-content article {
  width: 150px;
  height: auto;
  padding: 7.5px;
}

section.videos .video-content article header {
  display: none;
}

section.videos .video-content article .field {
  margin-bottom: 0;
}

section.videos .video-content article .group-video {
  position: relative;
}

section.videos .video-content article .group-video .video-title {
  position: absolute;
  bottom: 0;
  color: #fff !important;
  font-size: 0.8em !important;
  width: 100%;
  line-height: 1.4em !important;
  font-weight: normal !important;
}

section.videos .video-content article .group-video .video-title .field-title-field {
  padding-left: 10px;
  padding-right: 10px;
  white-space: normal;
  padding-bottom: 5px;
  text-shadow: 1px 1px 1px #000;
}

section.videos .video-content article .group-video .field-playlist-youtube-url img {
  width: 135px;
  height: 101px;
}

section.videos .video-content article a.play-video:not(.video-title) {
  display: block;
  position: relative;
}

section.videos .video-content article a.play-video:not(.video-title)::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -owg(compact(linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%), false, false, false, false, false, false, false, false, false));
  background-image: -webkit(compact(linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%), false, false, false, false, false, false, false, false, false));
  background-image: -moz(compact(linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%), false, false, false, false, false, false, false, false, false));
  background-image: -o(compact(linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%), false, false, false, false, false, false, false, false, false));
  background-image: compact(linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 100%), false, false, false, false, false, false, false, false, false);
}

section.videos .video-content article a.play-video,
section.videos .video-content article a.video-title {
  border-bottom: none;
  -webkit-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(all 0.3s ease, false, false, false, false, false, false, false, false, false);
}

section.videos .video-content article .group-video a.play-video {
  opacity: 0.85;
}

section.videos .video-content article .group-video:hover a.play-video,
section.videos .video-content article .group-video:hover a.video-title {
  opacity: 1;
}

section.videos .video-content article .group-video a.active-video,
section.videos .video-content article .group-video a.active-video + a.video-title {
  opacity: 1;
}

section.videos .video-carousel-pager {
  width: 100%;
  text-align: center;
}

section.videos .video-carousel-pager .cycle-prev,
section.videos .video-carousel-pager .cycle-next {
  width: 44px;
  height: 44px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px auto;
  text-indent: -9999px;
  border-bottom: none;
}

section.videos .video-carousel-pager .cycle-prev {
  background-image: url("../images/cycle-prev.png");
}

section.videos .video-carousel-pager .cycle-next {
  background-image: url("../images/cycle-next.png");
}

@media (min-width: 48em) {
  .page-node .field-page-image,
  .page-node .field-l4-image,
  .page-node .field-webcast-image,
  .page-node .field-article-image,
  .page-node .field-book-image,
  .page-node .field-basic-thumbnail {
    width: 32%;
    padding-right: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    float: left;
  }
  .page-node .view-mode-longteaser .field-book-image {
    margin-right: 0;
  }
}

.page-node .field-guide-image {
  width: 130px;
  padding-right: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  float: left;
}

/* L2 Pages */
@media (min-width: 48em) {
  .node-type-l2-page .group-l2-intro {
    width: 75%;
    float: left;
    padding-right: 15px;
  }
}

.node-type-l2-page .funding-credit {
  margin-left: 0 !important;
  margin-top: 0 !important;
}

@media (min-width: 48em) {
  .node-type-l2-page .funding-credit {
    padding-left: 15px !important;
  }
}

.node-type-l2-page #l2-listing-pages {
  clear: both;
  position: relative;
}

.node-type-l2-page #l2-listing-pages:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-l2-page #l2-listing-pages:after {
    display: none;
  }
}

.node-type-l2-page #l2-listing-pages:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-l2-page #l2-listing-pages:before {
    display: none;
  }
}

.node-type-l2-page #l2-listing-pages article {
  margin-top: 15px;
}

.node-type-l2-page #l2-listing-pages h2 {
  margin-top: 0;
}

@media (min-width: 48em) {
  .node-type-l2-page #l2-listing-pages .field-landing-image {
    width: 25%;
    float: left;
    padding-right: 15px;
  }
  .node-type-l2-page #l2-listing-pages .field-landing-image a {
    border-bottom: none;
  }
  .node-type-l2-page #l2-listing-pages .landing-intro {
    overflow: hidden;
    width: 75%;
    float: right;
    padding-left: 15px;
  }
  .node-type-l2-page #l2-listing-pages .landing-intro h3 {
    margin-top: 0;
    position: relative;
    top: -4px;
    margin-bottom: 5px;
  }
}

@media (max-width: 47.9375em) {
  .node-type-l2-page #l2-listing-pages .field-landing-image {
    width: 100%;
  }
  .node-type-l2-page #l2-listing-pages .field-landing-intro {
    width: 100%;
  }
  .node-type-l2-page #l2-listing-pages .field-title-field {
    width: 100%;
    text-align: left;
  }
}

.node-type-l2-page #l2-listing-pages .field-title-field a {
  background-color: #00539B;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
  background-color: #0D67A8;
}

.node-type-l2-page #l2-listing-pages .field-title-field a:hover {
  text-decoration: none;
  background-color: #003868;
}

.node-type-l2-page #l2-listing-pages .field-title-field a:active, .node-type-l2-page #l2-listing-pages .field-title-field a:focus {
  text-decoration: none;
  background-color: #003868;
}

.node-type-l2-page #l2-listing-pages .field-title-field a:hover, .node-type-l2-page #l2-listing-pages .field-title-field a:active, .node-type-l2-page #l2-listing-pages .field-title-field a:focus {
  background-color: #0b5890;
  color: #fff;
}

.node-type-l2-page #l2-listing-pages .field-title-field a:visited {
  color: #fff;
}

.node-type-l2-page .group-page-intro-bottom {
  position: relative;
}

.node-type-l2-page .group-page-intro-bottom:before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

@media print {
  .node-type-l2-page .group-page-intro-bottom:before {
    display: none;
  }
}

.node-type-l2-page .group-page-intro-bottom:after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

@media print {
  .node-type-l2-page .group-page-intro-bottom:after {
    display: none;
  }
}

.node-type-l2-page #l2-subpages {
  margin-bottom: 2em;
}

.node-type-l2-page #l2-subpages h2 {
  margin-top: 0;
}

/* L3 Pages */
.node-type-l3-page .hide-funding-credits .group-page-intro {
  width: 100%;
}

.node-type-l3-page .hide-funding-credits .funding-credit {
  display: none !important;
}

.node-type-l3-page .field-hide-funding-credits {
  display: none;
}

.node-type-l3-page .group-l3-bottom-resources {
  overflow: hidden;
  clear: both;
  border-top: 1px solid #ccc;
}

.node-type-l3-page .group-l3-bottom-resources .listing {
  width: 25%;
  float: left;
  padding: 15px;
}

.node-type-l3-page .group-l3-bottom-resources .listing h2 {
  font-size: 22px;
  margin-top: 15px;
}

.node-type-l3-page .group-l3-bottom-resources .listing li {
  margin-bottom: 7.5px;
}

.node-type-l3-page .group-l3-bottom-resources .listing:first-child {
  padding-left: 0;
}

.node-type-l3-page .group-l3-bottom-resources .listing:last-child {
  padding-right: 0;
}

.node-type-l3-page .group-page-intro-bottom + section::before, .node-type-l3-page .group-page-intro-bottom + section::after,
.node-type-l3-page .group-page-intro-bottom + div::before,
.node-type-l3-page .group-page-intro-bottom + div::after {
  display: none !important;
}

@media (min-width: 48em) {
  .node-type-l3-page .group-page-intro {
    width: 75%;
    float: left;
    padding-right: 15px;
  }
  .node-type-l3-page .funding-credit {
    width: 25%;
    float: left;
    padding-left: 15px;
    margin: 0 !important;
  }
}

/* L2 and L3 pages */
#l2-subpages article,
#l3-subpages article,
#videos-subpages article {
  position: relative;
}

#l2-subpages article .field-title-field,
#l3-subpages article .field-title-field,
#videos-subpages article .field-title-field {
  margin-bottom: 0;
}

#l2-subpages article h3,
#l3-subpages article h3,
#videos-subpages article h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

#l2-subpages article h3 a,
#l3-subpages article h3 a,
#videos-subpages article h3 a {
  position: relative;
  top: -4px;
}

#l2-subpages article .field-l4-image,
#l2-subpages article .field-page-image,
#l2-subpages article .field-landing-image,
#l3-subpages article .field-l4-image,
#l3-subpages article .field-page-image,
#l3-subpages article .field-landing-image,
#videos-subpages article .field-l4-image,
#videos-subpages article .field-page-image,
#videos-subpages article .field-landing-image {
  margin-bottom: 15px;
}

@media (min-width: 48em) {
  #l2-subpages article .field-l4-image,
  #l2-subpages article .field-page-image,
  #l2-subpages article .field-landing-image,
  #l3-subpages article .field-l4-image,
  #l3-subpages article .field-page-image,
  #l3-subpages article .field-landing-image,
  #videos-subpages article .field-l4-image,
  #videos-subpages article .field-page-image,
  #videos-subpages article .field-landing-image {
    width: 25%;
    padding-right: 15px;
    margin-right: 0;
    margin-bottom: 20px;
    float: left;
  }
}

#l2-subpages article.node-l4-page .node__title,
#l3-subpages article.node-l4-page .node__title,
#videos-subpages article.node-l4-page .node__title {
  display: none;
}

#l2-subpages article.node-basic-page header,
#l2-subpages article.node-basic-page .field-basic-body img,
#l2-subpages article.node-basic-page .field-basic-description img,
#l3-subpages article.node-basic-page header,
#l3-subpages article.node-basic-page .field-basic-body img,
#l3-subpages article.node-basic-page .field-basic-description img,
#videos-subpages article.node-basic-page header,
#videos-subpages article.node-basic-page .field-basic-body img,
#videos-subpages article.node-basic-page .field-basic-description img {
  display: none;
}

@media (min-width: 48em) {
  #l2-subpages article.node-l4-page .field-title-field, #l2-subpages article.node-basic-page .field-title-field, #l2-subpages article.node-l3-page .field-title-field,
  #l3-subpages article.node-l4-page .field-title-field,
  #l3-subpages article.node-basic-page .field-title-field,
  #l3-subpages article.node-l3-page .field-title-field,
  #videos-subpages article.node-l4-page .field-title-field,
  #videos-subpages article.node-basic-page .field-title-field,
  #videos-subpages article.node-l3-page .field-title-field {
    width: 75%;
    padding-left: 15px;
    float: left;
  }
}

@media (min-width: 48em) {
  #l2-subpages article.node-landing .node__title,
  #l3-subpages article.node-landing .node__title,
  #videos-subpages article.node-landing .node__title {
    width: 75%;
    padding-left: 15px;
    float: left;
  }
}

@media (min-width: 48em) {
  #l2-subpages article .field-l3-intro,
  #l2-subpages article .field-l4-intro,
  #l2-subpages article .field-basic-description,
  #l2-subpages article .field-basic-body,
  #l2-subpages article .field-landing-description,
  #l3-subpages article .field-l3-intro,
  #l3-subpages article .field-l4-intro,
  #l3-subpages article .field-basic-description,
  #l3-subpages article .field-basic-body,
  #l3-subpages article .field-landing-description,
  #videos-subpages article .field-l3-intro,
  #videos-subpages article .field-l4-intro,
  #videos-subpages article .field-basic-description,
  #videos-subpages article .field-basic-body,
  #videos-subpages article .field-landing-description {
    width: 75%;
    padding-left: 15px;
    float: right;
  }
}

@media (max-width: 47.9375em) {
  #l2-subpages article,
  #l3-subpages article,
  #l2-listing-pages article {
    position: relative;
    margin-bottom: 15px;
  }
  #l2-subpages article h3,
  #l3-subpages article h3,
  #l2-listing-pages article h3 {
    margin-right: 45px;
  }
  #l2-subpages article .field-l4-image,
  #l2-subpages article .field-page-image,
  #l2-subpages article .field-landing-image,
  #l2-subpages article .field-basic-thumbnail,
  #l3-subpages article .field-l4-image,
  #l3-subpages article .field-page-image,
  #l3-subpages article .field-landing-image,
  #l3-subpages article .field-basic-thumbnail,
  #l2-listing-pages article .field-l4-image,
  #l2-listing-pages article .field-page-image,
  #l2-listing-pages article .field-landing-image,
  #l2-listing-pages article .field-basic-thumbnail {
    display: none;
  }
  #l2-subpages article .field-l4-intro .toggle-subpages,
  #l2-subpages article .field-l3-intro .toggle-subpages,
  #l2-subpages article .field-basic-description .toggle-subpages,
  #l2-subpages article .field-landing-description .toggle-subpages,
  #l2-subpages article .field-basic-body .toggle-subpages,
  #l3-subpages article .field-l4-intro .toggle-subpages,
  #l3-subpages article .field-l3-intro .toggle-subpages,
  #l3-subpages article .field-basic-description .toggle-subpages,
  #l3-subpages article .field-landing-description .toggle-subpages,
  #l3-subpages article .field-basic-body .toggle-subpages,
  #l2-listing-pages article .field-l4-intro .toggle-subpages,
  #l2-listing-pages article .field-l3-intro .toggle-subpages,
  #l2-listing-pages article .field-basic-description .toggle-subpages,
  #l2-listing-pages article .field-landing-description .toggle-subpages,
  #l2-listing-pages article .field-basic-body .toggle-subpages {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #00539B;
    position: absolute;
    top: -4px;
    right: 0;
    cursor: pointer;
  }
  #l2-subpages article .field-l4-intro .toggle-subpages::before,
  #l2-subpages article .field-l3-intro .toggle-subpages::before,
  #l2-subpages article .field-basic-description .toggle-subpages::before,
  #l2-subpages article .field-landing-description .toggle-subpages::before,
  #l2-subpages article .field-basic-body .toggle-subpages::before,
  #l3-subpages article .field-l4-intro .toggle-subpages::before,
  #l3-subpages article .field-l3-intro .toggle-subpages::before,
  #l3-subpages article .field-basic-description .toggle-subpages::before,
  #l3-subpages article .field-landing-description .toggle-subpages::before,
  #l3-subpages article .field-basic-body .toggle-subpages::before,
  #l2-listing-pages article .field-l4-intro .toggle-subpages::before,
  #l2-listing-pages article .field-l3-intro .toggle-subpages::before,
  #l2-listing-pages article .field-basic-description .toggle-subpages::before,
  #l2-listing-pages article .field-landing-description .toggle-subpages::before,
  #l2-listing-pages article .field-basic-body .toggle-subpages::before {
    content: "";
    background: url("../images/toggle-subpages.png") no-repeat;
    background-size: 30px auto;
    background-position: top center;
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    line-height: 30px;
    height: 30px;
    font-size: 18px;
  }
  #l2-subpages article .field-l4-intro .item,
  #l2-subpages article .field-l3-intro .item,
  #l2-subpages article .field-basic-description .item,
  #l2-subpages article .field-landing-description .item,
  #l2-subpages article .field-basic-body .item,
  #l3-subpages article .field-l4-intro .item,
  #l3-subpages article .field-l3-intro .item,
  #l3-subpages article .field-basic-description .item,
  #l3-subpages article .field-landing-description .item,
  #l3-subpages article .field-basic-body .item,
  #l2-listing-pages article .field-l4-intro .item,
  #l2-listing-pages article .field-l3-intro .item,
  #l2-listing-pages article .field-basic-description .item,
  #l2-listing-pages article .field-landing-description .item,
  #l2-listing-pages article .field-basic-body .item {
    display: none;
  }
  #l2-subpages article .field-l4-intro .toggle-subpages-open::before,
  #l2-subpages article .field-l3-intro .toggle-subpages-open::before,
  #l2-subpages article .field-basic-description .toggle-subpages-open::before,
  #l2-subpages article .field-landing-description .toggle-subpages-open::before,
  #l2-subpages article .field-basic-body .toggle-subpages-open::before,
  #l3-subpages article .field-l4-intro .toggle-subpages-open::before,
  #l3-subpages article .field-l3-intro .toggle-subpages-open::before,
  #l3-subpages article .field-basic-description .toggle-subpages-open::before,
  #l3-subpages article .field-landing-description .toggle-subpages-open::before,
  #l3-subpages article .field-basic-body .toggle-subpages-open::before,
  #l2-listing-pages article .field-l4-intro .toggle-subpages-open::before,
  #l2-listing-pages article .field-l3-intro .toggle-subpages-open::before,
  #l2-listing-pages article .field-basic-description .toggle-subpages-open::before,
  #l2-listing-pages article .field-landing-description .toggle-subpages-open::before,
  #l2-listing-pages article .field-basic-body .toggle-subpages-open::before {
    content: "";
    background-position: bottom center;
  }
  #l2-subpages article .field-l4-intro .toggle-subpages-open + .item,
  #l2-subpages article .field-l3-intro .toggle-subpages-open + .item,
  #l2-subpages article .field-basic-description .toggle-subpages-open + .item,
  #l2-subpages article .field-landing-description .toggle-subpages-open + .item,
  #l2-subpages article .field-basic-body .toggle-subpages-open + .item,
  #l3-subpages article .field-l4-intro .toggle-subpages-open + .item,
  #l3-subpages article .field-l3-intro .toggle-subpages-open + .item,
  #l3-subpages article .field-basic-description .toggle-subpages-open + .item,
  #l3-subpages article .field-landing-description .toggle-subpages-open + .item,
  #l3-subpages article .field-basic-body .toggle-subpages-open + .item,
  #l2-listing-pages article .field-l4-intro .toggle-subpages-open + .item,
  #l2-listing-pages article .field-l3-intro .toggle-subpages-open + .item,
  #l2-listing-pages article .field-basic-description .toggle-subpages-open + .item,
  #l2-listing-pages article .field-landing-description .toggle-subpages-open + .item,
  #l2-listing-pages article .field-basic-body .toggle-subpages-open + .item {
    display: block;
  }
}

#l2-subpages article.node-landing .field-title-field {
  display: none;
}

#videos-subpages .field-title-field {
  display: none;
}

#videos-subpages .node-basic-page .field-title-field {
  display: block;
}

/* Gallery Listing */
article.view-mode-videomodule {
  margin-bottom: 15px;
  position: relative;
}

@media (min-width: 48em) {
  article.view-mode-videomodule {
    width: 33.33%;
    padding: 15px 15px 0 15px;
    float: left;
  }
  article.view-mode-videomodule:nth-child(3n + 1) {
    clear: both;
  }
}

article.view-mode-videomodule .field-title-field,
article.view-mode-videomodule .node__title {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.1em !important;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  font-weight: normal;
  z-index: 20;
}

@media (min-width: 48em) {
  article.view-mode-videomodule .field-title-field,
  article.view-mode-videomodule .node__title {
    margin-left: -30px;
    padding-left: 30px;
  }
}

article.view-mode-videomodule .field-title-field h3,
article.view-mode-videomodule .node__title a,
article.view-mode-videomodule .field-interview-title a {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 15px;
}

article.view-mode-videomodule .field-title-field a,
article.view-mode-videomodule .node__title a,
article.view-mode-videomodule .field-interview-title a {
  color: #fff !important;
  font-weight: normal;
}

article.view-mode-videomodule .field {
  margin-bottom: 0;
}

article.view-mode-videomodule.node-video-interview .field-classroom-city,
article.view-mode-videomodule.node-video-interview .field-classroom-state,
article.view-mode-videomodule.node-video-interview .field-classrrom-grade, article.view-mode-videomodule.node-classroom-video .field-classroom-city,
article.view-mode-videomodule.node-classroom-video .field-classroom-state,
article.view-mode-videomodule.node-classroom-video .field-classrrom-grade {
  color: #fff;
  font-size: 0.9em;
}

article.view-mode-videomodule.node-video-interview .landing-intro, article.view-mode-videomodule.node-classroom-video .landing-intro {
  position: absolute;
  bottom: 0;
  width: 100%;
}

article.view-mode-videomodule.node-video-interview .landing-intro .landing-intro-inside, article.view-mode-videomodule.node-classroom-video .landing-intro .landing-intro-inside {
  width: 100%;
}

@media (min-width: 48em) {
  article.view-mode-videomodule.node-video-interview .landing-intro .landing-intro-inside, article.view-mode-videomodule.node-classroom-video .landing-intro .landing-intro-inside {
    margin-left: -30px;
    padding-left: 30px;
  }
}

article.view-mode-videomodule.node-video-interview .landing-intro .landing-intro-inside .landing-intro-container, article.view-mode-videomodule.node-classroom-video .landing-intro .landing-intro-inside .landing-intro-container {
  background-color: rgba(0, 0, 0, 0.5);
}

article.view-mode-videomodule.node-video-interview .landing-intro .node__title, article.view-mode-videomodule.node-classroom-video .landing-intro .node__title {
  position: static;
}

article.view-mode-videomodule.node-video-interview .landing-intro .node__title a, article.view-mode-videomodule.node-classroom-video .landing-intro .node__title a {
  padding: 0;
  background-color: transparent;
}

article.view-mode-videomodule.node-video-interview .landing-intro .field-interview-title, article.view-mode-videomodule.node-classroom-video .landing-intro .field-interview-title {
  width: 100%;
}

article.view-mode-videomodule.node-video-interview .landing-intro .field-interview-title a, article.view-mode-videomodule.node-classroom-video .landing-intro .field-interview-title a {
  padding: 0;
  font-size: 12px;
  background-color: transparent;
}

article.view-mode-videomodule.node-video-interview.node-video-interview .landing-intro-container, article.view-mode-videomodule.node-classroom-video.node-video-interview .landing-intro-container {
  padding: 7.5px 15px;
}

article.view-mode-videomodule.node-video-interview.node-classroom-video .landing-intro-container, article.view-mode-videomodule.node-classroom-video.node-classroom-video .landing-intro-container {
  padding: 15px;
}

article.view-mode-videomodule.node-classroom-video .field-classroom-city,
article.view-mode-videomodule.node-classroom-video .field-classroom-state,
article.view-mode-videomodule.node-classroom-video .field-classrrom-grade {
  display: inline;
}

article.view-mode-videomodule.node-classroom-video .field-classroom-city .item,
article.view-mode-videomodule.node-classroom-video .field-classroom-state .item,
article.view-mode-videomodule.node-classroom-video .field-classrrom-grade .item {
  display: inline;
}

article.view-mode-videomodule.node-classroom-video .field-classroom-city + .field-classroom-state::before {
  content: ", ";
  margin-left: -4px;
}

article.view-mode-videomodule.node-classroom-video .field-classrrom-grade::after {
  display: none;
}

article.view-mode-videomodule.node-classroom-video .field-classrrom-grade + .field-classroom-city::before {
  content: "| ";
}

/* L4 Pages */
.node-type-l4-page .node-l4-page {
  overflow: hidden;
  overflow: visible;
}

.node-type-l4-page .node-l4-page ul.links:first-child {
  display: block;
}

@media (min-width: 48em) {
  .node-type-l4-page .group-page-intro-inner .field-l4-image {
    width: 33.33%;
  }
}

@media (min-width: 48em) {
  .node-type-l4-page .group-l4-content {
    border-right: 1px solid #aaaaaa;
    width: 68%;
    float: left;
    padding-right: 15px;
  }
}

.node-type-l4-page .group-l4-content .listing {
  overflow: hidden;
  position: relative;
  margin-bottom: 2em;
}

.node-type-l4-page .group-l4-content .listing:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-l4-page .group-l4-content .listing:after {
    display: none;
  }
}

.node-type-l4-page .group-l4-content .listing:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-l4-page .group-l4-content .listing:before {
    display: none;
  }
}

.node-type-l4-page .group-l4-content .listing h2 {
  margin-top: 0;
}

.node-type-l4-page .group-l4-content .listing li {
  margin-bottom: 18px;
}

.node-type-l4-page .group-l4-content .listing h3 {
  font-size: 1em;
}

.node-type-l4-page .group-l4-content .node-title-list {
  overflow: hidden;
}

.node-type-l4-page .group-l4-content .see-more {
  clear: left;
  background-color: #00539B;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
  display: block;
  margin: 15px auto;
  background-color: #0D67A8;
}

.node-type-l4-page .group-l4-content .see-more:hover {
  text-decoration: none;
  background-color: #003868;
}

.node-type-l4-page .group-l4-content .see-more:active, .node-type-l4-page .group-l4-content .see-more:focus {
  text-decoration: none;
  background-color: #003868;
}

.node-type-l4-page .group-l4-content .see-more:hover, .node-type-l4-page .group-l4-content .see-more:active, .node-type-l4-page .group-l4-content .see-more:focus {
  background-color: #0b5890;
  color: #fff;
}

.node-type-l4-page .group-l4-content .see-more:visited {
  color: #fff;
}

.node-type-l4-page .group-l4-subhead {
  position: relative;
}

.node-type-l4-page .group-l4-subhead:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-l4-page .group-l4-subhead:after {
    display: none;
  }
}

.node-type-l4-page .group-l4-subhead:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-l4-page .group-l4-subhead:before {
    display: none;
  }
}

.node-type-l4-page #l4-videos {
  position: relative;
}

.node-type-l4-page #l4-videos:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-l4-page #l4-videos:after {
    display: none;
  }
}

.node-type-l4-page #l4-videos:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-l4-page #l4-videos:before {
    display: none;
  }
}

.node-type-l4-page #l4-articles a:not(.see-more),
.node-type-l4-page #l4-books a:not(.see-more),
.node-type-l4-page #l4-guides a:not(.see-more) {
  font-weight: normal;
  color: #B7531C !important;
}

.node-type-l4-page #l4-articles a:not(.see-more):hover,
.node-type-l4-page #l4-books a:not(.see-more):hover,
.node-type-l4-page #l4-guides a:not(.see-more):hover {
  color: #8b3f15 !important;
}

.node-type-l4-page #l4-articles .node__title,
.node-type-l4-page #l4-books .node__title,
.node-type-l4-page #l4-guides .node__title {
  display: none;
}

.node-type-l4-page #l4-articles .field-thumbnail-image {
  width: 18%;
  float: left;
  padding-right: 15px;
  margin-right: 15px;
}

.node-type-l4-page #l4-articles .field-thumbnail-image a {
  border-bottom: none;
}

.node-type-l4-page #l4-books > div {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 48em) {
  .node-type-l4-page #l4-books article {
    width: 25%;
    float: left;
    padding: 0 15px;
  }
  .node-type-l4-page #l4-books article:nth-child(4n + 1) {
    clear: left;
  }
}

@media (max-width: 47.9375em) {
  .node-type-l4-page #l4-books article {
    width: 50%;
    float: left;
    padding: 0 15px;
  }
  .node-type-l4-page #l4-books article:nth-child(2n + 1) {
    clear: left;
  }
}

.node-type-l4-page #l4-books article .field-book-image,
.node-type-l4-page #l4-books article .field-guide-image {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}

.node-type-l4-page #l4-books article .group-book-list-info {
  padding-left: 0;
}

.node-type-l4-page #l4-books article h3 {
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  font-size: 1.05em;
}

.node-type-l4-page #l4-books article a {
  border-bottom: none;
}

.node-type-l4-page .group-page-intro-bottom + section::before, .node-type-l4-page .group-page-intro-bottom + section::after {
  display: none !important;
}

.node-type-l4-page .field-l4-related-page {
  border: none;
  margin-bottom: 2em;
}

.node-type-l4-page .field-l4-related-page > .item {
  padding: 0 !important;
}

.node-type-l4-page .field-l4-related-page .field-label {
  background-color: #1E7CBC;
  color: #fff;
  margin: 0;
  padding: 15px 15px 0;
  font-size: 0.9em;
  font-weight: normal;
}

.node-type-l4-page .field-l4-related-page article {
  padding: 0 !important;
  width: 100%;
  background-color: #1E7CBC;
  float: none;
}

.node-type-l4-page .field-l4-related-page article .field-title-field {
  margin: 0;
  padding: 0 15px 15px;
  position: static;
}

.node-type-l4-page .field-l4-related-page article h4 {
  padding: 0;
  margin: 0;
}

.node-type-l4-page .field-l4-related-page article h4 a {
  color: #fff !important;
  padding: 0 !important;
}

.node-type-l4-page .field-l4-related-page article .field-l4-image {
  width: 100%;
  padding: 0;
  margin: 0;
}

.node-type-l4-page .field-l4-related-page article .field-l4-image a {
  padding: 0 !important;
}

.node-type-l4-page .field-l4-related-page article .node__title {
  display: none;
}

section.l4-books .view-mode-longteaser,
section.l4-guides .view-mode-longteaser {
  margin-bottom: 15px;
}

section.l4-books .view-mode-longteaser h3,
section.l4-guides .view-mode-longteaser h3 {
  margin-top: 0;
}

section.l4-books .view-mode-longteaser .field-theme-image,
section.l4-books .view-mode-longteaser .field-guide-image,
section.l4-guides .view-mode-longteaser .field-theme-image,
section.l4-guides .view-mode-longteaser .field-guide-image {
  float: left;
  margin-right: 0;
}

section.l4-books .view-mode-longteaser h3,
section.l4-books .view-mode-longteaser .field-theme-description,
section.l4-books .view-mode-longteaser .field-guide-description,
section.l4-guides .view-mode-longteaser h3,
section.l4-guides .view-mode-longteaser .field-theme-description,
section.l4-guides .view-mode-longteaser .field-guide-description {
  float: right;
}

section.l4-books .view-mode-longteaser h3 img,
section.l4-books .view-mode-longteaser .field-theme-description img,
section.l4-books .view-mode-longteaser .field-guide-description img,
section.l4-guides .view-mode-longteaser h3 img,
section.l4-guides .view-mode-longteaser .field-theme-description img,
section.l4-guides .view-mode-longteaser .field-guide-description img {
  display: none;
}

@media (min-width: 48em) {
  section.l4-books .view-mode-longteaser .field-theme-image,
  section.l4-books .view-mode-longteaser .field-guide-image,
  section.l4-guides .view-mode-longteaser .field-theme-image,
  section.l4-guides .view-mode-longteaser .field-guide-image {
    width: 14%;
    padding-right: 15px;
  }
  section.l4-books .view-mode-longteaser h3,
  section.l4-books .view-mode-longteaser .field-theme-description,
  section.l4-books .view-mode-longteaser .field-guide-description,
  section.l4-guides .view-mode-longteaser h3,
  section.l4-guides .view-mode-longteaser .field-theme-description,
  section.l4-guides .view-mode-longteaser .field-guide-description {
    width: 86%;
    padding-left: 15px;
  }
}

@media (max-width: 47.9375em) {
  section.l4-books .view-mode-longteaser .field-theme-image,
  section.l4-books .view-mode-longteaser .field-guide-image,
  section.l4-guides .view-mode-longteaser .field-theme-image,
  section.l4-guides .view-mode-longteaser .field-guide-image {
    width: 25%;
    padding-right: 7.5px;
  }
  section.l4-books .view-mode-longteaser h3,
  section.l4-books .view-mode-longteaser .field-theme-description,
  section.l4-books .view-mode-longteaser .field-guide-description,
  section.l4-guides .view-mode-longteaser h3,
  section.l4-guides .view-mode-longteaser .field-theme-description,
  section.l4-guides .view-mode-longteaser .field-guide-description {
    width: 75%;
    padding-left: 7.5px;
  }
}

body:not(.node-type-l4-page) .group-video-feed {
  position: relative;
}

body:not(.node-type-l4-page) .group-video-feed:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  body:not(.node-type-l4-page) .group-video-feed:after {
    display: none;
  }
}

body:not(.node-type-l4-page) .group-video-feed:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  body:not(.node-type-l4-page) .group-video-feed:before {
    display: none;
  }
}

.section-videos #landing-webcasts-browse {
  position: relative;
}

.section-videos #landing-webcasts-browse:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .section-videos #landing-webcasts-browse:after {
    display: none;
  }
}

.section-videos #landing-webcasts-browse:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .section-videos #landing-webcasts-browse:before {
    display: none;
  }
}

.section-videos #interviews {
  position: relative;
}

.section-videos #interviews:before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

@media print {
  .section-videos #interviews:before {
    display: none;
  }
}

.section-videos #interviews:after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

@media print {
  .section-videos #interviews:after {
    display: none;
  }
}

@media (min-width: 48em) {
  .section-videos #interviews > div {
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
  }
  .section-videos #interviews .node-video-interview h3 {
    margin-bottom: 0;
  }
}

.section-videos #interviews .field-interview-video img {
  display: block !important;
  width: 100%;
  height: auto;
}

/* Articles */
.group-article-publish-info,
.group-publish-info,
.group-article-intro,
.group-blog-intro,
.group-basic-intro {
  overflow: hidden;
}

.group-article-intro,
.group-research-intro,
.group-blog-intro,
.group-basic-intro,
.node-quiz.view-mode-full .field-basic-description,
.node-quiz.view-mode-full .field-description-field {
  color: #00539B;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 2em;
}

.group-article-intro .field,
.group-research-intro .field,
.group-blog-intro .field,
.group-basic-intro .field,
.node-quiz.view-mode-full .field-basic-description .field,
.node-quiz.view-mode-full .field-description-field .field {
  margin-bottom: 0;
}

.group-basic-intro {
  overflow: visible;
}

.group-article-intro,
.group-blog-intro {
  border-top: 1px solid #646464;
  border-bottom: 1px solid #646464;
  padding: 15px 0;
  clear: both;
}

/* Internal Navigation */
.node-video-gallery .internal-navigation h3 {
  margin-left: 10px;
}

.internal-navigation h3,
.breakout h3 {
  font-size: 18px;
  font-size: 1.2857142857rem;
  line-height: 1.275;
}

@media (min-width: 48em) {
  .internal-navigation,
  .breakout {
    width: 33.33%;
    float: right;
    margin: 0 0 30px 60px;
    padding-left: 20px;
    background: url("../images/internal-nav-bg.png") #fff no-repeat left center;
    background-size: auto 150px;
    position: relative;
    z-index: 20;
    min-height: 150px;
  }
  .internal-navigation .item-list,
  .breakout .item-list {
    border-left: 1px solid #ccc;
    overflow: visible;
  }
  .internal-navigation ul,
  .breakout ul {
    padding-left: 0;
  }
  .internal-navigation ul li,
  .breakout ul li {
    list-style-type: none;
    display: block;
    padding-left: 20px !important;
    text-indent: -25px !important;
    margin-bottom: 0.5em;
    font-size: 12px;
  }
  .internal-navigation ul li::before,
  .breakout ul li::before {
    content: "";
    display: inline-block;
    background: url("../images/internal-nav-bullet.png") no-repeat top left;
    background-size: auto 10px;
    width: 25px;
    height: 10px;
    color: rgba(0, 0, 0, 0) !important;
    position: relative;
    top: 6px;
    margin-left: 0 !important;
  }
}

@media (max-width: 47.9375em) {
  .internal-navigation,
  .breakout {
    border-bottom: 1px solid #646464;
    padding-bottom: 1em;
    margin-bottom: 3em;
  }
}

li a,
.views-row a {
  font-weight: inherit;
}

@media (min-width: 48em) {
  .breakout ul {
    border-left: 1px solid #ccc;
  }
}

/* Comments */
.comments {
  clear: both;
  padding-top: 1em;
  margin-top: 0 !important;
}

.comments h2 {
  margin-top: 0;
}

@media (min-width: 48em) {
  .comments {
    width: 75%;
    padding-right: 30px;
  }
}

.comment .submitted {
  color: #aaa;
  font-style: italic;
}

.comment .links {
  margin-bottom: 15px;
  display: block;
}

.field-article-kicker {
  color: #B30425;
  font-size: 18px;
}

.node-type-article h1 {
  margin-top: 0;
}

@media (min-width: 60em) {
  .node-type-article .field-article-image {
    margin-bottom: 0;
  }
}

.node-type-article .field-article-body {
  margin: 3em 0;
  position: relative;
  z-index: 10;
}

.node-type-article .field-article-body h2 {
  position: relative;
  clear: both;
}

.node-type-article .field-article-body h2:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-article .field-article-body h2:after {
    display: none;
  }
}

.node-type-article .field-article-body h2:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-article .field-article-body h2:before {
    display: none;
  }
}

.node-type-article .field-article-body h2.norule {
  clear: none;
}

.node-type-article .field-article-body h2.norule::before, .node-type-article .field-article-body h2.norule::after {
  display: none !important;
}

.node-type-article #author-articles h2 {
  font-size: 18px;
}

.node-type-article #author-articles h3 {
  font-size: 1em;
}

.node-type-article .field-article-body ul {
  overflow: auto;
}

.node-type-article .field-article-body ul.links {
  float: none !important;
  width: 100%;
}

.node-type-article .field-article-body ul.links li {
  list-style-type: disc;
}

.node-type-article .field-body,
.node-type-blog-post .field-body {
  margin-bottom: 45px;
}

/* Special Landing Pages */
#landing-webcasts-featured .field-title-field {
  display: none;
}

#landing-webcasts-browse .node-webcast {
  margin-bottom: 15px;
}

#landing-webcasts-browse .node-webcast .node__title {
  display: none;
}

#landing-webcasts-browse .node-webcast .video-title {
  display: none;
}

@media (min-width: 48em) {
  #landing-webcasts-browse .node-playlist {
    width: 25%;
    float: left;
    padding-right: 15px;
  }
  #landing-webcasts-browse .field-title-field,
  #landing-webcasts-browse .field-webcast-intro {
    width: 75%;
    padding-left: 15px;
    float: left;
  }
}

@media (max-width: 47.9375em) {
  #landing-webcasts-browse .node-playlist .field-playlist-youtube-url {
    margin-bottom: 0;
  }
}

#landing-webcasts-browse .field-title-field {
  margin: 0;
}

#landing-webcasts-browse .field-title-field h3 {
  margin-top: 0;
}

#landing-webcasts-browse .node-playlist header {
  display: none;
}

/* Webcast */
.node-type-webcast .field-group-div h2 {
  border-top: 1px solid #646464;
  padding-top: 15px;
  margin-top: 45px;
}

.node-type-webcast .group-page-intro-bottom h2,
.node-type-webcast .group-page-intro-inner h2 {
  border-top: none;
}

.node-type-webcast .field-webcast-video .node__title,
.node-type-webcast .field-webcast-video .group-page-intro-bottom {
  display: none;
}

/* Classroom Module */
.node-type-classroom-video .field-classroom-video .group-page-intro-bottom,
.node-type-classroom-video .field-classroom-reflection .group-page-intro-bottom {
  display: none;
}

.node-type-classroom-video .field-classroom-video header {
  display: none;
}

.node-classroom-video.view-mode-full .field-classrrom-grade,
.node-classroom-video.view-mode-full .field-classroom-teacher {
  margin-bottom: 0;
  float: left;
  margin-right: 7.5px;
}

.node-classroom-video.view-mode-full .field-classroom-teacher + .field-classrrom-grade::before {
  content: "|";
}

.node-classroom-video.view-mode-full .field-classroom-location {
  clear: both;
}

div.collapsible {
  border: 1px solid #1E7CBC;
  margin-bottom: 24px;
}

div.collapsible > h3 {
  background-color: #1E7CBC;
  padding: 12px 12px 12px 36px;
  margin: 0;
  background-image: url("../images/accordion-arrow.png");
  background-repeat: no-repeat;
  background-position: top 23px left 12px;
  background-size: 16px auto;
}

div.collapsible > h3 a {
  color: #fff !important;
  border-bottom: none;
}

div.collapsible .field-group-format-wrapper {
  padding: 12px;
}

div.collapsible.collapsed h3 {
  background-position: top -13px left 12px !important;
}

/* Sidebar Items */
.node-type-article h1,
.node-type-research h1 {
  margin-top: 0;
  margin-bottom: 0;
  clear: both;
}

@media (min-width: 48em) {
  .node-type-article #content .links li.comment-add,
  .node-type-research #content .links li.comment-add {
    float: right;
  }
  .node-type-article #content .links li.es,
  .node-type-article #content .links li.en,
  .node-type-research #content .links li.es,
  .node-type-research #content .links li.en {
    float: left;
  }
}

.node-type-article .field-article-author,
.node-type-article .field-article-published,
.node-type-article .field-blog-author,
.node-type-research .field-article-author,
.node-type-research .field-article-published,
.node-type-research .field-blog-author,
.node-type-blog-post .field-article-author,
.node-type-blog-post .field-article-published,
.node-type-blog-post .field-blog-author {
  display: inline-block;
  font-size: 18px;
  float: left;
  color: #00539B;
  margin-right: 4px;
}

.node-type-article .field-article-author a,
.node-type-article .field-article-published a,
.node-type-article .field-blog-author a,
.node-type-research .field-article-author a,
.node-type-research .field-article-published a,
.node-type-research .field-blog-author a,
.node-type-blog-post .field-article-author a,
.node-type-blog-post .field-article-published a,
.node-type-blog-post .field-blog-author a {
  font-weight: 400;
}

.node-type-article .field-article-author .field-label,
.node-type-article .field-article-published .field-label,
.node-type-article .field-blog-author .field-label,
.node-type-research .field-article-author .field-label,
.node-type-research .field-article-published .field-label,
.node-type-research .field-blog-author .field-label,
.node-type-blog-post .field-article-author .field-label,
.node-type-blog-post .field-article-published .field-label,
.node-type-blog-post .field-blog-author .field-label {
  display: inline;
  font-weight: normal;
  margin-right: 5px;
}

.node-type-article .field-article-author .field-items,
.node-type-article .field-article-author .field-item,
.node-type-article .field-article-author .item,
.node-type-article .field-article-published .field-items,
.node-type-article .field-article-published .field-item,
.node-type-article .field-article-published .item,
.node-type-article .field-blog-author .field-items,
.node-type-article .field-blog-author .field-item,
.node-type-article .field-blog-author .item,
.node-type-research .field-article-author .field-items,
.node-type-research .field-article-author .field-item,
.node-type-research .field-article-author .item,
.node-type-research .field-article-published .field-items,
.node-type-research .field-article-published .field-item,
.node-type-research .field-article-published .item,
.node-type-research .field-blog-author .field-items,
.node-type-research .field-blog-author .field-item,
.node-type-research .field-blog-author .item,
.node-type-blog-post .field-article-author .field-items,
.node-type-blog-post .field-article-author .field-item,
.node-type-blog-post .field-article-author .item,
.node-type-blog-post .field-article-published .field-items,
.node-type-blog-post .field-article-published .field-item,
.node-type-blog-post .field-article-published .item,
.node-type-blog-post .field-blog-author .field-items,
.node-type-blog-post .field-blog-author .field-item,
.node-type-blog-post .field-blog-author .item {
  display: inline-block;
}

.node-type-article .field-topic-page,
.node-type-research .field-topic-page,
.node-type-blog-post .field-topic-page {
  font-size: 12px;
  font-size: 0.8571428571rem;
  clear: left;
}

.node-type-research .field-research-author,
.node-type-research .field-research-year,
.node-type-research .field-research-organization {
  margin-bottom: 0;
  margin-top: 9px;
}

.field-article-references,
.field-article-citations,
.field-article-endnotes,
.field-article-reprints,
.field-research-citation,
.field-blog-attachments,
.comments,
.node-article #author-articles {
  clear: both;
  margin-bottom: 1em;
  margin-top: 2em;
}

.field-article-references .field-label,
.field-article-references h2,
.field-article-references h3,
.field-article-citations .field-label,
.field-article-citations h2,
.field-article-citations h3,
.field-article-endnotes .field-label,
.field-article-endnotes h2,
.field-article-endnotes h3,
.field-article-reprints .field-label,
.field-article-reprints h2,
.field-article-reprints h3,
.field-research-citation .field-label,
.field-research-citation h2,
.field-research-citation h3,
.field-blog-attachments .field-label,
.field-blog-attachments h2,
.field-blog-attachments h3,
.comments .field-label,
.comments h2,
.comments h3,
.node-article #author-articles .field-label,
.node-article #author-articles h2,
.node-article #author-articles h3 {
  font-size: 22px;
  font-size: 1.5714285714rem;
  line-height: 1.2;
  font-weight: 300;
  border-top: 1px solid #646464;
  padding-top: 10px;
  margin-bottom: 1em;
  font-family: "myriad-pro", verdana, sans-serif;
}

.field-research-citation .field-label,
.field-research-citation h2,
.field-research-citation h3 {
  border-top: none;
}

.node-type-news .field-news-link .field-label {
  display: inline-block;
  float: left;
  font-weight: normal;
  padding-right: 4px;
  margin-top: 2px;
  font-size: 15px;
  color: #000000;
}

.node-type-news .submitted {
  color: #aaa;
  margin-bottom: 15px;
}

/* Book Pages */
.node-type-cc-book .field-book-image {
  width: 130px;
  float: left;
  padding-right: 15px;
  margin-right: 15px;
}

.node-type-cc-book .textformatter-list {
  display: inline;
}

.node-type-cc-book .group-page-intro-bottom {
  margin-top: 18px;
}

.node-type-cc-book .field-title-field {
  margin-bottom: 0.5em;
}

.node-type-cc-book .field-title-field h1 {
  margin-bottom: 0;
}

.node-type-cc-book .field-book-author,
.node-type-cc-book .field-book-illustrator,
.node-type-cc-book .field-book-editor,
.node-type-cc-book .field-book-contributor {
  margin-bottom: 9px;
  font-size: 18px;
}

.node-type-cc-book .field-book-author a,
.node-type-cc-book .field-book-illustrator a,
.node-type-cc-book .field-book-editor a,
.node-type-cc-book .field-book-contributor a {
  font-weight: normal !important;
}

.node-type-cc-book .field-book-author.clearfix::after,
.node-type-cc-book .field-book-illustrator.clearfix::after,
.node-type-cc-book .field-book-editor.clearfix::after,
.node-type-cc-book .field-book-contributor.clearfix::after {
  clear: none;
}

.node-type-cc-book .field-book-author .field-label,
.node-type-cc-book .field-book-illustrator .field-label,
.node-type-cc-book .field-book-editor .field-label,
.node-type-cc-book .field-book-contributor .field-label {
  display: inline;
  font-weight: normal;
  margin-right: 5px;
}

.node-type-cc-book .field-book-author .field-items,
.node-type-cc-book .field-book-author .field-item,
.node-type-cc-book .field-book-author .item,
.node-type-cc-book .field-book-illustrator .field-items,
.node-type-cc-book .field-book-illustrator .field-item,
.node-type-cc-book .field-book-illustrator .item,
.node-type-cc-book .field-book-editor .field-items,
.node-type-cc-book .field-book-editor .field-item,
.node-type-cc-book .field-book-editor .item,
.node-type-cc-book .field-book-contributor .field-items,
.node-type-cc-book .field-book-contributor .field-item,
.node-type-cc-book .field-book-contributor .item {
  margin-right: 5px;
}

@media (min-width: 48em) {
  #books-topics .listing-items {
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2;
    -webkit-column-gap: 15px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 15px;
    /* Firefox */
    column-gap: 15px;
  }
  #books-topics .listing-items .book-topic {
    -webkit-column-break-inside: avoid;
    /* Chrome, Safari */
    page-break-inside: avoid;
    /* Theoretically FF 20+ */
    break-inside: avoid-column;
    /* IE 11 */
    display: table;
    /* Actually FF 20+ */
  }
  #books-topics .listing-items .listing-items {
    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;
  }
}

#books-topics .listing-items .book-topic h3 {
  margin-bottom: 10px;
}

/* Author Pages */
.node-video-interview .field-interview-title {
  font-size: 18px;
  color: #00539B;
  margin-right: 4px;
}

.node-video-interview.view-mode-full .author-link a {
  background-color: #00539B;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
  width: auto;
}

.node-video-interview.view-mode-full .author-link a:hover {
  text-decoration: none;
  background-color: #003868;
}

.node-video-interview.view-mode-full .author-link a:active, .node-video-interview.view-mode-full .author-link a:focus {
  text-decoration: none;
  background-color: #003868;
}

.node-video-interview.view-mode-full .node-playlist header,
.node-video-interview.view-mode-full .embedded-video {
  max-width: 500px;
  margin: 0 auto;
}

.node-type-author .field-title-field {
  margin-bottom: 0.5em;
}

.node-type-author .field-title-field h1 {
  margin: 0;
}

.node-type-author .field-author-photo {
  width: 25%;
  float: left;
  padding-right: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.node-type-author #author-articles,
.node-type-author #author-books,
.node-type-author .group-author-interview,
.node-type-author #author-books-illustrator {
  clear: both;
  position: relative;
  margin-bottom: 2em;
}

.node-type-author #author-articles:after,
.node-type-author #author-books:after,
.node-type-author .group-author-interview:after,
.node-type-author #author-books-illustrator:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-author #author-articles:after,
  .node-type-author #author-books:after,
  .node-type-author .group-author-interview:after,
  .node-type-author #author-books-illustrator:after {
    display: none;
  }
}

.node-type-author #author-articles:before,
.node-type-author #author-books:before,
.node-type-author .group-author-interview:before,
.node-type-author #author-books-illustrator:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-author #author-articles:before,
  .node-type-author #author-books:before,
  .node-type-author .group-author-interview:before,
  .node-type-author #author-books-illustrator:before {
    display: none;
  }
}

.node-type-author #author-articles h2,
.node-type-author #author-books h2,
.node-type-author .group-author-interview h2,
.node-type-author #author-books-illustrator h2 {
  margin-top: 0;
}

.node-type-author .group-author-interview .node-video-interview header {
  display: none;
}

.node-type-author .group-author-interview .node-playlist header {
  display: block;
}

/* Glossary */
.view-filters {
  margin-bottom: 30px;
}

.views-exposed-widgets {
  position: relative;
}

.views-exposed-widgets .views-exposed-widget {
  margin-top: 10px;
}

@media (min-width: 48em) {
  .views-exposed-widgets .views-exposed-widget {
    width: 30%;
    padding-right: 0;
    padding-left: 15px;
    overflow: hidden;
  }
}

@media (max-width: 47.9375em) {
  .views-exposed-widgets .views-exposed-widget {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }
}

.views-exposed-widgets .views-exposed-widget:first-child {
  padding-left: 0;
}

.views-exposed-widgets .views-exposed-widget input {
  width: 100%;
}

.views-exposed-widgets .views-exposed-widget select {
  max-width: none;
  width: 100%;
  width: calc(100% + 16px);
}

.views-exposed-widgets .views-submit-button input {
  background-color: #00539B;
  margin-top: 25px !important;
}

@media (min-width: 48em) {
  .views-exposed-widgets .views-submit-button {
    width: 15%;
    min-width: 0;
  }
}

.views-exposed-widgets .views-reset-button input {
  color: #00539B;
  font-weight: 700;
  border: none;
  margin-top: 25px !important;
  width: auto !important;
  -webkit-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(all 0.3s ease, false, false, false, false, false, false, false, false, false);
}

.views-exposed-widgets .views-reset-button input:hover, .views-exposed-widgets .views-reset-button input:focus {
  text-decoration: underline;
  background-color: transparent;
}

@media (max-width: 47.9375em) {
  .views-exposed-widgets #edit-title-field-value-wrapper {
    width: 100%;
  }
}

.views-exposed-widgets input[type="text"] {
  text-align: left;
}

@media (min-width: 48em) {
  .views-exposed-widgets input[type="text"] {
    width: 263px;
    padding: 10px 50px 10px 20px;
  }
}

@media (max-width: 47.9375em) {
  .views-exposed-widgets input[type="text"] {
    width: 100%;
  }
}

/* Book Lists */
.field-book-author .item + .item::before,
.field-book-illustrator .item + .item::before,
.field-book-editor .item + .item::before,
.field-book-contributor .item + .item::before {
  content: ", ";
  position: relative;
  left: -4px;
}

.view-mode-full .field-book-author,
.view-mode-full .field-book-illustrator,
.view-mode-full .field-book-editor,
.view-mode-full .field-book-contributor {
  color: #00539B;
}

.section-books-authors .field-basic-body {
  border-top: none !important;
  padding-top: 0 !important;
}

.section-books-authors .field-basic-body .bookfinder-intro img {
  width: 15% !important;
  margin-right: 15px;
  margin-bottom: 15px;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons {
  float: right;
  width: 25%;
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #646464;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons p {
  font-weight: bold;
  margin-bottom: 15px;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a {
  list-style-type: none;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  display: block;
  color: #fff;
  border-bottom: none;
  font-weight: 700 !important;
  font-size: 0.95rem;
  width: 100%;
  margin-bottom: 15px;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a:hover, .section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a:focus {
  transition: 0.2s all ease-in-out;
  box-shadow: #bbbbbb 0px 0px 8px;
  border-radius: 8px;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a:focus {
  outline-color: #646464;
}

@media (min-width: 48em) {
  .section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a span {
    display: block;
    width: 85%;
  }
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a:nth-child(2) {
  background-color: #feb846;
  color: #000;
  position: relative;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a:nth-child(2)::before {
  width: 20%;
  height: 65%;
  background-image: url("../images/rocket.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a:last-child {
  background-color: #077E9C;
}

.section-books-authors .field-basic-body .bookfinder-intro .bookfinder-buttons a:last-child::before {
  width: 40%;
  height: 100%;
  background-image: url("../images/stars-white.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
}

.section-books-authors .book-cover-description .field-book-image {
  width: 100%;
  padding: 0;
}

.section-books-authors .book-cover-description .book-info-button {
  overflow: hidden;
}

.view-bookfinder .views-row .node-cc-book {
  padding: 0;
}

.view-bookfinder .views-row:first-child,
.view-bookfinder .view-filters + .views-row {
  border-top: none;
}

.view-bookfinder .views-widget-filter-field_book_audiobook_value {
  width: auto !important;
  height: 25px !important;
  min-height: 0 !important;
}

@media (min-width: 48em) {
  .view-bookfinder .views-widget-filter-field_book_audiobook_value {
    padding-top: 45px;
    min-height: 25px !important;
    height: auto !important;
  }
}

.view-bookfinder .views-widget-filter-field_book_audiobook_value .form-checkboxes input {
  width: auto !important;
}

.view-bookfinder .views-widget-filter-field_book_audiobook_value .form-checkboxes label {
  display: inline !important;
}

.view-bookfinder .views-widget-filter-field_book_audiobook_value input[type="checkbox"]:checked + label {
  font-weight: bold;
}

.view-bookfinder .views-exposed-widgets {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
}

.view-bookfinder .views-exposed-widgets .views-exposed-widget {
  padding-left: 0px;
}

@media (min-width: 48em) {
  .view-bookfinder .views-exposed-widgets .views-exposed-widget {
    width: 32%;
  }
}

@media (min-width: 64em) {
  .view-bookfinder .views-exposed-widgets .views-exposed-widget {
    width: 24%;
    padding-right: 0;
    overflow: hidden;
  }
}

@media (max-width: 47.9375em) {
  .view-bookfinder .views-exposed-widgets .views-exposed-widget {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }
}

.view-bookfinder .views-exposed-widgets .views-exposed-widget:first-child {
  padding-left: 0;
}

.view-bookfinder .views-exposed-widgets .views-exposed-widget input {
  width: 100%;
}

.view-bookfinder .views-exposed-widgets .views-exposed-widget select {
  max-width: none;
  width: 100%;
}

.view-bookfinder .views-exposed-widgets .views-submit-button input {
  background-color: #00539B;
  margin-top: 25px !important;
  -webkit-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false);
}

.view-bookfinder .views-exposed-widgets .views-submit-button input:hover, .view-bookfinder .views-exposed-widgets .views-submit-button input:focus {
  background-color: #003868;
}

@media (min-width: 48em) {
  .view-bookfinder .views-exposed-widgets .views-submit-button {
    width: 14%;
    min-width: 0;
  }
}

@media (max-width: 47.9375em) {
  .view-bookfinder .views-exposed-widgets #edit-title-field-value-wrapper {
    width: 100%;
  }
}

.view-bookfinder .views-exposed-widgets input[type="text"] {
  text-align: left;
}

@media (min-width: 48em) {
  .view-bookfinder .views-exposed-widgets input[type="text"] {
    width: 263px;
    padding: 10px 50px 10px 20px;
  }
}

@media (max-width: 47.9375em) {
  .view-bookfinder .views-exposed-widgets input[type="text"] {
    width: 100%;
  }
}

.view-bookfinder {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.view-bookfinder::after {
  display: none;
}

.view-bookfinder header {
  display: none;
}

.view-bookfinder .item-list,
.view-bookfinder .view-filters {
  width: 100%;
}

.view-bookfinder input {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  background-color: #fff;
}

.view-bookfinder input:hover, .view-bookfinder input:focus {
  border-color: #00539B;
}

.view-bookfinder .views-exposed-widget {
  overflow: visible !important;
}

.view-bookfinder .views-exposed-widget .option {
  float: none;
}

.view-bookfinder .views-exposed-widget label {
  float: left;
  font-size: 13px;
}

.view-bookfinder .views-exposed-widget .description {
  clear: both;
  border: 1px solid #00539B;
  background-color: #fff;
  font-size: 13px;
  color: #555;
  position: absolute;
  width: 80%;
  left: 0;
  padding: 10px;
  right: 0;
  top: 32px;
  z-index: 9999;
  display: none;
}

@media (min-width: 48em) {
  .view-bookfinder .views-exposed-widget .description {
    left: 20%;
    right: 0;
  }
}

.view-bookfinder .views-exposed-widget .description.expanded {
  display: block;
}

.view-bookfinder .views-exposed-widget .description-info {
  background: transparent;
  border: 1px solid transparent;
  -webkit-appearance: none;
  appearance: none;
  height: 28px;
  width: auto;
  float: left;
}

.view-bookfinder .views-exposed-widget .description-info + .description-info {
  display: none;
}

.view-bookfinder .views-exposed-widget .description-info::before {
  color: #00539B;
  content: "\f05a";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 16px;
}

.view-bookfinder .views-exposed-widget .description-info span {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.view-bookfinder .views-exposed-widget .description-info:hover, .view-bookfinder .views-exposed-widget .description-info:focus {
  outline: none;
  border-color: #00539B;
}

.view-bookfinder .views-exposed-widget .description-info:hover::before, .view-bookfinder .views-exposed-widget .description-info:focus::before {
  color: #00539B;
}

.view-bookfinder .item-list .pager {
  border: none;
}

.view-bookfinder .item-list .pager li {
  margin-right: 10px;
}

.view-bookfinder .item-list .pager li a {
  padding: 12px 18px !important;
  color: black;
  background-color: #eee;
  text-align: center;
  border-radius: 4px;
  min-width: 40px;
}

.view-bookfinder .item-list .pager li:hover, .view-bookfinder .item-list .pager li:focus {
  -webkit-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false);
}

.view-bookfinder .item-list .pager li:hover a, .view-bookfinder .item-list .pager li:focus a {
  color: white;
  background-color: #00539B;
}

.view-bookfinder .item-list .pager .pager-ellipsis {
  background-color: transparent;
  padding: 0 !important;
}

.view-bookfinder .item-list .pager .pager-ellipsis:hover, .view-bookfinder .item-list .pager .pager-ellipsis:focus {
  background-color: transparent;
}

.view-bookfinder .item-list .pager .pager-current {
  color: #fff;
  background-color: #00539B;
  font-weight: bold;
  padding: 12px 18px !important;
  border-radius: 4px;
}

.view-bookfinder .node-cc-book {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(92, 95, 97, 0.25);
}

.view-bookfinder .book-details {
  padding: 3.75px 2.5px;
  background: white;
  border-radius: 0 0 4px 4px;
}

@media (min-width: 37.5em) {
  .view-bookfinder .book-details {
    padding: 18px;
  }
}

.view-bookfinder .book-details * {
  font-size: 0.9rem;
}

.view-bookfinder .book-details h2 {
  margin: 0 0 4px;
  font-weight: 500;
}

.view-bookfinder .book-details h2 a,
.view-bookfinder .book-details h2 .field-bookfinder-title div {
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 700;
}

.view-bookfinder .book-details h2 a:hover, .view-bookfinder .book-details h2 a:focus,
.view-bookfinder .book-details h2 .field-bookfinder-title div:hover,
.view-bookfinder .book-details h2 .field-bookfinder-title div:focus {
  text-decoration: underline;
}

.view-bookfinder .book-details .field {
  margin-bottom: 0;
}

.view-bookfinder .book-details .field-label {
  font-weight: normal;
}

.view-bookfinder .book-details .field > * {
  display: inline;
  margin-right: 5px;
}

.view-bookfinder .book-cover-description {
  border-bottom: 3px solid #000;
  position: relative;
  background-image: url("../images/default-red.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-radius: 4px 4px 0 0;
  min-height: 160px;
  width: 100%;
  height: 100%;
}

.view-bookfinder .field-book-image {
  margin: 0;
}

.view-bookfinder .book-description-overlay {
  background: rgba(0, 83, 155, 0.95);
  padding: 18px;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px 4px 0 0;
  max-height: 50em;
  -webkit-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(all 1s false false), false, false, false, false, false, false, false, false, false);
}

.view-bookfinder .book-description-overlay[aria-hidden="true"] {
  overflow: hidden;
  max-height: 0;
  top: 100%;
  padding: 0;
}

.view-bookfinder .book-description-overlay * {
  font-size: 1rem;
}

.view-bookfinder .book-description-overlay a.tertiary {
  margin-top: 9px;
  margin-bottom: 4.5px;
  appearance: none;
  border: none;
  color: #fff;
}

.view-bookfinder .book-description-overlay a.tertiary:hover, .view-bookfinder .book-description-overlay a.tertiary:focus {
  border-bottom: 1px dotted #fff;
}

.view-bookfinder .book-description-overlay a.tertiary i {
  margin-left: 5px;
}

.view-bookfinder .book-info-button {
  color: #fff;
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  margin: auto;
  appearance: none;
  display: inline-block;
  background: #00539B;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 0;
  border: 1px solid #00539B;
  border-radius: 18px;
  width: 32px;
  z-index: 3;
  white-space: nowrap;
  transition: all 0.5s ease;
  overflow: hidden;
}

.view-bookfinder .book-info-button span {
  display: inline-block;
  line-height: 0;
  text-align: left;
}

.view-bookfinder .book-info-button i {
  font-size: 32px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  transform: scale(1);
  position: relative;
  bottom: -2px;
}

.view-bookfinder .book-info-button:hover {
  border-color: #000;
  max-width: 118px;
  width: 100%;
  padding: 0 8px 0 2px;
}

.view-bookfinder .book-info-button:hover i {
  transform: scale(0.5);
  margin-right: -6px;
  bottom: -1px;
}

.view-bookfinder .book-info-button:hover span {
  line-height: 1;
  max-width: 200px;
  vertical-align: middle;
  padding-left: 2px;
}

.view-bookfinder .book-info-button:focus {
  border-color: #000;
  padding: 0;
  border-radius: 18px;
  text-align: center;
  outline: 2px dotted #B7531C;
  outline-offset: 2px;
}

.view-bookfinder .book-info-button:focus i {
  transform: scale(0.5);
  display: block;
  text-align: center;
  bottom: -1px;
}

.view-bookfinder .book-info-button:focus span {
  max-width: 0;
  overflow: hidden;
  text-indent: -99px;
  position: absolute;
}

.view-bookfinder .book-info-button:focus:hover {
  max-width: 118px;
  width: 100%;
  padding: 0 8px 0 0;
  border-color: #000;
  display: inline-block;
}

.view-bookfinder .book-info-button:focus:hover i {
  display: inline-block;
}

.view-bookfinder .book-info-button:focus:hover span {
  max-width: 200px;
  vertical-align: middle;
  padding-left: 4px;
  opacity: 1;
  text-indent: 0;
  position: relative;
}

.bookshelf-book {
  flex-direction: column;
  padding-left: 3.75px;
  padding-right: 3.75px;
  padding-bottom: 18px;
}

.bookshelf-book .book-cover-description {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-grow: 0;
}

.bookshelf-book .book-cover-description .field-book-cover:not(.no-cover) {
  align-self: flex-end;
}

.bookshelf-book .book-cover-description img {
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.bookshelf-book .book-details {
  flex-grow: 2;
}

@media (min-width: 23.4375em) {
  .bookshelf-book {
    float: left;
    padding-left: 7.5px;
    padding-right: 7.5px;
    display: flex;
  }
  .bookshelf-book > * {
    width: 100%;
  }
  .bookshelf-book > *.views-field > .field-content,
  .bookshelf-book > *.views-field > .field-content > .card {
    height: 100%;
  }
}

@media (min-width: 37.5em) {
  .bookshelf-book {
    padding-bottom: 36px;
  }
}

@media (min-width: 48em) {
  .bookshelf-book {
    padding-bottom: 36px;
  }
}

@media (min-width: 64em) {
  .bookshelf-book {
    width: 33.33%;
  }
  .bookshelf-book:nth-child(3n + 1) {
    clear: both;
  }
}

@media (min-width: 48em) and (max-width: 63.9375em) {
  .bookshelf-book {
    width: 50%;
  }
  .bookshelf-book:nth-child(2n + 1) {
    clear: both;
  }
}

@media (min-width: 64em) {
  .bookshelf-book {
    width: 25%;
  }
  .bookshelf-book:nth-child(4n + 1) {
    clear: both;
  }
}

@media (min-width: 37.5em) and (max-width: 47.9375em) {
  .bookshelf-book {
    width: 33.3333%;
  }
  .bookshelf-book:nth-child(even) {
    padding-left: 18px;
  }
  .bookshelf-book:nth-child(odd) {
    padding-right: 18px;
  }
  .bookshelf-book:nth-child(3n + 1) {
    clear: both;
  }
}

@media (min-width: 23.4375em) and (max-width: 37.4375em) {
  .bookshelf-book {
    width: 50%;
  }
  .bookshelf-book:nth-child(even) {
    padding-left: 9px;
  }
  .bookshelf-book:nth-child(odd) {
    padding-right: 9px;
  }
  .bookshelf-book:nth-child(odd) {
    clear: both;
  }
}

@media (max-width: 23.375em) {
  .bookshelf-book {
    width: 100%;
  }
}

.node-cc-book.node-teaser .field-book-image,
.node-cc-book.view-mode-longteaser .field-book-image {
  float: left;
}

@media (min-width: 48em) {
  .node-cc-book.node-teaser .field-book-image,
  .node-cc-book.view-mode-longteaser .field-book-image {
    width: 18%;
    padding-right: 15px;
  }
}

@media (max-width: 47.9375em) {
  .node-cc-book.node-teaser .field-book-image,
  .node-cc-book.view-mode-longteaser .field-book-image {
    width: 25%;
    padding-right: 7.5px;
  }
}

.node-cc-book.node-teaser .group-book-description-tags,
.node-cc-book.view-mode-longteaser .group-book-description-tags {
  float: left;
}

@media (min-width: 48em) {
  .node-cc-book.node-teaser .group-book-description-tags,
  .node-cc-book.view-mode-longteaser .group-book-description-tags {
    width: 82%;
    padding-left: 15px;
  }
}

@media (max-width: 47.9375em) {
  .node-cc-book.node-teaser .group-book-description-tags,
  .node-cc-book.view-mode-longteaser .group-book-description-tags {
    width: 75%;
    padding-left: 7.5px;
  }
}

.node-cc-book.node-teaser .group-book-contributors,
.node-cc-book.view-mode-longteaser .group-book-contributors {
  margin-bottom: 7.5px;
}

.node-cc-book.node-teaser .group-book-contributors .field-book-illustrator,
.node-cc-book.node-teaser .group-book-contributors .field-book-author,
.node-cc-book.node-teaser .group-book-contributors .field-book-editor,
.node-cc-book.node-teaser .group-book-contributors .field-book-contributor,
.node-cc-book.view-mode-longteaser .group-book-contributors .field-book-illustrator,
.node-cc-book.view-mode-longteaser .group-book-contributors .field-book-author,
.node-cc-book.view-mode-longteaser .group-book-contributors .field-book-editor,
.node-cc-book.view-mode-longteaser .group-book-contributors .field-book-contributor {
  color: #000000;
}

.node-cc-book.node-teaser .group-book-contributors .field,
.node-cc-book.view-mode-longteaser .group-book-contributors .field {
  margin-bottom: 0;
}

.node-cc-book.node-teaser .group-book-contributors .field .field-label,
.node-cc-book.view-mode-longteaser .group-book-contributors .field .field-label {
  font-weight: normal;
}

/* Blog Posts */
.node-type-blog-post .submitted {
  color: #aaa;
}

.node-type-blog-post h1 {
  margin-top: 0;
}

.node-type-blog-post .group-blog-body {
  clear: both;
}

.node-type-blog-post .group-blog-body .field-blog-image {
  float: left;
  width: 25%;
  margin-right: 15px;
  margin-bottom: 15px;
}

.node-type-blog-post .group-blog-intro .field-blog-image {
  width: 25%;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}

.node-type-blog-post .alignleft {
  float: left;
  margin-right: 15px !important;
  margin-bottom: 12px !important;
  margin-left: 0 !important;
}

.node-type-blog-post .alignright {
  float: right;
  margin-left: 15px !important;
  margin-bottom: 12px !important;
  margin-right: 0 !important;
}

.node-type-blog-post img {
  max-width: 100%;
  height: auto !important;
}

.not-front .node-blog-post.node-teaser {
  margin-bottom: 30px;
}

@media (min-width: 48em) {
  .not-front .node-blog-post.node-teaser .field-blog-image {
    width: 25%;
    float: left;
    padding-right: 15px;
  }
  .not-front .node-blog-post.node-teaser .field-body,
  .not-front .node-blog-post.node-teaser .field-title-field {
    width: 75%;
    float: right;
    padding-left: 15px;
  }
}

.not-front .node-blog-post.node-teaser .field-title-field {
  margin-bottom: 0;
}

.not-front .node-blog-post.node-teaser .field-title-field h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

#home-blog .node-blog-post.node-teaser .field-blog-image {
  display: none;
}

/* Newsletters */
.node-type-newsletter .field-newsletter-body h2 {
  border-top: 1px solid #646464;
  margin-top: 45px;
  padding-top: 15px;
}

/* Books and Authors */
.node-type-landing.section-books-authors #books-classroom,
.node-type-landing.section-libros-autores #books-classroom {
  position: relative;
}

.node-type-landing.section-books-authors #books-classroom:after,
.node-type-landing.section-libros-autores #books-classroom:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-landing.section-books-authors #books-classroom:after,
  .node-type-landing.section-libros-autores #books-classroom:after {
    display: none;
  }
}

.node-type-landing.section-books-authors #books-classroom:before,
.node-type-landing.section-libros-autores #books-classroom:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-landing.section-books-authors #books-classroom:before,
  .node-type-landing.section-libros-autores #books-classroom:before {
    display: none;
  }
}

.node-type-landing.section-books-authors #books-classroom h2,
.node-type-landing.section-libros-autores #books-classroom h2 {
  margin-top: 0;
}

.node-type-landing.section-books-authors #books-classroom .node-l4-page .node__title,
.node-type-landing.section-libros-autores #books-classroom .node-l4-page .node__title {
  display: none;
}

.node-type-landing.section-books-authors #books-classroom .node-landing .field-title-field,
.node-type-landing.section-libros-autores #books-classroom .node-landing .field-title-field {
  display: none;
}

.node-type-landing.section-books-authors .node-landing.view-mode-longteaser,
.node-type-landing.section-books-authors .node-landing.node-teaser,
.node-type-landing.section-books-authors .node-l4-page.node-teaser,
.node-type-landing.section-libros-autores .node-landing.view-mode-longteaser,
.node-type-landing.section-libros-autores .node-landing.node-teaser,
.node-type-landing.section-libros-autores .node-l4-page.node-teaser {
  overflow: hidden;
  margin-bottom: 15px;
}

@media (min-width: 48em) {
  .node-type-landing.section-books-authors .node-landing.view-mode-longteaser .field-landing-image,
  .node-type-landing.section-books-authors .node-landing.view-mode-longteaser .field-l4-image,
  .node-type-landing.section-books-authors .node-landing.node-teaser .field-landing-image,
  .node-type-landing.section-books-authors .node-landing.node-teaser .field-l4-image,
  .node-type-landing.section-books-authors .node-l4-page.node-teaser .field-landing-image,
  .node-type-landing.section-books-authors .node-l4-page.node-teaser .field-l4-image,
  .node-type-landing.section-libros-autores .node-landing.view-mode-longteaser .field-landing-image,
  .node-type-landing.section-libros-autores .node-landing.view-mode-longteaser .field-l4-image,
  .node-type-landing.section-libros-autores .node-landing.node-teaser .field-landing-image,
  .node-type-landing.section-libros-autores .node-landing.node-teaser .field-l4-image,
  .node-type-landing.section-libros-autores .node-l4-page.node-teaser .field-landing-image,
  .node-type-landing.section-libros-autores .node-l4-page.node-teaser .field-l4-image {
    width: 25%;
    float: left;
    padding-right: 15px;
    margin-right: 15px;
  }
}

.node-type-landing.section-books-authors .node-landing.view-mode-longteaser h3,
.node-type-landing.section-books-authors .node-landing.node-teaser h3,
.node-type-landing.section-books-authors .node-l4-page.node-teaser h3,
.node-type-landing.section-libros-autores .node-landing.view-mode-longteaser h3,
.node-type-landing.section-libros-autores .node-landing.node-teaser h3,
.node-type-landing.section-libros-autores .node-l4-page.node-teaser h3 {
  margin-top: 0;
}

.node-type-landing.section-books-authors #books-topics,
.node-type-landing.section-libros-autores #books-topics {
  position: relative;
}

.node-type-landing.section-books-authors #books-topics:after,
.node-type-landing.section-libros-autores #books-topics:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-landing.section-books-authors #books-topics:after,
  .node-type-landing.section-libros-autores #books-topics:after {
    display: none;
  }
}

.node-type-landing.section-books-authors #books-topics:before,
.node-type-landing.section-libros-autores #books-topics:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-landing.section-books-authors #books-topics:before,
  .node-type-landing.section-libros-autores #books-topics:before {
    display: none;
  }
}

.node-type-landing.section-books-authors #books-topics h2,
.node-type-landing.section-libros-autores #books-topics h2 {
  margin-top: 0;
}

.node-type-landing.section-books-authors #books-themes .listing-items,
.node-type-landing.section-libros-autores #books-themes .listing-items {
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
}

.node-type-landing.section-books-authors #books-themes .listing-items h3,
.node-type-landing.section-libros-autores #books-themes .listing-items h3 {
  font-size: 1.2em;
}

.node-type-landing.section-books-authors #books-themes .node-book-theme,
.node-type-landing.section-libros-autores #books-themes .node-book-theme {
  padding: 7.5px 15px;
}

.node-type-landing.section-books-authors #books-themes .node-book-theme header,
.node-type-landing.section-libros-autores #books-themes .node-book-theme header {
  display: none;
}

@media (min-width: 48em) {
  .node-type-landing.section-books-authors #books-themes .node-book-theme,
  .node-type-landing.section-libros-autores #books-themes .node-book-theme {
    width: 25%;
    float: left;
  }
}

@media (max-width: 47.9375em) {
  .node-type-landing.section-books-authors #books-themes .node-book-theme,
  .node-type-landing.section-libros-autores #books-themes .node-book-theme {
    width: 50%;
    float: left;
  }
}

.node-type-landing.section-books-authors #books-themes .node-landing .field-title-field,
.node-type-landing.section-libros-autores #books-themes .node-landing .field-title-field {
  display: none;
}

/* Video Landing Page */
.featured-video-container,
.embedded-video {
  max-width: 715px;
  margin: 0 auto;
}

.node-type-landing.section-videos #videos-videos,
.node-type-landing.section-videos #videos-subpages,
.node-type-landing.section-video #videos-videos,
.node-type-landing.section-video #videos-subpages {
  margin-bottom: 30px;
}

.node-type-landing.section-videos #videos-videos h2,
.node-type-landing.section-videos #videos-subpages h2,
.node-type-landing.section-video #videos-videos h2,
.node-type-landing.section-video #videos-subpages h2 {
  margin-top: 0;
}

.node-type-landing.section-videos .featured,
.node-type-landing.section-video .featured {
  margin-top: 2em;
  position: relative;
}

.node-type-landing.section-videos .featured:after,
.node-type-landing.section-video .featured:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-landing.section-videos .featured:after,
  .node-type-landing.section-video .featured:after {
    display: none;
  }
}

.node-type-landing.section-videos .featured:before,
.node-type-landing.section-video .featured:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-landing.section-videos .featured:before,
  .node-type-landing.section-video .featured:before {
    display: none;
  }
}

.node-type-landing.section-videos .featured h2,
.node-type-landing.section-video .featured h2 {
  margin-top: 0;
}

@media (min-width: 48em) {
  .node-type-landing.section-videos #videos-subpages > div,
  .node-type-landing.section-videos .listing:not(.featured) .listing-items,
  .node-type-landing.section-video #videos-subpages > div,
  .node-type-landing.section-video .listing:not(.featured) .listing-items {
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
  }
  .node-type-landing.section-videos #landing-webcasts-featured header,
  .node-type-landing.section-video #landing-webcasts-featured header {
    display: none;
  }
  .node-type-landing.section-videos #landing-webcasts-featured .listing-items,
  .node-type-landing.section-videos #landing-webcasts-browse .listing-items,
  .node-type-landing.section-video #landing-webcasts-featured .listing-items,
  .node-type-landing.section-video #landing-webcasts-browse .listing-items {
    margin-left: 0;
    margin-right: 0;
  }
  .node-type-landing.section-videos .listing.featured .listing-items header,
  .node-type-landing.section-video .listing.featured .listing-items header {
    display: none;
  }
}

.node-type-landing.section-videos .view-mode-videomodule .field-title-field,
.node-type-landing.section-videos .view-mode-videomodule .video-title,
.node-type-landing.section-videos .view-mode-videomodule .node-playlist .node__title,
.node-type-landing.section-video .view-mode-videomodule .field-title-field,
.node-type-landing.section-video .view-mode-videomodule .video-title,
.node-type-landing.section-video .view-mode-videomodule .node-playlist .node__title {
  display: none;
}

#landing-webcasts-featured {
  border-top: none;
}

#videos-subpages {
  position: relative;
}

#videos-subpages:before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

@media print {
  #videos-subpages:before {
    display: none;
  }
}

#videos-subpages:after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

@media print {
  #videos-subpages:after {
    display: none;
  }
}

#videos-subpages > div {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Glossary */
#glossary-entries section {
  position: relative;
}

#glossary-entries section:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  #glossary-entries section:after {
    display: none;
  }
}

#glossary-entries section:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  #glossary-entries section:before {
    display: none;
  }
}

#glossary-entries section h2 {
  margin-top: 0;
}

#glossary-entries section .node__title {
  margin-bottom: 5px;
}

#glossary-index {
  text-align: center;
  color: #aaa;
}

#glossary-index span {
  display: inline-block;
  padding: 0 3.75px;
}

/* ELL News */
.node-type-landing.section-ell-news-headlines .node-news {
  margin-bottom: 30px;
}

.node-type-landing.section-ell-news-headlines .node-news .node__title {
  margin-top: 0;
  margin-bottom: 5px;
}

.node-type-landing.section-ell-news-headlines .node-news .field-news-summary {
  margin-bottom: 0;
  margin-top: 7.5px;
}

.node-type-landing.section-ell-news-headlines .node-news .field-news-link {
  margin-bottom: 0;
  font-style: italic;
}

.node-type-landing.section-ell-news-headlines .node-news .field-news-link a {
  color: #aaa;
}

.node-type-landing.section-ell-news-headlines .node-news .submitted {
  color: #aaa;
  margin-bottom: 7.5px;
}

/* Books for Kids, YA */
.node-type-landing .node-35545 .group-content,
.node-type-landing .node-35544 .group-content {
  width: 100% !important;
  padding-right: 0;
  border: none;
}

/* Topics A-Z */
@media (min-width: 48em) {
  .node-type-landing.section-topics .listing > .listing-items,
  .node-type-landing.section-research-reports .listing > .listing-items {
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2;
    -webkit-column-gap: 15px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 15px;
    /* Firefox */
    column-gap: 15px;
    padding-top: 15px;
  }
}

.node-type-landing.section-topics .listing > .listing-items div,
.node-type-landing.section-topics .listing > .listing-items li,
.node-type-landing.section-research-reports .listing > .listing-items div,
.node-type-landing.section-research-reports .listing > .listing-items li {
  margin-bottom: 7.5px;
  margin-left: 15px;
  padding-left: 0 !important;
  text-indent: 0 !important;
}

.node-type-landing.section-topics .listing > .listing-items div::before,
.node-type-landing.section-topics .listing > .listing-items li::before,
.node-type-landing.section-research-reports .listing > .listing-items div::before,
.node-type-landing.section-research-reports .listing > .listing-items li::before {
  color: #000000 !important;
  content: "•";
  padding-right: 7.5px !important;
  margin-left: 0 !important;
}

.node-type-landing.section-topics .listing > .listing-items li,
.node-type-landing.section-research-reports .listing > .listing-items li {
  margin-bottom: 7.5px;
}

.node-type-landing.section-topics .listing > .listing-items a,
.node-type-landing.section-research-reports .listing > .listing-items a {
  font-weight: normal;
}

.node-type-landing.section-topics .node-topic h3,
.node-type-landing.section-research-reports .node-topic h3 {
  margin-top: 0;
}

/* Content Finder */
.view-mode-search_result {
  padding-left: 22.5px;
  margin-bottom: 15px;
}

.view-mode-search_result h3 {
  margin-top: 0;
  margin-bottom: 7.5px;
}

.node-playlist.view-mode-search_result {
  background: url("../images/video-icon.png") no-repeat top 10px left;
  background-size: 14px auto;
}

.author-interview-link {
  background: url("../images/video-icon.png") no-repeat top 7px left;
  background-size: 14px auto;
  padding-left: 20px;
  margin-bottom: 15px;
}

/* FAQ */
#faq-index,
.faq-landing ul {
  list-style-type: disc !important;
  padding-left: 13px !important;
}

#faq-index li,
.faq-landing ul li {
  text-indent: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 5px;
}

#faq-index li::before,
.faq-landing ul li::before {
  display: none;
}

@media (min-width: 48em) {
  .faq-landing {
    width: 75%;
    float: left;
  }
}

#faq-entries > div {
  border-top: 1px solid #646464;
  padding: 3em 0;
}

#faq-entries > div ul.links,
#faq-entries > div ul {
  list-style-type: disc;
  padding-left: 30px;
  margin-top: 7.5px;
  margin-bottom: 15px;
}

#faq-entries > div header {
  display: none;
}

#faq-entries > div .field-faq-question h3 {
  margin-top: 0;
}

#faq-entries > div .field-label {
  font-size: 22px;
  font-size: 1.5714285714rem;
  line-height: 1.2;
  font-weight: 300;
}

.group-page-intro-inner {
  clear: both;
}

.group-page-intro-inner .field-title-field h1 {
  margin-top: 0;
}

.group-page-intro-inner .funding-credit .partner-aft {
  margin-top: 0 !important;
  background-color: #fff;
}

.group-page-intro-inner .funding-credit .partner-nea {
  background-color: #fff;
  margin: 0;
}

@media (min-width: 48em) {
  .group-page-intro-inner .funding-credit {
    width: 25%;
    float: right;
    padding-left: 15px;
    margin-left: 15px;
    margin-bottom: 20px;
  }
  .group-page-intro-inner .field-landing-image,
  .group-page-intro-inner .field-page-image,
  .group-page-intro-inner .field-interview-image {
    width: 33%;
    float: left;
    padding-right: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .group-page-intro-inner .field-theme-image {
    width: 130px;
    float: left;
    padding-right: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
}

.group-page-intro-inner .bookfinder {
  margin-top: 22.5px;
}

.group-page-intro-inner .bookfinder a {
  background-color: #00539B;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
  background-color: #1E7CBC;
  color: #fff;
}

.group-page-intro-inner .bookfinder a:hover {
  text-decoration: none;
  background-color: #003868;
}

.group-page-intro-inner .bookfinder a:active, .group-page-intro-inner .bookfinder a:focus {
  text-decoration: none;
  background-color: #003868;
}

.group-page-intro-inner .bookfinder a:hover {
  background-color: #1a6da6;
}

.group-page-intro-inner .bookfinder a:hover, .group-page-intro-inner .bookfinder a:visited, .group-page-intro-inner .bookfinder a:active {
  color: #fff;
}

.field-guide-documents img {
  display: none;
}

.field-guide-documents a {
  background-color: #00539B;
  color: #fff !important;
  font-weight: normal;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  font-size: 0.9285714286rem;
  text-transform: none;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 14px 8px;
  width: 150px;
  background-color: #0D67A8;
}

.field-guide-documents a:hover {
  text-decoration: none;
  background-color: #003868;
}

.field-guide-documents a:active, .field-guide-documents a:focus {
  text-decoration: none;
  background-color: #003868;
}

.field-guide-documents a:hover {
  background-color: #0b5890;
  color: #fff;
}

.field-guide-documents a:visited {
  color: #fff;
}

.view-mode-videomodule a {
  border-bottom: none;
}

.view-mode-videomodule .field-page-image {
  width: 100%;
  margin: 0;
  padding: 0;
  float: none;
}

.view-mode-videomodule .field-interview-image {
  margin-bottom: 0;
}

article ul.links:first-child {
  display: block;
}

article ul.links:first-child li {
  display: inline-block;
}

article ul.links:first-child li.sharethis {
  padding-right: 0;
  margin-bottom: 30px;
}

@media (min-width: 48em) {
  article ul.links:first-child li.sharethis {
    float: right;
    position: relative;
    top: 10px;
  }
}

@media (max-width: 47.9375em) {
  article ul.links:first-child li.sharethis {
    position: relative;
    top: -5px;
    margin-bottom: 15px;
  }
}

article ul.links:first-child li.sharethis .stButton .stLarge {
  width: 29px;
  height: 29px;
  background-size: 100%;
}

article ul.links:first-child li.sharethis .stButton .stLarge:hover {
  background-position: -29px;
}

article ul.links:first-child li.comment-add {
  position: relative;
  top: 10px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  display: inline-block !important;
  white-space: nowrap;
  padding: 0;
}

@media (min-width: 48em) {
  article ul.links:first-child li.comment-add {
    float: right;
    margin-left: 15px !important;
  }
}

@media (max-width: 47.9375em) {
  article ul.links:first-child li.comment-add {
    margin-right: 5px;
  }
}

article ul.links:first-child li.comment-add a {
  font-size: 1.6em;
}

article ul.links:first-child li.comment-add a::before {
  content: "\f086";
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 100;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  margin-right: 15px;
  margin-left: 1px;
}

@media (max-width: 47.9375em) {
  article ul.links:first-child li.last {
    width: 100%;
    clear: both;
  }
}

/* Book Theme */
@media (max-width: 47.9375em) {
  .node-type-book-theme .field-theme-image {
    width: 25%;
    float: left;
    padding-right: 7.5px;
    margin-right: 7.5px;
  }
}

@media (min-width: 48em) {
  .page-node-35543 .funding-credit,
  .page-node-35548 .funding-credit {
    margin-left: 0 !important;
  }
  .page-node-35543 .group-special-landing-intro,
  .page-node-35548 .group-special-landing-intro {
    width: 75%;
    float: left;
    padding-right: 15px;
  }
}

/* Podcasts */
.jp-audio {
  max-width: 420px;
  width: 100% !important;
  margin: 0 auto;
}

.jp-audio .jp-controls li,
.jp-audio .jp-playlist li {
  text-indent: 0 !important;
  padding-left: 0 !important;
  list-style-type: none !important;
  margin-bottom: 0 !important;
}

.jp-audio .jp-controls li::before,
.jp-audio .jp-playlist li::before {
  display: none !important;
}

.jp-audio .jp-controls li a,
.jp-audio .jp-playlist li a {
  border-bottom: none;
}

.jp-audio .jp-playlist {
  display: none;
}

/* Search Results */
.view-search-page .view-mode-search_result {
  margin-bottom: 15px;
}

@media (min-width: 48em) {
  .view-search-page .view-mode-search_result div[class*="image"] {
    width: 115px;
    float: left;
    padding-right: 15px;
    margin-right: 15px;
  }
}

.view-search-page .view-mode-search_result div[class*="body"] h1,
.view-search-page .view-mode-search_result div[class*="body"] h2,
.view-search-page .view-mode-search_result div[class*="body"] h3,
.view-search-page .view-mode-search_result div[class*="body"] h4,
.view-search-page .view-mode-search_result div[class*="body"] h5,
.view-search-page .view-mode-search_result div[class*="body"] h6,
.view-search-page .view-mode-search_result div[class*="body"] img,
.view-search-page .view-mode-search_result div[class*="intro"] h1,
.view-search-page .view-mode-search_result div[class*="intro"] h2,
.view-search-page .view-mode-search_result div[class*="intro"] h3,
.view-search-page .view-mode-search_result div[class*="intro"] h4,
.view-search-page .view-mode-search_result div[class*="intro"] h5,
.view-search-page .view-mode-search_result div[class*="intro"] h6,
.view-search-page .view-mode-search_result div[class*="intro"] img,
.view-search-page .view-mode-search_result div[class*="description"] h1,
.view-search-page .view-mode-search_result div[class*="description"] h2,
.view-search-page .view-mode-search_result div[class*="description"] h3,
.view-search-page .view-mode-search_result div[class*="description"] h4,
.view-search-page .view-mode-search_result div[class*="description"] h5,
.view-search-page .view-mode-search_result div[class*="description"] h6,
.view-search-page .view-mode-search_result div[class*="description"] img {
  display: none;
}

.view-search-page .view-mode-search_result .field-label {
  font-weight: normal;
}

.view-search-page .view-mode-search_result .textformatter-list {
  display: inline-block;
}

.view-search-page .view-mode-search_result footer {
  display: none;
}

.node-type-basic-page .node-basic-page.full-width .group-content {
  float: none;
  clear: both;
  padding-right: 0;
  border-right: 0;
  width: 100%;
}

.node-type-article .node-article.full-width .group-article-main {
  float: none;
  clear: both;
  padding-right: 0;
  border-right: 0;
  width: 100%;
}

.node-type-article .node-article.full-width .sidebar:not(.full-width-callout) {
  display: none;
}

.node-type-article .node-article.hide-comments section.comments {
  display: none;
}

.st_email_large,
.st_facebook_large,
.st_twitter_large,
.st_linkedin_large,
.st_sharethis_large,
.st_pinterest_large {
  display: inline-block;
}

.node-playlist.view-mode-full .addtoany_list {
  display: none;
}

.node-playlist .transcript-accordion {
  margin-top: 72px;
}

.node-playlist .transcript-accordion .accordion-title {
  font-size: 22px;
  cursor: pointer;
  color: #ffffff;
}

.node-playlist .transcript-accordion .accordion-title:focus {
  text-decoration: underline;
}

.node-playlist .transcript-accordion .accordion-body {
  display: none;
  padding: 12px;
}

.addtoany_list {
  display: flex;
}

.addtoany_list a {
  border-bottom: none;
}

.addtoany_list a.a2a_button_copy_link span {
  background-color: #258082 !important;
}

.addtoany_list a.a2a_button_email span {
  background-color: #78458D !important;
}

.node-video-gallery .czi-language-selection {
  background: transparent;
}

.node-video-gallery .czi-language-selection a.button {
  margin: 5px 10px;
}

.node-video-gallery span.addtoany_list {
  display: none;
}

.node-video-gallery .links.inline {
  text-align: right;
}

@media (max-width: 47.9375em) {
  .node-video-gallery .links.inline {
    text-align: left;
  }
}

.node-video-gallery .links.inline .addtoany_list {
  display: inline-block;
}

.field-gallery-videos,
.field-gallery-videos-spanish,
.field-gallery-videos-mam {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.field-gallery-videos > .item,
.field-gallery-videos-spanish > .item,
.field-gallery-videos-mam > .item {
  flex: 0 1 33%;
}

@media (max-width: 59.9375em) {
  .field-gallery-videos > .item,
  .field-gallery-videos-spanish > .item,
  .field-gallery-videos-mam > .item {
    flex: 0 1 50%;
  }
}

@media (max-width: 47.9375em) {
  .field-gallery-videos > .item,
  .field-gallery-videos-spanish > .item,
  .field-gallery-videos-mam > .item {
    flex: 0 1 100%;
  }
}

.field-gallery-videos .view-mode-czi_video,
.field-gallery-videos-spanish .view-mode-czi_video,
.field-gallery-videos-mam .view-mode-czi_video {
  position: relative;
}

.field-gallery-videos .view-mode-czi_video .field-video-header,
.field-gallery-videos-spanish .view-mode-czi_video .field-video-header,
.field-gallery-videos-mam .view-mode-czi_video .field-video-header {
  padding-left: 12px;
  margin-bottom: 8px;
}

.field-gallery-videos .view-mode-czi_video .landing-intro-container .field-title-field,
.field-gallery-videos-spanish .view-mode-czi_video .landing-intro-container .field-title-field,
.field-gallery-videos-mam .view-mode-czi_video .landing-intro-container .field-title-field {
  display: none;
}

.field-gallery-videos .view-mode-czi_video .field-playlist-youtube-url,
.field-gallery-videos-spanish .view-mode-czi_video .field-playlist-youtube-url,
.field-gallery-videos-mam .view-mode-czi_video .field-playlist-youtube-url {
  position: relative;
}

.field-gallery-videos .view-mode-czi_video .field-playlist-youtube-url::before,
.field-gallery-videos-spanish .view-mode-czi_video .field-playlist-youtube-url::before,
.field-gallery-videos-mam .view-mode-czi_video .field-playlist-youtube-url::before {
  content: "\f144";
  font-family: fontawesome;
  color: #ffffff;
  position: absolute;
  top: 18px;
  left: 18px;
  font-size: 40px;
  height: 40px;
  line-height: 40px;
  z-index: 999;
  pointer-events: none;
}

.field-gallery-videos .view-mode-czi_video .node-title,
.field-gallery-videos-spanish .view-mode-czi_video .node-title,
.field-gallery-videos-mam .view-mode-czi_video .node-title {
  display: none;
}

.field-gallery-videos .addtoany_list,
.field-gallery-videos-spanish .addtoany_list,
.field-gallery-videos-mam .addtoany_list {
  margin-bottom: 54px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.field-gallery-videos .addtoany_list .sharethis-video-label,
.field-gallery-videos-spanish .addtoany_list .sharethis-video-label,
.field-gallery-videos-mam .addtoany_list .sharethis-video-label {
  flex: 0 1 100%;
  text-align: center;
  margin-bottom: 0;
  margin-top: 4px;
  font-size: 0.85rem;
  line-height: 1;
}

.field-gallery-videos .addtoany_list a,
.field-gallery-videos-spanish .addtoany_list a,
.field-gallery-videos-mam .addtoany_list a {
  width: 44px;
  height: 44px;
  padding: 0;
}

.field-gallery-videos .addtoany_list a span,
.field-gallery-videos-spanish .addtoany_list a span,
.field-gallery-videos-mam .addtoany_list a span {
  width: 24px;
  height: 24px;
}

.group-czi-video {
  position: relative;
  width: 95%;
  margin: 0 auto;
}

.group-czi-video:hover .group-video-overlay {
  background: rgba(255, 255, 255, 0.6);
  color: #000000;
}

.group-czi-video .group-video-overlay {
  position: absolute;
  bottom: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  width: 100%;
  pointer-events: none;
}

.group-czi-video .group-video-overlay .field {
  margin-bottom: 6px;
}

.group-czi-video .group-video-overlay .field:last-child {
  margin-bottom: 0;
}

.group-czi-video .field-playlist-youtube-url {
  margin-bottom: 6px;
}

.group-czi-video .field-playlist-youtube-url img {
  width: 100%;
}

.czi-yt-embed-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.czi-yt-embed-wrapper .czi-yt-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.field-video-section-header h2 {
  position: relative;
}

.field-video-section-header h2:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .field-video-section-header h2:after {
    display: none;
  }
}

.field-video-section-header h2:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .field-video-section-header h2:before {
    display: none;
  }
}

.group-page-intro-bottom + .paragraphs-items-field-video-sections > .field-video-sections > .item:first-child .field-video-section-header h2::before, .group-page-intro-bottom + .paragraphs-items-field-video-sections > .field-video-sections > .item:first-child .field-video-section-header h2::after {
  display: none;
}

.node-type-discussion-guide h1 {
  margin-bottom: 0px;
}

.node-discussion-guide .field-dg-discussion-questions .field-label,
.node-discussion-guide .field-dg-overview .field-label {
  font-size: 22px;
  font-weight: normal;
}

.node-discussion-guide .field-dg-books {
  float: left;
  width: 25%;
  padding: 10px 10px 0px 0px;
  margin: 0px 10px 0px 0px;
}

@media (max-width: 47.9375em) {
  .node-discussion-guide .field-dg-books {
    float: none;
    width: 50%;
    margin: 0 auto;
  }
}

.node-discussion-guide .field-dg-books article.node-cc-book {
  display: flex;
  flex-wrap: wrap;
}

.node-discussion-guide .field-dg-books article.node-cc-book div {
  flex: 0 0 100%;
  margin-right: 0px;
  padding-right: 0px;
  width: auto;
  margin-bottom: 5px;
}

.node-playlist.view-mode-videomodule .addtoany_list {
  display: none;
}

.node-playlist.view-mode-czi_video .addtoany_list {
  display: flex;
}

header .views-exposed-form .views-widget {
  position: relative !important;
  top: 0;
  z-index: 2;
}

.node-type-classroom-strategy .group-classroom-strategy-intro {
  width: 100%;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .group-classroom-strategy-intro {
    width: 75%;
    float: left;
    padding-right: 15px;
  }
}

.node-type-classroom-strategy .page__title {
  display: none;
}

.node-type-classroom-strategy .field-kicker {
  margin-bottom: 4.5px;
  color: #B30425;
  font-size: 1.3em;
}

.node-type-classroom-strategy .funding-credit {
  width: 100%;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .funding-credit {
    padding-left: 15px !important;
    width: 25%;
    float: right;
  }
  .node-type-classroom-strategy .funding-credit p {
    font-size: 10px;
  }
}

.node-type-classroom-strategy .funding-credit img#aft {
  max-width: 25%;
}

.node-type-classroom-strategy .funding-credit .partner-nea {
  display: none;
}

.node-type-classroom-strategy .group-page-intro-bottom {
  margin-bottom: -15px;
}

.node-type-classroom-strategy table {
  border-color: transparent;
  width: 100% !important;
}

.node-type-classroom-strategy table th {
  visibility: visible !important;
}

.node-type-classroom-strategy .field-resources h3,
.node-type-classroom-strategy .field-references h3,
.node-type-classroom-strategy .field-basic-attachments h3 {
  font-size: 28px;
  font-size: 2rem;
  line-height: 1.275;
  font-family: "lemonde-journal", georgia, serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.275;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
}

.node-type-classroom-strategy .field-resources ul,
.node-type-classroom-strategy .field-references ul,
.node-type-classroom-strategy .field-basic-attachments ul {
  margin: 0 !important;
}

.node-type-classroom-strategy .group-tags,
.node-type-classroom-strategy .field-resources,
.node-type-classroom-strategy .field-references,
.node-type-classroom-strategy .field-basic-attachments {
  position: relative;
  margin-bottom: 30px;
}

.node-type-classroom-strategy .group-tags:after,
.node-type-classroom-strategy .field-resources:after,
.node-type-classroom-strategy .field-references:after,
.node-type-classroom-strategy .field-basic-attachments:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .node-type-classroom-strategy .group-tags:after,
  .node-type-classroom-strategy .field-resources:after,
  .node-type-classroom-strategy .field-references:after,
  .node-type-classroom-strategy .field-basic-attachments:after {
    display: none;
  }
}

.node-type-classroom-strategy .group-tags:before,
.node-type-classroom-strategy .field-resources:before,
.node-type-classroom-strategy .field-references:before,
.node-type-classroom-strategy .field-basic-attachments:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .node-type-classroom-strategy .group-tags:before,
  .node-type-classroom-strategy .field-resources:before,
  .node-type-classroom-strategy .field-references:before,
  .node-type-classroom-strategy .field-basic-attachments:before {
    display: none;
  }
}

.node-type-classroom-strategy .group-tags::before,
.node-type-classroom-strategy .field-resources::before,
.node-type-classroom-strategy .field-references::before,
.node-type-classroom-strategy .field-basic-attachments::before {
  content: "";
  margin-bottom: -15px;
}

.node-type-classroom-strategy .field-categories .item,
.node-type-classroom-strategy .field-additional-tags .item {
  display: inline;
}

.node-type-classroom-strategy .field-categories .item::before,
.node-type-classroom-strategy .field-additional-tags .item::before {
  content: ", ";
  margin-left: 0;
}

.node-type-classroom-strategy .field-categories .item:first-child::before,
.node-type-classroom-strategy .field-additional-tags .item:first-child::before {
  content: "";
  margin-left: -4px;
}

.node-type-classroom-strategy .field-categories .item:nth-child(2)::before,
.node-type-classroom-strategy .field-additional-tags .item:nth-child(2)::before {
  content: "";
  margin-left: 0;
}

.node-type-classroom-strategy .field-gallery-videos {
  display: flex;
}

.node-type-classroom-strategy .field-gallery-videos .item {
  flex: 0 1 100%;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .field-gallery-videos .item {
    flex: 0 1 50%;
  }
}

.view-classroom-strategies {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
}

.view-classroom-strategies .views-exposed-widgets {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
}

.view-classroom-strategies .views-exposed-widgets .views-exposed-widget {
  padding-left: 0px;
  min-height: 25px !important;
}

@media (min-width: 48em) {
  .view-classroom-strategies .views-exposed-widgets .views-exposed-widget {
    width: 32%;
  }
}

@media (min-width: 64em) {
  .view-classroom-strategies .views-exposed-widgets .views-exposed-widget {
    width: 24%;
    padding-right: 0;
    overflow: hidden;
  }
}

@media (max-width: 47.9375em) {
  .view-classroom-strategies .views-exposed-widgets .views-exposed-widget {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }
}

.view-classroom-strategies .views-exposed-widgets .views-exposed-widget:first-child {
  padding-left: 0;
  width: 100%;
}

.view-classroom-strategies .views-exposed-widgets .views-exposed-widget input {
  width: 100%;
}

.view-classroom-strategies .views-exposed-widgets .views-exposed-widget label {
  font-size: 13px;
}

.view-classroom-strategies .views-exposed-widgets .views-exposed-widget select {
  max-width: none;
  width: 100%;
}

@media (min-width: 48em) {
  .view-classroom-strategies {
    gap: 10px;
  }
}

.view-classroom-strategies .item-list {
  width: 100%;
}

.view-classroom-strategies .view-header {
  display: flex;
  align-items: flex-start;
  gap: 36px;
}

.view-classroom-strategies .view-header .intro,
.view-classroom-strategies .view-header .funding-credit {
  width: 100%;
}

@media (max-width: 47.9375em) {
  .view-classroom-strategies .view-header {
    flex-wrap: wrap;
  }
}

@media (min-width: 64em) {
  .view-classroom-strategies .view-header .intro {
    width: 75%;
  }
  .view-classroom-strategies .view-header .funding-credit {
    width: 25%;
  }
}

.view-classroom-strategies .partner-aft {
  margin-top: 0;
  margin-bottom: 12px;
  border: 1px solid #194997;
}

.view-classroom-strategies .partner-aft p {
  background-color: #194997;
  color: #fff;
  padding: 10px;
  font-size: 11px;
  text-align: left;
  line-height: 1.4em;
  margin: 0;
}

.view-classroom-strategies .partner-aft a {
  color: #fff !important;
  border-bottom: none !important;
}

.view-classroom-strategies .partner-aft .funding-credit-link {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.view-classroom-strategies .partner-aft img {
  width: 100%;
  height: auto;
  max-width: 200px;
  padding: 12px 0;
}

.view-classroom-strategies .views-row {
  width: 100%;
  height: 100%;
}

@media (min-width: 48em) {
  .view-classroom-strategies .views-row {
    width: 32%;
  }
}

.view-classroom-strategies input {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  background-color: #fff;
}

.view-classroom-strategies input:hover, .view-classroom-strategies input:focus {
  border-color: #00539B;
}

.classroom-strategy-card {
  border: 3px solid transparent;
  width: 100%;
  height: 100%;
  background-color: #F4F2F2;
  -webkit-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(all 0.3s ease, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(all 0.3s ease, false, false, false, false, false, false, false, false, false);
}

.classroom-strategy-card:hover, .classroom-strategy-card:focus {
  border: 3px solid #00539B;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.classroom-strategy-card .field-article-kicker {
  margin-bottom: 10px;
  color: #B30425;
  font-size: 1rem;
}

.classroom-strategy-card a {
  color: #000;
}

.classroom-strategy-card .field-page-image {
  margin-bottom: 0;
}

.classroom-strategy-card .card-content {
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 48em) {
  .classroom-strategy-card .card-content {
    min-height: 100px;
  }
}

.classroom-strategy-card .card-content img {
  width: 100%;
}

.classroom-strategy-card .card-content h3 {
  font-size: 18px;
  margin-top: 0;
}

.classroom-strategy-card .card-content .field-categories {
  border-top: 1px solid #c4c4c4;
  padding-top: 5px;
  margin-top: auto;
}

.classroom-strategy-card .card-content .field-categories .item {
  display: inline;
  font-size: 12px;
}

.classroom-strategy-card .card-content .field-categories .item::before {
  content: ", ";
  margin-left: 0;
}

.classroom-strategy-card .card-content .field-categories .item:first-child::before {
  content: "";
  margin-left: -4px;
}

.node-type-classroom-strategy .field-tags-for-strategies .item,
.node-type-classroom-strategy .field_categories .item {
  display: inline;
}

.node-type-classroom-strategy .field-tags-for-strategies .item + .item::before,
.node-type-classroom-strategy .field_categories .item + .item::before {
  content: ", ";
  margin-left: 0;
}

.node-type-classroom-strategy .funding-credit .partner-aft,
.node-type-classroom-strategy .funding-credit .partner-nea {
  width: 100%;
  max-width: 200px;
}

.node-type-classroom-strategy .group-page-container {
  position: relative;
}

.node-type-classroom-strategy .views-classroom-strategy-button-block {
  margin-bottom: 0;
  margin-top: 36px;
  width: 100%;
}

.node-type-classroom-strategy .views-classroom-strategy-button-block div {
  width: 100%;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .views-classroom-strategy-button-block {
    max-width: 25%;
    padding-left: 15px;
    float: right;
    margin-top: 0;
  }
}

.node-type-classroom-strategy .views-print-button-block {
  margin-top: 36px;
  width: 100%;
  position: relative;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .views-print-button-block {
    max-width: 25%;
    padding-left: 15px;
    margin-top: 18px;
    float: right;
    clear: both;
  }
}

.node-type-classroom-strategy .print-button a {
  background: #0f8099;
  border-radius: 8px;
  position: relative;
  width: 100%;
  display: block;
  color: #fff;
  border-bottom: none;
  font-weight: 700 !important;
  padding: 15px !important;
}

.node-type-classroom-strategy .print-button a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.node-type-classroom-strategy .print-button a::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background-image: url("../images/social/print-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.node-type-classroom-strategy .print-button::after {
  content: "";
  background-image: url("../images/teal-flower-rotated.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 25%;
  height: 100%;
  position: absolute;
  right: 4px;
  bottom: 0;
  border-radius: 0 0 8px 0;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .print-button::after {
    width: 35%;
    height: 96%;
  }
}

.node-type-classroom-strategy .print-button:hover, .node-type-classroom-strategy .print-button:focus {
  transition: 0.2s all ease-in-out;
  box-shadow: #bbbbbb 0px 0px 8px;
  border-radius: 8px;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .print-button a {
    padding: 15px;
  }
}

.node-type-classroom-strategy .classroom-strategies-button {
  background: #7d349b;
  padding: 30px 15px;
  border-radius: 8px;
  position: relative;
  width: 100%;
}

.node-type-classroom-strategy .classroom-strategies-button a {
  display: block;
  color: #fff;
  border-bottom: none;
  font-weight: 700 !important;
  width: 70%;
}

.node-type-classroom-strategy .classroom-strategies-button a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.node-type-classroom-strategy .classroom-strategies-button::after {
  content: "";
  background-image: url("../images/butterfly.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 25%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .classroom-strategies-button::after {
    width: 35%;
    height: 100%;
  }
}

.node-type-classroom-strategy .classroom-strategies-button:hover, .node-type-classroom-strategy .classroom-strategies-button:focus {
  transition: 0.2s all ease-in-out;
  box-shadow: #bbbbbb 0px 0px 8px;
}

@media (min-width: 48em) {
  .node-type-classroom-strategy .classroom-strategies-button {
    padding: 15px;
  }
}

.section-tags-strategies .node-teaser {
  width: 100%;
}

@media (min-width: 48em) {
  .section-tags-strategies .node-teaser {
    width: 33%;
    float: left;
    padding: 10px 7.5px;
  }
}

.section-tags-strategies .item-list {
  width: 100%;
}

@media (min-width: 48em) {
  .node-type-video-gallery .field-basic-thumbnail {
    width: 23%;
  }
  .node-type-video-gallery .internal-navigation {
    width: 25%;
    margin-left: 15px;
  }
  .node-type-video-gallery .internal-navigation h3 {
    margin-top: 0;
  }
}

#interviews .node-video-gallery .field-basic-thumbnail {
  width: 100%;
  margin: 0;
  padding: 0;
}

#interviews .node-video-gallery a {
  padding: 7.5px 15px;
}

#interviews .node-video-gallery a .field {
  display: inline-block;
}

#interviews .node-video-gallery a .field + .field {
  margin-left: 5px;
}

.sidebar.full-width-callout {
  display: block;
  width: 100% !important;
  margin-left: 0;
  float: none;
  background: #FBF6E4;
  border: 1px solid #646464;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
}

.sidebar.full-width-callout:before {
  content: "";
  display: block;
  width: 100%;
  height: 60px;
  background-image: url("../images/flower-separator-full.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 60px;
}

@media print {
  .sidebar.full-width-callout:before {
    display: none;
  }
}

@media (min-width: 48em) {
  .sidebar.full-width-callout {
    margin-top: 30px;
  }
  .sidebar.full-width-callout::before {
    background-size: auto 30px;
    position: absolute;
    top: -30px;
    left: 0;
    height: 30px;
  }
}

@media (max-width: 47.9375em) {
  .sidebar.full-width-callout {
    margin-top: 45px;
  }
  .sidebar.full-width-callout::before {
    background-size: auto 40px;
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
  }
}

/* @partial views-styles */
.block-views {
  margin-bottom: 2em;
}

.block-views .block__title,
.block-views .view-header h2 {
  font-size: 1.7rem;
  margin-bottom: 1em;
  margin-top: 0;
  padding-top: 15px;
  border-top: 1px solid #aaa;
}

.block-views .view-header p {
  color: #555;
}

.block-views .views-field-title {
  font-size: 1.1em;
  color: #B30425;
}

.block-views .views-field-title a {
  color: #B7531C;
}

@media (min-width: 48em) {
  .region-bottom {
    border-top: 1px solid #aaa;
  }
  .region-bottom .block__title {
    border-top: none;
  }
}

/* Slides */
.view-slides,
.slideshow-wrapper {
  background-color: #00529b;
}

.view-slides a,
.slideshow-wrapper a {
  border-bottom: none;
}

.view-slides .views-row,
.view-slides article,
.slideshow-wrapper .views-row,
.slideshow-wrapper article {
  overflow: hidden;
  width: 100%;
}

@media (max-width: 47.9375em) {
  .view-slides .views-row,
  .view-slides article,
  .slideshow-wrapper .views-row,
  .slideshow-wrapper article {
    height: 100%;
    background-color: #00529b;
  }
}

.view-slides .views-row .field-slide-image,
.view-slides article .field-slide-image,
.slideshow-wrapper .views-row .field-slide-image,
.slideshow-wrapper article .field-slide-image {
  margin-bottom: 0;
}

.view-slides .views-row .views-fieldset,
.view-slides article .views-fieldset,
.slideshow-wrapper .views-row .views-fieldset,
.slideshow-wrapper article .views-fieldset {
  background-color: #00529b;
  border: none;
  padding: 40px 30px;
  color: #fff;
}

.view-slides .views-row .views-fieldset a,
.view-slides article .views-fieldset a,
.slideshow-wrapper .views-row .views-fieldset a,
.slideshow-wrapper article .views-fieldset a {
  color: #fff;
}

.view-slides .views-row .views-fieldset .views-field-title,
.view-slides .views-row .views-fieldset .node__title,
.view-slides article .views-fieldset .views-field-title,
.view-slides article .views-fieldset .node__title,
.slideshow-wrapper .views-row .views-fieldset .views-field-title,
.slideshow-wrapper .views-row .views-fieldset .node__title,
.slideshow-wrapper article .views-fieldset .views-field-title,
.slideshow-wrapper article .views-fieldset .node__title {
  font-size: 1.8em;
  margin-bottom: 1.2em;
  line-height: 1.2em;
  margin-top: 0;
  color: #fff;
}

.view-slides .views-row .views-fieldset .views-field-title a,
.view-slides .views-row .views-fieldset .node__title a,
.view-slides article .views-fieldset .views-field-title a,
.view-slides article .views-fieldset .node__title a,
.slideshow-wrapper .views-row .views-fieldset .views-field-title a,
.slideshow-wrapper .views-row .views-fieldset .node__title a,
.slideshow-wrapper article .views-fieldset .views-field-title a,
.slideshow-wrapper article .views-fieldset .node__title a {
  color: #fff !important;
}

.view-slides .views-row .views-fieldset .views-field-field-slide-subhead,
.view-slides .views-row .views-fieldset .field-slide-subhead,
.view-slides .views-row .views-fieldset .field-slide-description,
.view-slides .views-row .views-fieldset .views-field-field-slide-description,
.view-slides article .views-fieldset .views-field-field-slide-subhead,
.view-slides article .views-fieldset .field-slide-subhead,
.view-slides article .views-fieldset .field-slide-description,
.view-slides article .views-fieldset .views-field-field-slide-description,
.slideshow-wrapper .views-row .views-fieldset .views-field-field-slide-subhead,
.slideshow-wrapper .views-row .views-fieldset .field-slide-subhead,
.slideshow-wrapper .views-row .views-fieldset .field-slide-description,
.slideshow-wrapper .views-row .views-fieldset .views-field-field-slide-description,
.slideshow-wrapper article .views-fieldset .views-field-field-slide-subhead,
.slideshow-wrapper article .views-fieldset .field-slide-subhead,
.slideshow-wrapper article .views-fieldset .field-slide-description,
.slideshow-wrapper article .views-fieldset .views-field-field-slide-description {
  font-size: 1.1em;
  margin-bottom: 18px;
}

.view-slides .cycle-pager,
.view-slides .slideshow-pager,
.slideshow-wrapper .cycle-pager,
.slideshow-wrapper .slideshow-pager {
  text-align: center;
  font-size: 2em;
  color: rgba(255, 255, 255, 0.5);
}

.view-slides .cycle-pager span,
.view-slides .slideshow-pager span,
.slideshow-wrapper .cycle-pager span,
.slideshow-wrapper .slideshow-pager span {
  padding: 3px;
  cursor: pointer;
}

.view-slides .cycle-pager .cycle-pager-active,
.view-slides .slideshow-pager .cycle-pager-active,
.slideshow-wrapper .cycle-pager .cycle-pager-active,
.slideshow-wrapper .slideshow-pager .cycle-pager-active {
  color: white;
}

@media (min-width: 48em) {
  .view-slides .views-field-field-slide-image,
  .view-slides .field-slide-image,
  .slideshow-wrapper .views-field-field-slide-image,
  .slideshow-wrapper .field-slide-image {
    width: 66.67%;
    float: left;
  }
  .view-slides .views-fieldset,
  .slideshow-wrapper .views-fieldset {
    width: 33.33%;
    float: right;
  }
  .view-slides .cycle-pager,
  .view-slides .slideshow-pager,
  .slideshow-wrapper .cycle-pager,
  .slideshow-wrapper .slideshow-pager {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 33.33%;
    z-index: 105;
  }
}

@media (max-width: 47.9375em) {
  #home-slides {
    margin-bottom: 30px;
  }
}

.feed-icon {
  display: none;
}

@media (min-width: 48em) {
  #block-views-slides-block,
  .slideshow {
    position: relative;
  }
  #block-views-slides-block:before,
  .slideshow:before {
    content: "";
    display: block;
    border-top: 1px solid #646464;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    z-index: 1;
  }
}

@media print and (min-width: 48em) {
  #block-views-slides-block:before,
  .slideshow:before {
    display: none;
  }
}

@media (min-width: 48em) {
  #block-views-slides-block:after,
  .slideshow:after {
    content: "";
    display: block;
    background-image: url("../images/flower-separator.png");
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px auto;
    margin: 15px auto;
    height: 38px;
    width: 50px;
    position: relative;
    z-index: 10;
    visibility: visible !important;
  }
}

@media print and (min-width: 48em) {
  #block-views-slides-block:after,
  .slideshow:after {
    display: none;
  }
}

@media (max-width: 47.9375em) {
  #block-views-slides-block .views-field-title,
  #block-views-slides-block .node__title,
  #block-views-slides-block .view-footer,
  .slideshow .views-field-title,
  .slideshow .node__title,
  .slideshow .view-footer {
    display: none;
  }
  #block-views-slides-block img,
  .slideshow img {
    width: 100%;
    height: auto;
  }
}

#slider-video > div {
  position: relative;
}

#slider-video > div:before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

@media print {
  #slider-video > div:before {
    display: none;
  }
}

#slider-video > div:after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

@media print {
  #slider-video > div:after {
    display: none;
  }
}

@media (min-width: 48em) {
  #slider-video > div .clearfix {
    display: flex;
  }
  #slider-video > div .clearfix #home-slides {
    width: 66.67%;
    flex: 0 1 66.67%;
    padding-right: 15px;
  }
}

@media (min-width: 48em) and (max-width: 62.9375em) {
  #slider-video > div .clearfix #home-slides {
    width: 50%;
    flex: 0 1 50%;
  }
}

@media (min-width: 48em) and (max-width: 47.9375em) {
  #slider-video > div .clearfix #home-slides {
    width: 100%;
    flex: 0 1 100%;
  }
}

@media (min-width: 48em) {
  #slider-video > div .clearfix #home-video {
    margin-top: 15px;
    width: 33.33%;
    flex: 0 1 66.67%;
    padding-left: 15px;
  }
}

@media (min-width: 48em) and (max-width: 62.9375em) {
  #slider-video > div .clearfix #home-video {
    width: 50%;
    flex: 0 1 50%;
  }
}

@media (min-width: 48em) and (max-width: 47.9375em) {
  #slider-video > div .clearfix #home-video {
    width: 100%;
    flex: 0 1 100%;
  }
}

#slider-video #home-video a {
  border-bottom: none;
}

#slider-video #home-video h2 {
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 10px;
  color: #00539B;
  background: url("../images/video-icon.png") no-repeat left center;
  background-size: 20px auto;
  padding-left: 30px;
}

#slider-video #home-video article {
  background-color: #111;
  color: #fff;
  padding: 0;
  width: 100%;
  float: none;
}

#slider-video #home-video article .node__title {
  display: none;
}

#slider-video #home-video article .field-playlist-youtube-url {
  margin-bottom: 0;
}

#slider-video #home-video article .field-title-field {
  margin-left: 0;
  padding-left: 0;
}

#slider-video #home-video article .field-title-field h3 {
  padding: 15px 15px 20px;
}

#slider-video #home-video article .field-title-field h3 a {
  color: #fff !important;
  font-weight: 300;
}

#slider-video #home-video article .field-playlist-description {
  padding: 0 15px 15px;
}

#slider-video #home-video .see-more {
  text-align: center;
}

#slider-video #home-video .see-more .btn {
  background-color: #00539B;
}

#slider-video #home-video .see-more .btn:hover {
  background-color: #003868;
  color: #fff;
}

#slider-video #home-video .see-more .btn:visited {
  color: #fff;
}

.section-home ul.links:first-child,
.front ul.links:first-child {
  display: none;
}

.section-home .group-page-intro-bottom,
.section-home .page__title,
.front .group-page-intro-bottom,
.front .page__title {
  display: none;
}

.section-home #main,
.front #main {
  max-width: none;
}

.section-home #main #content,
.front #main #content {
  padding: 30px 0 0 0;
}

@media (min-width: 48em) {
  .section-home #main #content #home-blog div.inside,
  .section-home #main #content #home-featured,
  .section-home #main #content #slider-video,
  .section-home #main #content #home-featured-bottom,
  .section-home #main #content #home-testimonial-name > div,
  .front #main #content #home-blog div.inside,
  .front #main #content #home-featured,
  .front #main #content #slider-video,
  .front #main #content #home-featured-bottom,
  .front #main #content #home-testimonial-name > div {
    max-width: 999px;
  }
}

@media (max-width: 47.9375em) {
  .section-home #main #content #home-blog div.inside,
  .section-home #main #content #home-featured,
  .section-home #main #content #slider-video,
  .section-home #main #content #home-featured-bottom,
  .section-home #main #content #home-testimonial-name > div,
  .front #main #content #home-blog div.inside,
  .front #main #content #home-featured,
  .front #main #content #slider-video,
  .front #main #content #home-featured-bottom,
  .front #main #content #home-testimonial-name > div {
    max-width: 660px;
  }
}

.section-home #main #content #home-blog div.inside,
.section-home #main #content #home-featured,
.section-home #main #content #slider-video,
.section-home #main #content #home-featured-bottom,
.section-home #main #content #home-testimonial-name > div,
.front #main #content #home-blog div.inside,
.front #main #content #home-featured,
.front #main #content #slider-video,
.front #main #content #home-featured-bottom,
.front #main #content #home-testimonial-name > div {
  padding-left: 7.5px;
  padding-right: 7.5px;
  margin: 0 auto;
}

.section-home #main #content #home-testimonial-name > div,
.front #main #content #home-testimonial-name > div {
  padding-top: 30px;
  padding-bottom: 30px;
}

#home-featured a {
  border-bottom: none;
}

#home-featured > div {
  overflow: hidden;
  margin-top: 7.5px;
  margin-bottom: 15px;
}

@media (min-width: 48em) {
  #home-featured > div {
    margin-left: -15px;
    margin-right: -15px;
  }
  #home-featured > div .listing-resources,
  #home-featured > div .listing-news,
  #home-featured > div #home-popular,
  #home-featured > div .funding-credit {
    padding-left: 15px;
    padding-right: 15px;
  }
  #home-featured > div .listing-resources,
  #home-featured > div .listing-news,
  #home-featured > div #home-popular {
    border-right: 1px solid #aaaaaa;
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
  #home-featured > div .listing-resources,
  #home-featured > div .listing-news {
    width: 37%;
    float: left;
  }
  #home-featured > div #home-popular {
    width: 38%;
    float: left;
  }
  #home-featured > div .funding-credit {
    width: 25%;
    float: left;
  }
  #home-featured > div .listing-resources,
  #home-featured > div .listing-news {
    background-position: left top;
  }
  #home-featured > div .listing-resources h2,
  #home-featured > div .listing-news h2 {
    padding-left: 40px;
  }
}

@media (max-width: 47.9375em) {
  #home-featured > div .listing-resources,
  #home-featured > div .listing-news,
  #home-featured > div #home-popular,
  #home-featured > div .funding-credit {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #home-featured > div .listing-resources,
  #home-featured > div .listing-news,
  #home-featured > div #home-popular {
    border-bottom: 1px solid #aaaaaa;
  }
  #home-featured > div .listing-resources,
  #home-featured > div .listing-news {
    background-position: left top 28px;
  }
  #home-featured > div .listing-resources h2,
  #home-featured > div .listing-news h2 {
    padding-left: 50px;
  }
}

#home-featured > div .listing-resources h2,
#home-featured > div .listing-news h2,
#home-featured > div #home-popular h2,
#home-featured > div .funding-credit h2 {
  color: #00539B;
  margin-top: 0;
}

#home-featured > div .listing-resources li {
  margin-bottom: 22.5px;
}

#home-featured > div .listing-news h2,
#home-featured > div #home-popular h2 {
  padding-top: 5px;
}

#home-featured > div .listing-news article,
#home-featured > div .listing-news li,
#home-featured > div #home-popular article,
#home-featured > div #home-popular li {
  margin-bottom: 22.5px;
  height: 76px;
  overflow: hidden;
}

#home-featured > div .listing-news article .news-title,
#home-featured > div .listing-news article .field-title-field,
#home-featured > div .listing-news li .news-title,
#home-featured > div .listing-news li .field-title-field,
#home-featured > div #home-popular article .news-title,
#home-featured > div #home-popular article .field-title-field,
#home-featured > div #home-popular li .news-title,
#home-featured > div #home-popular li .field-title-field {
  display: block;
  max-height: 40px;
  overflow: hidden;
}

#home-featured > div .listing-resources,
#home-featured > div .listing-news {
  background-image: url("../images/cc-sun.png");
  background-repeat: no-repeat;
  background-size: 48px auto;
}

#home-featured > div .listing-resources li,
#home-featured > div .listing-news li {
  text-indent: 0 !important;
  padding-left: 0 !important;
}

#home-featured > div .listing-resources li:before,
#home-featured > div .listing-news li:before {
  display: none !important;
}

#home-featured > div .listing-resources li a.news-title,
#home-featured > div .listing-news li a.news-title {
  font-size: 1.1em;
}

#home-featured > div .listing-resources li a.news-source,
#home-featured > div .listing-news li a.news-source {
  display: inline-block;
}

#home-featured > div .listing-resources li span.news-date,
#home-featured > div .listing-news li span.news-date {
  font-size: 0.9em;
  padding-left: 5px;
}

#home-featured > div .listing-news > a,
#home-featured > div #home-popular > a {
  border-bottom: 1px dotted #B7531C;
}

#home-featured > div #home-popular .field-article-image {
  width: 25%;
  float: left;
  margin-bottom: 0;
}

@media (min-width: 48em) {
  #home-featured > div #home-popular .field-article-image {
    margin-right: 0;
  }
}

@media (max-width: 47.9375em) {
  #home-featured > div #home-popular .field-article-image {
    margin-right: 15px;
  }
}

#home-featured > div #home-popular .field-guide-image {
  width: 25%;
  padding-right: 0;
  height: 0;
  padding-bottom: 25%;
  overflow: hidden;
}

#home-featured > div #home-popular h3 {
  margin: 0;
  font-size: 1.1em;
  padding: 0;
  max-height: 63px;
  overflow: hidden;
}

#home-featured > div #home-popular h3 a {
  color: #B7531C !important;
  font-weight: normal;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
}

#home-featured > div #home-popular h3 a:hover {
  color: #8b3f15 !important;
}

#home-blog {
  margin-top: 45px;
  position: relative;
}

#home-blog:before {
  content: "";
  display: block;
  width: 100%;
  height: 60px;
  background-image: url("../images/flower-separator-full.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 60px;
}

@media print {
  #home-blog:before {
    display: none;
  }
}

#home-blog > div {
  padding-top: 30px;
  padding-bottom: 22.5px;
  background-color: #F9D270;
}

#home-blog h4 {
  margin: 0;
}

#home-blog h4 a {
  font-size: 0.9em;
  border-bottom: none;
}

#home-blog a,
#home-blog h4 a {
  color: #B30425 !important;
}

#home-blog .field-title-field h3 {
  margin-top: 0;
}

#home-blog .field-title-field h3 a {
  color: #00539B !important;
  border-bottom: none;
}

#home-blog .home-blog-image {
  float: left;
  margin-right: 30px;
  overflow: hidden;
}

#home-blog .home-blog-image img {
  object-fit: cover;
  object-position: center center;
  height: 150px;
  z-index: 1000;
  width: 150px;
}

@media (min-width: 48em) {
  #home-blog .home-blog-image {
    border-radius: 80px;
  }
}

@media (max-width: 47.9375em) {
  #home-blog .home-blog-image {
    display: none;
  }
}

#home-testimonial-name {
  background-color: #0D67A8;
  color: #fff;
}

#home-testimonial-name > div {
  overflow: hidden;
}

@media (min-width: 64em) {
  #home-testimonial-name #home-testimonial {
    width: 67%;
    float: left;
    padding: 0 45px 0 0;
    background: url("../images/testimonial-vert-rule.png") no-repeat right center;
    background-size: 3px auto;
    min-height: 145px;
  }
  #home-testimonial-name #home-testimonial .field-testimonial-quote {
    font-size: 1.3em;
  }
  #home-testimonial-name #home-testimonial .field-testimonial-quote .item {
    padding-left: 27px;
  }
  #home-testimonial-name #home-name {
    width: 33%;
    float: left;
    padding: 0 0 0 45px;
  }
}

@media (min-width: 48em) and (max-width: 63.9375em) {
  #home-testimonial-name #home-testimonial {
    width: 60%;
    float: left;
    padding: 0 45px 0 0;
    background: url("../images/testimonial-vert-rule.png") no-repeat right center;
    background-size: 3px auto;
  }
  #home-testimonial-name #home-testimonial .views-field-body {
    font-size: 1.3em;
  }
  #home-testimonial-name #home-testimonial .field-testimonial-quote .item {
    padding-left: 27px;
  }
  #home-testimonial-name #home-name {
    width: 40%;
    float: left;
    padding: 25px 0 35px 30px;
  }
  #home-testimonial-name div.testimonial-text:after {
    position: absolute;
    bottom: 3px;
    right: -40px;
  }
}

@media (max-width: 47.9375em) {
  #home-testimonial-name #home-testimonial,
  #home-testimonial-name #home-name {
    width: 100%;
    padding: 15px;
  }
  #home-testimonial-name .field-testimonial-quote {
    font-size: 1.2em;
  }
  #home-testimonial-name .field-testimonial-quote .item {
    padding-left: 0;
  }
}

#home-testimonial-name .field-testimonial-quote {
  line-height: 1.7em;
}

#home-testimonial-name .field-testimonial-quote .item {
  position: relative;
  top: 0;
  left: 10px;
  font-style: italic;
  display: inline-block;
}

@media (max-width: 47.9375em) {
  #home-testimonial-name .field-testimonial-quote .item {
    padding-left: 8px;
  }
}

#home-testimonial-name .field-testimonial-quote .item:after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 24px;
  background: url("../images/testimonial-endquote.png") no-repeat center;
  background-size: 100%;
  margin-left: 10px;
  margin-bottom: -7px;
}

#home-testimonial-name .field-title-field {
  margin-left: 60px;
  margin-bottom: 0;
}

#home-testimonial-name .field-title-field .item:before {
  content: "—";
}

#home-testimonial-name #home-name {
  font-size: 1.6em;
  line-height: 1.4em;
  font-style: italic;
  text-align: center;
}

#home-testimonial-name #home-name > div {
  background: url("../images/branch.png") no-repeat top center;
}

@media (min-width: 48em) {
  #home-testimonial-name #home-name > div {
    background-size: 75% auto;
    padding-top: 12%;
  }
}

@media (max-width: 47.9375em) {
  #home-testimonial-name #home-name > div {
    background-size: 200px auto;
    padding-top: 30px;
  }
}

#home-testimonial-name #home-name img {
  width: 180px;
}

#home-testimonial-name #home-name img,
#home-testimonial-name #home-name p {
  position: relative;
  top: -6px;
}

#home-testimonial-name #home-name a {
  color: #fff;
  font-weight: normal;
  border-bottom: 1px dotted #fff;
}

#home-featured-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
}

#home-featured-bottom h2 {
  margin-top: 0;
  margin-bottom: 30px;
}

@media (min-width: 48em) {
  #home-featured-bottom #home-featured-bottom-container > div {
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
  }
  #home-featured-bottom #home-featured-bottom-container > div article {
    width: 33.33%;
    padding: 0 15px 15px;
    float: left;
    border-left: 1px solid #aaaaaa;
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
  #home-featured-bottom #home-featured-bottom-container > div article:first-child {
    border-left: none;
  }
  #home-featured-bottom #home-featured-bottom-container > div article .field-theme-image {
    margin-bottom: 0;
  }
}

@media (max-width: 47.9375em) {
  #home-featured-bottom #home-featured-bottom-container > div article {
    padding: 7.5px 0;
  }
}

#home-featured-bottom #home-featured-bottom-container > div article h3 {
  margin-top: 0;
}

#home-featured-bottom #home-featured-bottom-container > div article .field-theme-image {
  width: 30%;
  float: left;
  margin-right: 15px;
}

#home-featured-bottom #home-featured-bottom-container > div article .field-theme-description {
  color: #555;
}

#home-featured-bottom #home-featured-bottom-container > div article .field-title-field {
  margin-bottom: 0;
}

#home-featured-bottom h3 {
  font-weight: normal;
}

#home-featured-bottom h3 a {
  font-weight: normal;
}

/* Homepage Blog, News, Calendar Listings */
#block-views-featured-posts-block {
  position: relative;
  float: none;
  clear: both;
  width: 100%;
  margin-bottom: 0;
}

#block-views-featured-posts-block:before {
  content: "";
  display: block;
  width: 100%;
  height: 60px;
  background-image: url("../images/flower-separator-full.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 60px;
}

@media print {
  #block-views-featured-posts-block:before {
    display: none;
  }
}

#block-views-featured-posts-block .view-featured-posts {
  background-color: #F9D270;
}

#block-views-featured-posts-block .view-featured-posts .views-row {
  overflow: hidden;
}

#block-views-featured-posts-block .view-featured-posts .views-row .views-field-field-article-image {
  width: 150px;
  border-radius: 75px;
  overflow: hidden;
}

#block-views-featured-posts-block .view-featured-posts .views-row .views-field-field-article-type {
  margin-top: 7px;
  margin-bottom: 5px;
  font-size: 1.1em;
  color: #B30425;
}

#block-views-featured-posts-block .view-featured-posts .views-row .views-field-title {
  font-size: 1.4em;
  margin-bottom: 10px;
  font-weight: bold;
}

#block-views-featured-posts-block .view-featured-posts .views-row .views-field-title a {
  color: #333;
}

#block-views-featured-posts-block .view-featured-posts .views-row .views-field-title .featured-post-kicker {
  font-size: 0.75em;
  color: #B30425;
  margin-bottom: 7.5px;
}

#block-views-featured-posts-block .view-featured-posts .views-row .views-field-field-article-body-1 a {
  color: #B30425;
}

/* Featured Video */
@media (min-width: 48em) {
  #block-views-video-block-1 {
    width: 67%;
    float: left;
    padding: 10px 15px 0 0;
  }
}

@media (max-width: 47.9375em) {
  #block-views-video-block-1 {
    width: 100%;
  }
}

.node-type-l4-page #block-views-video-block-1 {
  width: 100%;
}

.player,
.iframe,
.video-container {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 60%;
}

.player iframe,
.iframe iframe,
.video-container iframe {
  position: absolute;
  height: 100%;
  width: 100%;
}

/* Listings */
#block-views-sections-listings-block {
  clear: both;
}

.view-sections-listings {
  overflow: hidden;
}

@media (min-width: 48em) {
  .view-sections-listings.view-display-id-block .views-row {
    width: 23.5%;
    float: left;
    margin-left: 2%;
  }
  .view-sections-listings.view-display-id-block .views-row:first-child {
    margin-left: 0;
  }
}

@media (min-width: 48em) {
  .view-sections-listings.view-display-id-block_1 .views-row {
    width: 31.33%;
    float: left;
    margin-left: 2%;
  }
  .view-sections-listings.view-display-id-block_1 .views-row:first-child {
    margin-left: 0;
  }
}

@media (min-width: 37.5em) and (max-width: 47.9375em) {
  .view-sections-listings .views-row {
    width: 48%;
    float: left;
    margin-bottom: 2em;
  }
  .view-sections-listings .views-row:nth-child(odd) {
    margin-right: 2%;
  }
  .view-sections-listings .views-row:nth-child(even) {
    margin-left: 2%;
  }
}

@media (max-width: 37.4375em) {
  .view-sections-listings .views-row {
    width: 100%;
    margin-bottom: 2em;
  }
}

.view-sections-listings .views-row .views-field-field-page-image,
.view-sections-listings .views-row .views-field-field-l4-image {
  margin-bottom: 0.5em;
}

.view-sections-listings .views-row .views-field-title {
  font-size: 1.3em;
  line-height: 1.3em;
  color: #00539B;
}

.view-sections-listings .views-row .views-field-title a {
  color: #00539B;
}

#block-views-video-block .views-row {
  overflow: hidden;
}

#block-views-video-block .views-row .views-field-field-video-url {
  width: 25%;
  float: left;
  margin-right: 20px;
}

#block-views-video-block .views-row .views-field-field-video-url:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: url("../images/cc-video-overlay.png") no-repeat center;
  background-size: 100%;
}

/* Research Reports Page */
.view-id-research.view-display-id-page .views-row {
  margin-bottom: 2em;
}

.view-id-research.view-display-id-page .views-field-title {
  font-size: 1.8em;
  margin-bottom: 0.5em;
}

.view-id-research.view-display-id-page .views-label {
  font-weight: bold;
}

.view-id-research.view-display-id-page .views-field-field-research-author,
.view-id-research.view-display-id-page .views-field-field-research-organization {
  margin-bottom: 0.5em;
}

.view-id-research.view-display-id-page .views-field-field-research-abstract .field-content {
  display: inline-block;
}

.accordion-container {
  margin-top: 2em;
  padding-bottom: 2em;
}

.accordion.accordion-hide {
  max-height: 50px;
  overflow: hidden;
}

.accordion.accordion-show {
  max-height: none;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1em;
}

.accordion h3 {
  margin-top: 0;
}

/* Testimonials */
#home-testimonial .field-testimonial-quote {
  line-height: 1.7em;
}

#home-testimonial .field-testimonial-quote .item:before {
  content: "";
  display: block;
  width: 30px;
  height: 24px;
  background: url("../images/testimonial-startquote.png") no-repeat center;
  background-size: 100%;
  float: left;
  margin-left: -37px;
}

@media (max-width: 47.9375em) {
  #home-testimonial .field-testimonial-quote .item:before {
    position: relative;
    top: 0;
    left: -3px;
  }
}

#home-testimonial.view-display-id-page .views-row {
  border-bottom: 1px solid #646464;
  padding-bottom: 3em;
  margin-bottom: 3em;
  font-size: 1.1em;
}

#home-testimonial.view-display-id-page .views-row:first-child {
  margin-top: 3em;
}

#home-testimonial.view-display-id-page .views-field-field-testimonial-quote {
  font-style: italic;
}

#home-testimonial.view-display-id-page .views-field-field-testimonial-quote .field-content {
  padding-left: 3em;
}

#home-testimonial.view-display-id-page .views-field-title {
  margin-top: 0.5em;
  padding-left: 3em;
}

/* Book Theme Pages */
.view-books.view-display-id-block_2 .views-row,
.view-books.view-display-id-block_4 .views-row,
.view-authors.view-display-id-block .views-row {
  overflow: hidden;
  margin-bottom: 3em;
}

.view-books.view-display-id-block_2 .views-row .views-field-field-book-image,
.view-books.view-display-id-block_2 .views-row .views-field-field-author-photo,
.view-books.view-display-id-block_4 .views-row .views-field-field-book-image,
.view-books.view-display-id-block_4 .views-row .views-field-field-author-photo,
.view-authors.view-display-id-block .views-row .views-field-field-book-image,
.view-authors.view-display-id-block .views-row .views-field-field-author-photo {
  width: 25%;
  float: left;
  padding-right: 15px;
}

.front.i18n-es #home-featured .listing-resources h2 {
  padding-top: 5px;
}

.front.i18n-es #home-featured .listing-resources li:not(:last-child) {
  position: relative;
}

.front.i18n-es #home-featured .listing-resources li:not(:last-child):before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

@media print {
  .front.i18n-es #home-featured .listing-resources li:not(:last-child):before {
    display: none;
  }
}

.front.i18n-es #home-featured .listing-resources li:not(:last-child):after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

@media print {
  .front.i18n-es #home-featured .listing-resources li:not(:last-child):after {
    display: none;
  }
}

.front.i18n-es #home-featured .listing-resources li:not(:last-child):before {
  content: "" !important;
  display: block !important;
  border-top: 1px solid #646464 !important;
  height: 1px !important;
  width: 100% !important;
  position: absolute !important;
  bottom: 20px !important;
  z-index: 1 !important;
  margin-left: 0 !important;
}

@media print {
  .front.i18n-es #home-featured .listing-resources li:not(:last-child):before {
    display: none;
  }
}

.front.i18n-es #home-featured .listing-resources .question h4 {
  margin-bottom: 10px;
}

.front.i18n-es #home-featured .listing-resources .answer a {
  border-bottom: 1px dotted #B7531C;
}

.front.i18n-es #home-featured .listing-resources .answer a:after {
  content: " ›";
}

.front.i18n-es #home-featured-bottom {
  position: relative;
  padding-top: 0;
}

.front.i18n-es #home-featured-bottom:after {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 20px;
  z-index: 1;
  visibility: visible;
}

@media print {
  .front.i18n-es #home-featured-bottom:after {
    display: none;
  }
}

.front.i18n-es #home-featured-bottom:before {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 0 auto 15px;
  height: 38px;
  width: 50px;
  position: relative;
  top: 0;
  z-index: 10;
}

@media print {
  .front.i18n-es #home-featured-bottom:before {
    display: none;
  }
}

.view-meet-the-authors-gallery {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.view-meet-the-authors-gallery .views-row {
  width: 100%;
  position: relative;
}

.view-meet-the-authors-gallery .views-row .views-field-title {
  position: absolute;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: auto;
  color: #fff;
  padding: 7.5px 15px;
}

@media (min-width: 48em) {
  .view-meet-the-authors-gallery .views-row {
    width: calc(33.33% - 25px);
  }
}

.card-gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 20px;
  margin-right: -10px;
  margin-left: 0;
}

.card-gallery .paragraphs-item-text {
  width: 100%;
}

.card-gallery > .paragraphs-item-card {
  clear: both;
  padding: 20px;
  position: relative;
}

@media (min-width: 37.5em) {
  .card-gallery > .paragraphs-item-card {
    flex-basis: calc(50% - 14px);
  }
}

@media (min-width: 60em) {
  .card-gallery > .paragraphs-item-card {
    flex-basis: calc(33.33% - 14px);
  }
}

.card-gallery > .paragraphs-item-card .field-image,
.card-gallery > .paragraphs-item-card .field-card-image,
.card-gallery > .paragraphs-item-card .field-card-video {
  margin-left: -20px;
  margin-right: -20px;
}

.card-gallery > .paragraphs-item-card .content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-gallery > div {
  margin-bottom: 20px;
}

.card-gallery .field-cardgallery {
  display: flex;
  flex-wrap: wrap;
}

.card-gallery .field-cardgallery .field-card-category {
  text-transform: uppercase;
}

.node-cardgallery .body-content .field-summary,
.node-cardgallery .body-content .field-cardgallery-image {
  width: 100%;
}

.node-cardgallery .body-content .field-cardgallery-image img {
  width: 100%;
}

@media (max-width: 59.9375em) {
  .node-cardgallery .body-content .field {
    flex-basis: 100%;
  }
  .node-cardgallery .body-content .field-cg-image {
    padding-right: 0px;
  }
}

@media (min-width: 48em) {
  .node-cardgallery .body-content .field-summary {
    width: 75%;
  }
  .node-cardgallery .body-content .field-cardgallery-image {
    float: left;
    width: 25%;
    padding-right: 20px;
  }
}

.node-cardgallery .field-kicker {
  margin-bottom: 4.5px;
  color: #B30425;
  font-size: 1.3em;
}

.node-cardgallery .field-summary {
  font-size: 1.1em;
}

.divider {
  position: relative;
}

.divider::before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

.divider::after {
  content: "";
  display: block;
  background-image: url(../images/flower-separator.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

.paragraphs-item-card,
.field-cardgallery-categories label {
  border: 0;
}

.paragraphs-item-card,
.field-cardgallery-categories label,
.field-cardgallery-categories select {
  border-radius: 8px;
}

.field-cardgallery-categories .form-item {
  margin: 0;
}

.field-cardgallery-categories label {
  font-weight: 400;
  color: #B30425;
}

.field-cardgallery-categories select {
  appearance: none;
}

.field-cardgallery-categories select:hover, .field-cardgallery-categories select:focus {
  border-color: #B30425;
}

.field-cardgallery-categories .form-select {
  border: 1px solid #646464;
}

.field-cardgallery-categories .form-type-select label::after {
  content: "";
  background: url(../images/dropdown-arrow.png) no-repeat right 12px center;
  background-color: transparent;
  background-size: 12px auto;
  width: 100%;
  height: 38px;
  position: absolute;
  top: 28px;
  right: 0;
  z-index: 20;
}

.paragraphs-item-card .field-card-category {
  font-size: 0.9rem;
  color: #000;
}

.paragraphs-item-card .field-card-title {
  margin-bottom: 10px;
}

.paragraphs-item-card .field-card-body {
  color: #00539B;
  margin-bottom: 0;
}

.paragraphs-item-card .field-card-body h3 {
  font-weight: 700;
  margin-bottom: 5px;
}

.paragraphs-item-card .field-card-body > span {
  display: inline-block;
}

.paragraphs-item-card .field-card-link {
  margin: 0;
  padding: 0;
}

.paragraphs-item-card .field-card-link a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-indent: -9999px;
  border: none;
}

.paragraphs-item-card .field-card-link a:focus {
  outline-color: #B30425;
  outline-offset: 5px;
  border-radius: 1.749993px;
}

.paragraphs-item-card:hover, .paragraphs-item-card:focus-within {
  box-shadow: 0 4px 12px rgba(92, 95, 97, 0.25);
  transition: 0.3s all ease-in-out;
}

.paragraphs-item-card:hover .field-card-title, .paragraphs-item-card:focus-within .field-card-title {
  transition: 0.3s all ease-in-out;
  text-decoration: underline;
}

.node-cardgallery.node-full {
  margin-bottom: 0;
}

.node-cardgallery.node-full .node-content {
  overflow: hidden;
  margin-bottom: -30px;
}

.node-cardgallery.node-full h1 {
  margin-bottom: 1rem;
}

.node-cardgallery.node-full .field-summary {
  color: #2581bc;
  font-size: 1.1em;
}

.node-cardgallery.node-full .field-body {
  margin-bottom: 30px;
}

.node-cardgallery.node-full .-field-image {
  margin-bottom: 30px;
}

@media (min-width: 30em) {
  .node-cardgallery.node-full .field-image {
    width: 31%;
    float: left;
    margin-right: 30px;
  }
}

.node-type-cardgallery .block-main {
  margin-bottom: 0;
}

#block-menu-block-1 #mega-menu > li ul li a {
  opacity: 0;
  transition: all 1s ease-in-out;
}

#block-menu-block-1 #mega-menu > li.is-active ul li {
  background: transparent;
}

#block-menu-block-1 #mega-menu > li.is-active ul li a {
  opacity: 1;
  transition: all 1s ease-in-out;
}

.paragraphs-item-related-resource {
  color: #646464;
}

.paragraphs-item-related-resource .field {
  margin: 0;
}

.paragraphs-item-related-resource .field-label {
  font-weight: normal;
}

.paragraphs-item-related-resource a {
  border-bottom: 0;
}

.related-resources {
  border: 1px solid #1E7CBC;
}

.related-resources h2 {
  background-color: #1E7CBC;
  color: #fff;
  padding: 12px;
  margin: 0;
  border: none;
  font-size: 22px;
  font-family: "myriad-pro", verdana, sans-serif;
  font-weight: normal !important;
}

.related-resources h2 a {
  color: #fff;
  border-bottom: none;
}

.related-resources ul {
  margin: 0;
}

.related-resources ul li {
  padding: 0 12px !important;
  text-indent: 0 !important;
  margin: 0;
}

.related-resources ul li::before {
  display: none;
}

.related-resources ul li .entity {
  padding: 12px 0;
  border-bottom: 1px solid #aaaaaa;
}

.related-resources ul li:last-child {
  border-bottom: 0;
}

.block-book,
.block-i18n-book-navigation {
  margin: 0 0 30px 0;
}

.block-book .block__title,
.block-i18n-book-navigation .block__title {
  position: relative;
}

.block-book .block__title a,
.block-i18n-book-navigation .block__title a {
  border-bottom: 0;
  color: #00539B;
  font-family: "utopia-std-subhead", georgia, serif;
  font-size: 28px;
}

.block-book .block__title a.active,
.block-i18n-book-navigation .block__title a.active {
  display: none;
}

.block-book > .menu li, .block-book > .menu .parent-book-link,
.block-i18n-book-navigation > .menu li,
.block-i18n-book-navigation > .menu .parent-book-link {
  padding: 0 !important;
  text-indent: 0 !important;
  list-style-type: none;
}

.block-book > .menu li::before, .block-book > .menu .parent-book-link::before,
.block-i18n-book-navigation > .menu li::before,
.block-i18n-book-navigation > .menu .parent-book-link::before {
  display: none;
}

.block-book > .menu > li, .block-book > .menu .parent-book-link,
.block-i18n-book-navigation > .menu > li,
.block-i18n-book-navigation > .menu .parent-book-link {
  border-bottom: 1px solid #646464;
  padding-top: 15px !important;
  padding-bottom: 18px !important;
  margin-bottom: 0 !important;
}

.block-book > .menu > li > a, .block-book > .menu .parent-book-link > a,
.block-i18n-book-navigation > .menu > li > a,
.block-i18n-book-navigation > .menu .parent-book-link > a {
  color: #00539B;
  display: block;
  margin: 0;
  border: none;
  font-size: 18px;
  font-family: "proxima-nova-alt", verdana, sans-serif;
  font-weight: normal !important;
  position: relative;
  padding-right: 10px;
}

.block-book > .menu > li.is-collapsed > a::after, .block-book > .menu > li.is-expanded > a::after, .block-book > .menu .parent-book-link.is-collapsed > a::after, .block-book > .menu .parent-book-link.is-expanded > a::after,
.block-i18n-book-navigation > .menu > li.is-collapsed > a::after,
.block-i18n-book-navigation > .menu > li.is-expanded > a::after,
.block-i18n-book-navigation > .menu .parent-book-link.is-collapsed > a::after,
.block-i18n-book-navigation > .menu .parent-book-link.is-expanded > a::after {
  content: "\2228";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.block-book > .menu > li.is-expanded > a::after, .block-book > .menu .parent-book-link.is-expanded > a::after,
.block-i18n-book-navigation > .menu > li.is-expanded > a::after,
.block-i18n-book-navigation > .menu .parent-book-link.is-expanded > a::after {
  transform: rotate(90deg);
}

.block-book > .menu > li .menu, .block-book > .menu .parent-book-link .menu,
.block-i18n-book-navigation > .menu > li .menu,
.block-i18n-book-navigation > .menu .parent-book-link .menu {
  margin-top: 5px;
}

.block-book > .menu > li li, .block-book > .menu > li .parent-book-link, .block-book > .menu .parent-book-link li, .block-book > .menu .parent-book-link .parent-book-link,
.block-i18n-book-navigation > .menu > li li,
.block-i18n-book-navigation > .menu > li .parent-book-link,
.block-i18n-book-navigation > .menu .parent-book-link li,
.block-i18n-book-navigation > .menu .parent-book-link .parent-book-link {
  margin: 8px 0 0;
}

.block-book > .menu > li li a, .block-book > .menu > li .parent-book-link a, .block-book > .menu .parent-book-link li a, .block-book > .menu .parent-book-link .parent-book-link a,
.block-i18n-book-navigation > .menu > li li a,
.block-i18n-book-navigation > .menu > li .parent-book-link a,
.block-i18n-book-navigation > .menu .parent-book-link li a,
.block-i18n-book-navigation > .menu .parent-book-link .parent-book-link a {
  color: #B7531C;
}

.block-book > .menu > li li a.active, .block-book > .menu > li .parent-book-link a.active, .block-book > .menu .parent-book-link li a.active, .block-book > .menu .parent-book-link .parent-book-link a.active,
.block-i18n-book-navigation > .menu > li li a.active,
.block-i18n-book-navigation > .menu > li .parent-book-link a.active,
.block-i18n-book-navigation > .menu .parent-book-link li a.active,
.block-i18n-book-navigation > .menu .parent-book-link .parent-book-link a.active {
  color: #000000;
  font-weight: bold;
  border-bottom: 0;
}

.block-book > .menu > li li a.active::before, .block-book > .menu > li .parent-book-link a.active::before, .block-book > .menu .parent-book-link li a.active::before, .block-book > .menu .parent-book-link .parent-book-link a.active::before,
.block-i18n-book-navigation > .menu > li li a.active::before,
.block-i18n-book-navigation > .menu > li .parent-book-link a.active::before,
.block-i18n-book-navigation > .menu .parent-book-link li a.active::before,
.block-i18n-book-navigation > .menu .parent-book-link .parent-book-link a.active::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #B7531C;
  display: inline-block;
  margin-right: 8px;
}

@media (min-width: 48em) {
  .block-book,
  .block-i18n-book-navigation {
    margin-bottom: 0;
  }
  .block-book .block__title,
  .block-i18n-book-navigation .block__title {
    position: relative;
  }
  .block-book .block__title a,
  .block-i18n-book-navigation .block__title a {
    position: relative;
    position: static;
  }
  .block-book .block__title a:before,
  .block-i18n-book-navigation .block__title a:before {
    content: "";
    display: block;
    border-top: 1px solid #646464;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    z-index: 1;
  }
}

@media print and (min-width: 48em) {
  .block-book .block__title a:before,
  .block-i18n-book-navigation .block__title a:before {
    display: none;
  }
}

@media (min-width: 48em) {
  .block-book .block__title a:after,
  .block-i18n-book-navigation .block__title a:after {
    content: "";
    display: block;
    background-image: url("../images/flower-separator.png");
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px auto;
    margin: 15px auto;
    height: 38px;
    width: 50px;
    position: relative;
    z-index: 10;
    visibility: visible !important;
  }
}

@media print and (min-width: 48em) {
  .block-book .block__title a:after,
  .block-i18n-book-navigation .block__title a:after {
    display: none;
  }
}

@media (min-width: 48em) {
  .block-book > .menu,
  .block-i18n-book-navigation > .menu {
    width: 33.33%;
    float: left;
    padding-right: 15px !important;
    padding-top: 15px !important;
    border-right: 1px solid #646464;
  }
  .block-book + .node,
  .block-i18n-book-navigation + .node {
    width: 66.67%;
    float: right;
    padding-left: 15px;
    border-left: 1px solid #646464;
    position: relative;
    left: -1px;
  }
  .block-book + .node .field-title-field,
  .block-book + .node .field-title-field h1,
  .block-i18n-book-navigation + .node .field-title-field,
  .block-i18n-book-navigation + .node .field-title-field h1 {
    margin-bottom: 10px;
  }
}

@media (min-width: 60em) {
  .block-book > .menu,
  .block-i18n-book-navigation > .menu {
    width: 25%;
  }
  .block-book + .node,
  .block-i18n-book-navigation + .node {
    width: 75%;
  }
}

.views-course-intro-block {
  margin-bottom: 30px !important;
}

.book-navigation {
  clear: both;
  float: none;
}

.book-navigation .menu {
  display: none;
}

.book-navigation .page-links {
  border-top: 1px solid #646464;
  border-bottom: 0;
  padding: 15px 0 0;
  margin-top: 30px;
}

.book-navigation .page-links a {
  display: inline-block;
  width: auto;
}

.book-navigation .page-links a.page-up {
  display: none;
}

.node-quiz .book_add_child,
.book_printer {
  display: none !important;
}

.node-quiz.view-mode-longteaser .field-basic-thumbnail {
  margin-bottom: 15px;
  padding-left: 0;
}

@media (min-width: 48em) {
  .node-quiz.view-mode-longteaser .field-basic-thumbnail {
    width: 25%;
    padding-right: 15px;
    margin-right: 0;
    margin-bottom: 20px;
    float: left;
  }
}

.node-quiz.view-mode-longteaser .field-basic-thumbnail img {
  width: 100%;
}

@media (min-width: 48em) {
  .node-quiz.view-mode-longteaser .field-description-field,
  .node-quiz.view-mode-longteaser .field-title-field {
    width: 75%;
    padding-left: 15px;
    float: left;
  }
}

.node-quiz .page__title {
  border-bottom: 1px solid #646464;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.node-quiz .field-basic-thumbnail {
  padding-right: 0;
  margin-right: 0;
}

.node-quiz .paragraphs-items-field-quiz-questions {
  border-top: 1px solid #646464;
  clear: both;
  padding-top: 30px;
}

.node-quiz .btn {
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.node-quiz .btn:not([disabled]) {
  background: #B30425;
  color: #fff;
}

.node-quiz .btn:not([disabled]):hover, .node-quiz .btn:not([disabled]):focus {
  background: #81031b;
}

.node-quiz .btn[disabled]:hover, .node-quiz .btn[disabled]:focus {
  background: #eeeeee;
}

@media (min-width: 48em) {
  .node-quiz .field-basic-thumbnail {
    width: 33.33%;
    float: left;
    padding-right: 15px;
    margin-right: 15px;
  }
}

.views-course-intro-block {
  margin-bottom: 0;
}

.views-course-intro-block .views-field-field-course-image {
  margin-bottom: 15px;
}

.views-course-intro-block .view-footer {
  margin-top: 15px;
}

.views-field-field-funder-credit {
  border: 1px solid #00539B;
  margin-bottom: 15px;
}

.views-field-field-funder-credit img {
  padding: 15px;
}

.views-field-field-funder-credit p:last-child {
  background-color: #00539B;
  width: 100%;
  color: #fff;
  padding: 15px;
  font-size: smaller;
}

@media (min-width: 30em) {
  .views-course-intro-block .views-field-field-course-image {
    width: 33.33%;
    float: left;
    padding-right: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .views-field-field-funder-credit {
    margin: 30px 0 45px;
  }
  .views-course-intro-block .view-footer {
    overflow: visible;
  }
}

@media (min-width: 48em) {
  .views-course-intro-block .views-row {
    width: 100%;
    float: left;
    padding-right: 15px;
  }
  .views-course-intro-block .views-field-field-course-image {
    width: 25%;
  }
  .views-course-intro-block .views-field-field-course-description {
    width: 48%;
    float: left;
    padding-right: 15px;
  }
  .views-course-intro-block .views-field-field-funder-credit {
    width: 23%;
    float: right;
    margin-top: 0;
  }
  .views-course-intro-block .view-footer {
    width: 100%;
    float: right;
  }
  .view-course-intro::after {
    content: "";
    display: block;
    clear: both;
  }
}

.group-basic-intro .field-basic-thumbnail {
  margin-bottom: 2em !important;
}

.node-basic-page .group-content > .field-basic-body {
  border-top: 1px solid #646464;
  clear: both;
  padding-top: 30px;
}

.node-basic-page .group-content > .field-basic-body > .item h2 {
  margin-top: 0;
  padding-top: 0;
}

.node-type-course-landing-page *:first-child {
  margin-top: 0;
}

.node-type-course-landing-page .field-course-body .course-landing-links,
.node-type-course-landing-page .field-course-body table {
  width: 100% !important;
  border-color: transparent;
}

.node-type-course-landing-page .field-course-body .course-landing-links tr,
.node-type-course-landing-page .field-course-body table tr {
  background-color: transparent !important;
}

.node-type-course-landing-page .field-course-body .course-landing-links tr a,
.node-type-course-landing-page .field-course-body table tr a {
  border-bottom: none;
}

.view-course-intro-block {
  position: relative;
}

.view-course-intro-block:before {
  content: "";
  display: block;
  border-top: 1px solid #646464;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

@media print {
  .view-course-intro-block:before {
    display: none;
  }
}

.view-course-intro-block:after {
  content: "";
  display: block;
  background-image: url("../images/flower-separator.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px auto;
  margin: 15px auto;
  height: 38px;
  width: 50px;
  position: relative;
  z-index: 10;
  visibility: visible !important;
}

@media print {
  .view-course-intro-block:after {
    display: none;
  }
}

.node-type-course-landing-page .sidebar {
  width: 100% !important;
  margin-left: 0;
  float: none;
  background: #FBF6E4;
  border: 1px solid #646464;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
}

.node-type-course-landing-page .sidebar:before {
  content: "";
  display: block;
  width: 100%;
  height: 60px;
  background-image: url("../images/flower-separator-full.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 60px;
}

@media print {
  .node-type-course-landing-page .sidebar:before {
    display: none;
  }
}

@media (min-width: 48em) {
  .node-type-course-landing-page .sidebar {
    margin-top: 30px;
  }
  .node-type-course-landing-page .sidebar:before {
    background-size: auto 30px;
    position: absolute;
    top: -30px;
    left: 0;
    height: 30px;
  }
}

@media (max-width: 47.9375em) {
  .node-type-course-landing-page .sidebar {
    margin-top: 45px;
  }
  .node-type-course-landing-page .sidebar:before {
    background-size: auto 40px;
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
  }
}

.field-quiz-questions > .quiz-question {
  margin-bottom: 30px;
}

.quiz-question {
  background: transparent;
  border: 0;
  padding: 0;
}

.quiz-question .field {
  margin-bottom: 0;
}

.quiz-question .answer {
  margin-top: 10px;
}

.quiz-question .answer input[disabled] {
  display: none;
}

.quiz-question .answer input[disabled] + label {
  padding-left: 8px;
  padding-right: 8px;
}

.quiz-question .answer.selected label {
  background-color: #eeeeee;
  border-radius: 4px;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 10px;
}

.quiz-question .answer .key {
  display: inline-block;
  font-size: 0.825em;
}

.quiz-question .answer .key strong::before {
  font-family: "Font Awesome 5 Pro";
  margin-right: 4px;
}

.quiz-question .answer.correct .key strong {
  color: #0A8023;
}

.quiz-question .answer.correct .key strong::before {
  content: "\f058";
}

.quiz-question .answer.incorrect .key strong {
  color: #B30425;
}

.quiz-question .answer.incorrect .key strong::before {
  content: "\f057";
}

.quiz-question.not-answered {
  border-left: 2px solid #B30425;
  padding-left: 15px;
}

.quiz-message {
  display: none;
}

.quiz-confirmation .quiz-message {
  display: block;
}

.quiz-confirmation > * {
  background: #FBF6E4;
  border: 1px solid #646464;
  padding: 15px;
  margin-bottom: 15px;
}

.quiz-confirmation .btn {
  margin-right: 15px;
}

.quiz-confirmation .continue::after,
.quiz-confirmation .retake::before,
.quiz-confirmation .download::before {
  font-family: "Font Awesome 5 Pro";
}

.quiz-confirmation .continue::after {
  content: "\f061";
  margin-left: 5px;
  font-weight: 300;
}

.quiz-confirmation .download::before {
  content: "\f33d";
  margin-right: 5px;
  font-weight: 300;
}

.quiz-confirmation .retake {
  color: #B30425;
  border-bottom: 0;
}

.quiz-confirmation .retake:hover, .quiz-confirmation .retake:focus {
  color: #81031b;
  border-bottom: 1px dotted #81031b;
}

.quiz-confirmation .retake::before {
  content: "\f0e2";
  margin-right: 5px;
  font-weight: 300;
}

.error-message p {
  color: #B30425;
  font-weight: bold;
}

.error-message p::before {
  content: "\f057";
  font-family: "Font Awesome 5 Pro";
  margin-right: 5px;
}

.field-content {
  position: relative;
}

.field-content .image-field-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(84, 84, 86, 0.95);
  width: 100%;
  padding: 8px 15px;
  color: #fff;
  margin: 0;
  font-size: .8rem;
  text-align: center;
}

/**
 * @file
 * Print styling
 *
 * We provide some sane print styling for Drupal using Zen's layout method.
 */
@media print {
  a:link,
  a:visited {
    /* underline all links */
    text-decoration: underline !important;
  }
  #site-name a:link, #site-name
  a:visited {
    /* Don't underline header */
    text-decoration: none !important;
  }
  #content a[href]:after {
    display: none;
  }
  #content a[href^="javascript:"]:after,
  #content a[href^="#"]:after {
    /* Only display useful links. */
    content: "";
  }
  #content {
    /* Un-float the content */
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  body,
  #page,
  #main,
  #content {
    /* Turn off any background colors or images */
    color: #000;
    background-color: transparent !important;
    background-image: none !important;
  }
  #skip-link,
  #toolbar,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .breadcrumb,
  .tabs,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .pager,
  .feed-icons,
  .secondary-menu,
  #cc-nav-search,
  .funding-credit,
  .partner-aft,
  .views-classroom-strategy-button-block,
  .views-print-button-block,
  .views-bookfinder-button-block,
  .view-filters, .messages, .messages--status, .messages--warning, .messages--error {
    /* Hide sidebars and nav elements */
    visibility: hidden;
    display: none;
  }
  #header {
    height: auto !important;
  }
  #logo {
    margin-top: 0;
    padding-top: 0;
  }
  .group-page-intro .field-page-image {
    float: left;
    width: 25%;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .group-page-intro .field-strategy-intro {
    width: 100%;
  }
  h1 {
    font-size: 28px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  h2,
  .field-label {
    font-size: 21px !important;
  }
  h4 {
    font-size: 14px !important;
  }
  p,
  a,
  li {
    font-size: 12px !important;
  }
  table th {
    color: #00539B !important;
    font-size: 12px !important;
  }
  table tr td {
    font-size: 11px !important;
    color: #000000 !important;
  }
}

/* End @media print */
