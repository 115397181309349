.card-gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 20px;
  margin-right: -10px;
  margin-left: 0;

  .paragraphs-item-text {
    width: 100%;
  }

  > .paragraphs-item-card {
    clear: both;
    padding: 20px;
    position: relative;

    @include breakpoint($tablet-small-min) {
      flex-basis: calc(50% - 14px);
    }

    @include breakpoint($desktop-min) {
      flex-basis: calc(33.33% - 14px);
    }

    .field-image,
    .field-card-image,
    .field-card-video {
      margin-left: -20px;
      margin-right: -20px;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  > div {
    margin-bottom: 20px;
  }

  .field-cardgallery {
    display: flex;
    flex-wrap: wrap;

    .field-card-category {
      text-transform: uppercase;
    }
  }
}

.node-cardgallery {
  .body-content {
    .field-summary,
    .field-cardgallery-image {
      width: 100%;
    }

    .field-cardgallery-image {
      img {
        width: 100%;
      }
    }

    @include breakpoint($desktop-max) {
      .field {
        flex-basis: 100%;
      }

      .field-cg-image {
        padding-right: 0px;
      }
    }

    @include breakpoint($tablet-portrait-min) {
      .field-summary {
        width: 75%;
      }

      .field-cardgallery-image {
        float: left;
        width: 25%;
        padding-right: 20px;
      }
    }
  }

  .field-kicker {
    margin-bottom: $vert-gutter*0.125;
    color: $header-red;
    font-size: 1.3em;
  }

  .field-summary {
    font-size: 1.1em;
  }
}

.divider {
  position: relative;

  &::before {
    content: "";
    display: block;
    border-top: 1px solid #646464;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    z-index: 1;
  }

  &::after {
    content: "";
    display: block;
    background-image: url(../images/flower-separator.png);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px auto;
    margin: 15px auto;
    height: 38px;
    width: 50px;
    position: relative;
    z-index: 10;
    visibility: visible !important;
  }
}

.paragraphs-item-card,
.field-cardgallery-categories label {
  border: 0;
}

.paragraphs-item-card,
.field-cardgallery-categories label,
.field-cardgallery-categories select {
  border-radius: 8px;
}

.field-cardgallery-categories {
  .form-item {
    margin: 0;
  }

  label {
    font-weight: 400;
    color: $header-red;
  }

  select {
    appearance: none;

    &:hover,
    &:focus {
      border-color: $header-red;
    }
  }

  .form-select {
    border: 1px solid $rule-color;
  }

  .form-type-select {
    label {
      &::after {
        content: "";
        background: url(../images/dropdown-arrow.png) no-repeat right 12px
          center;
        background-color: transparent;
        background-size: 12px auto;
        width: 100%;
        height: 38px;
        position: absolute;
        top: 28px;
        right: 0;
        z-index: 20;
      }
    }
  }
}

.paragraphs-item-card {
  .field-card-category {
    font-size: 0.9rem;
    color: #000;
  }

  .field-card-title {
    margin-bottom: 10px;
  }

  .field-card-body {
    color: $primary-color;
    margin-bottom: 0;

    h3 {
      font-weight: 700;
      margin-bottom: 5px;
    }

    > span {
      display: inline-block;
    }
  }

  .field-card-link {
    margin: 0;
    padding: 0;

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-indent: -9999px;
      border: none;

      &:focus {
        outline-color: $header-red;
        outline-offset: 5px;
        border-radius: $ui-border-radius;
      }
    }
  }

  &:hover,
  &:focus-within {
    box-shadow: $box-shadow;
    transition: 0.3s all ease-in-out;

    .field-card-title {
      transition: 0.3s all ease-in-out;
      text-decoration: underline;
    }
  }
}

.node-cardgallery.node-full {
  margin-bottom: 0;

  .node-content {
    @include clearfix;

    margin-bottom: -$horiz-gutter * 2;
  }

  h1 {
    margin-bottom: 1rem;
  }

  .field-summary {
    color: #2581bc;
    font-size: 1.1em;
  }

  .field-body {
    margin-bottom: $horiz-gutter * 2;
  }

  .-field-image {
    margin-bottom: $horiz-gutter * 2;
  }

  @include breakpoint($phone-min) {
    .field-image {
      width: 31%;
      float: left;
      margin-right: $horiz-gutter * 2;
    }
  }
}

.node-type-cardgallery .block-main {
  margin-bottom: 0;
}
