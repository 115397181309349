body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
  
*,
*:before,
*:after {
  @include box-sizing(border-box);
}

@include breakpoint($tablet-portrait-min) {
  #main,
  #header,
  #highlighted-inner,
  #footer,
  .region-bottom,
  .container,
  #block-views-featured-posts-block .views-row,
  #block-views-testimonials-block .view-testimonials {
    margin-left: auto;
    margin-right: auto;
    max-width: $layout-max;
  }
}

@include breakpoint($tablet-portrait-max) {
  #main,
  #header,
  #highlighted-inner,
  #footer,
  .region-bottom,
  .container,
  #block-views-featured-posts-block .views-row,
  #block-views-testimonials-block .view-testimonials {
    margin-left: auto;
    margin-right: auto;
    max-width: 660px;
  }
}

#page {
  min-width: $layout-min;
}

.header__region,
#highlighted-inner,
.no-sidebars #content,
#footer {
  padding-left: $horiz-gutter/2;
  padding-right: $horiz-gutter/2;
}

.sidebar-second #content,
.region-sidebar-first {
  padding-left: $horiz-gutter;
  padding-right: $horiz-gutter*2;
}

.sidebar-first #content,
.region-sidebar-second {
  padding-left: $horiz-gutter*2;
  padding-right: $horiz-gutter;
}

.block {
  margin-bottom: $vert-gutter;
  margin-top: 0;
}

.field {
  margin-bottom: $vert-gutter/2;
  margin-top: 0;
}

/*
 * Containers for grid items and flow items.
 */

#header,
#main,
.region-highlighted {
  @include micro-clearfix;
}


/*
 * The layout when there is only one sidebar, the left one.
 */

.sidebar-first {
  @include breakpoint($tablet-small-min $desktop-max) {
    #content {
      @include grid-span(16, 9);
    }
    .region-sidebar-first { 
      @include grid-span(8, 1);
    }
  }

  @include breakpoint($desktop-min) {
    #content { 
      @include grid-span(18, 7);
      padding-left:$horiz-gutter;
      padding-right:$horiz-gutter/2;
    }
    .region-sidebar-first { 
      @include grid-span(6, 1);
      padding-left:$horiz-gutter/2;
      padding-right:$horiz-gutter;
    }
  }
}

/*
 * The layout when there is only one sidebar, the right one.
 */

.sidebar-second {
  @include breakpoint($tablet-small-min $desktop-max) {
    #content { 
     @include grid-span(16, 1);
    }
    .region-sidebar-second {
      @include grid-span(8, 17);
    }
  }

  @include breakpoint($desktop-min) {
    #content { 
      @include grid-span(18, 1);
      padding-left:$horiz-gutter/2;
      padding-right:$horiz-gutter;
    }
    .region-sidebar-second { 
      @include grid-span(6, 19);
      padding-left:$horiz-gutter;
      padding-right:$horiz-gutter/2;
    }
  }
}

/*
 * The layout when there are two sidebars.
 */

.two-sidebars {
  @include breakpoint($tablet-small-min $desktop-max) {
    #content { 
      @include grid-span(16, 9);
    }
    .region-sidebar-first { 
      @include grid-span(8, 1);
    }
    .region-sidebar-second { 
      @include grid-span(24, 1);
    }
  }

  @include breakpoint($desktop-min) {
    #content { 
      @include grid-span(12, 7);
    }
    .region-sidebar-first { 
      @include grid-span(6, 1);
    }
    .region-sidebar-second { 
      @include grid-span(6, 19);
    }
  }
}

.region-bottom {
  @include breakpoint($tablet-portrait-max) {
    padding:0 $horiz-gutter/2;
  }
}


/* Debug wireframes. Toggled at admin/appearance settings.
   ========================================================================== */


.with-wireframes {
  #header,
  #main,
  #content,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .region-bottom {
    outline: 1px solid #ccc;
  }
}
