/* @partial node-pages */

.node-unpublished {
  /* Unpublished nodes */
  /* background-color: #fff4f4; */ /* Drupal core uses a #fff4f4 background */

  p.unpublished {
    /* The word "Unpublished" displayed underneath the content. */
    @include unpublished-div;
  }
}

.node-title-list {
  margin: 0;
  padding: 0;

  li {
    margin-bottom: $horiz-gutter/2;
    margin-bottom: 0;
    // &:last-child {
    // 	margin-bottom:$horiz-gutter;
    // }
    a {
      font-family: $body-font;

      &.news-title {
        border-bottom: none !important;
        padding-bottom: 0 !important;
      }
    }

    .news-source {
      padding-top: 0 !important;
      color: $body-font-color;
      font-size: 0.9em;
    }
  }
}

.group-l4-content,
.group-content,
.l4-listing,
#author-articles {
  .node-title-list {
    list-style-type: disc !important;
    padding-left: 27px !important;

    li {
      text-indent: -14px !important;
      padding-left: 0 !important;
      margin-bottom: $horiz-gutter/3;

      &::before {
        display: none;
      }
    }
  }
}

p + .l4-listing {
  margin-top: $vert-gutter/2;
}

.group-page-intro-inner {
  @include clearfix;

  .group-image-intro-copy {
    @include breakpoint($tablet-portrait-min) {
      width: 75%;
      padding-right: $horiz-gutter;
    }
  }
}

.group-page-intro-bottom {
  @include flower-rule(bottom, #fff);
  font-size: 15px;
  line-height: 22px;
}

.group-page-intro-bottom + div,
.group-page-intro-bottom + section {
  border-top: none !important;

  h2 {
    border-top: none !important;
    margin-top: 0;
  }
}

.group-page-intro-bottom + .group-l4-subhead {
  &::before,
  &::after {
    display: none !important;
  }
}

// .node-type-l2-page,
// .node-type-l3-page,
// .node-type-basic-page {
// 	.group-page-intro-bottom {
// 		color:$body-font-color;
// 	}
// }

.node-type-l2-page,
.node-type-l3-page,
.node-type-l4-page {
  // h3 {
  // 	font-size:1.2em;
  // 	margin-top:0;
  // }
  // h2 {
  // 	font-size:1.8em;
  // }
  h1 {
    margin-top: 0;
  }

  .videos,
  .slideshow {
    margin-bottom: 2em;
  }

  .listing-news {
    border: 1px solid $feed-bgrd;
    // border-radius:4px;
    h2 {
      margin: 0;
      padding: $horiz-gutter/2;
      background-color: $feed-bgrd;
      color: #fff;
      font-size: $h3-font-size;
    }

    article,
    .node-title-list {
      li {
        padding: 0 12px !important;

        a {
          display: block;
          border-bottom: 1px solid $light-rule-color;
          padding: 12px 0;

          &.news-title {
            border-bottom: none !important;
            padding-bottom: 0 !important;
          }

          &.news-source {
            padding-top: 0 !important;
          }
        }

        &:last-child {
          a {
            border-bottom: none;
          }
        }

        h3 {
          margin: 0;
          font-size: 1em;
        }
      }
    }
  }
  @include breakpoint($tablet-portrait-min) {
    // .field-l2-intro {
    // 	width:50%;
    // 	float:left;
    // 	padding:0 $horiz-gutter;
    // }
    .funding-credit {
      .partner-aft {
        margin-top: 0;
      }

      .partner-nea {
        margin: 0;
      }
    }

    .feed,
    .listing-news {
      width: 25%;
      //float: left;
      margin-top: 56px;
      margin-bottom: $horiz-gutter * 1.5;
    }

    .videos {
      h2 {
        margin-top: 0;
      }
    }
  }
}

.node-type-l2-page,
.node-type-l3-page {
  @include breakpoint($tablet-portrait-min) {
    .funding-credit {
      width: 25%;
      float: right;
      padding-left: $horiz-gutter;
    }

    .videos {
      width: 75%;
      float: left;
      padding-right: $horiz-gutter * 2;
    }

    .funding-credit {
      width: 25%;
      float: left;
      margin-top: 56px;
      margin-bottom: $horiz-gutter * 1.5;
    }
  }
}

section.videos {
  overflow: hidden;

  .iframe,
  .player {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60% !important;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid #ccc;
    }
  }

  .video-content {
    width: 100% !important;
    margin-left: -11px;
    margin-right: -11px;

    article {
      width: 150px;
      height: auto;
      padding: $horiz-gutter/2;

      header {
        display: none;
      }

      .field {
        margin-bottom: 0;
      }

      .group-video {
        position: relative;

        .video-title {
          position: absolute;
          bottom: 0;
          color: #fff !important;
          font-size: 0.8em !important;
          width: 100%;
          line-height: 1.4em !important;
          font-weight: normal !important;

          .field-title-field {
            padding-left: 10px;
            padding-right: 10px;
            white-space: normal;
            padding-bottom: 5px;
            text-shadow: 1px 1px 1px #000;
          }
        }

        .field-playlist-youtube-url {
          img {
            width: 135px;
            height: 101px;
          }
        }
      }

      a.play-video:not(.video-title) {
        display: block;
        position: relative;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include background-image(
            linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 50%,
              rgba(0, 0, 0, 0.75) 100%
            )
          );
        }
      }

      a.play-video,
      a.video-title {
        border-bottom: none;
        @include transition(all 0.3s ease);
      }

      .group-video {
        a.play-video {
          opacity: 0.85;
        }

        &:hover {
          a.play-video,
          a.video-title {
            opacity: 1;
          }
        }

        a.active-video,
        a.active-video + a.video-title {
          opacity: 1;
        }
      }
    }
  }

  .video-carousel-pager {
    // position:absolute;
    // top:0;
    // left:0;
    width: 100%;
    // height:100%;
    text-align: center;

    .cycle-prev,
    .cycle-next {
      width: 44px;
      height: 44px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px auto;
      text-indent: -9999px;
      border-bottom: none;
    }

    .cycle-prev {
      background-image: url("../images/cycle-prev.png");
    }

    .cycle-next {
      background-image: url("../images/cycle-next.png");
    }
  }
}

.page-node {
  @include breakpoint($tablet-portrait-min) {
    .field-page-image,
    .field-l4-image,
    .field-webcast-image,
    .field-article-image,
    .field-book-image,
    .field-basic-thumbnail {
      width: 32%;
      padding-right: $horiz-gutter;
      margin-right: $horiz-gutter;
      margin-bottom: 20px;
      float: left;
    }

    .view-mode-longteaser {
      .field-book-image {
        margin-right: 0;
      }
    }
  }

  .field-guide-image {
    width: 130px;
    padding-right: $horiz-gutter;
    margin-right: $horiz-gutter;
    margin-bottom: 20px;
    float: left;
  }
}

/* L2 Pages */

.node-type-l2-page {
  .group-l2-intro {
    @include breakpoint($tablet-portrait-min) {
      width: 75%;
      float: left;
      padding-right: $horiz-gutter;
    }
  }

  .funding-credit {
    margin-left: 0 !important;
    margin-top: 0 !important;
    @include breakpoint($tablet-portrait-min) {
      padding-left: $horiz-gutter !important;
    }
  }
  // .group-page-intro-bottom {
  // 	&:before {
  // 		max-width:951px !important;
  // 	}
  // }
  // #main {
  // 	width:100%;
  // 	max-width:none;
  // 	#content {
  // 		padding-top:0;
  // 		padding-left:0;
  // 		padding-right:0;
  // 		// .group-page-intro,
  // 		// .l2-page-bg,
  // 		// .links.inline:first-child,
  // 		// .l2-page-title {
  // 		// 	background-color:$callout-bgrd;
  // 		// }
  // 		.links.inline:first-child,
  // 		.links.inline:first-child li {
  // 			display:block;
  // 		}
  // 		.page__title,
  // 		.tabs-primary,
  // 		.messages,
  // 		.breadcrumb,
  // 		.group-page-intro-bottom,
  // 		.links.inline:first-child li,
  // 		.group-l2-page-container {
  // 			max-width:$layout-max;
  // 			margin:0 auto !important;
  // 			padding-left:$horiz-gutter !important;
  // 			padding-right:$horiz-gutter;
  // 		}
  // 		ul.links {
  // 			position:relative;
  // 		}
  // 		.links.inline .language-link {
  // 			position:relative;
  // 			z-index:20;
  // 		}
  // 		.links.inline li.sharethis {
  // 			@include breakpoint($tablet-portrait-min) {
  // 				position:absolute;
  // 				width:100%;
  // 				max-width:none;
  // 				z-index:10;
  // 				float:none;
  // 				.sharethis-wrapper {
  // 					max-width:$layout-max;
  // 					margin:0 auto;
  // 					text-align:right;
  // 					padding-right:12px;
  // 				}
  // 			}
  // 			@include breakpoint($tablet-portrait-max) {
  // 				float:none;
  // 				position:static;
  // 				margin-bottom:$horiz-gutter !important;
  // 			}
  // 		}
  // 		.breadcrumb {
  // 			padding-top:$horiz-gutter;
  // 			padding-bottom:$horiz-gutter;
  // 		}
  // 		.tabs-primary {
  // 			padding-bottom:$horiz-gutter/2;
  // 		}
  // 		.messages {
  // 			padding-top:$horiz-gutter/2;
  // 			max-width:$layout-max;
  // 			margin:0 auto;
  // 		}
  // 		.page__title {
  // 			padding-top:$horiz-gutter/2;
  // 			padding-bottom:$horiz-gutter*2;
  // 		}
  // 		.group-l2-page-container {
  // 			padding-top:$horiz-gutter;
  // 		}
  // 	}
  // }
  #l2-listing-pages {
    clear: both;
    @include flower-rule(top, #fff);

    article {
      margin-top: $horiz-gutter;
    }

    h2 {
      margin-top: 0;
    }
    // header {
    // 	width:75%;
    // 	float:right;
    // }
    @include breakpoint($tablet-portrait-min) {
      .field-landing-image {
        width: 25%;
        float: left;
        padding-right: $horiz-gutter;

        a {
          border-bottom: none;
        }
      }

      .landing-intro {
        @include clearfix;
        width: 75%;
        float: right;
        padding-left: $horiz-gutter;

        h3 {
          margin-top: 0;
          position: relative;
          top: -4px;
          margin-bottom: 5px;
        }
        // .field-landing-intro,
        // .field-landing-description {
        // 	width:75%;
        // 	float:left;
        // 	padding-right:$horiz-gutter;
        // }
        // .field-title-field {
        // 	width:25%;
        // 	float:right;
        // 	text-align:right;
        // 	padding-left:$horiz-gutter;
        // 	position:relative;
        // 	top:-10px;
        // 	a {
        // 		width:100% !important;
        // 	}
        // }
      }
    }
    @include breakpoint($tablet-portrait-max) {
      .field-landing-image {
        width: 100%;
      }

      .field-landing-intro {
        width: 100%;
      }

      .field-title-field {
        width: 100%;
        text-align: left;
      }
    }

    .field-title-field {
      a {
        @include submit-btn;
        background-color: $see-more-btn;

        &:hover,
        &:active,
        &:focus {
          background-color: darken($see-more-btn, 5%);
          color: #fff;
        }

        &:visited {
          color: #fff;
        }
      }
    }
  }
  // @include breakpoint($tablet-landscape-min) {
  // 	#l2-subpages > div {
  // 		@include clearfix;
  // 		margin-left:-$horiz-gutter;
  // 		margin-right:-$horiz-gutter;
  // 		article {
  // 			padding:0 $horiz-gutter;
  // 			margin-bottom:$horiz-gutter*1.5;
  // 			width:33.33%;
  // 			float:left;
  // 			&:nth-child(3n+1) {
  // 				clear:both;
  // 			}
  // 		}
  // 	}
  // }
  // @include breakpoint($tablet-landscape-max $tablet-portrait-min) {
  // 	#l2-subpages article {
  // 		padding-right:$horiz-gutter;
  // 		margin-bottom:$horiz-gutter*1.5;
  // 		&.row-3-across {
  // 			width:50%;
  // 			float:left;
  // 			&:nth-child(2n+1) {
  // 				clear:both;
  // 			}
  // 		}
  // 	}
  // }
  .group-page-intro-bottom {
    @include flower-rule(bottom, #fff);
  }
  // #l2-subpages {
  // 	@include clearfix;
  // 	article {
  // 		.field-page-image,
  // 		.field-l4-image {
  // 			width:100%;
  // 			float:none;
  // 			padding:0;
  // 		}
  // 		&.node-l4-page,
  // 		&.node-basic-page {
  // 			.field-title-field,
  // 			.field-l4-intro {
  // 				display:none;
  // 			}
  // 		}
  // 	}
  // }
  #l2-subpages {
    margin-bottom: 2em;

    h2 {
      margin-top: 0;
    }
  }
}

/* L3 Pages */

.node-type-l3-page {
  .hide-funding-credits {
    .group-page-intro {
      width: 100%;
    }

    .funding-credit {
      display: none !important;
    }
  }

  .field-hide-funding-credits {
    display: none;
  }

  .group-l3-bottom-resources {
    @include clearfix;
    clear: both;
    border-top: 1px solid #ccc;

    .listing {
      width: 25%;
      float: left;
      padding: $horiz-gutter;

      h2 {
        font-size: $h3-font-size;
        margin-top: $horiz-gutter;
      }

      li {
        margin-bottom: $horiz-gutter/2;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .group-page-intro-bottom + section,
  .group-page-intro-bottom + div {
    &::before,
    &::after {
      display: none !important;
    }
  }
  @include breakpoint($tablet-portrait-min) {
    .group-page-intro {
      width: 75%;
      float: left;
      padding-right: $horiz-gutter;
    }

    .funding-credit {
      width: 25%;
      float: left;
      padding-left: $horiz-gutter;
      margin: 0 !important;
    }
  }
}

/* L2 and L3 pages */

#l2-subpages,
#l3-subpages,
#videos-subpages {
  article {
    position: relative;

    .field-title-field {
      margin-bottom: 0;
    }

    h3 {
      margin-top: 0;
      margin-bottom: $horiz-gutter/3;

      a {
        position: relative;
        top: -4px;
      }
    }

    .field-l4-image,
    .field-page-image,
    .field-landing-image {
      margin-bottom: $horiz-gutter;
      @include breakpoint($tablet-portrait-min) {
        width: 25%;
        padding-right: $horiz-gutter;
        margin-right: 0;
        margin-bottom: 20px;
        float: left;
      }
    }

    &.node-l4-page {
      .node__title {
        display: none;
      }
    }

    &.node-basic-page {
      header,
      .field-basic-body img,
      .field-basic-description img {
        display: none;
      }
    }

    &.node-l4-page,
    &.node-basic-page,
    &.node-l3-page {
      .field-title-field {
        @include breakpoint($tablet-portrait-min) {
          width: 75%;
          padding-left: $horiz-gutter;
          float: left;
        }
      }
    }

    &.node-landing {
      @include breakpoint($tablet-portrait-min) {
        .node__title {
          width: 75%;
          padding-left: $horiz-gutter;
          float: left;
        }
      }
    }

    .field-l3-intro,
    .field-l4-intro,
    .field-basic-description,
    .field-basic-body,
    .field-landing-description {
      @include breakpoint($tablet-portrait-min) {
        width: 75%;
        padding-left: $horiz-gutter;
        float: right;
      }
    }
  }
}

#l2-subpages,
#l3-subpages,
#l2-listing-pages {
  @include breakpoint($tablet-portrait-max) {
    article {
      position: relative;
      margin-bottom: $horiz-gutter;

      h3 {
        margin-right: 45px;
      }

      .field-l4-image,
      .field-page-image,
      .field-landing-image,
      .field-basic-thumbnail {
        display: none;
      }

      .field-l4-intro,
      .field-l3-intro,
      .field-basic-description,
      .field-landing-description,
      .field-basic-body {
        .toggle-subpages {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 15px;
          background-color: $header-blue;
          position: absolute;
          top: -4px;
          right: 0;
          cursor: pointer;

          &::before {
            content: "";
            background: url("../images/toggle-subpages.png") no-repeat;
            background-size: 30px auto;
            background-position: top center;
            color: #fff;
            display: block;
            width: 100%;
            text-align: center;
            line-height: 30px;
            height: 30px;
            font-size: 18px;
          }
        }

        .item {
          display: none;
        }

        .toggle-subpages-open {
          &::before {
            content: "";
            background-position: bottom center;
          }
        }

        .toggle-subpages-open + .item {
          display: block;
        }
      }
    }
  }
}

#l2-subpages {
  article {
    &.node-landing {
      .field-title-field {
        display: none;
      }
    }
  }
}

#videos-subpages {
  .field-title-field {
    display: none;
  }

  .node-basic-page {
    .field-title-field {
      display: block;
    }
  }
}

/* Gallery Listing */

article.view-mode-videomodule {
  @include breakpoint($tablet-portrait-min) {
    width: 33.33%;
    padding: $horiz-gutter $horiz-gutter 0 $horiz-gutter;
    float: left;

    &:nth-child(3n + 1) {
      clear: both;
    }
  }
  margin-bottom: $horiz-gutter;
  position: relative;

  .field-title-field,
  .node__title {
    position: absolute;
    bottom: 0;
    width: 100%;
    @include breakpoint($tablet-portrait-min) {
      margin-left: -$horiz-gutter * 2;
      padding-left: $horiz-gutter * 2;
    }
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.1em !important;
    font-family: $body-font;
    font-weight: normal;
    z-index: 20;
  }

  .field-title-field h3,
  .node__title a,
  .field-interview-title a {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: $horiz-gutter;
  }

  .field-title-field a,
  .node__title a,
  .field-interview-title a {
    color: #fff !important;
    font-weight: normal;
  }

  .field {
    margin-bottom: 0;
  }

  &.node-video-interview,
  &.node-classroom-video {
    .field-classroom-city,
    .field-classroom-state,
    .field-classrrom-grade {
      color: #fff;
      font-size: 0.9em;
    }

    .landing-intro {
      position: absolute;
      bottom: 0;
      width: 100%;

      .landing-intro-inside {
        width: 100%;
        @include breakpoint($tablet-portrait-min) {
          margin-left: -$horiz-gutter * 2;
          padding-left: $horiz-gutter * 2;
        }

        .landing-intro-container {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

      .node__title {
        position: static;

        a {
          padding: 0;
          background-color: transparent;
        }
      }

      .field-interview-title {
        width: 100%;

        a {
          padding: 0;
          font-size: 12px;
          background-color: transparent;
        }
      }
    }

    &.node-video-interview {
      .landing-intro-container {
        padding: $horiz-gutter/2 $horiz-gutter;
      }
    }

    &.node-classroom-video {
      .landing-intro-container {
        padding: $horiz-gutter;
      }
    }
  }

  &.node-classroom-video {
    .field-classroom-city,
    .field-classroom-state,
    .field-classrrom-grade {
      display: inline;

      .item {
        display: inline;
      }
    }

    .field-classroom-city + .field-classroom-state {
      &::before {
        content: ", ";
        margin-left: -4px;
      }
    }

    .field-classrrom-grade {
      &::after {
        display: none;
      }
    }

    .field-classrrom-grade + .field-classroom-city {
      &::before {
        content: "| ";
      }
    }
  }
}

/* L4 Pages */

.node-type-l4-page {
  .node-l4-page {
    @include clearfix;
    overflow: visible;

    ul.links:first-child {
      display: block;
    }
  }

  .group-page-intro-inner {
    @include breakpoint($tablet-portrait-min) {
      .field-l4-image {
        width: 33.33%;
      }
    }
  }

  .group-l4-content {
    @include breakpoint($tablet-portrait-min) {
      border-right: 1px solid $light-rule-color;
      width: 68%;
      float: left;
      padding-right: $horiz-gutter;
    }

    .listing {
      @include clearfix;
      @include flower-rule(top, #fff);
      margin-bottom: 2em;

      h2 {
        margin-top: 0;
      }

      li {
        margin-bottom: $vert-gutter/2;
      }
      // @include breakpoint($tablet-portrait-min) {
      // 	li {
      // 		width:33.33%;
      // 		float:left;
      // 		padding-right:$horiz-gutter;
      // 	}
      // }
      h3 {
        font-size: 1em;
      }
    }

    .node-title-list {
      @include clearfix;
    }

    .see-more {
      clear: left;
      @include submit-btn;
      display: block;
      margin: $horiz-gutter auto;
      background-color: $see-more-btn;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($see-more-btn, 5%);
        color: #fff;
      }

      &:visited {
        color: #fff;
      }
    }
  }

  .group-l4-subhead {
    @include flower-rule(top, #fff);
  }

  #l4-videos {
    @include flower-rule(top, #fff);
  }

  #l4-articles,
  #l4-books,
  #l4-guides {
    a:not(.see-more) {
      font-weight: normal;
      color: $link-color !important;

      &:hover {
        color: darken($link-color, 10%) !important;
      }
    }

    .node__title {
      display: none;
    }
  }

  #l4-articles {
    .field-thumbnail-image {
      width: 18%;
      float: left;
      padding-right: $horiz-gutter;
      margin-right: $horiz-gutter;

      a {
        border-bottom: none;
      }
    }
  }

  #l4-books {
    > div {
      margin-left: -$horiz-gutter;
      margin-right: -$horiz-gutter;
    }
    @include breakpoint($tablet-portrait-min) {
      article {
        width: 25%;
        float: left;
        padding: 0 $horiz-gutter;

        &:nth-child(4n + 1) {
          clear: left;
        }
      }
    }
    @include breakpoint($tablet-portrait-max) {
      article {
        width: 50%;
        float: left;
        padding: 0 $horiz-gutter;

        &:nth-child(2n + 1) {
          clear: left;
        }
      }
    }

    article {
      .field-book-image,
      .field-guide-image {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
      }

      .group-book-list-info {
        padding-left: 0;
      }
      // img {
      // 	max-width:115px;
      // }
      h3 {
        font-family: $body-font;
        font-size: 1.05em;
      }

      a {
        border-bottom: none;
      }
    }
  }

  .group-page-intro-bottom + section {
    &::before,
    &::after {
      display: none !important;
    }
  }

  .field-l4-related-page {
    border: none;
    margin-bottom: 2em;

    > .item {
      padding: 0 !important;
    }

    .field-label {
      background-color: $related-feature-bgrd;
      color: #fff;
      margin: 0;
      padding: $horiz-gutter $horiz-gutter 0;
      font-size: 0.9em;
      font-weight: normal;
    }

    article {
      padding: 0 !important;
      width: 100%;
      background-color: $related-feature-bgrd;
      float: none;

      .field-title-field {
        margin: 0;
        padding: 0 $horiz-gutter $horiz-gutter;
        position: static;
      }

      h4 {
        padding: 0;
        margin: 0;

        a {
          color: #fff !important;
          padding: 0 !important;
        }
      }

      .field-l4-image {
        width: 100%;
        padding: 0;
        margin: 0;

        a {
          padding: 0 !important;
        }
      }

      .node__title {
        display: none;
      }
    }
  }
}

section.l4-books,
section.l4-guides {
  .view-mode-longteaser {
    margin-bottom: $horiz-gutter;

    h3 {
      margin-top: 0;
    }

    .field-theme-image,
    .field-guide-image {
      float: left;
      margin-right: 0;
    }

    h3,
    .field-theme-description,
    .field-guide-description {
      float: right;

      img {
        display: none;
      }
    }
    @include breakpoint($tablet-portrait-min) {
      .field-theme-image,
      .field-guide-image {
        width: 14%;
        padding-right: $horiz-gutter;
      }

      h3,
      .field-theme-description,
      .field-guide-description {
        width: 86%;
        padding-left: $horiz-gutter;
      }
    }
    @include breakpoint($tablet-portrait-max) {
      .field-theme-image,
      .field-guide-image {
        width: 25%;
        padding-right: $horiz-gutter/2;
      }

      h3,
      .field-theme-description,
      .field-guide-description {
        width: 75%;
        padding-left: $horiz-gutter/2;
      }
    }
  }
}

body:not(.node-type-l4-page) {
  .group-video-feed {
    @include flower-rule(top, #fff);
  }
}

.section-videos {
  #landing-webcasts-browse {
    @include flower-rule(top, #fff);
  }

  #interviews {
    @include flower-rule(bottom, #fff);
    @include breakpoint($tablet-portrait-min) {
      > div {
        @include clearfix;
        margin-left: -$horiz-gutter;
        margin-right: -$horiz-gutter;
      }

      .node-video-interview {
        h3 {
          margin-bottom: 0;
        }
      }
    }
    @include breakpoint($tablet-portrait-max) {
    }

    .field-interview-video {
      img {
        display: block !important;
        width: 100%;
        height: auto;
      }
    }
  }
}

/* Articles */

.group-article-publish-info,
.group-publish-info,
.group-article-intro,
.group-blog-intro,
.group-basic-intro {
  @include clearfix;
}

.group-article-intro,
.group-research-intro,
.group-blog-intro,
.group-basic-intro,
.node-quiz.view-mode-full .field-basic-description,
.node-quiz.view-mode-full .field-description-field {
  color: $header-blue;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 2em;

  .field {
    margin-bottom: 0;
  }
}

.group-basic-intro {
  overflow: visible;
}

.group-article-intro,
.group-blog-intro {
  border-top: 1px solid $rule-color;
  border-bottom: 1px solid $rule-color;
  padding: $horiz-gutter 0;
  clear: both;
}

/* Internal Navigation */

.node-video-gallery {
  .internal-navigation {
    h3 {
      margin-left: 10px;
    }
  }
}

.internal-navigation,
.breakout {
  h3 {
    @include h4;
  }
  @include breakpoint($tablet-portrait-min) {
    width: 33.33%;
    float: right;
    margin: 0 0 30px 60px;
    padding-left: 20px;
    background: url("../images/internal-nav-bg.png") #fff no-repeat left center;
    background-size: auto 150px;
    position: relative;
    z-index: 20;
    min-height: 150px;

    .item-list {
      border-left: 1px solid #ccc;
      overflow: visible;
    }

    ul {
      padding-left: 0;

      li {
        list-style-type: none;
        display: block;
        padding-left: 20px !important;
        text-indent: -25px !important;
        margin-bottom: 0.5em;
        font-size: 12px;

        &::before {
          content: "";
          display: inline-block;
          background: url("../images/internal-nav-bullet.png") no-repeat top
            left;
          background-size: auto 10px;
          width: 25px;
          height: 10px;
          color: rgba(0, 0, 0, 0) !important;
          position: relative;
          top: 6px;
          margin-left: 0 !important;
        }
      }
    }
  }
  @include breakpoint($tablet-portrait-max) {
    border-bottom: 1px solid $rule-color;
    padding-bottom: 1em;
    margin-bottom: 3em;
  }
}

li a,
.views-row a {
  font-weight: inherit;
}

.breakout {
  @include breakpoint($tablet-portrait-min) {
    ul {
      border-left: 1px solid #ccc;
    }
  }
}

/* Comments */

.comments {
  clear: both;
  padding-top: 1em;
  margin-top: 0 !important;

  h2 {
    margin-top: 0;
  }
  @include breakpoint($tablet-portrait-min) {
    width: 75%;
    padding-right: $horiz-gutter * 2;
  }
}

.comment {
  .submitted {
    color: #aaa;
    font-style: italic;
  }

  .links {
    margin-bottom: $horiz-gutter;
    display: block;
  }
}

.field-article-kicker {
  color: $header-red;
  font-size: 18px;
  // @include breakpoint($desktop-max) {
  // 	margin-bottom:2em;
  // }
}

.node-type-article {
  h1 {
    margin-top: 0;
  }
  @include breakpoint($desktop-min) {
    .field-article-image {
      margin-bottom: 0;
    }
  }
  // @include breakpoint($desktop-max) {
  // 	.field-article-kicker {
  // 		margin-bottom:2em;
  // 	}
  // }
  .field-article-body {
    margin: 3em 0;
    position: relative;
    z-index: 10;

    h2 {
      @include flower-rule(top, #fff);
      clear: both;

      &.norule {
        clear: none;

        &::before,
        &::after {
          display: none !important;
        }
      }
    }
  }

  #author-articles {
    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 1em;
    }
  }

  .field-article-body {
    ul {
      overflow: auto;
    }

    ul.links {
      float: none !important;
      width: 100%;

      li {
        list-style-type: disc;
      }
    }
  }
}

.node-type-article,
.node-type-blog-post {
  .field-body {
    margin-bottom: $horiz-gutter * 3;
  }
}

/* Special Landing Pages */

#landing-webcasts-featured {
  .field-title-field {
    display: none;
  }
}

#landing-webcasts-browse {
  // .field-title-field {
  // 	display:none;
  // }
  .node-webcast {
    margin-bottom: $horiz-gutter;

    .node__title {
      display: none;
    }

    .video-title {
      display: none;
    }
  }
  @include breakpoint($tablet-portrait-min) {
    .node-playlist {
      width: 25%;
      float: left;
      padding-right: $horiz-gutter;
    }

    .field-title-field,
    .field-webcast-intro {
      width: 75%;
      padding-left: $horiz-gutter;
      float: left;
    }
  }
  @include breakpoint($tablet-portrait-max) {
    .node-playlist {
      .field-playlist-youtube-url {
        margin-bottom: 0;
      }
    }
  }

  .field-title-field {
    margin: 0;

    h3 {
      margin-top: 0;
    }
  }

  .node-playlist {
    header {
      display: none;
    }
  }
  // a.play-video {
  // 	display:block;
  // 	width:25%;
  // 	float:left;
  // 	padding-right:$horiz-gutter;
  // }
  // header {
  // 	width:75%;
  // 	float:right;
  // 	h3 {
  // 		margin-top:0;
  // 	}
  // }
}

/* Webcast */

.node-type-webcast {
  .field-group-div {
    h2 {
      border-top: 1px solid $rule-color;
      padding-top: $horiz-gutter;
      margin-top: $horiz-gutter * 3;
    }
  }

  .group-page-intro-bottom,
  .group-page-intro-inner {
    h2 {
      border-top: none;
    }
  }

  .field-webcast-video {
    .node__title,
    .group-page-intro-bottom {
      display: none;
    }
  }
}

/* Classroom Module */

.node-type-classroom-video {
  .field-classroom-video,
  .field-classroom-reflection {
    .group-page-intro-bottom {
      display: none;
    }
  }

  .field-classroom-video {
    header {
      display: none;
    }
  }
}

.node-classroom-video.view-mode-full {
  .field-classrrom-grade,
  .field-classroom-teacher {
    margin-bottom: 0;
    float: left;
    margin-right: 7.5px;
  }

  .field-classroom-teacher + .field-classrrom-grade {
    &::before {
      content: "|";
    }
  }

  .field-classroom-location {
    clear: both;
  }
}

div.collapsible {
  border: 1px solid $accordion-bgrd;
  // border-radius:4px;
  margin-bottom: 24px;

  > h3 {
    background-color: $accordion-bgrd;
    padding: 12px 12px 12px 36px;
    margin: 0;
    background-image: url("../images/accordion-arrow.png");
    background-repeat: no-repeat;
    background-position: top 23px left 12px;
    background-size: 16px auto;

    a {
      color: #fff !important;
      border-bottom: none;
    }
  }

  .field-group-format-wrapper {
    padding: 12px;
  }

  &.collapsed h3 {
    background-position: top -13px left 12px !important;
  }
}

/* Sidebar Items */

// .sidebar {
// 	.block-views {
// 		border-radius:3px;
// 		border:1px solid #ccc;
// 		overflow:hidden;
// 		.view-header {
// 			h2 {
// 				padding-top:10px;
// 				margin-bottom:0;
// 			}
// 			p {
// 				display:none;
// 			}
// 		}
// 		h2 {
// 			font-size:24px;
// 			color:#fff;
// 			background-color:$slider-bgrd;
// 			font-weight:normal;
// 			padding:10px;
// 			margin:0;
// 			border-top:none;
// 		}
// 		ul {
// 			list-style-type:none;
// 			padding:0;
// 			margin:0;
// 			li {
// 				padding:10px;
// 				border-top:1px solid $rule-color;
// 				.views-field-title,
// 				a {
// 					font-size:1em;
// 				}
// 				.views-field-field-news-link {
// 					a {
// 						color:#aaa;
// 						text-transform:uppercase;
// 						font-size:0.8em;
// 					}
// 				}
// 			}
// 		}
// 		&.views-books-3 {
// 			.views-row {
// 				padding:10px;
// 				border-top:1px solid $rule-color;
// 				.views-field-title,
// 				a {
// 					font-size:1em;
// 				}
// 			}
// 		}
// 		&.views-authors-1 {
// 			.views-field {
// 				padding:10px;
// 				border-top:1px solid $rule-color;
// 				.views-field-title,
// 				a {
// 					font-size:1em;
// 				}
// 			}
// 		}
// 	}
// }

.node-type-article,
.node-type-research {
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    clear: both;
  }
  @include breakpoint($tablet-portrait-min) {
    #content {
      .links {
        li.comment-add {
          float: right;
        }

        li.es,
        li.en {
          float: left;
        }
      }
    }
  }
}

.node-type-article,
.node-type-research,
.node-type-blog-post {
  .field-article-author,
	.field-article-published,
	// .field-research-author,
	// .field-research-organization,
	// .field-research-year,
	.field-blog-author {
    display: inline-block;
    font-size: 18px;
    float: left;
    color: $header-blue;
    margin-right: 4px;

    a {
      // color:$header-blue;
      font-weight: 400;
    }

    .field-label {
      display: inline;
      font-weight: normal;
      margin-right: 5px; // Simulate space removed by compression on prod
    }

    .field-items,
    .field-item,
    .item {
      display: inline-block;
    }
  }

  // .field-research-author,
  // .field-research-organization {
  //    clear: left;
  //    margin-top: 0 !important;
  //  }

  .field-topic-page {
    @include rem("font-size", 12px);
    clear: left;
  }
}

.node-type-research {
  // .field-research-year {
  // 	font-size:18px;
  // }
  .field-research-author,
  .field-research-year,
  .field-research-organization {
    margin-bottom: 0;
    margin-top: $vert-gutter/4;
  }
}

.field-article-references,
.field-article-citations,
.field-article-endnotes,
.field-article-reprints,
.field-research-citation,
.field-blog-attachments,
.comments,
.node-article #author-articles {
  clear: both;
  margin-bottom: 1em;
  margin-top: 2em;

  .field-label,
  h2,
  h3 {
    @include h3;
    // color:$header-blue;
    // font-size:16px;
    font-weight: 300;
    border-top: 1px solid $rule-color;
    padding-top: 10px;
    margin-bottom: 1em;
    font-family: $myriad;
  }
}

.field-research-citation {
  .field-label,
  h2,
  h3 {
    border-top: none;
  }
}

.node-type-news {
  .field-news-link {
    .field-label {
      display: inline-block;
      float: left;
      font-weight: normal;
      padding-right: 4px;
      margin-top: 2px;
      font-size: 15px;
      color: $body-font-color;
    }
  }

  .submitted {
    color: #aaa;
    margin-bottom: $horiz-gutter;
  }
}

/* Book Pages */

.node-type-cc-book {
  .field-book-image {
    width: 130px;
    float: left;
    padding-right: $horiz-gutter;
    margin-right: $horiz-gutter;
  }

  .textformatter-list {
    display: inline;
  }
}

.node-type-cc-book {
  .group-page-intro-bottom {
    margin-top: 18px;
  }

  .field-title-field {
    margin-bottom: 0.5em;

    h1 {
      margin-bottom: 0;
    }
  }

  .field-book-author,
  .field-book-illustrator,
  .field-book-editor,
  .field-book-contributor {
    margin-bottom: 9px;
    font-size: 18px;

    a {
      font-weight: normal !important;
    }

    &.clearfix {
      &::after {
        clear: none;
      }
    }

    .field-label {
      display: inline;
      font-weight: normal;
      margin-right: 5px;
    }

    .field-items,
    .field-item,
    .item {
      // display: inline-block;
      margin-right: 5px;
    }
  }
}

#books-topics .listing-items {
  @include breakpoint($tablet-portrait-min) {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
    -webkit-column-gap: $horiz-gutter; /* Chrome, Safari, Opera */
    -moz-column-gap: $horiz-gutter; /* Firefox */
    column-gap: $horiz-gutter;
    // padding-top:$horiz-gutter;
    .book-topic {
      -webkit-column-break-inside: avoid; /* Chrome, Safari */
      page-break-inside: avoid; /* Theoretically FF 20+ */
      break-inside: avoid-column; /* IE 11 */
      display: table; /* Actually FF 20+ */
    }

    .listing-items {
      -webkit-column-count: 1; /* Chrome, Safari, Opera */
      -moz-column-count: 1; /* Firefox */
      column-count: 1;
    }
  }

  .book-topic {
    h3 {
      margin-bottom: 10px;
    }
  }
  // @include flower-rule(top, #fff);
  // .listing-items {
  // 	@include breakpoint($tablet-portrait-min) {
  // 		@include clearfix;
  // 		.book-topic {
  // 			width:50%;
  // 			float:left;
  // 		}
  // 	}
  // }
}

/* Author Pages */

.node-video-interview {
  .field-interview-title {
    font-size: 18px;
    color: $header-blue;
    margin-right: 4px;
  }

  &.view-mode-full {
    .author-link {
      a {
        @include submit-btn;
        width: auto;
      }
    }

    .node-playlist header,
    .embedded-video {
      max-width: 500px;
      margin: 0 auto;
    }
  }
}

.node-type-author {
  .field-title-field {
    margin-bottom: 0.5em;

    h1 {
      margin: 0;
    }
  }

  .field-author-photo {
    width: 25%;
    float: left;
    padding-right: $horiz-gutter;
    margin-right: $horiz-gutter;
    margin-bottom: 20px;
  }

  #author-articles,
  #author-books,
  .group-author-interview,
  #author-books-illustrator {
    clear: both;
    @include flower-rule(top, #fff);
    margin-bottom: 2em;

    h2 {
      margin-top: 0;
    }
  }

  .group-author-interview {
    .node-video-interview {
      header {
        display: none;
      }
    }

    .node-playlist {
      header {
        display: block;
      }
    }
  }
}

/* Glossary */

.view-filters {
  margin-bottom: $horiz-gutter * 2;
}

.views-exposed-widgets {
  position: relative;

  .views-exposed-widget {
    margin-top: 10px;

    @include breakpoint($tablet-portrait-min) {
      width: 30%;
      padding-right: 0;
      padding-left: $horiz-gutter;
      overflow: hidden;
    }
    @include breakpoint($tablet-portrait-max) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
    }

    &:first-child {
      padding-left: 0;
    }

    input {
      width: 100%;
    }

    select {
      max-width: none;
      width: 100%;
      width: calc(100% + 16px);
    }
  }

  .views-submit-button {
    input {
      background-color: $primary-button-color;
      margin-top: 25px !important;
    }
    @include breakpoint($tablet-portrait-min) {
      width: 15%;
      min-width: 0;
    }
  }

  .views-reset-button {
    input {
      color: $primary-button-color;
      font-weight: 700;
      border: none;
      margin-top: 25px !important;
      width: auto !important;
      @include transition(all 0.3s ease);

      &:hover,
      &:focus {
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }

  #edit-title-field-value-wrapper {
    @include breakpoint($tablet-portrait-max) {
      width: 100%;
    }
  }
  // .views-submit-button {
  // 	width:40px;
  // 	height:40px;
  // 	.form-submit {
  // 		margin-top:0;
  // 	}
  // 	@include breakpoint($tablet-portrait-min) {
  //     	position:absolute;
  // 		top:25px;
  // 		right:-6px;
  //     }
  //     @include breakpoint($tablet-portrait-max) {
  //     	position:absolute;
  // 		top:24px;
  // 		right:7px;
  //     }
  // }
  input[type="text"] {
    text-align: left;
    @include breakpoint($tablet-portrait-min) {
      width: 263px;
      padding: 10px 50px 10px 20px;
    }
    @include breakpoint($tablet-portrait-max) {
      width: 100%;
    }
  }
  //  	input[type="submit"] {
  //     background:url('../images/search.png') #fff no-repeat;
  //     background-position:center;
  //     background-size:24px auto;
  //     width:40px;
  //     box-shadow:none;
  //     border:none;
  //     text-indent:-9999px;
  //     margin-top:0;
  // }
}

/* Book Lists */

.field-book-author,
.field-book-illustrator,
.field-book-editor,
.field-book-contributor {
  .item + .item {
    &::before {
      content: ", ";
      position: relative;
      left: -4px;
    }
  }
}

.view-mode-full {
  .field-book-author,
  .field-book-illustrator,
  .field-book-editor,
  .field-book-contributor {
    color: $header-blue;
  }
}

.section-books-authors {
  .field-basic-body {
    border-top: none !important;
    padding-top: 0 !important;

    .bookfinder-intro {
      img {
        width: 15% !important;
        margin-right: $horiz-gutter;
        margin-bottom: $horiz-gutter;
      }

      .bookfinder-buttons {
        float: right;
        width: 25%;
        padding-left: $horiz-gutter;
        margin-left: $horiz-gutter;
        border-left: 1px solid $rule-color;

        p {
          font-weight: bold;
          margin-bottom: $horiz-gutter;
        }

        a {
          list-style-type: none;
          padding: $horiz-gutter;
          border-radius: 8px;
          position: relative;
          display: block;
          color: #fff;
          border-bottom: none;
          font-weight: 700 !important;
          font-size: 0.95rem;
          width: 100%;
          margin-bottom: $horiz-gutter;

          &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
          }

          &:hover,
          &:focus {
            transition: 0.2s all ease-in-out;
            box-shadow: rgb(187, 187, 187) 0px 0px 8px;
            border-radius: 8px;
          }

          &:focus {
            outline-color: $rule-color;
          }

          @include breakpoint($tablet-portrait-min) {
            span {
              display: block;
              width: 85%;
            }
          }
        }

        a:nth-child(2) {
          background-color: #feb846;
          color: #000;
          position: relative;

          &::before {
            width: 20%;
            height: 65%;
            background-image: url("../images/rocket.svg");
            background-repeat: no-repeat;
            background-position: top left;
            background-size: cover;
          }
        }

        a:last-child {
          background-color: #077E9C;

          &::before {
            width: 40%;
            height: 100%;
            background-image: url("../images/stars-white.svg");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: cover;
          }
        }
      }
    }
  }

  .book-cover-description {
    .field-book-image {
      width: 100%;
      padding: 0;
    }

    .book-info-button {
      overflow: hidden;
    }
  }
}

.view-bookfinder {
  .views-row {
    .node-cc-book {
      padding: 0;
    }
  }

  .views-row:first-child,
  .view-filters + .views-row {
    border-top: none;
  }

  .views-widget-filter-field_book_audiobook_value {
    width: auto !important;
    height: 25px !important;
    min-height: 0 !important;

    @include breakpoint($tablet-portrait-min) {
      padding-top: 45px;
      min-height: 25px !important;
      height: auto !important;
    }

    .form-checkboxes {
      input {
        width: auto !important;
      }

      label {
        display: inline !important;
      }
    }

    input[type="checkbox"] {
      &:checked {
        + label {
          font-weight: bold;
        }
      }
    }
  }

  .views-exposed-widgets {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;

    .views-exposed-widget {
      padding-left: 0px;
      @include breakpoint($tablet-portrait-min) {
        width: 32%;
      }
      @include breakpoint($tablet-landscape-min) {
        width: 24%;
        padding-right: 0;
        overflow: hidden;
      }
      @include breakpoint($tablet-portrait-max) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
      }

      &:first-child {
        padding-left: 0;
      }

      input {
        width: 100%;
      }

      select {
        max-width: none;
        width: 100%;
      }
    }

    .views-submit-button {
      input {
        background-color: $primary-button-color;
        margin-top: 25px !important;
        @include transition;

        &:hover,
        &:focus {
          background-color: darken($primary-button-color, 10%);
        }
      }
      @include breakpoint($tablet-portrait-min) {
        width: 14%;
        min-width: 0;
      }
    }

    #edit-title-field-value-wrapper {
      @include breakpoint($tablet-portrait-max) {
        width: 100%;
      }
    }

    input[type="text"] {
      text-align: left;
      @include breakpoint($tablet-portrait-min) {
        width: 263px;
        padding: 10px 50px 10px 20px;
      }
      @include breakpoint($tablet-portrait-max) {
        width: 100%;
      }
    }
  }
}

.view-bookfinder {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;

  &::after {
    display: none;
  }

  header {
    display: none;
  }

  .item-list,
  .view-filters {
    width: 100%;
  }

  input {
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    background-color: #fff;

    &:hover,
    &:focus {
      border-color: $primary-color;
    }
  }

  .views-exposed-widget {
    overflow: visible !important;

    .option {
      float: none;
    }

    label {
      float: left;
      font-size: 13px;
    }

    .description {
      clear: both;
      border: 1px solid $primary-color;
      background-color: #fff;
      font-size: 13px;
      color: #555;
      position: absolute;
      width: 80%;
      left: 0;
      padding: 10px;
      right: 0;
      top: 32px;
      z-index: 9999;
      display: none;

      @include breakpoint($tablet-portrait-min) {
        left: 20%;
        right: 0;
      }

      &.expanded {
        display: block;
      }
    }

    .description-info {
      background: transparent;
      border: 1px solid transparent;
      -webkit-appearance: none;
      appearance: none;
      height: 28px;
      width: auto;
      float: left;

      & + .description-info {
        display: none;
      }

      &::before {
        color: $primary-color;
        content: "\f05a";
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        font-size: 16px;
      }

      span {
        @include element-invisible;
      }

      &:hover,
      &:focus {
        outline: none;
        border-color: $primary-color;

        &::before {
          color: $primary-color;
        }
      }
    }
  }

  .item-list {
    .pager {
      border: none;

      li {
        margin-right: 10px;

        a {
          padding: 12px 18px !important;
          color: black;
          background-color: #eee;
          text-align: center;
          border-radius: 4px;
          min-width: 40px;
        }

        &:hover,
        &:focus {
          @include transition;

          a {
            color: white;
            background-color: $primary-button-color;
          }
        }
      }

      .pager-ellipsis {
        background-color: transparent;
        padding: 0 !important;

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }

      .pager-current {
        color: #fff;
        background-color: $primary-color;
        font-weight: bold;
        padding: 12px 18px !important;
        border-radius: 4px;
      }
    }
  }

  .node-cc-book {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 4px;
    box-shadow: $box-shadow;
  }

  .book-details {
    padding: $horiz-gutter/4 $horiz-gutter/6;
    background: white;
    border-radius: 0 0 4px 4px;

    @include breakpoint($tablet-small-min) {
      padding: $vert-gutter/2;
    }

    * {
      font-size: 0.9rem;
    }

    h2 {
      margin: 0 0 4px;
      font-weight: 500;

      a,
      .field-bookfinder-title div {
        text-decoration: none;
        font-size: 1.1rem;
        font-weight: 700;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .field {
      margin-bottom: 0;
    }

    .field-label {
      font-weight: normal;
    }

    .field > * {
      display: inline;
      margin-right: 5px;
    }
  }

  .book-cover-description {
    border-bottom: $thick-border;
    position: relative;
    background-image: url("../images/default-red.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border-radius: 4px 4px 0 0;
    min-height: 160px;
    width: 100%;
    height: 100%;
  }

  .field-book-image {
    margin: 0;
  }

  .book-description-overlay {
    background: rgba(0, 83, 155, 0.95);
    // color: $body-font-color;
    padding: $vert-gutter/2;
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 4px 4px 0 0;
    max-height: 50em;
    @include transition;

    &[aria-hidden="true"] {
      overflow: hidden;
      max-height: 0;
      top: 100%;
      padding: 0;
    }

    * {
      font-size: 1rem;
    }

    a.tertiary {
      margin-top: $vert-gutter/4;
      margin-bottom: $vert-gutter/8;
      appearance: none;
      border: none;
      color: #fff;

      &:hover,
      &:focus {
        border-bottom: 1px dotted #fff;
      }

      i {
        margin-left: 5px;
      }
    }
  }

  .book-info-button {
    color: #fff;
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    margin: auto;
    appearance: none;
    display: inline-block;
    background: $primary-button-color;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 0;
    border: 1px solid $primary-button-color;
    border-radius: 18px;
    // min-width: 32px;
    width: 32px;
    // max-width: 32px;
    z-index: 3;
    white-space: nowrap;
    transition: all 0.5s ease;
    overflow: hidden;

    span {
      display: inline-block;
      line-height: 0;
      text-align: left;
    }

    i {
      font-size: 32px;
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
      margin-top: -1px;
      margin-right: -1px;
      margin-bottom: -1px;
      margin-left: -1px;
      transform: scale(1);
      position: relative;
      bottom: -2px;
    }

    &:hover {
      border-color: $black;
      max-width: 118px;
      width: 100%;
      padding: 0 8px 0 2px;

      i {
        transform: scale(0.5);
        margin-right: -6px;
        bottom: -1px;
      }

      span {
        line-height: 1;
        max-width: 200px;
        vertical-align: middle;
        padding-left: 2px;
      }
    }

    &:focus {
      border-color: $black;
      padding: 0;
      // min-width: 32px;
      border-radius: 18px;
      text-align: center;
      outline: 2px dotted $link-color;
      outline-offset: 2px;

      i {
        transform: scale(0.5);
        display: block;
        text-align: center;
        bottom: -1px;
      }

      span {
        max-width: 0;
        overflow: hidden;
        text-indent: -99px;
        position: absolute;
      }

      &:hover {
        max-width: 118px;
        width: 100%;
        padding: 0 8px 0 0;
        border-color: $black;
        // min-width: auto;
        // width: auto;
        display: inline-block;

        i {
          display: inline-block;
        }

        span {
          max-width: 200px;
          vertical-align: middle;
          padding-left: 4px;
          opacity: 1;
          text-indent: 0;
          position: relative;
        }
      }
    }
  }
}

.bookshelf-book {
  flex-direction: column;
  padding-left: $horiz-gutter/4;
  padding-right: $horiz-gutter/4;
  padding-bottom: $vert-gutter/2;

  .book-cover-description {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex-grow: 0;

    .field-book-cover:not(.no-cover) {
      align-self: flex-end;
    }

    img {
      width: 100%;
      border-radius: 4px 4px 0 0;
    }
  }

  .book-details {
    flex-grow: 2;
  }

  @include breakpoint($phone-small-min) {
    float: left;
    padding-left: $horiz-gutter/2;
    padding-right: $horiz-gutter/2;
    display: flex;

    & > * {
      width: 100%;

      &.views-field > .field-content,
      &.views-field > .field-content > .card {
        height: 100%;
      }
    }
  }
}

@include breakpoint($tablet-small-min) {
  .bookshelf-book {
    padding-bottom: $vert-gutter;
  }
}

@include breakpoint($tablet-portrait-min) {
  .bookshelf-book {
    padding-bottom: $vert-gutter;
  }
}

@include breakpoint($tablet-landscape-min) {
  .bookshelf-book {
    width: 33.33%;

    &:nth-child(3n + 1) {
      clear: both;
    }
  }
}

@include breakpoint($tablet-portrait-min $tablet-landscape-max) {
  .bookshelf-book {
    width: 50%;

    &:nth-child(2n + 1) {
      clear: both;
    }
  }
}

@include breakpoint($tablet-landscape-min) {
  .bookshelf-book {
    width: 25%;

    &:nth-child(4n + 1) {
      clear: both;
    }
  }
}

@include breakpoint($tablet-small-min $tablet-portrait-max) {
  .bookshelf-book {
    width: 33.3333%;

    &:nth-child(even) {
      padding-left: $vert-gutter/2;
    }

    &:nth-child(odd) {
      padding-right: $vert-gutter/2;
    }

    &:nth-child(3n + 1) {
      clear: both;
    }
  }
}

@include breakpoint($phone-small-min $tablet-small-max) {
  .bookshelf-book {
    width: 50%;

    &:nth-child(even) {
      padding-left: $vert-gutter/4;
    }

    &:nth-child(odd) {
      padding-right: $vert-gutter/4;
    }

    &:nth-child(odd) {
      clear: both;
    }
  }
}

@include breakpoint($phone-small-max) {
  .bookshelf-book {
    width: 100%;
  }
}

.node-cc-book.node-teaser,
.node-cc-book.view-mode-longteaser {
  //  padding:3em 0;
  //  border-top:1px solid $light-rule-color;
  //  &:first-child {
  //    padding-top:0;
  //    border-top:none;
  //  }
  // h3 {
  // 	margin-top:0;
  // }
  .field-book-image {
    float: left;
    @include breakpoint($tablet-portrait-min) {
      width: 18%;
      padding-right: $horiz-gutter;
    }
    @include breakpoint($tablet-portrait-max) {
      width: 25%;
      padding-right: $horiz-gutter/2;
    }
  }

  .group-book-description-tags {
    float: left;
    @include breakpoint($tablet-portrait-min) {
      width: 82%;
      padding-left: $horiz-gutter;
    }
    @include breakpoint($tablet-portrait-max) {
      width: 75%;
      padding-left: $horiz-gutter/2;
    }
  }

  .group-book-contributors {
    .field-book-illustrator,
    .field-book-author,
    .field-book-editor,
    .field-book-contributor {
      color: $body-font-color;
    }

    .field {
      margin-bottom: 0;

      .field-label {
        font-weight: normal;
      }
    }
    margin-bottom: $horiz-gutter/2;
    // .field-group-div {
    // 	margin-bottom:$horiz-gutter/2;
    // }
  }
}

/* Blog Posts */

.node-type-blog-post {
  .submitted {
    color: #aaa;
  }

  h1 {
    margin-top: 0;
  }

  .group-blog-body {
    clear: both;

    .field-blog-image {
      float: left;
      width: 25%;
      margin-right: $horiz-gutter;
      margin-bottom: $horiz-gutter;
    }
  }

  .group-blog-intro {
    .field-blog-image {
      width: 25%;
      float: left;
      margin-right: $horiz-gutter;
      margin-bottom: $horiz-gutter;
    }
  }

  .alignleft {
    float: left;
    margin-right: $horiz-gutter !important;
    margin-bottom: 12px !important;
    margin-left: 0 !important;
  }

  .alignright {
    float: right;
    margin-left: $horiz-gutter !important;
    margin-bottom: 12px !important;
    margin-right: 0 !important;
  }

  img {
    max-width: 100%;
    height: auto !important;
  }
}

.not-front {
  .node-blog-post.node-teaser {
    margin-bottom: $horiz-gutter * 2;
    @include breakpoint($tablet-portrait-min) {
      .field-blog-image {
        width: 25%;
        float: left;
        padding-right: $horiz-gutter;
      }

      .field-body,
      .field-title-field {
        width: 75%;
        float: right;
        padding-left: $horiz-gutter;
      }
    }

    .field-title-field {
      margin-bottom: 0;

      h3 {
        margin-top: 0;
        margin-bottom: $horiz-gutter;
      }
    }
  }
}

#home-blog .node-blog-post.node-teaser {
  .field-blog-image {
    display: none;
  }
}

/* Newsletters */

.node-type-newsletter {
  .field-newsletter-body {
    h2 {
      border-top: 1px solid $rule-color;
      margin-top: $horiz-gutter * 3;
      padding-top: $horiz-gutter;
    }
  }
}

/* Books and Authors */

.node-type-landing.section-books-authors,
.node-type-landing.section-libros-autores {
  #books-classroom {
    @include flower-rule(top, #fff);

    h2 {
      margin-top: 0;
    }

    .node-l4-page {
      .node__title {
        display: none;
      }
    }

    .node-landing {
      .field-title-field {
        display: none;
      }
    }
  }

  .node-landing.view-mode-longteaser,
  .node-landing.node-teaser,
  .node-l4-page.node-teaser {
    @include clearfix;
    margin-bottom: $horiz-gutter;

    .field-landing-image,
    .field-l4-image {
      @include breakpoint($tablet-portrait-min) {
        width: 25%;
        float: left;
        padding-right: $horiz-gutter;
        margin-right: $horiz-gutter;
      }
    }

    h3 {
      margin-top: 0;
    }
  }

  #books-topics {
    @include flower-rule(top, #fff);

    h2 {
      margin-top: 0;
    }
  }

  #books-themes {
    .listing-items {
      @include clearfix;
      margin-left: -$horiz-gutter;
      margin-right: -$horiz-gutter;

      h3 {
        font-size: 1.2em;
      }
    }
    // .field-theme-image {
    // 	img {
    // 		max-width:115px;
    // 		height:auto;
    // 	}
    // }
    .node-book-theme {
      padding: $horiz-gutter/2 $horiz-gutter;

      header {
        display: none;
      }
      @include breakpoint($tablet-portrait-min) {
        width: 25%;
        float: left;
      }
      @include breakpoint($tablet-portrait-max) {
        width: 50%;
        float: left;
      }
    }

    .node-landing {
      .field-title-field {
        display: none;
      }
    }
  }
}

/* Video Landing Page */

.featured-video-container,
.embedded-video {
  max-width: 715px;
  margin: 0 auto;
}

.node-type-landing.section-videos,
.node-type-landing.section-video {
  #videos-videos,
  #videos-subpages {
    margin-bottom: $horiz-gutter * 2;

    h2 {
      margin-top: 0;
    }
  }

  .featured {
    margin-top: 2em;
    @include flower-rule(top, #fff);

    h2 {
      margin-top: 0;
    }
  }
  @include breakpoint($tablet-portrait-min) {
    #videos-subpages > div,
    .listing:not(.featured) .listing-items {
      @include clearfix;
      margin-left: -$horiz-gutter;
      margin-right: -$horiz-gutter;
    }

    #landing-webcasts-featured {
      header {
        display: none;
      }
    }

    #landing-webcasts-featured,
    #landing-webcasts-browse {
      .listing-items {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .listing.featured {
      .listing-items {
        header {
          display: none;
        }
      }
    }
  }

  .view-mode-videomodule {
    .field-title-field,
    .video-title,
    .node-playlist .node__title {
      display: none;
    }
  }
  // .node-landing.view-mode-longteaser,
  // .node-classroom-video.videomodule {
  // // .view-mode-videomodule
  // 	margin-bottom:$horiz-gutter;
  // 	.field-landing-image,
  // 	.field-classroom-video,
  // 	.field-playlist-youtube-url {
  // 		margin-bottom:0;
  // 	}
  // 	.landing-intro,
  // 	.group-module-info {
  // 		background-color:$related-feature-bgrd;
  // 		padding:$horiz-gutter;
  // 		color:#fff;
  // 		h3 {
  // 			margin-top:0;
  // 		}
  // 		a {
  // 			color:#fff !important;
  // 		}
  // 		img {
  // 			display:none;
  // 		}
  // 	}
  // 	.landing-intro {
  // 		.field-title-field {
  // 			display:none;
  // 		}
  // 	}
  // 	.node-playlist {
  // 		header {
  // 			display:none;
  // 		}
  // 	}
  // }
}

#landing-webcasts-featured {
  border-top: none;
}

#videos-subpages {
  @include flower-rule(bottom, #fff);

  > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/* Glossary */

#glossary-entries {
  section {
    @include flower-rule(top, #fff);

    h2 {
      margin-top: 0;
    }

    .node__title {
      margin-bottom: $horiz-gutter/3;
    }
  }
}

#glossary-index {
  text-align: center;
  color: #aaa;

  span {
    display: inline-block;
    padding: 0 $horiz-gutter/4;
  }
}

/* ELL News */
.node-type-landing.section-ell-news-headlines {
  .node-news {
    margin-bottom: $horiz-gutter * 2;

    .node__title {
      margin-top: 0;
      margin-bottom: $horiz-gutter/3;
    }

    .field-news-summary {
      margin-bottom: 0;
      margin-top: $horiz-gutter/2;
    }

    .field-news-link {
      margin-bottom: 0;
      font-style: italic;

      a {
        color: #aaa;
      }
    }

    .submitted {
      color: #aaa;
      margin-bottom: $horiz-gutter/2;
    }
  }
}

/* Books for Kids, YA */
.node-type-landing {
  .node-35545,
  .node-35544 {
    .group-content {
      width: 100% !important;
      padding-right: 0;
      border: none;
    }
  }
}

/* Topics A-Z */
.node-type-landing.section-topics,
.node-type-landing.section-research-reports {
  // .listing-items > .node-title-list,
  .listing > .listing-items {
    @include breakpoint($tablet-portrait-min) {
      -webkit-column-count: 2; /* Chrome, Safari, Opera */
      -moz-column-count: 2; /* Firefox */
      column-count: 2;
      -webkit-column-gap: $horiz-gutter; /* Chrome, Safari, Opera */
      -moz-column-gap: $horiz-gutter; /* Firefox */
      column-gap: $horiz-gutter;
      padding-top: $horiz-gutter;
    }

    div,
    li {
      margin-bottom: $horiz-gutter/2;
      margin-left: $horiz-gutter;
      padding-left: 0 !important;
      text-indent: 0 !important;

      &::before {
        color: $body-font-color !important;
        content: "•";
        padding-right: $horiz-gutter/2 !important;
        margin-left: 0 !important;
      }
    }

    li {
      margin-bottom: $horiz-gutter/2;
    }

    a {
      font-weight: normal;
    }
  }

  .node-topic {
    h3 {
      margin-top: 0;
    }
  }
}

/* Content Finder */

.view-mode-search_result {
  padding-left: $horiz-gutter * 1.5;
  margin-bottom: $horiz-gutter;

  h3 {
    margin-top: 0;
    margin-bottom: $horiz-gutter/2;
  }
}

.node-playlist.view-mode-search_result {
  background: url("../images/video-icon.png") no-repeat top 10px left;
  background-size: 14px auto;
}

.author-interview-link {
  background: url("../images/video-icon.png") no-repeat top 7px left;
  background-size: 14px auto;
  padding-left: 20px;
  margin-bottom: $horiz-gutter;
}

/* FAQ */

#faq-index,
.faq-landing ul {
  list-style-type: disc !important;
  padding-left: 13px !important;

  li {
    text-indent: 0 !important;
    padding-left: 0 !important;
    margin-bottom: $horiz-gutter/3;

    &::before {
      display: none;
    }
  }
}

.faq-landing {
  @include breakpoint($tablet-portrait-min) {
    width: 75%;
    float: left;
  }
}

#faq-entries {
  > div {
    border-top: 1px solid $rule-color;
    padding: 3em 0;

    ul.links,
    ul {
      list-style-type: disc;
      padding-left: $horiz-gutter * 2;
      margin-top: $horiz-gutter/2;
      margin-bottom: $horiz-gutter;
    }

    header {
      display: none;
    }

    .field-faq-question h3 {
      margin-top: 0;
    }

    .field-label {
      @include h3;
      font-weight: 300;
    }
  }
}

.group-page-intro-inner {
  clear: both;

  .field-title-field {
    h1 {
      margin-top: 0;
    }
  }

  .funding-credit {
    .partner-aft {
      margin-top: 0 !important;
      background-color: #fff;
    }

    .partner-nea {
      background-color: #fff;
      margin: 0;
    }
  }
  @include breakpoint($tablet-portrait-min) {
    .funding-credit {
      width: 25%;
      float: right;
      padding-left: $horiz-gutter;
      margin-left: $horiz-gutter;
      margin-bottom: 20px;
    }

    .field-landing-image,
    .field-page-image,
    .field-interview-image {
      width: 33%;
      float: left;
      padding-right: $horiz-gutter;
      margin-right: $horiz-gutter;
      margin-bottom: 20px;
    }

    .field-theme-image {
      width: 130px;
      float: left;
      padding-right: $horiz-gutter;
      margin-right: $horiz-gutter;
      margin-bottom: 20px;
    }
  }

  .bookfinder {
    margin-top: $horiz-gutter * 1.5;

    a {
      @include submit-btn;
      background-color: $related-feature-bgrd;
      color: #fff;

      &:hover {
        background-color: darken($related-feature-bgrd, 5%);
      }

      &:hover,
      &:visited,
      &:active {
        color: #fff;
      }
    }
  }
}

.field-guide-documents {
  img {
    display: none;
  }

  a {
    @include submit-btn;
    background-color: $see-more-btn;

    &:hover {
      background-color: darken($see-more-btn, 5%);
      color: #fff;
    }

    &:visited {
      color: #fff;
    }
  }
}

.view-mode-videomodule {
  a {
    border-bottom: none;
  }

  .field-page-image {
    width: 100%;
    margin: 0;
    padding: 0;
    float: none;
  }

  .field-interview-image {
    margin-bottom: 0;
  }
}

article {
  ul.links:first-child {
    display: block;

    li {
      display: inline-block;
    }
    // .language-link {
    // 	margin-left:-16px;
    // }
    li.sharethis {
      @include breakpoint($tablet-portrait-min) {
        float: right;
        position: relative;
        top: 10px;
      }
      @include breakpoint($tablet-portrait-max) {
        position: relative;
        top: -5px;
        margin-bottom: $horiz-gutter;
      }

      padding-right: 0;
      margin-bottom: $horiz-gutter * 2;

      .stButton .stLarge {
        width: 29px;
        height: 29px;
        background-size: 100%;

        &:hover {
          background-position: -29px;
        }
      }
    }

    li.comment-add {
      @include breakpoint($tablet-portrait-min) {
        float: right;
        margin-left: $horiz-gutter !important;
      }
      @include breakpoint($tablet-portrait-max) {
        margin-right: $horiz-gutter/3;
      }
      position: relative;
      top: 10px;
      width: 24px;
      height: 24px;
      overflow: hidden;
      display: inline-block !important;
      white-space: nowrap;
      padding: 0;

      a {
        font-size: 1.6em;

        &::before {
          content: "\f086";
          display: inline-block;
          font-family: "Font Awesome 5 Pro";
          font-weight: 100;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          transform: translate(0, 0);
          margin-right: 15px;
          margin-left: 1px;
        }
      }
    }

    li.last {
      @include breakpoint($tablet-portrait-max) {
        width: 100%;
        clear: both;
      }
    }
  }
}

/* Book Theme */

.node-type-book-theme {
  @include breakpoint($tablet-portrait-max) {
    .field-theme-image {
      width: 25%;
      float: left;
      padding-right: $horiz-gutter/2;
      margin-right: $horiz-gutter/2;
    }
  }
}

.page-node-35543,
.page-node-35548 {
  @include breakpoint($tablet-portrait-min) {
    .funding-credit {
      margin-left: 0 !important;
    }

    .group-special-landing-intro {
      width: 75%;
      float: left;
      padding-right: $horiz-gutter;
    }
  }
}

/* Podcasts */

.jp-audio {
  max-width: 420px;
  width: 100% !important;
  margin: 0 auto;

  .jp-controls,
  .jp-playlist {
    li {
      text-indent: 0 !important;
      padding-left: 0 !important;
      list-style-type: none !important;
      margin-bottom: 0 !important;

      &::before {
        display: none !important;
      }

      a {
        border-bottom: none;
      }
    }
  }

  .jp-playlist {
    display: none;
    // text-align:center;
    // a {
    // 	color:$link-color !important;
    // 	border-bottom:1px dotted $link-color !important;
    // 	&:hover {
    // 		color:$link-color-hover !important;
    // 	}
    // }
  }
}

/* Search Results */

.view-search-page {
  .view-mode-search_result {
    margin-bottom: $horiz-gutter;
    @include breakpoint($tablet-portrait-min) {
      div[class*="image"] {
        width: 115px;
        float: left;
        padding-right: $horiz-gutter;
        margin-right: $horiz-gutter;
      }
    }

    div[class*="body"],
    div[class*="intro"],
    div[class*="description"] {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      img {
        display: none;
      }
    }

    .field-label {
      font-weight: normal;
    }

    .textformatter-list {
      display: inline-block;
    }

    footer {
      display: none;
    }
  }
}

.node-type-basic-page {
  .node-basic-page.full-width {
    .group-content {
      float: none;
      clear: both;
      padding-right: 0;
      border-right: 0;
      width: 100%;
    }
  }
}

.node-type-article {
  .node-article.full-width {
    .group-article-main {
      float: none;
      clear: both;
      padding-right: 0;
      border-right: 0;
      width: 100%;
    }

    .sidebar:not(.full-width-callout) {
      display: none;
    }
  }

  .node-article.hide-comments {
    section.comments {
      display: none;
    }
  }
}

.st_email_large,
.st_facebook_large,
.st_twitter_large,
.st_linkedin_large,
.st_sharethis_large,
.st_pinterest_large {
  display: inline-block;
}

.node-playlist {
  &.view-mode-full {
    .addtoany_list {
      display: none;
    }
  }

  .transcript-accordion {
    margin-top: $vert-gutter * 2;

    .accordion-title {
      font-size: $h3-font-size;
      cursor: pointer;
      color: #ffffff;

      &:focus {
        text-decoration: underline;
      }
    }

    .accordion-body {
      display: none;
      padding: 12px;
    }
  }
}

.addtoany_list {
  display: flex;

  a {
    border-bottom: none;

    &.a2a_button_copy_link {
      span {
        background-color: #258082 !important;
      }
    }

    &.a2a_button_email {
      span {
        background-color: $nav-quick-links !important;
      }
    }
  }
}

// Video gallery content type styles.
.node-video-gallery {
  .czi-language-selection {
    background: transparent;

    a {
      &.button {
        margin: 5px 10px;
      }
    }
  }

  span {
    &.addtoany_list {
      display: none;
    }
  }

  .links {
    &.inline {
      text-align: right;

      @include breakpoint($tablet-portrait-max) {
        text-align: left;
      }

      .addtoany_list {
        display: inline-block;
      }
    }
  }
}

.field-gallery-videos,
  .field-gallery-videos-spanish,
  .field-gallery-videos-mam {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    & > .item {
      flex: 0 1 33%;

      @include breakpoint($desktop-max) {
        flex: 0 1 50%
      }

      @include breakpoint($tablet-portrait-max) {
        flex: 0 1 100%
      }
    }

    .view-mode-czi_video {
      position: relative;

      .field-video-header {
        padding-left: 12px;
        margin-bottom: 8px;
      }

      .landing-intro-container {
        .field-title-field {
          display: none;
        }
      }

      .field-playlist-youtube-url {
        position: relative;

        &::before {
          content: "\f144";
          font-family: fontawesome;
          color: #ffffff;
          position: absolute;
          top: 18px;
          left: 18px;
          font-size: 40px;
          height: 40px;
          line-height: 40px;
          z-index: 999;
          pointer-events: none;
        }
      }

      .node-title {
        display: none;
      }
    }

    .addtoany_list {
      margin-bottom: $vert-gutter*1.5;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .sharethis-video-label {
        flex: 0 1 100%;
        text-align: center;
        margin-bottom: 0;
        margin-top: 4px;
        font-size: 0.85rem;
        line-height: 1;
      }

      a {
        width: 44px;
        height: 44px;
        padding: 0;

        span {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

.group-czi-video {
  position: relative;
  width: 95%;
  margin: 0 auto;

  &:hover {
    .group-video-overlay {
      background: rgba(255, 255, 255, 0.6);
      color: #000000;
    }
  }

  .group-video-overlay {
    position: absolute;
    bottom: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    width: 100%;
    pointer-events: none;

    .field {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .field-playlist-youtube-url {
    margin-bottom: 6px;

    img {
      width: 100%;
    }
  }
}

.czi-yt-embed-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  .czi-yt-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.field-video-section-header {
  h2 {
    @include flower-rule(top, #fff);
  }
}

.group-page-intro-bottom + .paragraphs-items-field-video-sections > .field-video-sections > .item:first-child .field-video-section-header h2 {
  &::before,
  &::after {
    display: none;
  }
}

// Discussion guides
.node-type-discussion-guide {
  h1 {
    margin-bottom: 0px;
  };
}

.node-discussion-guide {
  .field-dg-discussion-questions,
  .field-dg-overview {
    .field-label {
      font-size: $h3-font-size;
      font-weight: normal;
    }
  }

  .field-dg-books {
    float: left;
    width: 25%;
    padding: 10px 10px 0px 0px;
    margin: 0px 10px 0px 0px;

    @include breakpoint($tablet-portrait-max) {
      float: none;
      width: 50%;
      margin: 0 auto;
    }

    article {
      &.node-cc-book {
        display: flex;
        flex-wrap: wrap;

        div {
          flex: 0 0 100%;
          margin-right: 0px;
          padding-right: 0px;
          width: auto;
          margin-bottom: 5px;
        }
      }
    }
  }
}

// A2A on videos
.node-playlist {
  &.view-mode-videomodule  {
    .addtoany_list {
      display: none;
    }
  }

  &.view-mode-czi_video {
    .addtoany_list {
      display: flex;
    }
  }
}

header {
  .views-exposed-form {
    .views-widget {
      position: relative !important;
      top: 0;
      z-index: 2;
    }
  }
}

// Classroom Strategies
.node-type-classroom-strategy {
  .group-classroom-strategy-intro {
    width: 100%;
    @include breakpoint($tablet-portrait-min) {
      width: 75%;
      float: left;
      padding-right: $horiz-gutter;
    }
  }

  .page__title {
    display: none;
  }

  .field-kicker {
    margin-bottom: $vert-gutter*0.125;
    color: $header-red;
    font-size: 1.3em;
  }

  .funding-credit {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 0 !important;
    @include breakpoint($tablet-portrait-min) {
      padding-left: $horiz-gutter !important;
      width: 25%;
      float: right;

      p {
        font-size: 10px;
      }
    }

    img#aft {
      max-width: 25%;
    }

    .partner-nea {
      display: none;
    }
  }

  .group-page-intro-bottom {
    margin-bottom: -15px;
  }

  table {
    border-color: transparent;
    width: 100% !important;

    th {
      visibility: visible !important;
    }
  }

  .field-resources,
  .field-references,
  .field-basic-attachments {
    h3 {
      @include h2;
      font-family: $lemonde;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.275;
      margin-bottom: 1.5rem;
      margin-top: 3rem;
    }

    ul {
      margin: 0 !important;
    }
  }

  .group-tags,
  .field-resources,
  .field-references,
  .field-basic-attachments {
    @include flower-rule(top, #fff);
    margin-bottom: 30px;

    &::before {
      content: "";
      margin-bottom: -15px;
    }
  }

  .field-categories,
  .field-additional-tags {
    .item {
      display: inline;

      &::before {
        content: ", ";
        margin-left: 0;
      }

      &:first-child {
        &::before {
          content: "";
          margin-left: -4px;
        }
      }

      &:nth-child(2) {
        &::before {
          content: "";
          margin-left: 0;
        }
      }
    }
  }

  .field-gallery-videos {
    display: flex;

    .item {
      flex: 0 1 100%;

      @include breakpoint($tablet-portrait-min) {
        flex: 0 1 50%;
      }
    }
  }
}

.view-classroom-strategies {
  .views-exposed-widgets {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;

    .views-exposed-widget {
      padding-left: 0px;
      min-height: 25px !important;

      @include breakpoint($tablet-portrait-min) {
        width: 32%;
      }

      @include breakpoint($tablet-landscape-min) {
        width: 24%;
        padding-right: 0;
        overflow: hidden;
      }

      @include breakpoint($tablet-portrait-max) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
      }

      &:first-child {
        padding-left: 0;
        width: 100%;
      }

      input {
        width: 100%;
      }

      label {
        font-size: 13px;
      }

      select {
        max-width: none;
        width: 100%;
      }
    }
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;

  @include breakpoint($tablet-portrait-min) {
    gap: 10px;
  }

  .item-list {
    width: 100%;
  }

  .view-header {
    display: flex;
    align-items: flex-start;
    gap: $vert-gutter;

    .intro,
    .funding-credit {
      width: 100%;
    }

    @include breakpoint($tablet-portrait-max) {
      flex-wrap: wrap;
    }

    @include breakpoint($tablet-landscape-min) {
      .intro {
        width: 75%;
      }

      .funding-credit {
        width: 25%;
      }
    }
  }

  .partner-aft {
    // border-radius:4px;
    margin-top: 0;
    margin-bottom: 12px;
    border: 1px solid #194997;

    p {
      background-color: #194997;
      color: #fff;
      padding: 10px;
      font-size: 11px;
      text-align: left;
      line-height: 1.4em;
      margin: 0;
    }

    a {
      color: #fff !important;
      border-bottom: none !important;
    }

    .funding-credit-link {
      text-align: center;
      margin: 0 auto;
      display: block;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 200px;
      padding: 12px 0;
    }
  }

  .views-row {
    width: 100%;
    height: 100%;

    @include breakpoint($tablet-portrait-min) {
      width: 32%;
    }
  }

  input {
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    background-color: #fff;

    &:hover,
    &:focus {
      border-color: $primary-color;
    }
  }
}

.classroom-strategy-card {
  border: 3px solid transparent;
  width: 100%;
  height: 100%;
  background-color: $card-bgrd-color;
  @include transition(all 0.3s ease);

  &:hover,
  &:focus {
    border: 3px solid $primary-color;
    box-shadow: 0 4px 8px rgba(0,0,0,0.25);
  }

  .field-article-kicker {
    margin-bottom: 10px;
    color: $header-red;
    font-size: 1rem;
  }

  a {
    color: #000;
  }

  .field-page-image {
    margin-bottom: 0;
  }

  .card-content {
    padding: $vert-gutter/2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint($tablet-portrait-min) {
      min-height: 100px;
    }

    img {
      width: 100%;
    }

    h3 {
      font-size: 18px;
      margin-top: 0;
    }

    .field-categories {
      border-top: 1px solid $med-gray;
      padding-top: $horiz-gutter/3;
      margin-top: auto;

      .item {
        display: inline;
        font-size: 12px;

        &::before {
          content: ", ";
          margin-left: 0;
        }

        &:first-child {
          &::before {
            content: "";
            margin-left: -4px;
          }
        }
      }
    }
  }
}

.node-type-classroom-strategy {
  .field-tags-for-strategies,
  .field_categories {
    .item {
      display: inline;

      & + .item {
        &::before {
          content: ", ";
          margin-left: 0;
        }
      }
    }
  }

  .funding-credit {
    .partner-aft,
    .partner-nea {
      width: 100%;
      max-width: 200px;
    }
  }

  .group-page-container {
    position: relative;
  }

  .views-classroom-strategy-button-block {
    margin-bottom: 0;
    margin-top: $vert-gutter;
    width: 100%;

    div {
      width: 100%;
    }

    @include breakpoint($tablet-portrait-min) {
      max-width: 25%;
      padding-left: 15px;
      float: right;
      margin-top: 0;
    }
  }

  .views-print-button-block {
    margin-top: $vert-gutter;
    width: 100%;
    position: relative;

    @include breakpoint($tablet-portrait-min) {
      max-width: 25%;
      padding-left: 15px;
      margin-top: $vert-gutter*0.5;
      float: right;
      clear: both;
    }
  }

  .print-button {
    a {
      background: $teal;
      border-radius: 8px;
      position: relative;
      width: 100%;
      display: block;
      color: #fff;
      border-bottom: none;
      font-weight: 700 !important;
      padding: 15px !important;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
      }

      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        background-image: url("../images/social/print-icon.png");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 10px;
      }
    }

    &::after {
      content: "";
      background-image: url("../images/teal-flower-rotated.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 25%;
      height: 100%;
      position: absolute;
      right: 4px;
      bottom: 0;
      border-radius: 0 0 8px 0;

      @include breakpoint($tablet-portrait-min) {
        width: 35%;
        height: 96%;
      }
    }

    &:hover,
    &:focus {
      transition: 0.2s all ease-in-out;
      box-shadow: rgb(187, 187, 187) 0px 0px 8px;
      border-radius: 8px;
    }

    @include breakpoint($tablet-portrait-min) {
      a {
        padding: $horiz-gutter;
      }
    }
  }

  .classroom-strategies-button {
    background: $purple;
    padding: $horiz-gutter*2 $horiz-gutter;
    border-radius: 8px;
    position: relative;
    width: 100%;

    a {
      display: block;
      color: #fff;
      border-bottom: none;
      font-weight: 700 !important;
      width: 70%;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
      }
    }

    &::after {
      content: "";
      background-image: url("../images/butterfly.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 25%;
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;

      @include breakpoint($tablet-portrait-min) {
        width: 35%;
        height: 100%;
      }
    }

    &:hover,
    &:focus {
      transition: 0.2s all ease-in-out;
      box-shadow: rgb(187, 187, 187) 0px 0px 8px;
    }

    @include breakpoint($tablet-portrait-min) {
      padding: $horiz-gutter;
    }
  }
}

.section-tags-strategies {
  .node-teaser {
    width: 100%;

    @include breakpoint($tablet-portrait-min) {
      width: 33%;
      float: left;
      padding: 10px $horiz-gutter*0.5;
    }
  }

  .item-list {
    width: 100%;
  }
}

.node-type-video-gallery {
  @include breakpoint($tablet-portrait-min) {
    .field-basic-thumbnail {
      width: 23%;
    }

    .internal-navigation {
      width: 25%;
      margin-left: $horiz-gutter;

      h3 {
        margin-top: 0;
      }
    }
  }
}

#interviews {
  .node-video-gallery {
    .field-basic-thumbnail {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    a {
      padding: $horiz-gutter*0.5 $horiz-gutter;

      .field {
        display: inline-block;
      }

      .field + .field {
        margin-left: 5px;
      }
    }
  }
}

.sidebar {
  &.full-width-callout {
    display: block;
    width: 100% !important;
    margin-left: 0;
    float: none;
    background: $callout-bgrd;
    border: 1px solid $rule-color;
    padding: $horiz-gutter;
    margin-bottom: $horiz-gutter;

    @include flower-rule-full;

    @include breakpoint($tablet-portrait-min) {
      margin-top: 30px;

      &::before {
        background-size: auto 30px;
        position: absolute;
        top: -30px;
        left: 0;
        height: 30px;
      }
    }
    @include breakpoint($tablet-portrait-max) {
      margin-top: 45px;

      &::before {
        background-size: auto 40px;
        position: absolute;
        top: -40px;
        left: 0;
        height: 40px;
      }
    }
  }
}
