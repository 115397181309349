/* @partial typography */

// Example font-face declaration
// @font-face {
//   font-family: 'Example';
//   src: url('../fonts/example.eot');
//   src: url('../fonts/example.eot?iefix') format('eot'),
//     url('../fonts/example.woff') format('woff'),
//     url('../fonts/example.ttf') format('truetype'),
//     url('../fonts/example.svg#webfontOkOndcij') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 * 3. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 */
html {
  font-family: $body-font; /* 1 */
  font-size: 14px; /* 3 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  line-height: $body-line-height;
}

/*
 * Addresses margins handled incorrectly in IE6/7
 */

body {
  margin: 0;
  padding: 0;
  color: $body-font-color;
}

/* =============================================================================
   Typography

   To achieve a pleasant vertical rhythm, we use Compass' Vertical Rhythm mixins
   so that the line height of our base font becomes the basic unit of vertical
   measurement. We use multiples of that unit to set the top and bottom margins
   for our block level elements and to set the line heights of any fonts.
   For more information, see http://24ways.org/2006/compose-to-a-vertical-rhythm
   ========================================================================== */

/*  Header Styles
   ========================================================================== */

h1 {
  @include h1; // Mixin found in _theme.scss
  @include rem("margin-bottom", $h1-margin-bottom);
  @include rem("margin-top", $h1-margin-top);
}

h2 {
  @include h2;
  @include rem("margin-bottom", $h2-margin-bottom);
  @include rem("margin-top", $h2-margin-top);
}

h3 {
  @include h3;
  @include rem("margin-bottom", $h3-margin-bottom);
  @include rem("margin-top", $h3-margin-top);
  font-weight: 700;
}

h4,
h5,
h6 {
  @include h4;
  @include rem("margin-bottom", $h4-margin-bottom);
  @include rem("margin-top", $h4-margin-top);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // color: $page-title-color;
  word-wrap: break-word;
}

h1,
h2 {
  font-family: $lemonde;
  font-weight: 300;
}

h3,
h4 {
  font-family: $myriad;
}

h3 {
  font-weight: 300;
}

h4 {
  font-weight: 300;
}

h1,
h2,
h3,
h4 {
  a {
    font-weight: 300;
    border-bottom: none;
  }
}

h5,
h6 {
  font-family: $block-title-font;
}

h1,
h2,
h5,
h6 {
  font-weight: normal;

  a {
    font-weight: 400;
  }
}

h1,
h2,
h3,
h4 {
  color: $header-blue;

  a {
    color: $header-blue !important;

    &:hover {
      color: darken($header-blue, 10%) !important;
    }
  }
}

/*  Drupal Titles
   ========================================================================== */

// .page__title,          /* The title of the page. */
// .node__title,          /* Title of a piece of content when it is given in a list of content. */
// .block__title,         Block title.
// .comments__title,      /* Comment section heading. */
// .comments__form-title, /* Comment form heading. */
// .comment__title {      /* Comment title. */
//   font-family: $header-font;
// }

.block-title {
  // font-family: $block-title-font;
  // color: $block-title-color;
}

.page__title {
  // color: $page-title-color;
  // font-family: $page-title-font;
  margin-top: 0;
}

.field-label {
  font-weight: bold;
  font-size: 1em;
}

h3.field-label {
  margin-bottom: 0.25em;
}

/*  Misc
   ========================================================================== */

/* Thematic break */
hr {
  height: 1px;
  border: 1px solid $horizontal-rule-border;
  padding-bottom: -1px;
  margin: $vert-gutter 0;
}

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
  white-space: nowrap;
}

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
*/

b,
strong {
  font-weight: bold;
}

blockquote {
  /* Sets 1 unit of vertical rhythm on the top and bottom margin. */
  margin-left: 40px;
}

/*
 * Addresses styling not present in S5, Chrome
 */

dfn {
  font-style: italic;
}

/*
 * Addresses styling not present in IE6/7/8/9
 */

mark {
  background: #ff0;
  color: #000;
}

/*
 * Addresses margins set differently in IE6/7
 */

pre {
  /* Sets 1 unit of vertical rhythm on the top and bottom margin. */
  margin: rhythm(2) 0;
}

p {
  margin: 0;
}

p + p {
  margin-top: $vert-gutter/2;
}

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */

pre,
code,
kbd,
samp,
tt,
var {
  font-family: $font-monospace; /* The $font-monospace value ends with ", serif". */

  @if $legacy-support-for-ie6 {
    _font-family: "courier new", monospace;
  }
}

p {
  pre,
  code,
  kbd,
  samp,
  tt,
  var {
    margin: 0;
    padding: 0;
    color: $body-font-color;
    background: transparent;
  }
}

/*
 * Improves readability of pre-formatted text in all browsers
 */

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */

/* 1 */

q {
  quotes: none;
}

/* 2 */

q::before,
q::after {
  content: "";
  content: none;
}

/* @todo Determine how big element affects vertical rhythm. */
small {
  font-size: 75%;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* WYSIWYG / BASIC PAGE FIELD CSS */

.wys-divider {
  height: 0;
  border-bottom: 1px solid darken($body-bgrd-color, 20%);
  margin: $vert-gutter 0;
}

.wys-intro-title {
  font-weight: bold;
}

.wys-intro-text {
  font-weight: normal;
}

.wys-section-title {
  font-weight: bold;
}

.wys-block-title {
  font-weight: bold;
  color: darken($body-font-color, 20%);
}

h4 {
  &.toggle-accordion {
    border-top: 2px solid $nav-hover;
    cursor: pointer;
    padding-top: 0.5em;
    padding-right: 50px;

    &.accordion-hide {
      background: url("../images/accordion-arrow.png") no-repeat right 14px;
      background-size: 12px auto;
    }

    &.accordion-show {
      background: url("../images/accordion-arrow.png") no-repeat right bottom
        5px;
      background-size: 12px auto;
    }
  }
}

.columns {
  @include clearfix;
  margin-left: -$horiz-gutter/2;
  margin-right: -$horiz-gutter/2;

  .column {
    @include breakpoint($tablet-portrait-min) {
      padding: 0 $horiz-gutter/2;
      margin-bottom: $horiz-gutter;

      &.half {
        width: 50%;
        float: left;
      }

      &.third {
        width: 33.33%;
        float: left;
      }
    }
    @include breakpoint($tablet-landscape-min) {
      &.quarter {
        width: 25%;
        float: left;
      }
    }
    @include breakpoint($tablet-landscape-max $tablet-portrait-min) {
      &.quarter {
        width: 50%;
        float: left;
      }
    }
  }
}

.callout,
.callout-left,
.group-page-intro-inner .sidebar,
.field-article-body .sidebar,
.field-basic-body .sidebar,
.group-blog-body .sidebar,
.cke_editable .sidebar,
.podcastbox {
  background-color: $callout-bgrd;
  border: 1px solid $rule-color;
  position: relative;
  left: 0;
  padding: $horiz-gutter;
  margin-bottom: $vert-gutter/2;

  *:first-child {
    margin-top: 0;
  }

  &::before {
    background: url("../images/flower-separator-full.png") no-repeat bottom
      center;
    content: "";
    display: block;
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 40px;
  }

  h2 {
    &::before,
    &::after {
      display: none !important;
    }
  }
}

.group-page-intro-inner .sidebar,
.field-article-body .sidebar,
.field-basic-body .sidebar,
.group-blog-body .sidebar,
.cke_editable .sidebar {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 45px;

  &::before {
    background-size: auto 40px;
  }
}

.callout,
.podcastbox {
  clear: right;
  @include breakpoint($tablet-portrait-min) {
    margin-top: 30px;
    width: 33.33%;
    float: right;
    margin-left: $horiz-gutter * 2;

    &::before {
      background-size: auto 30px;
      position: absolute;
      top: -30px;
      left: 0;
      height: 30px;
    }
  }
  @include breakpoint($tablet-portrait-max) {
    margin-top: 45px;

    &::before {
      background-size: auto 40px;
      position: absolute;
      top: -40px;
      left: 0;
      height: 40px;
    }
  }
}

.callout-left {
  clear: right;
  @include breakpoint($tablet-portrait-min) {
    margin-top: 30px;
    width: 33.33%;
    float: left;
    margin-right: $horiz-gutter * 2;
    margin-bottom: 0px;

    &::before {
      background-size: auto 30px;
      position: absolute;
      top: -30px;
      left: 0;
      height: 30px;
    }
  }
  @include breakpoint($tablet-portrait-max) {
    margin-top: 45px;

    &::before {
      background-size: auto 40px;
      position: absolute;
      top: -40px;
      left: 0;
      height: 40px;
    }
  }
}

hr {
  border: none;
  position: relative;
  overflow: visible;

  &::before {
    content: "";
    display: block;
    width: 38px;
    height: 50px;
    margin: 0 auto;
    background: url("../images/flower-separator.png") #fff no-repeat center;
    background-size: 30px auto;
    position: relative;
    top: -25px;
    z-index: 5;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $rule-color;
    position: absolute;
    top: 0;
  }
}

ul.black-bullet-list {
  list-style-type: disc !important;
  padding-left: 27px !important;

  li {
    list-style-type: disc;
    text-indent: -14px !important;
    padding-left: 0 !important;

    &::before {
      display: none;
    }
  }
}

.wys-intro-container {
  .wys-intro {
    color: $body-font-color;
  }

  hr {
    clear: both;
    margin: $vert-gutter 0 $horiz-gutter;
  }
}

.wys-image-caption {
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: $horiz-gutter;
  position: relative;

  .caption {
    margin-top: 5px;
    line-height: 1.5;
  }

  &.wys-image-caption-left {
    width: 33.33%;
    float: left;
    margin-right: $horiz-gutter;
    padding-right: $horiz-gutter;
  }

  &.wys-image-caption-right {
    width: 33.33%;
    float: right;
    margin-left: $horiz-gutter;
    padding-left: $horiz-gutter;
  }

  &.variable-width-image {
    width: auto;

    .caption {
      width: min-content;
      min-width: 100%;
      background: rgba(0,0,0,0.75);
      color: #fff;
      margin: 0;
      padding: 5px 10px;
    }

    a {
      color: #fff;
      border-bottom-color: #fff;

      &:hover,
      &:focus {
        border-bottom-color: transparent;
      }
    }

    img {
      min-width: 44px;
      min-height: 44px;
      background: #f7f7f7;
      height: auto !important;
      float: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.primary-button {
  @include submit-btn;
  width: auto;
  margin-top: $horiz-gutter;
}

a.primary-button {
  &:hover {
    color: #fff;
  }
}

.textformatter-separator {
  margin-right: 5px;
}
