/* @partial views-styles */

.block-views {
  margin-bottom: 2em;
  .block__title,
  .view-header h2 {
    font-size: 1.7rem;
    margin-bottom: 1em;
    margin-top: 0;
    padding-top: 15px;
    border-top: 1px solid #aaa;
  }
  .view-header {
    p {
      color: #555;
    }
  }
  .views-field-title {
    font-size: 1.1em;
    color: $header-red;
    a {
      color: $link-color;
    }
  }
}

.region-bottom {
  @include breakpoint($tablet-portrait-min) {
    border-top: 1px solid #aaa;
    .block__title {
      border-top: none;
    }
  }
}

/* Slides */

.view-slides,
.slideshow-wrapper {
  a {
    border-bottom: none;
  }
  background-color: $slider-bgrd;
  .views-row,
  article {
    @include clearfix;
    width: 100%;
    @include breakpoint($tablet-portrait-max) {
      height: 100%;
      background-color: $slider-bgrd;
    }
    .field-slide-image {
      margin-bottom: 0;
    }
    .views-fieldset {
      background-color: $slider-bgrd;
      border: none;
      padding: 40px 30px;
      color: #fff;
      a {
        color: #fff;
      }
      .views-field-title,
      .node__title {
        font-size: 1.8em;
        margin-bottom: 1.2em;
        line-height: 1.2em;
        margin-top: 0;
        color: #fff;
        a {
          color: #fff !important;
        }
      }
      .views-field-field-slide-subhead,
      .field-slide-subhead,
      .field-slide-description,
      .views-field-field-slide-description {
        font-size: 1.1em;
        // color:#fff;
        margin-bottom: 18px;
      }
      .views-field-field-slide-link,
      .field-slide-link {
        // a {
        // 	color:#fff;
        // }
      }
    }
  }
  .cycle-pager,
  .slideshow-pager {
    text-align: center;
    font-size: 2em;
    color: rgba(255, 255, 255, 0.5);
    span {
      padding: 3px;
      cursor: pointer;
    }
    .cycle-pager-active {
      color: rgba(255, 255, 255, 1);
    }
  }
  @include breakpoint($tablet-portrait-min) {
    .views-field-field-slide-image,
    .field-slide-image {
      width: 66.67%;
      float: left;
    }
    .views-fieldset {
      width: 33.33%;
      float: right;
    }
    .cycle-pager,
    .slideshow-pager {
      position: absolute;
      bottom: 20px;
      right: 0;
      width: 33.33%;
      z-index: 105;
    }
  }
}

#home-slides {
  @include breakpoint($tablet-portrait-max) {
    margin-bottom: $horiz-gutter * 2;
  }
}

.feed-icon {
  display: none;
}

#block-views-slides-block,
.slideshow {
  @include breakpoint($tablet-portrait-min) {
    @include flower-rule(bottom, #fff);
  }
  @include breakpoint($tablet-portrait-max) {
    .views-field-title,
    .node__title,
    .view-footer {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

#slider-video {
  > div {
    @include flower-rule(bottom, #fff);
    @include breakpoint($tablet-portrait-min) {
      .clearfix {
        display: flex;

        #home-slides {
          width: 66.67%;
          flex: 0 1 66.67%;
          padding-right: $horiz-gutter;

          @include breakpoint($nav-max) {
            width: 50%;
            flex: 0 1 50%;
          }

          @include breakpoint($tablet-portrait-max) {
            width: 100%;
            flex: 0 1 100%;
          }
        }
        #home-video {
          margin-top: 15px;
          width: 33.33%;
          flex: 0 1 66.67%;
          padding-left: $horiz-gutter;

          @include breakpoint($nav-max) {
            width: 50%;
            flex: 0 1 50%;
          }

          @include breakpoint($tablet-portrait-max) {
            width: 100%;
            flex: 0 1 100%;
          }
        }
      }
    }
  }
  #home-video {
    a {
      border-bottom: none;
    }
    h2 {
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 10px;
      color: $header-blue;
      background: url("../images/video-icon.png") no-repeat left center;
      background-size: 20px auto;
      padding-left: 30px;
      // font-family:$myriad;
    }
    article {
      background-color: #111;
      color: #fff;
      padding: 0;
      width: 100%;
      float: none;
      .node__title {
        display: none;
      }
      .field-playlist-youtube-url {
        margin-bottom: 0;
      }
      .field-title-field {
        // position:static;
        margin-left: 0;
        padding-left: 0;
        h3 {
          // font-size:1.2em;
          // line-height:1.1em;
          // background-color:transparent;
          padding: $horiz-gutter $horiz-gutter 20px;
          a {
            color: #fff !important;
            font-weight: 300;
          }
        }
      }
      .field-playlist-description {
        padding: 0 $horiz-gutter $horiz-gutter;
      }
    }
    .see-more {
      text-align: center;
      .btn {
        background-color: $header-blue;
        &:hover {
          background-color: darken($header-blue, 10%);
          color: #fff;
        }
        &:visited {
          color: #fff;
        }
      }
    }
  }
}

.section-home,
.front {
  ul.links:first-child {
    display: none;
  }
  .group-page-intro-bottom,
  .page__title {
    display: none;
  }
  #main {
    max-width: none;
    #content {
      padding: $horiz-gutter * 2 0 0 0;
      @include breakpoint($tablet-portrait-min) {
        #home-blog div.inside,
        #home-featured,
        #slider-video,
        #home-featured-bottom,
        #home-testimonial-name > div {
          max-width: $layout-max;
        }
      }
      @include breakpoint($tablet-portrait-max) {
        #home-blog div.inside,
        #home-featured,
        #slider-video,
        #home-featured-bottom,
        #home-testimonial-name > div {
          max-width: 660px;
        }
      }
      #home-blog div.inside,
      #home-featured,
      #slider-video,
      #home-featured-bottom,
      #home-testimonial-name > div {
        padding-left: $horiz-gutter/2;
        padding-right: $horiz-gutter/2;
        margin: 0 auto;
      }
      #home-testimonial-name > div {
        padding-top: $horiz-gutter * 2;
        padding-bottom: $horiz-gutter * 2;
      }
    }
  }
}

#home-featured {
  a {
    border-bottom: none;
  }
  > div {
    @include clearfix;
    margin-top: $horiz-gutter/2;
    margin-bottom: $horiz-gutter;
    @include breakpoint($tablet-portrait-min) {
      margin-left: -$horiz-gutter;
      margin-right: -$horiz-gutter;
      .listing-resources,
      .listing-news,
      #home-popular,
      .funding-credit {
        padding-left: $horiz-gutter;
        padding-right: $horiz-gutter;
      }
      .listing-resources,
      .listing-news,
      #home-popular {
        border-right: 1px solid $light-rule-color;
        margin-bottom: -99999px;
        padding-bottom: 99999px;
      }
      .listing-resources,
      .listing-news {
        width: 37%;
        float: left;
      }
      #home-popular {
        width: 38%;
        float: left;
      }
      .funding-credit {
        width: 25%;
        float: left;
      }
      .listing-resources,
      .listing-news {
        background-position: left top;
        h2 {
          padding-left: 40px;
        }
      }
    }
    @include breakpoint($tablet-portrait-max) {
      .listing-resources,
      .listing-news,
      #home-popular,
      .funding-credit {
        padding-top: $horiz-gutter * 2;
        padding-bottom: $horiz-gutter * 2;
      }
      .listing-resources,
      .listing-news,
      #home-popular {
        border-bottom: 1px solid $light-rule-color;
      }
      .listing-resources,
      .listing-news {
        background-position: left top 28px;
        h2 {
          padding-left: 50px;
        }
      }
    }
    .listing-resources,
    .listing-news,
    #home-popular,
    .funding-credit {
      h2 {
        color: $header-blue;
        margin-top: 0;
      }
    }
    .listing-resources {
      li {
        margin-bottom: $horiz-gutter * 1.5;
      }
    }
    .listing-news,
    #home-popular {
      h2 {
        padding-top: 5px;
      }
      article,
      li {
        margin-bottom: $horiz-gutter * 1.5;
        height: 76px;
        overflow: hidden;
        .news-title,
        .field-title-field {
          display: block;
          max-height: 40px;
          overflow: hidden;
        }
      }
    }
    .listing-resources,
    .listing-news {
      background-image: url("../images/cc-sun.png");
      background-repeat: no-repeat;
      background-size: 48px auto;
      li {
        text-indent: 0 !important;
        padding-left: 0 !important;
        &:before {
          display: none !important;
        }
        a.news-title {
          font-size: 1.1em;
        }
        a.news-source {
          display: inline-block;
        }
        span.news-date {
          font-size: 0.9em;
          padding-left: 5px;
        }
      }
    }
    .listing-news > a,
    #home-popular > a {
      border-bottom: 1px dotted $link-color;
    }
    #home-popular {
      .field-article-image {
        width: 25%;
        float: left;
        margin-bottom: 0;
        @include breakpoint($tablet-portrait-min) {
          margin-right: 0;
        }
        @include breakpoint($tablet-portrait-max) {
          margin-right: $horiz-gutter;
        }
      }
      .field-guide-image {
        width: 25%;
        padding-right: 0;
        height: 0;
        padding-bottom: 25%;
        overflow: hidden;
      }
      h3 {
        margin: 0;
        font-size: 1.1em;
        padding: 0;
        max-height: 63px;
        overflow: hidden;
        a {
          color: $link-color !important;
          font-weight: normal;
          font-family: $body-font;
          &:hover {
            color: $link-color-hover !important;
          }
        }
      }
    }
  }
}

#home-blog {
  margin-top: $horiz-gutter * 3;
  @include flower-rule-full;
  > div {
    padding-top: $horiz-gutter * 2;
    padding-bottom: $horiz-gutter * 1.5;
    background-color: $feature-bgrd;
  }
  h4 {
    margin: 0;
    a {
      font-size: 0.9em;
      border-bottom: none;
    }
  }
  a,
  h4 a {
    color: $header-red !important;
  }
  .field-title-field {
    h3 {
      margin-top: 0;
      a {
        color: $header-blue !important;
        border-bottom: none;
      }
    }
  }
  .home-blog-image {
    float: left;
    margin-right: $horiz-gutter * 2;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center center;
      height: 150px;
      z-index: 1000;
      width: 150px;
    }

    @include breakpoint($tablet-portrait-min) {
      border-radius: 80px;
    }

    @include breakpoint($tablet-portrait-max) {
      display: none;
    }
  }
}

#home-testimonial-name {
  background-color: $testimonial-bgrd;
  color: #fff;
  > div {
    @include clearfix;
  }
  @include breakpoint($tablet-landscape-min) {
    #home-testimonial {
      width: 67%;
      float: left;
      padding: 0 $horiz-gutter * 3 0 0;
      background: url("../images/testimonial-vert-rule.png") no-repeat right
        center;
      background-size: 3px auto;
      min-height: 145px;
      .field-testimonial-quote {
        font-size: 1.3em;
        .item {
          padding-left: 27px;
        }
      }
    }
    #home-name {
      width: 33%;
      float: left;
      padding: 0 0 0 $horiz-gutter * 3;
    }
  }
  @include breakpoint($tablet-portrait-min $tablet-landscape-max) {
    #home-testimonial {
      width: 60%;
      float: left;
      padding: 0 $horiz-gutter * 3 0 0;
      background: url("../images/testimonial-vert-rule.png") no-repeat right
        center;
      background-size: 3px auto;
      .views-field-body {
        font-size: 1.3em;
      }
      .field-testimonial-quote {
        .item {
          padding-left: 27px;
        }
      }
    }
    #home-name {
      width: 40%;
      float: left;
      padding: 25px 0 35px $horiz-gutter * 2;
    }
    div.testimonial-text {
      &:after {
        position: absolute;
        bottom: 3px;
        right: -40px;
      }
    }
  }
  @include breakpoint($tablet-portrait-max) {
    #home-testimonial,
    #home-name {
      width: 100%;
      padding: $horiz-gutter;
    }
    .field-testimonial-quote {
      font-size: 1.2em;
      .item {
        padding-left: 0;
        // &:after {
        //   position:absolute;
        //   bottom:3px;
        //   right:-20px;
        // }
      }
    }
  }
  .field-testimonial-quote {
    line-height: 1.7em;
    .item {
      position: relative;
      top: 0;
      left: 10px;
      font-style: italic;
      display: inline-block;
      @include breakpoint($tablet-portrait-max) {
        padding-left: 8px;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 30px;
        height: 24px;
        background: url("../images/testimonial-endquote.png") no-repeat center;
        background-size: 100%;
        margin-left: 10px;
        margin-bottom: -7px;
      }
      // p.indent {
      //   position:relative;
      //   left:40px;
      // }
    }
  }
  .field-title-field {
    margin-left: $horiz-gutter * 4;
    margin-bottom: 0;
    .item {
      &:before {
        content: "—";
      }
    }
  }
  #home-name {
    font-size: 1.6em;
    line-height: 1.4em;
    font-style: italic;
    text-align: center;
    > div {
      background: url("../images/branch.png") no-repeat top center;
      @include breakpoint($tablet-portrait-min) {
        background-size: 75% auto;
        padding-top: 12%;
      }
      @include breakpoint($tablet-portrait-max) {
        background-size: 200px auto;
        padding-top: 30px;
      }
    }
    img {
      width: 180px;
    }
    img,
    p {
      position: relative;
      top: -6px;
    }
    a {
      color: #fff;
      font-weight: normal;
      border-bottom: 1px dotted #fff;
    }
  }
}

#home-featured-bottom {
  padding-top: $horiz-gutter * 2;
  padding-bottom: $horiz-gutter * 2;
  h2 {
    margin-top: 0;
    margin-bottom: $horiz-gutter * 2;
  }
  #home-featured-bottom-container {
    > div {
      @include breakpoint($tablet-portrait-min) {
        @include clearfix;
        margin-left: -$horiz-gutter;
        margin-right: -$horiz-gutter;
        overflow: hidden;
        article {
          width: 33.33%;
          padding: 0 $horiz-gutter $horiz-gutter;
          float: left;
          border-left: 1px solid $light-rule-color;
          margin-bottom: -99999px;
          padding-bottom: 99999px;
          &:first-child {
            border-left: none;
          }
          .field-theme-image {
            margin-bottom: 0;
          }
        }
      }
      @include breakpoint($tablet-portrait-max) {
        article {
          padding: $horiz-gutter/2 0;
        }
      }
      article {
        h3 {
          margin-top: 0;
        }
        .field-theme-image {
          width: 30%;
          float: left;
          margin-right: $horiz-gutter;
        }
        .field-theme-description {
          color: #555;
        }
        .field-title-field {
          margin-bottom: 0;
        }
      }
    }
  }
  h3 {
    font-weight: normal;
    a {
      font-weight: normal;
    }
  }
}

/* Homepage Blog, News, Calendar Listings */

// @include breakpoint($tablet-portrait-min) {
// 	#block-views-faq-block,
// 	#block-views-research-block,
// 	#block-block-3,
// 	#block-views-books-block,
// 	#block-views-web-resources-block {
// 		float:left;
// 		padding-top:5px;
// 	}

// 	.sidebar #block-block-3 {
// 		margin-top:0;
// 		padding-top:0;
// 		float:none;
// 	}

// 	.front #block-block-3 {
// 		margin:0;
// 	}

// 	#block-views-blog-listing-block-1 {
// 		width:33%;
// 		float:left;
// 		padding:0 0 0 $horiz-gutter;
// 	}

// 	#block-views-news-listing-block {
// 		width:37%;
// 		float:left;
// 		border-right:1px solid $light-rule-color;
// 	}

// 	#block-views-blog-listing-block {
// 		width:38%;
// 		float:left;
// 		border-right:1px solid $light-rule-color;
// 	}

// 	.front #content #block-block-3,
// 	.front .region-bottom #block-block-3 {
// 		width:25%;
// 		padding-left:$horiz-gutter;
// 	}

// 	.not-front #content #block-block-3,
// 	.not-front .region-bottom #block-block-3 {
// 		width:33%;
// 		padding:0 $horiz-gutter 0 $horiz-gutter;
// 	}

// 	#block-views-news-listing-block {
// 		padding-right:$horiz-gutter;
// 	}

// 	#block-views-blog-listing-block {
// 		padding:0 $horiz-gutter;
// 	}

// 	#block-views-featured-posts-block {
// 		.view-featured-posts {
// 			padding:3em 0;
// 			.views-field-field-article-image {
// 				float:left;
// 				margin-right:3em;
// 			}
// 			.views-row {
// 				padding:0 6em;
// 			}
// 		}
// 	}
// }

// @include breakpoint($tablet-portrait-max) {
// 	#block-views-multicultural-calendar-block,
// 	#block-views-blog-listing-block,
// 	#block-views-news-listing-block,
// 	#block-block-3,
// 	#block-views-blog-listing-block-1,
// 	#block-views-faq-block,
// 	#block-views-research-block {
// 		width:100%;
// 		float:none;
// 	}

// 	#block-views-multicultural-calendar-block,
// 	#block-views-blog-listing-block,
// 	#block-views-news-listing-block,
// 	#block-block-3 {
// 		padding:$horiz-gutter 0;
// 		border-top:2px dotted $rule-color;
// 	}
// 	// #block-views-blog-listing-block-1,
// 	// #block-views-faq-block,
// 	// #block-views-research-block,
// 	// #block-views-books-block,
// 	// #block-views-web-resources-block {
// 	// 	padding:$horiz-gutter;
// 	// }
// 	.views-field-field-calendar-image {
// 		margin-bottom:10px;
// 	}
// 	#block-views-featured-posts-block {
// 		.view-featured-posts {
// 			padding:3em $horiz-gutter;
// 			.views-field-field-article-image {
// 				margin:0 auto 1em;
// 			}
// 		}
// 	}
// }

// #block-views-multicultural-calendar-block,
// #block-views-blog-listing-block,
// #block-views-blog-listing-block-1,
// #block-views-news-listing-block {
// 	.views-row {
// 		@include clearfix;
// 		margin-bottom:2em;
// 		.views-field-field-blog-image {
// 			width:82px;
// 			float:left;
// 			margin-right:10px;
// 		}
// 		.views-field-field-news-link {
// 			a {
// 				color:$body-font-color;
// 			}
// 		}
// 	}
// }

// #block-views-news-listing-block {
// 	.view-header {
// 		background-image:url('../images/cc-sun.png');
// 		background-repeat:no-repeat;
// 		background-size:50px auto;
// 		background-position:left 140px center;
// 	}
// }

// .front {
// 	#block-views-news-listing-block,
// 	#block-views-blog-listing-block {
// 		.view-header {
// 			margin-bottom:2em;
// 			h2 {
// 				padding-top:5px;
// 				margin-bottom:0;
// 				border-top:none;
// 				color:$header-blue;
// 			}
// 		}
// 	}
// }

// .node-type-l2-page,
// .node-type-l3-page {
// 	#block-views-blog-listing-block-1 {
// 		.view-header {
// 			margin-bottom:2em;
// 			h2 {
// 				margin-bottom:0;
// 				@include breakpoint($tablet-portrait-min) {
// 					border-top:none;
// 				}
// 			}
// 		}
// 	}
// }

#block-views-featured-posts-block {
  @include flower-rule-full;
  float: none;
  clear: both;
  width: 100%;
  margin-bottom: 0;
  .view-featured-posts {
    background-color: $feature-bgrd;
    .views-row {
      @include clearfix;
      .views-field-field-article-image {
        width: 150px;
        border-radius: 75px;
        overflow: hidden;
      }
      .views-field-field-article-type {
        margin-top: 7px;
        margin-bottom: 5px;
        font-size: 1.1em;
        color: $header-red;
      }
      .views-field-title {
        font-size: 1.4em;
        margin-bottom: 10px;
        font-weight: bold;
        a {
          color: #333;
        }
        .featured-post-kicker {
          font-size: 0.75em;
          color: $header-red;
          margin-bottom: $horiz-gutter/2;
        }
      }
      .views-field-field-article-body-1 {
        a {
          color: $header-red;
        }
      }
    }
  }
}

/* Featured Video */

#block-views-video-block-1 {
  @include breakpoint($tablet-portrait-min) {
    width: 67%;
    float: left;
    padding: 10px $horiz-gutter 0 0;
  }
  @include breakpoint($tablet-portrait-max) {
    width: 100%;
  }
}

.node-type-l4-page {
  #block-views-video-block-1 {
    width: 100%;
  }
}

.player,
.iframe,
.video-container {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 60%;
  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

/* Listings */

#block-views-sections-listings-block {
  clear: both;
}

.view-sections-listings {
  @include clearfix;
  &.view-display-id-block {
    .views-row {
      @include breakpoint($tablet-portrait-min) {
        width: 23.5%;
        float: left;
        margin-left: 2%;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  &.view-display-id-block_1 {
    .views-row {
      @include breakpoint($tablet-portrait-min) {
        width: 31.33%;
        float: left;
        margin-left: 2%;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .views-row {
    @include breakpoint($tablet-small-min $tablet-portrait-max) {
      width: 48%;
      float: left;
      margin-bottom: 2em;
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:nth-child(even) {
        margin-left: 2%;
      }
    }
    @include breakpoint($tablet-small-max) {
      width: 100%;
      margin-bottom: 2em;
    }
    .views-field-field-page-image,
    .views-field-field-l4-image {
      margin-bottom: 0.5em;
    }
    .views-field-title {
      font-size: 1.3em;
      line-height: 1.3em;
      color: $header-blue;
      a {
        color: $header-blue;
      }
    }
  }
}

#block-views-video-block {
  .views-row {
    @include clearfix;
    .views-field-field-video-url {
      width: 25%;
      float: left;
      margin-right: 20px;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: url("../images/cc-video-overlay.png") no-repeat center;
        background-size: 100%;
      }
    }
  }
}

/* Research Reports Page */

.view-id-research.view-display-id-page {
  .views-row {
    margin-bottom: 2em;
  }
  .views-field-title {
    font-size: 1.8em;
    margin-bottom: 0.5em;
  }
  .views-label {
    font-weight: bold;
  }
  .views-field-field-research-author,
  .views-field-field-research-organization {
    margin-bottom: 0.5em;
  }
  .views-field-field-research-abstract .field-content {
    display: inline-block;
  }
}

.accordion-container {
  margin-top: 2em;
  padding-bottom: 2em;
}

.accordion {
  &.accordion-hide {
    max-height: 50px;
    overflow: hidden;
  }
  &.accordion-show {
    max-height: none;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1em;
  }
  h3 {
    margin-top: 0;
  }
}

/* Testimonials */

#home-testimonial {
  .field-testimonial-quote {
    line-height: 1.7em;
    .item {
      &:before {
        content: "";
        display: block;
        width: 30px;
        height: 24px;
        background: url("../images/testimonial-startquote.png") no-repeat center;
        background-size: 100%;
        float: left;
        margin-left: -37px;
        @include breakpoint($tablet-portrait-max) {
          position: relative;
          top: 0;
          left: -3px;
        }
      }
    }
  }
  // &.view-display-id-block {
  // 	.views-field-field-testimonial-quote {
  // 		&:before {

  // 	    }
  // 	}
  // }
  &.view-display-id-page {
    .views-row {
      border-bottom: 1px solid $rule-color;
      padding-bottom: 3em;
      margin-bottom: 3em;
      font-size: 1.1em;
      &:first-child {
        margin-top: 3em;
      }
    }
    .views-field-field-testimonial-quote {
      font-style: italic;
      .field-content {
        padding-left: 3em;
      }
    }
    .views-field-title {
      margin-top: 0.5em;
      padding-left: 3em;
    }
  }
}

/* Book Theme Pages */

.view-books.view-display-id-block_2,
.view-books.view-display-id-block_4,
.view-authors.view-display-id-block {
  .views-row {
    @include clearfix;
    margin-bottom: 3em;
    .views-field-field-book-image,
    .views-field-field-author-photo {
      width: 25%;
      float: left;
      padding-right: $horiz-gutter;
    }
  }
}

.front.i18n-es {
  #home-featured {
    .listing-resources {
      h2 {
        padding-top: 5px;
      }
      li:not(:last-child) {
        @include flower-rule(bottom, #fff);
        &:before {
          content: "" !important;
          display: block !important;
          border-top: 1px solid $rule-color !important;
          height: 1px !important;
          width: 100% !important;
          position: absolute !important;
          bottom: 20px !important;
          z-index: 1 !important;
          margin-left: 0 !important;
          @media print {
            display: none;
          }
        }
      }
      .question {
        h4 {
          // font-size:1.1em;
          margin-bottom: $horiz-gutter/1.5;
        }
      }
      .answer {
        a {
          border-bottom: 1px dotted $link-color;
          &:after {
            content: " ›";
          }
        }
      }
      // ul {
      // 	list-style-type:disc !important;
      // 	padding-left:0 !important;
      // 	li {
      // 		text-indent:-14px !important;
      // 		padding-left:14px !important;
      // 		&:before {
      // 			display:none;
      // 		}
      // 	}
      // }
    }
  }
  #home-featured-bottom {
    @include flower-rule(top, #fff);
    padding-top: 0;
  }
}

.view-meet-the-authors-gallery {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;

  .views-row {
    width: 100%;
    position: relative;

    .views-field-title {
      position: absolute;
      bottom: 0;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: auto;
      color: #fff;
      padding: $horiz-gutter*0.5 $horiz-gutter;
    }

    @include breakpoint($tablet-portrait-min) {
      width: calc(33.33% - 25px);
    }
  }
}