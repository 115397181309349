#block-menu-block-1 {
  #mega-menu {
    & > li {
      ul {
        li {
          a {
            opacity: 0;
            transition: all 1s ease-in-out;
          }
        }
      }
      &.is-active {
        ul {
          li {
            background: transparent;

            a {
              opacity: 1;
              transition: all 1s ease-in-out;
            }
          }
        }
      }
    }
  }
}
