/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
      content: "";
      display: table;
  }

  &:after {
      clear: both;
  }

  .slick-loading & {
      visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
      float: right;
  }
  img {
      display: block;
  }
  &.slick-loading img {
      display: none;
  }

  display: none;

  &.dragging img {
      pointer-events: none;
  }

  .slick-initialized & {
      display: block;
  }

  .slick-loading & {
      visibility: hidden;
  }

  .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}


// theme specific
.slick-slider {
  background: $slider-bgrd;
  padding-bottom: 30px;

  * {
    box-sizing: border-box;
  }

  .node-slide {
    max-width: 100%;
    width: 100%;
    text-align: left;

    .node-title {
      display: inline-block;
      margin: 0 15px;
    }
  }

  .slick-slide img {
    width: 100%;
    margin: 0;
  }

  .slick-slide h3 {
    color: #ffffff;

    a {
      color: #ffffff !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .slick-dots {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 10px;

    .slick-active {
      button {
        background: #48a8db;
      }
    }

    li {
      all: unset;
      list-style-type: none;
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }

    li::before {
      all: unset;
    }

    button {
      all: unset;
      background: #ffffff;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 12px solid $slider-bgrd;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      text-indent: -9999px;

      &:hover {
        background: $feature-bgrd;
        cursor: pointer;
      }

      &:focus {
        background: #48a8db;
      }

      &[aria-selected="true"] {
        background: $slider-bgrd;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: 0;
    z-index: 3;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: left;
    text-indent: -9999px;
    margin-top: 10px;
    background: $rule-color;
    border: 1px solid #eaeaea;
    transition: all 0.2s ease;

    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      position: absolute;
      top: 10px;
    }

    &.slick-prev {
      left: 0;
      margin-left: 10px;

      &::after {
        border-bottom: 2px solid #222222;
        border-left: 2px solid #222222;
        left: 12px;
      }
    }

    &.slick-next {
      right: 0;
      margin-right: 10px;

      &::after {
        border-top: 2px solid #222222;
        border-right: 2px solid #222222;
        right: 12px;
      }
    }

    &:hover,
    &:focus {
      background: $link-color;
      border-color: transparent;

      &::after {
        border-color: white;
      }
    }

    &.slick-disabled {
      pointer-events: none;
      border: 1px solid $rule-color !important;
      background: #eaeaea !important;

      &::after {
        border-color: $rule-color !important;
      }
    }
  }
}
