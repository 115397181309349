/* @partial navigation */

/* Main Navigation
   ========================================================================== */

#cc-nav-search {
  @include clearfix;
  width: 100%;
  @include breakpoint($tablet-portrait-min) {
    position: absolute;
    top: 0;
    right: 0;
  }
  @include breakpoint($tablet-portrait-max) {
    background-color: $nav;
    position: relative;
    padding: 20px;
  }
}

.navigation {
  @include breakpoint($tablet-portrait-min) {
    margin-top: -1px;
    background-color: $nav;
  }
  @include breakpoint($tablet-portrait-max) {
    background-color: $nav-hover;
  }
  position: relative;

  .block {
    margin-bottom: 0;
  }

  .block-menu .block__title,
  .block-menu-block .block__title {
    @extend %element-invisible;
  }

  /* Main menu and secondary menu links and menu block links. */
  .links,
  .menu {
    margin: 0;
    padding: 0;
    text-align: left; /* LTR */
    overflow: visible !important;
    @include clearfix;

    li {
      /* A simple method to get navigation links to appear in one line. */
      display: inline-block;
      list-style-type: none;
      list-style-image: none;
      &.last {
        margin-bottom: 0;
      }
    }
  }
}

ul.menu {
  @include clearfix;
  li {
    list-style-image: none;
    list-style-type: none;
    a {
      -webkit-user-select: none; // Prevents text within from being selected.
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: #555;
    }
  }
}

@include breakpoint($tablet-portrait-min) {
  #header .secondary-menu,
  #block-menu-menu-footer-links {
    display: block;
  }
  #footer .secondary-menu {
    display: none;
  }
}

@include breakpoint($tablet-portrait-max) {
  #header-wrapper {
    padding-top: 50px;
    position: relative;
  }
  #block-menu-menu-footer-links,
  #header .cc-social {
    display: none;
  }
  #header,
  #header .header__region {
    position: static;
  }
  #header .secondary-menu {
    width: 100%;
    position: static;
    height: 0;
    padding: 0;
    ul.links,
    #weta-link {
      display: none;
    }
    .translate-site {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $language-button-color;
      color: #fff;
      width: 100%;
      text-align: center;
      font-size: 1.2em;
      font-weight: 300;
      padding: $horiz-gutter;
      &:active,
      &:visited {
        color: #fff;
      }
      &:hover {
        background-color: darken($language-button-color, 5%);
        color: #fff;
      }
    }
  }
  #footer .secondary-menu,
  #footer .cc-social {
    display: block;
    ul.links:first-child {
      display: block;
    }
  }
}

.secondary-menu {
  font-size: 0.9em;
  @include breakpoint($tablet-portrait-min) {
    float: left;
    padding: 12px 0;
    margin: 0;
    position: relative;
    left: 20px;
    z-index: 99;
    ul.links {
      @include clearfix;
      float: left;
    }
    #weta-link {
      display: block;
      float: left;
    }
    #donate-link {
      float: left;
    }
  }
  @include breakpoint($tablet-portrait-max) {
    @include clearfix;
    float: none;
    position: relative;
    width: 300px;
    margin: 0 auto;
    padding-bottom: 20px;
    ul.links {
      float: left;
    }
    li.first {
      &:before {
        display: none;
      }
    }
    #weta-link {
      float: right;
    }
  }

  ul.links {
    @include clearfix;
    li {
      list-style-type: none;
      list-style-image: none;
      float: left;
      padding: 8px 10px;
      color: #555;
      &:before {
        content: "|";
        padding-right: 13px;
      }
      a {
        color: #555;
      }
      &.menu-774 a {
        color: $header-red;
      }
    }
  }
}

#weta-link {
  background: url("../images/weta.svg") no-repeat top center;
  background-size: 100%;
  display: block;
  width: 80px;
  height: 40px;
  text-indent: -9999px;
}

#donate-link {
  @include transition(background-color 0.15s ease-in);
  background-color: #f07e04;
  border-radius: 5px;
  color: #fff;
  padding: 5px 20px;
  margin-left: $horiz-gutter;

  @include breakpoint($tablet-portrait-max) {
    position: absolute;
    right: $horiz-gutter;
  }
  @include breakpoint($tablet-portrait-max $phone-min) {
    top: 75px;
  }

  @include breakpoint($phone-max) {
    top: 80px;
    padding: 5px 30px;
  }

  &:hover,
  &:focus {
    background-color: darken(#f07e04, 10%);
  }
}

/* Breadcrumb
   ========================================================================== */

.breadcrumb {
  margin-bottom: 2em;
  @include rem("font-size", 12px);
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;

    & + li {
      margin-left: 4px;
    }
  }
}

/* Book
   ========================================================================== */

// #block-book-navigation {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 25%;
//   padding-right: $horiz-gutter;
//   padding-left: 30px;
//   h2 {
//     font-size: 1.4em;
//     margin-top: 75px;
//     margin-bottom: 10px;
//     a {
//       color: $header-red;
//     }
//   }
//   ul {
//     li {
//       font-size: 1.1em;
//       padding: 10px 0;
//       border-bottom: 1px solid #eee;
//       a {
//         color: $header-blue;
//         &.active {
//           color: #aaa;
//         }
//       }
//     }
//   }
//   @include breakpoint($tablet-portrait-max) {
//     display: none;
//   }
// }

/* General Drupal Menus
   ========================================================================== */

.menu__item.is-leaf {
  list-style-image: inline-image("menu-leaf.png");
  list-style-type: square;
}
.menu__item.is-expanded {
  list-style-image: inline-image("menu-expanded.png");
  list-style-type: circle;
}
.menu__item.is-collapsed {
  list-style-image: inline-image("menu-collapsed.png"); /* LTR */
  list-style-type: disc;
}

/* The active item in a Drupal menu. */
.menu a.active {
  color: $link-color-visited;
}

/* Pagination
   ========================================================================== */

/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  text-align: center;
  list-style-type: none;
  border-top: 1px solid $rule-color;
  padding: $horiz-gutter 0 0 0 !important;
  text-align: center;
}
%pager__item {
  list-style-type: none;
  @include border-radius($ui-border-radius);
  text-transform: uppercase;
  padding: 4px 8px;
  display: inline-block;
  @include transition(padding 0.15s ease-in);
}

.pager-item,      /* A list item containing a page number in the list of pages. */
.pager-first,     /* The first page's list item. */
.pager-previous,  /* The previous page's list item. */
.pager-next,      /* The next page's list item. */
.pager-last,      /* The last page's list item. */
.pager-ellipsis {
  /* A concatenation of several list items using an ellipsis. */
  &:before {
    display: none;
  }
  text-indent: 0 !important;
  a {
    @extend %pager__item;
    background-color: transparent;
    font-family: $proxima;
    font-weight: bold;
    border-bottom: none;
    &:hover,
    :active {
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}

/* The current page's list item. */
.pager-current {
  text-indent: 0 !important;
  &:before {
    display: none;
  }
  @extend %pager__item;
  background-color: transparent;
  color: $body-font-color;
}

.item-list .pager {
  /* A list of page numbers when more than 1 page of content is available */
  padding: $horiz-gutter 0 0 0;
  overflow: hidden;
  li {
    display: inline-block;
    margin-right: 2px;
    &.pager-current {
      @extend %pager__item;
      padding: 4px 8px;
    }
  }
}

.item-list {
  overflow: hidden;
}

/* Node edit tabs
   ========================================================================== */

$tab-radius: 4px;
$tab-color: $link-color;
$tab-font-size: 0.75 * $base-font-size;

ul.tabs-primary,
ul.tabs-secondary {
  @include clearfix;
  list-style: none;
  margin: 1em 0 2.5em;
  padding: 0 2px;
  white-space: nowrap;
  li {
    float: left; /* LTR */
    margin: 0.5em 3px 0;
  }
  a,
  a:link {
    display: block;
    text-decoration: none;
  }
}

ul.tabs-primary {
  li {
    margin: 0.5em 0 0 3px;
    &.is-active {
      a {
        background-color: $tab-color;
        color: #494949;
        border: 1px solid #d1d1d1;
      }
    }
    a {
      font-size: $tab-font-size;
      text-transform: uppercase;
      background-color: $tab-color;
      border: 1px solid $tab-color;
      color: #ffffff;
      line-height: 26px;
      padding: 0 20px;
      &:hover {
        text-decoration: none;
        background-color: darken($tab-color, 15%);
        border: 1px solid darken($tab-color, 10%);
      }
      &.active {
        background-color: #ffffff;
        color: #494949;
        &:hover {
        }
      }
    }
  }
}

// /*
//  * Secondary tabs STOCK
//  */

// ul.secondary {
//   font-size: .9em;
//   margin-top: -1.5em; /* Collapse bottom margin of ul.primary. */
//   padding-bottom: .5em;

//   a:link,
//   a:visited {
//     @include border-radius(.75em);
//     @include transition(background-color 0.3s);
//     @include single-text-shadow(#fff, 1px, 1px, 0);
//     background-color: #f2f2f2;
//     color: #666;
//     padding: 0 .5em;
//   }
//   a:hover,
//   a:focus {
//     background-color: #dedede;
//     border-color: #999;
//     color: #333;
//   }
//   a.active,
//   a:active {
//     @include single-text-shadow(#333, 1px, 1px, 0);
//     background-color: #666;
//     border-color: #000;
//     color: #fff;
//   }
// }

/* Translate Links */

li.statistics_counter {
  display: none !important;
}

li.es {
  display: block;
}

.language-link {
  background-color: $language-button-color;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 1em;
  padding: 10px 24px;
  &:visited {
    color: #fff;
  }
  &:hover {
    background-color: darken($language-button-color, 10%);
    color: #fff;
  }
}

.translate-site {
  color: $language-button-color;
  display: inline-block;
  float: left;
  padding: 8px 10px;
  font-weight: 700;
  &:visited {
    color: $language-button-color;
  }
  &:hover {
    color: darken($language-button-color, 10%);
  }
}

/* More Links */

.node-type-research .field-research-link,
.node-type-resource .field-resource-link {
  a {
    @include submit-btn;
    background-color: $see-more-btn;
    &:hover {
      background-color: darken($see-more-btn, 5%);
      color: #fff;
    }
    &:visited {
      color: #fff;
    }
  }
}
