/* @partial page-theme */

///////////////////////////////////////////////////////////////////////////////
// General page elements layout and theming that do not chnage across pages.
// Background images, logos, etc.
///////////////////////////////////////////////////////////////////////////////

/* Body
   ========================================================================== */

body {
  background-color: $body-bgrd-color;
}

.float {
  @include breakpoint($tablet-portrait-min) {
    float: left;
  }
  @include breakpoint($tablet-portrait-max) {
    margin: 0 auto;
  }
}

#name-and-slogan {
  h1 {
    display: none;
  }

  #site-slogan {
    font-family: $proxima;
    position: relative;
    top: -47px;
    color: #888;
    padding-left: 4px;
    font-size: 13.65px;
    margin-bottom: -40px;
  }
  @include breakpoint($tablet-portrait-min) {
    display: block !important;
  }
  @include breakpoint($tablet-portrait-max) {
    display: none !important;
  }
}

// .orange {
//   color:$cc-dark-orange;
//   &:visited {
//     color:darken($cc-dark-orange, 10%);
//   }
// }

#higlighted-wrapper {
  background-color: $feature-bgrd;
  color: $header-blue;
  font-size: 15px;
  line-height: 26px;

  h1.page__title {
    margin-top: 0;
  }

  .breadcrumb {
    padding: $horiz-gutter 0;
    margin-bottom: 0;
  }
}

#higlighted-wrapper .region-highlighted {
  @include flower-rule-split;
}

.node-type-l3-page #landing-page-intro {
  @include flower-rule(bottom, #fff);
}

/* Header
   ========================================================================== */

@include breakpoint($tablet-portrait-min) {
  .background-1 {
    #header-wrapper {
      background-image: url("../images/header1.jpg");
    }
  }

  .background-2 {
    #header-wrapper {
      background-image: url("../images/header2.jpg");
    }
  }

  .background-3 {
    #header-wrapper {
      background-image: url("../images/header3.jpg");
    }
  }
}

#header-wrapper {
  a {
    border-bottom: none;
  }
  @include breakpoint($tablet-portrait-min) {
    background-repeat: no-repeat;
    background-position: top right;
    background-color: #fff;
    background-size: auto 200px;
  }
  @include breakpoint($tablet-landscape-min) {
    background-position: top right;
  }
  @include breakpoint($tablet-landscape-max $tablet-portrait-min) {
    background-position: top right;
  }
}

#header {
  position: relative;
  @include breakpoint($tablet-portrait-min) {
    height: 200px;
  }

  .header__region {
    @include clearfix;
    position: relative;
  }
}

#mobile-translate {
  @include breakpoint($tablet-portrait-min) {
    display: none;
  }
  width: 100%;
  @include clearfix;

  a {
    width: 50%;
    display: block;
    float: left;
  }
}

.spanish,
.english {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  text-align: center;
  font-size: 1.1em;

  &:hover,
  &:visited {
    color: #fff;
  }
}

.spanish {
  background-color: $language-button-color;
}

.english {
  background-color: $nav-quick-links;
}

#logo {
  margin: 0;
  padding: 0;
  display: block;
  color: #555;
  clear: both;
  position: relative;
  z-index: 99;

  img {
    width: 100%;
    max-width: 470px;
    height: auto;
  }

  p {
    font-style: italic;
    margin-top: 10px;
  }

  @include breakpoint($tablet-portrait-min) {
    padding: 8px 0 22px;
    width: 50%;
  }
  @include breakpoint($tablet-portrait-max $phone-min) {
    padding: 15px 0 10px;
    width: 300px;
  }
  @include breakpoint($phone-max) {
    background: url("../images/logo-mobile.png") no-repeat center;
    background-size: 100% auto;
    padding: 16px 0 13px;
    width: 200px;
    height: 90px;

    img {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }
}

.secondary-menu {
  float: right;
}

#search-block-form {
  @include breakpoint($tablet-landscape-min) {
    padding: 145px 0 19px;
    width: $layout-max;
    margin: 0 auto;

    input[type="text"] {
      position: absolute;
      right: 0;
    }

    input[type="submit"] {
      height: 35px;
      position: relative;
      top: 1px;
      right: 0;
    }
  }
  @include breakpoint($tablet-landscape-max $tablet-portrait-min) {
    padding: 90px 0 18px;

    input[type="text"] {
      position: absolute;
      right: 7px;
    }

    input[type="submit"] {
      height: 35px;
      position: relative;
      top: 1px;
      right: -4px;
    }
  }
  @include breakpoint($tablet-portrait-max) {
    padding: 0;
    float: right;
    width: 75%;

    input[type="text"] {
      position: absolute;
      right: 0;
    }

    input[type="submit"] {
      height: 33px;
      position: relative;
      top: 1px;
      right: 0;
    }
  }
  position: relative;
  text-align: right;

  input[type="text"] {
    text-align: left;
    border: 1px solid $header-blue;
    background-color: #fff;
    @include breakpoint($tablet-portrait-min) {
      width: 263px;
      padding: 10px 50px 10px 20px;
    }
    @include breakpoint($tablet-portrait-max) {
      width: 100%;
    }
  }

  input[type="submit"] {
    background: url("../images/search.png") #fff no-repeat;
    background-size: 24px auto;
    width: 40px;
    box-shadow: none;
    border: none;
    text-indent: -9999px;
    @include breakpoint($tablet-portrait-min) {
      background-position: center;
    }
    @include breakpoint($tablet-portrait-max) {
      background-position: center;
    }
  }
}

#views-exposed-form-search-page-page {
  .views-exposed-widget,
  .views-exposed-widgets {
    margin: 0;
    padding: 0;
    min-height: 0;
  }
  @include breakpoint($tablet-landscape-min) {
    .views-exposed-widgets {
      width: 263px;
      float: right;
      position: relative;
    }

    .views-widget-filter-search_api_views_fulltext {
      width: 100%;
      position: absolute;

      input {
        width: 100%;
      }
    }

    .views-submit-button {
      width: auto;
      float: right;
      z-index: 2;

      input {
        max-width: none;
      }
    }
    padding: 145px 0 19px;
    width: $layout-max;
    margin: 0 auto;
    // input[type="text"] {
    //   position:absolute;
    //   right:0;
    // }
    // input[type="submit"] {
    //   height:35px;
    //   position:relative;
    //   top:1px;
    //   right:0;
    // }
  }
  @include breakpoint($tablet-landscape-max $tablet-portrait-min) {
    padding: 90px 0 18px;

    .views-exposed-widgets {
      width: 263px;
      float: right;
      position: relative;
    }

    .views-widget-filter-search_api_views_fulltext {
      width: 100%;
      position: absolute;

      input {
        width: 100%;
      }
    }

    .views-submit-button {
      width: auto;
      float: right;

      input {
        max-width: none;
      }
    }
  }
  @include breakpoint($tablet-portrait-max) {
    padding: 0;
    float: right;
    width: 75%;

    .views-widget-filter-search_api_views_fulltext {
      width: 100%;
      position: absolute;

      input {
        width: 100%;
      }
    }

    .views-submit-button {
      width: auto;
      float: right;

      input {
        max-width: none;
      }
    }
    // input[type="text"] {
    //   position:absolute;
    //   right:0;
    // }
    // input[type="submit"] {
    //   height:33px;
    //   position:relative;
    //   top:1px;
    //   right:0;
    // }
  }
  position: relative;
  text-align: right;

  input[type="text"] {
    text-align: left;
    border: 1px solid $header-blue;
    background-color: #fff;
    margin-top: 0;
    @include breakpoint($tablet-portrait-min) {
      width: 263px;
      padding: 9px 50px 9px 20px;
    }
    @include breakpoint($tablet-portrait-max) {
      width: 100%;
    }
  }

  input[type="submit"] {
    background: url("../images/search.png") transparent no-repeat;
    background-size: 24px auto;
    width: 40px;
    box-shadow: none;
    border: none;
    text-indent: -9999px;
    margin-top: 0 !important;
    height: 37px;
    @include breakpoint($tablet-portrait-min) {
      background-position: center;
    }
    @include breakpoint($tablet-portrait-max) {
      background-position: center;
    }
  }
}

.cc-social {
  @include breakpoint($tablet-portrait-min) {
    float: left;
    position: relative;
    top: 16px;
    left: 0;
    z-index: 99;

    a {
      width: 30px;
      height: 30px;
    }
  }
  @include breakpoint($tablet-portrait-max) {
    @include clearfix;
    margin: 0 auto;
    width: 216px;
    padding-top: 30px;

    a {
      width: 40px;
      height: 40px;
    }
  }

  li {
    float: left;
  }

  a {
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;

    &.facebook {
      background-image: url("../images/social/facebook.png");
    }

    &.twitter {
      background-image: url("../images/social/twitter.png");
    }

    &.rss {
      background-image: url("../images/social/rss.png");
    }

    &.youtube {
      background-image: url("../images/social/youtube.png");
    }

    &.print {
      background-image: url("../images/social/print.png");
    }
  }
}

/* Main / Content
   ========================================================================== */

.view-mode-full {
  h1 {
    margin-top: 0;
  }
}

#landing-listings,
.group-content,
.group-article-main,
section.comments {
  @include breakpoint($tablet-portrait-min) {
    width: 68%;
    padding-right: $horiz-gutter;
    float: left;
    border-right: 1px solid $light-rule-color;
  }
}

.group-content {
  section:first-child {
    &::before,
    &::after {
      display: none !important;
    }
  }
}

.group-article-main {
  clear: both;
}

#main {
  ul {
    overflow: auto;
  }
}

#content {
  @include clearfix;
  position: relative;
  padding-top: $horiz-gutter;
  padding-bottom: $horiz-gutter;
}

.field-body {
  ul {
    padding: 0; /* LTR */
    list-style-type: none;

    li {
      padding-left: 28px;
      text-indent: -28px;

      &::before {
        content: "•";
        color: $link-color;
        padding-right: 20px;
      }
    }
  }
}

/* Messages
   ========================================================================== */

.messages {
  margin: $vert-gutter 0;
  padding: 10px 10px 10px 50px; /* LTR */
  background-image: inline-image("message-24-ok.png");
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}

.messages--status {
  @extend .messages;
  @extend %ok;
}

.messages--warning {
  @extend .messages;
  @extend %warning;
  background-image: inline-image("message-24-warning.png");
  border-color: #ed5;
}

.messages--error {
  @extend .messages;
  @extend %error;
  background-image: inline-image("message-24-error.png");
  border-color: #ed541d;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333;
}

/* System status report. */
.ok,
%ok {
  background-color: #f8fff0;
  color: #234600;
}

.warning,
%warning {
  background-color: #fffce5;
  color: #840;
}

.error,
%error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/* First Sidebar
   ========================================================================== */

/* Second Sidebar
   ========================================================================== */

.region-sidebar-second {
  margin-top: 72px;
}

.sidebar,
.group-sidebar {
  @include breakpoint($tablet-portrait-min) {
    width: 32%;
    float: right;
    padding-left: $horiz-gutter;
    border-left: 1px solid $light-rule-color;
    position: relative;
    left: -1px;
  }

  .node-title-list {
    list-style-type: none !important;
  }

  .listing,
  .funding-credit,
  .twitter,
  .promo {
    margin-bottom: 2em;
  }

  .partner-aft {
    margin-top: 0 !important;
  }

  .listing,
  .block-views {
    width: 100% !important;
    margin-top: 0 !important;
    border: 1px solid $feed-bgrd;
    // border-radius:4px;
    h2 {
      background-color: $feed-bgrd;
      color: #fff;
      padding: 12px;
      margin: 0 !important;
      border: none !important;
      font-size: $h3-font-size;
      font-family: $myriad;

      a {
        color: #fff !important;
        border-bottom: none;
      }
    }

    article,
    .views-row,
    .node-title-list li {
      padding: 0 12px !important;
      margin: 0;

      h3,
      .views-field-title {
        margin: 0;
        font-size: 0.9em;
        line-height: 1.4em;
        position: relative;
        top: -3px;
      }

      a {
        display: block;
        padding: 12px 0;
        border-bottom: 1px solid $light-rule-color;
      }

      &:last-child {
        a {
          border-bottom: none;
        }
      }

      .views-field-title {
        @include h3;
        font-size: 1em !important;
        font-family: $proxima;
      }
    }

    .node-title-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    // &.listing-popular {
    //   h2 {
    //     background-color:$feed-bgrd;
    //   }
    // }
    &.listing-related,
    &#l4-related {
      border: 1px solid $related-feature-bgrd;

      h2 {
        background-color: $related-feature-bgrd;
      }
    }
  }

  .news-title {
    font-size: 0.9em;
    line-height: 1.4em;
  }

  .news-date {
    display: none;
  }
  // .field-l4-related-page {
  //   width:100% !important;
  //   margin-top:0 !important;
  //   border:1px solid $feed-bgrd;
  //   // border-radius:4px;

  //   > h3 {
  //     background-color:$feed-bgrd;
  //     color:#fff;
  //     padding:12px;
  //     margin:0 !important;
  //     border:none !important;
  //     font-size:$h3-font-size;
  //     font-weight: normal;
  //   }
  //   > .item {
  //     padding:0 12px !important;
  //     margin:0;

  //     a {
  //       display:block;
  //       padding:12px 0;
  //       border-bottom:1px solid $light-rule-color;
  //       font-weight: normal;
  //     }
  //     &:last-child {
  //       a {
  //         border-bottom:none;
  //       }
  //     }
  //   }
  // }
  .listing-related {
    li {
      font-size: 0.9em;
      line-height: 1.4em;
    }
  }

  .listing-popular {
    border: 1px solid $popular-bgrd;

    h2 {
      background-color: $popular-bgrd;
    }

    > div {
      padding: 12px;
    }

    article {
      border-bottom: 1px solid $light-rule-color;
      padding: 12px 0 !important;

      a {
        font-family: $body-font !important;
        border-bottom: 0;
        font-weight: normal;
        padding: 0 !important;
        color: $link-color !important;

        &:hover {
          color: darken($link-color, 10%) !important;
        }
      }

      .field {
        margin-bottom: 0;
      }

      .field-article-image {
        margin-right: 0;
        width: 41%;
        float: left;
        padding-right: $horiz-gutter;
      }

      .field-article-image + .field-title-field {
        width: 59%;
        float: right;
      }

      &:first-child {
        padding-top: 0 !important;
      }

      &:last-child {
        padding-bottom: 0 !important;
        border-bottom: none;
      }
    }
  }

  .listing-news {
    > a {
      display: inline-block;
      margin: 0 12px 12px;
    }

    ul.node-title-list {
      padding: 12px !important;

      li {
        padding: 12px 0 !important;
        border-bottom: 1px solid $light-rule-color;

        a.news-title {
          padding-top: 0;
        }

        &:first-child {
          padding-top: 0 !important;
        }
        // &:last-child {
        //   padding-bottom:0 !important;
        //   border-bottom:none;
        // }
      }
    }
  }

  .view {
    .item-list {
      ul {
        padding: 0;
        list-style-type: none;
        margin: 0;

        li {
          padding-top: $horiz-gutter/2;
          padding-bottom: $horiz-gutter/2;
          margin-bottom: 0;
        }
      }
    }
  }
  @include breakpoint($tablet-portrait-max) {
    .listing-news,
    .listing-popular,
    .twitter,
    .promo {
      display: none;
    }
  }
}

/* Footer
   ========================================================================== */

#block-views-testimonials-block {
  @include clearfix;
  background-color: $testimonial-bgrd;
  color: #fff;
  margin-bottom: 0;
  @include breakpoint($tablet-landscape-min) {
    .views-row {
      width: 67%;
      float: left;
      padding: 60px $horiz-gutter * 3 60px 0;
      background: url("../images/testimonial-vert-rule.png") no-repeat right
        center;
      background-size: 3px auto;
      min-height: 218px;

      .views-field-field-testimonial-quote {
        font-size: 1.3em;
      }
    }

    .view-footer {
      width: 33%;
      float: left;
      padding: 60px 0 60px $horiz-gutter * 3;
    }

    .field-content {
      float: left;
      width: 550px;
    }
  }
  @include breakpoint($tablet-portrait-min $tablet-landscape-max) {
    .views-row {
      width: 60%;
      float: left;
      padding: 60px 60px 60px 0;

      .views-field-body {
        font-size: 1.3em;
      }
    }

    .view-footer {
      width: 40%;
      float: left;
      padding: 35px 0 35px $horiz-gutter * 2;
      background: url("../images/testimonial-vert-rule.png") no-repeat left
        center;
      background-size: 3px auto;
    }

    .field-content {
      float: left;
      width: 90%;
    }

    div.testimonial-text {
      &::after {
        position: absolute;
        bottom: 3px;
        right: -40px;
      }
    }
  }
  @include breakpoint($tablet-portrait-max) {
    .views-row,
    .view-footer {
      width: 100%;
      padding: $horiz-gutter;
    }

    .views-field-field-testimonial-quote {
      font-size: 1.2em;
    }

    div.testimonial-text {
      &::after {
        position: absolute;
        bottom: 3px;
        right: -20px;
      }
    }
  }

  .views-field-field-testimonial-quote {
    line-height: 1.7em;

    .field-content {
      position: relative;
      top: 0;
      left: 10px;

      div.testimonial-text {
        position: relative;
        font-style: italic;
        display: inline-block;

        &::after {
          content: "";
          display: block;
          width: 30px;
          height: 24px;
          background: url("../images/testimonial-endquote.png") no-repeat center;
          background-size: 100%;
          float: right;
          margin-left: 10px;
        }
      }

      p.indent {
        position: relative;
        left: 40px;
      }
    }
  }

  .view-footer {
    font-size: 1.6em;
    line-height: 1.4em;
    font-style: italic;
    text-align: center;

    img {
      width: 180px;
    }

    img,
    p {
      position: relative;
      top: -6px;
    }

    a {
      color: #fff;
      font-weight: normal;
    }
  }
}

#footer-wrapper {
  clear: both;
  background-color: $footer-bgrd;
}

.not-front #footer-wrapper {
  margin-top: $horiz-gutter * 2;
}

#footer {
  @include clearfix;
  font-size: 0.9em;
  padding-top: $horiz-gutter * 3;

  a {
    color: $body-font-color !important;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }
  }
  @include breakpoint($tablet-portrait-min) {
    #block-menu-block-3 {
      width: 20%;
      float: left;
      padding-right: $horiz-gutter * 2;
    }

    #block-menu-block-2 {
      width: 60%;
      float: left;
    }

    #block-menu-block-4 {
      width: 20%;
      float: left;
      padding-left: $horiz-gutter * 2;
    }
  }

  #block-menu-block-2 {
    ul {
      li {
        font-weight: bold;
        margin-bottom: $horiz-gutter;

        ul {
          li {
            font-weight: normal;
            margin-bottom: 0;
          }
        }
      }
      @include breakpoint($tablet-portrait-min) {
        -webkit-column-count: 3; /* Chrome, Safari, Opera */
        -moz-column-count: 3; /* Firefox */
        column-count: 3;
        -webkit-column-gap: $horiz-gutter; /* Chrome, Safari, Opera */
        -moz-column-gap: $horiz-gutter; /* Firefox */
        column-gap: $horiz-gutter;

        li {
          list-style-image: none;
          list-style-type: none;
          -webkit-column-break-inside: avoid; /* Chrome, Safari */
          page-break-inside: avoid; /* Theoretically FF 20+ */
          break-inside: avoid-column; /* IE 11 */
          display: table;

          ul {
            margin: 0;
            -webkit-column-count: 1; /* Chrome, Safari, Opera */
            -moz-column-count: 1; /* Firefox */
            column-count: 1;

            li {
              width: 100%;
              float: none;
              padding: 0;
              line-height: 1.2em;
              margin-top: $horiz-gutter/2;
            }
          }
        }
      }
    }
  }

  #block-block-7 {
    clear: both;
  }

  #block-block-2 {
    @include clearfix;
    margin-bottom: 0;
    padding-bottom: 2em;
    @include breakpoint($tablet-portrait-min) {
      width: 20%;
      float: right;
      padding-left: $horiz-gutter * 2;

      .cc-social {
        a {
          background-size: auto 100%;
          background-position: left center;
          padding-left: 40px;
          line-height: 30px;
          width: 100%;
          text-indent: 0;
        }
      }
    }
  }
}

#block-block-1 {
  @include clearfix;
  background-color: #fff;
  margin-bottom: 0;
  @include breakpoint($tablet-portrait-min) {
    padding: 3em 16em;
  }
  @include breakpoint($tablet-portrait-max) {
    padding-top: 3em;
  }

  .float {
    @include breakpoint($tablet-portrait-min) {
      width: 33.33%;
      padding: 0 30px;

      #nea {
        margin-top: 20px;
        margin-bottom: 22px;
      }
    }
    @include breakpoint($tablet-portrait-max) {
      margin-bottom: 4em;

      img {
        max-width: 150px;
      }
    }
    text-align: center;
  }
}

#block-menu-menu-footer-links {
  @include breakpoint($tablet-portrait-min) {
    width: 50%;
    float: left;
  }
  margin-bottom: 0;
  padding: 1px 6em 3em;

  h2 {
    color: #555;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

#block-views-faq-block,
#block-views-research-block,
#block-views-books-block-1,
#block-views-books-block,
#block-views-web-resources-block {
  .views-field-title {
    color: $header-blue;
    font-size: 1.2em;

    a {
      color: $header-blue;
    }
    // &:before {
    //   content:"•";
    //   color:$cc-dark-orange;
    //   padding-right:10px;
    // }
  }

  .views-row {
    margin-bottom: 0.75em;
  }

  .view-footer {
    font-size: 1.2em;
  }
}

#block-block-3,
#block-block-4,
.funding-credit,
.view-course-intro .view-footer {
  @include breakpoint($tablet-portrait-max) {
    @include clearfix;
  }

  h2 {
    font-size: 1.7rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  div {
    width: 100%;
    clear: both;
    @include breakpoint($tablet-portrait-max) {
      width: 49%;
      float: left;
      clear: none;

      &.partner-aft {
        margin-right: 1%;
      }

      &.partner-nea {
        margin-left: 1%;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      padding: 1px;
      max-width: 200px;
    }

    p {
      padding: $horiz-gutter;
      font-size: 14px;
      line-height: 1.6em;
    }

    &.partner-aft {
      // border-radius:4px;
      margin-top: 0;
      margin-bottom: 12px;
      border: 1px solid #194997;

      p {
        background-color: #194997;
        color: #fff;

        a {
          color: #fff !important;
          border-bottom: none !important;
        }
      }

      &::after {
        content: "";
        clear: both;
        display: block;
      }
    }

    &.partner-nea {
      // border-radius:4px;
      border: 1px solid #22489a;
      color: #22489a;

      a {
        color: #22489a !important;
        border-bottom: none !important;
      }
    }
  }
}

.not-front {
  #block-block-3,
  #block-block-4,
  .funding-credit {
    div {
      p {
        font-size: 11px;
        line-height: 1.4em;
        padding: 10px;
      }
    }

    img {
      &#aft {
        max-width: 35%;
      }
    }
  }
}

#block-block-3 img,
#block-block-4 img,
.funding-credit img {
  &#aft {
    max-width: 45%;
    padding: 12px 0;
  }
}

@include breakpoint($tablet-portrait-max) {
  .front {
    #block-block-3,
    #block-block-4,
    .funding-credit {
      div {
        padding-bottom: 200px;
        margin-bottom: -200px;

        p {
          padding-bottom: 200px;
          margin-bottom: -200px;
        }

        img#aft {
          width: 100%;
          max-width: 85px;
        }

        img#nea {
          width: 100%;
          max-width: 250px;
        }

        &.partner-nea {
          p {
            background-color: #194997;
            color: #fff;

            a {
              color: #fff !important;
              border-bottom: none !important;
            }
          }
        }
      }
    }
  }

  .not-front {
    #block-block-3,
    #block-block-4,
    .funding-credit {
      .partner-aft,
      .partner-nea {
        height: 150px;
        overflow: hidden;

        p {
          padding-bottom: 200px;
          margin-bottom: -200px;
        }

        img#aft {
          width: 100%;
          max-width: 85px;
        }

        img#nea {
          width: 100%;
          max-width: 250px;
        }

        &.partner-nea {
          p {
            background-color: #194997;
            color: #fff;

            a {
              color: #fff !important;
              border-bottom: none !important;
            }
          }
        }
      }
    }
  }
}

/* Instant Click Bar
   ========================================================================== */

#instantclick-bar {
  background: $primary-color;
}

.node-type-cc-book {
  .node-cc-book {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .field-book-illustrator {
      margin-bottom: $horiz-gutter*2;
    }

    &.hide-audiobook {
      .field-book-audiobook {
        display: none;
      }
    }

    .field-bookshop {
      display: none;
    }

    .group-page-bottom {
      width: 100%;
    }

    @include breakpoint($tablet-portrait-min) {
      .group-page-bottom {
        width: 75%;
        padding-right: $vert-gutter;
      }
    }
  }
}

.views-bookfinder-button-block {
  width: 100%;

  @include breakpoint($tablet-portrait-min) {
    width: 25%;
  }
}

.bookfinder-button {
  background: $teal;
  padding: $horiz-gutter*2 $horiz-gutter;
  border-radius: 8px;
  position: relative;

  a {
    display: block;
    color: #fff;
    border-bottom: none;
    font-weight: 700 !important;
    width: 70%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  &::after {
    content: "";
    background-image: url("../images/teal-flower.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 25%;
    height: 90%;
    position: absolute;
    right: 0;
    bottom: 0;

    @include breakpoint($tablet-portrait-min) {
      width: 50%;
      height: 90%;
    }
  }

  &:hover,
  &:focus {
    transition: 0.2s all ease-in-out;
    box-shadow: rgb(187, 187, 187) 0px 0px 8px;
  }

  @include breakpoint($tablet-portrait-min) {
    padding: $horiz-gutter;
  }
}

.links.inline {
  position: relative;
  z-index: 4;
  float: right;
  text-align: right;

  @include breakpoint($tablet-portrait-min) {
    width: 32%;
    text-align: left;
    padding-left: $horiz-gutter;
  }
}
