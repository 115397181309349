/* @partial normalize */

///////////////////////////////////////////////////////////////////////////////////
// NOTE: Normalize.css is intended to be used as an alternative to CSS resets.
// This is a customized normalize file. Some css has moved to forms and typography.
///////////////////////////////////////////////////////////////////////////////////

/* HTML5 display definitions
   ========================================================================== */

/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */

audio,
canvas,
video {
  display: inline-block;
}

/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */

[hidden] {
  display: none;
}

/* Address styling not present in IE 8/9, Safari 5, and Chrome. */
abbr[title] {
  border-bottom: 1px dotted;
}

/* Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome. */
b,
strong {
  font-weight: bold;
}

/* Address styling not present in Safari 5 and Chrome. */
dfn {
  font-style: italic;
}

/* Address differences between Firefox and other browsers. */
hr {
  @include box-sizing(content-box);
  height: 0;
  border: 1px solid #666;
  padding-bottom: -1px;
  margin: $vert-gutter 0;
}

/* Address styling not present in IE 8/9. */
mark {
  background: #ff0;
  color: #000;
}

/* Correct font family set oddly in Safari 5 and Chrome. */
code,
kbd,
pre,
samp,
tt,
var {
  font-family: $font-monospace; // The value of $font-monospace ends with ", serif".
  @include adjust-font-size-to($base-font-size);
}

/* Improve readability of pre-formatted text in all browsers. */
pre {
  white-space: pre-wrap;
}

/* Set consistent quote types. */
q {
  quotes: "\201C""\201D""\2018""\2019";
}

/* Address inconsistent and variable font size in all browsers. */
small {
  font-size: 80%;
}

/* Prevent `sub` and `sup` affecting `line-height` in all browsers. */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

/* Links
   ========================================================================== */

/*
 * The order of link states are based on Eric Meyer's article:
 * http://meyerweb.com/eric/thoughts/2007/06/11/who-ordered-the-link-states
 */

a {
  color: $link-color;
  text-decoration: $link-text-decoration;
  @include transition-property(color);
  @include transition-duration(0.1s);
  @include transition-timing-function(ease-in);
  // font-weight:700;
  border-bottom: 1px dotted $link-color;
}

a:visited {
  color: $link-color-visited;
  text-decoration: none;
}

a:hover {
  color: $link-color-hover;
  text-decoration: none; /* ie8 */
  cursor: pointer;
}

a:focus {
  color: $link-color-focus;
  text-decoration: none; /* ie8 */
}

a:active {
  color: $link-color-active;
  text-decoration: none;
}

/*
 * Addresses outline displayed oddly in Chrome
 */

a:focus {
  outline: thin dotted;
}

/*
 * Addresses outline displayed oddly in Chrome
 */

a:focus {
  outline: thin dotted;
}

/*
 * Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test
 */

a:hover,
a:active {
  outline: 0;
}

/* Input placeholder color
  ========================================================================== */

::-webkit-input-placeholder {
  color: $placeholder-color;
  &:focus {
    color: $placeholder-focus-color;
  }
}

// Make disappear on focus in iOS... This works in Chrome as opposed to the above focus style
:focus::-webkit-input-placeholder {
  color: $placeholder-focus-color;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $placeholder-color;
  &:focus {
    color: $placeholder-focus-color;
  }
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $placeholder-color;
  &:focus {
    color: $placeholder-focus-color;
  }
}

:-ms-input-placeholder {
  color: $placeholder-color;
  &:focus {
    color: $placeholder-focus-color;
  }
}

/* Text highlight color
  ========================================================================== */

::selection {
  background: $link-color; /* Safari */
  color: lighten($link-color, 60%);
}

::-moz-selection {
  background: $link-color; /* Firefox */
  color: lighten($link-color, 60%);
}

/* Lists
   ========================================================================== */

/*
 * Addresses margins set differently in IE6/7
 */

dl,
menu,
ol,
ul {
  margin: $vert-gutter/3 0 $vert-gutter/2;
}

#content {
  ul:not(.inline):not(.slick-dots) {
    list-style-type: none;
    padding: 0;
    li {
      text-indent: -46px;
      list-style-position: inside;
      padding-left: 27px;
      &:before {
        content: "•";
        color: #99003a;
        padding-right: 8px;
        margin-left: $horiz-gutter * 2;
      }
    }
  }
  ol li:before {
    display: none;
  }
}

ul {
  &.links,
  &.tabs {
    li {
      text-indent: 0 !important;
      padding-left: 0 !important;
      &:before {
        display: none !important;
      }
    }
  }
  &.links {
    li {
      margin-left: 12px;
    }
  }
  &.links.inline {
    li {
      margin-left: 0 !important;
    }
  }
  &.pager {
    li {
      padding-left: 0 !important;
    }
  }
}

ul.contextual-links,
ul.krumo-node {
  li {
    text-indent: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    &:before {
      display: none !important;
    }
  }
}

ul li p {
  text-indent: 0;
}

ul.subheads {
  li {
    font-weight: 700;
    p {
      font-weight: 300;
      padding-left: 2px;
    }
  }
}

.sidebar,
.group-sidebar {
  .node-title-list {
    li {
      text-indent: 0 !important;
      padding-left: 0 !important;
      &:before {
        display: none;
      }
    }
  }
}

ul.inline {
  /* List of links generated by theme_links(). @Drupal override */
  display: inline;
  padding: 0;

  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0; /* LTR */
  }
}

.item-list ul li {
  /* @Drupal override */
  margin: 0;
}

dd {
  margin: 0 0 0 $indent-amount; /* LTR */
}

ul,
ol,
.item-list ul,
.item-list ol {
  ul,
  ol {
    margin: 0; /* Turn off margins on nested lists. */
  }
}

/*
 * Addresses paddings set differently in IE6/7
 */

menu,
ol,
ul,
.item-list ul /* Drupal override */ {
  padding: 0 0 0 $indent-amount; /* LTR */
  li {
    margin-bottom: 7px;
  }
}

/*
 * Corrects list images handled incorrectly in IE7
 */

nav ul,
nav ol, /* Remove list bullets from standard Drupal */ .menu-block-wrapper, ul.links, ul.menu {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

/* Embedded content
   ========================================================================== */

/**
 * Embedded content and figures
 *
 * @todo Look into adding responsive embedded video.
 */
img {
  /* Remove border when inside `a` element in IE 8/9. */
  border: 0;

  /* Suppress the space beneath the baseline */
  vertical-align: bottom;

  /* Responsive images */
  max-width: 100%;
  height: auto;
}

.ie8 img {
  max-width: none; /* ie8 needs "width: auto;" and "max-width: none;" for ie8 only */
  width: auto;
}

/* Responsive video embed. Youtube / Vimeo */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
 * Corrects overflow displayed oddly in IE9
 */

svg:not(:root) {
  overflow: hidden;
}

/* Figures
   ========================================================================== */

/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */

figure {
  margin: 0;
}

/* Accessibility features
 ========================================================================== */

/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
%element-invisible {
  @include element-invisible;
}

/* Turns off the element-invisible effect. */
%element-invisible-off {
  @include element-invisible-off;
}

.element-focusable,
%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}
